import { getBox2ShelfSuggests, getSuggestsByStatusAndType } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { ScheduleTypeEnum } from '@/models/Product';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import OrderEvent from '@/models/events/OrderEvent';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import ClientOrder from '@/models/orders/ClientOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { getOrderNumber } from '@/utils';
import dayjs from 'dayjs';

interface Conditions {
  fragile: boolean;
  // флаг, что показывает, что нужно отправить сигнал на согласование
  confirm_assembled_products: boolean;
}

export default class OrderOrder extends ClientOrder {
  // специфично для клиентского заказа, если null - паузы нет. если строка - время до которого выставлена пауза(по времени бека)
  public paused_until: string | null = null;
  public isOrderPaused: boolean = false;
  public type: OrderTypeEnum.order = OrderTypeEnum.order;
  public conditions: Conditions = { fragile: false, confirm_assembled_products: false };

  constructor(data: any) {
    super(data);
    this.paused_until = data.paused_until;
    this.conditions = data.conditions;
    if (data.paused_until) {
      this.isOrderPaused = this.isPaused();
      if (this.isOrderPaused) {
        this.startPauseTimeout();
      }
    }
  }

  updateFromEvent(event: OrderEvent) {
    this.self.paused_until = event.paused_until;
    this.self.isOrderPaused = this.isPaused();
    if (this.self.isOrderPaused) {
      this.startPauseTimeout();
    }
    return super.updateFromEvent(event);
  }

  private startPauseTimeout() {
    setTimeout(() => {
      this.updateOrderPause();
    }, this.getPausedTime() + 1000);
  }

  private updateOrderPause() {
    const order = useOrders().orderById(this.order_id) as any as OrderOrder;
    if (order) {
      order.isOrderPaused = this.isPaused();
    }
  }

  isPaused(): boolean {
    return Boolean(this.paused_until && dayjs().add(useUser().timeOffset) < dayjs(this.paused_until));
  }

  getPausedTime(): number {
    if (this.paused_until) {
      // проверяем наличие паузы, если она стоит, то блокируем кнопку. так как текущее время не реактивно
      // запускаем пересчет этого свойства после истечения времени
      if (dayjs().add(useUser().timeOffset) < dayjs(this.paused_until)) {
        return dayjs(this.paused_until).diff(dayjs().add(useUser().timeOffset));
      }
    }
    return 0;
  }

  get hasFragile() {
    return this.conditions.fragile;
  }

  // ts сходит с ума. хз пока как поправить
  get self() {
    if (this._self) {
      return this._self;
    }
    this._self = useOrders().orderById(this.order_id) as OrderOrder;
    return this._self;
  }

  get requiredActionsCount() {
    if (this.target === 'canceled') {
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.request, SuggestTypeEnum.box2shelf).length;
    }
    if (this.status === 'processing') {
      return this.suggests.filter(suggest => !suggest.isPackaging).filter(s => s.status !== 'done').length;
    }
    return super.requiredActionsCount;
  }

  get allActionsCount() {
    if (this.target === 'canceled') {
      return getBox2ShelfSuggests(this.suggests).length;
    }
    if (this.status === 'processing') {
      return this.suggests.filter(suggest => !suggest.isPackaging).length;
    }
    return super.allActionsCount;
  }

  get title() {
    if (this.target === 'canceled') {
      return `${$gettext('Возврат товара на полки')} ${getOrderNumber(this)}`;
    }
    if (this.courier?.related_orders.length! >= 2) {
      const order_ids = [...this.courier!.related_orders, this.order_id].sort();
      const idx = order_ids.findIndex(id => id === this.order_id);
      const part = idx + 1;

      return $gettext('%{part}-ая часть мультизаказа %{number}', {
        part: String(part),
        number: getOrderNumber(this),
      });
    }

    return `${$gettext('Заказ')} ${getOrderNumber(this)}`;
  }

  get isRover() {
    return this.courier?.type === 'rover';
  }

  get hasBlockedSuggests() {
    return this.suggests.some(s => s.status === SuggestStatusEnum.blocked);
  }

  get hasOnlyBlockedSuggests() {
    return (
      this.suggests.some(s => s.status === SuggestStatusEnum.blocked) &&
      this.suggests.every(s => s.status === SuggestStatusEnum.blocked || s.status === 'done')
    );
  }

  get hasKitchenSuggests() {
    // Кофе не всегда готовится на кухне, на некоторых складах оно готовится прямо на складе кладовщиком. в таком случае мы не хотим воспринимать кофе как ГЕ
    return this.suggests.some(
      s =>
        s.shelf?.type === 'kitchen_on_demand' &&
        (s.product?.schedule_type !== ScheduleTypeEnum.coffee ||
          useUser().experimentByName(experiments.coffee_machine_in_the_kitchen)),
    );
  }

  get hasCoffee() {
    // Имеется в виду есть ли в заказе кофе, что должен готовить сборщик.
    if (useUser().experimentByName(experiments.coffee_machine_in_the_kitchen)) return false;
    return this.suggests.some(
      s => s.shelf?.type === 'kitchen_on_demand' && s.product?.schedule_type === ScheduleTypeEnum.coffee,
    );
  }

  protected sorter(suggests: Suggest[]): Suggest[] {
    // саджесты на выдачу кухни всегда в самом конце.сортируем сперва по типу полки, потом по ордеру.
    const shelfTypeSorter = (a: Suggest, b: Suggest): -1 | 0 | 1 => {
      if (!a.shelf || !b.shelf) return 0;
      switch (true) {
        case a.shelf.type === 'kitchen_on_demand' && b.shelf.type === 'kitchen_on_demand':
          return 0;
        case a.shelf.type === 'kitchen_on_demand':
          return 1;
        case b.shelf.type === 'kitchen_on_demand':
          return -1;
        default:
          return 0;
      }
    };

    const shelfOrderSorter = (a: Suggest, b: Suggest): number => {
      if (!a.shelf || !b.shelf) return 0;
      return a.shelf.order - b.shelf.order;
    };

    const samplingSuggestSorter = (a: Suggest, b: Suggest): number => {
      // если эксп включен
      if (useUser().experimentByName(experiments.exp_sten_gustaf_thulin)) {
        // сортируем саджесты с семлпами в самый конец
        return Number(a.isSample) - Number(b.isSample);
      }
      // иначе просто сохраняем тот порядок, который был
      return 0;
    };

    return suggests.sort((a, b) => samplingSuggestSorter(a, b) || shelfTypeSorter(a, b) || shelfOrderSorter(a, b));
  }

  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.totalWeight > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}

export const isOrderOrder = (o: BaseOrder): o is OrderOrder => {
  return o.type === OrderTypeEnum.order;
};
