<template>
  <div>
    <div class="settings-row" @click="onRoleSettingsClick">
      <Typography>
        {{ $gettext('Роль в системе') }}
      </Typography>
      <div class="flex items-center">
        <Typography class="value" color="secondary-text" margin="0 10px 0 0">
          {{ currentRole }}
        </Typography>
        <div>
          <IconChevron v-if="canChangeRole" color="text-primary" />
        </div>
      </div>
    </div>
    <RoleUpgrade v-if="upgradeRole.visible.value" @close="upgradeRole.hide" />
  </div>
</template>

<script lang="ts">
import { useComponent } from '@/hooks/useComponent';
import { useUser } from '@/store/modules/user';
import { permits } from '@/temp/constants';
import IconChevron from '@/temp/icons/icon-chevron.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Typography from '@/ui/common/typography.vue';
import RoleUpgrade from '@/ui/home/role-upgrade/role-upgrade.vue';
import { checkPermit } from '@/utils/checkPermit';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Role',
  components: {
    IconChevron,
    Typography,
    RoleUpgrade,
  },
  props: {
    upgrade: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { showLoader } = useLoader();
    const userStore = useUser();
    const upgradeRole = useComponent();

    return { showLoader, userStore, upgradeRole };
  },
  computed: {
    canChangeRole(): boolean {
      return checkPermit(permits.tsd_upgrade) || checkPermit(permits.tsd_downgrade);
    },
    downgradeTitle(): string {
      return this.$gettext('Вы уверены, что хотите изменить свою роль в системе до обычной?');
    },
    downgradeText(): string {
      return this.$gettext(
        'После изменения некоторые функции будут недоступны. Если в будущем вы захотите изменить свою роль на повышенную, то вам нужно будет ввести личный код',
      );
    },
    currentRole(): string {
      if (this.userStore.permitByName(permits.tsd_upgrade)) {
        return this.$gettext('Обычная');
      }
      if (this.userStore.permitByName(permits.tsd_downgrade)) {
        return this.$gettext('Повышенная');
      }
      return this.$gettext('Повышенная');
    },
  },
  mounted() {
    if (this.upgrade) this.onRoleSettingsClick();
  },
  methods: {
    onRoleSettingsClick(): void {
      if (this.userStore.permitByName(permits.tsd_upgrade)) {
        this.upgradeRole.show();
      }
      if (this.userStore.permitByName(permits.tsd_downgrade)) {
        this.$notification.universalModal({
          title: this.downgradeTitle,
          titleAlign: 'center',
          text: this.downgradeText,
          textAlign: 'center',
          position: 'bottom',
          buttons: [
            {
              title: this.$gettext('Нет'),
              color: 'primary',
              dataTest: 'confirm decline-btn',
              onClick: () => false,
            },
            {
              title: this.$gettext('Да'),
              color: 'secondary',
              dataTest: 'confirm ok-btn',
              onClick: () => {
                this.downgradeRole();
              },
            },
          ],
        });
      }
    },
    async downgradeRole(): Promise<void> {
      const { closeLoader } = this.showLoader();
      try {
        await this.userStore.downgrade();
        this.$notification.success.micro(this.$gettext('Ваша роль была успешно изменена'));
      } catch (error) {
        this.$notification.error.micro(this.$gettext('Произошла ошибка изменения роли'));
      } finally {
        closeLoader();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.settings-row {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
}
</style>
