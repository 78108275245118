import { EndOptions, endOrder } from '@/fsd/data/utils/endOrder';
import retryEndOrder from '@/fsd/entities/modals/retryEndOrder';
import BaseOrder from '@/models/orders/BaseOrder';
import { useIndicators } from '@/store/indicators';
import { useOrders } from '@/store/modules/orders';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { useLoader } from '@/ui/common/loader/useLoader';
import { AxiosError } from 'axios';
import { merge } from 'lodash';

export const useEndOrder = async (order_id?: BaseOrder['order_id'], options?: EndOptions) => {
  const { showLoader } = useLoader();

  let loader: ReturnType<typeof showLoader>;

  const defaultOptions: EndOptions = {
    onValidateError: e => Notifications.modal({ title: $gettext('Не удалось завершить задание'), text: e }),
    beforeRequest: () => (loader = showLoader($gettext('Отправляем запрос на завершение задания'), order_id)),
    afterRequest: () => {
      useIndicators().clearApiErrors();
      loader?.updateLoader($gettext('Ожидаем ответ сервера'));
    },
    onRequestError: async (e, retry) => {
      const { response } = e as AxiosError;
      if (response?.status === 410) {
        Notifications.success.micro($gettext('Задание завершено'));
        useOrders().removeOrder(order_id!);
        return true;
      }
      if (response?.data?.code === 'ER_WMS_ONLY_ORDER') {
        Notifications.modal({
          title: $gettext('Подсчет стеллажа завершен'),
          text: $gettext('Требуется ручное завершение подсчета в диспетчерской'),
        });
        return true;
      }
      const confirmed = await retryEndOrder(e);
      if (!confirmed) return false;
      return retry();
    },
    beforeEventWaiting: () => {},
    afterEventWaiting: () => Notifications.success.micro($gettext('Задание завершено')),
    onEventWaitingError: e => {
      Notifications.modal({
        title: $gettext('Не удалось завершить задание'),
        text: e,
      });
    },
    closeLoader: () => loader?.closeLoader(),
  };

  return endOrder(order_id, merge({}, defaultOptions, options));
};

export type UseEndOrderOptions = EndOptions;
