import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { CheckMoreRequestDefault, OrderDoneCheckMoreRequest } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useLoader } from '@/ui/common/loader/useLoader';
import { defineComponent } from 'vue';

export interface CheckMorePayload {
  suggest: Suggest;
  product?: Product;
  count?: number;
  weight?: number;
  valid?: string;
  endInventory?: boolean;
  isChange?: boolean;
}

export default defineComponent({
  setup() {
    const { showLoader } = useLoader();
    return { showLoader };
  },
  methods: {
    createCheckMorePayload({
      suggest,
      product,
      count,
      weight,
      endInventory,
      isChange,
      valid,
    }: CheckMorePayload): OrderDoneCheckMoreRequest {
      const getReason = (valid, count): CheckMoreRequestDefault['reason'] | undefined => {
        if (valid && count) return { code: 'CHANGE_COUNT_VALID' };
        if (valid) return { code: 'CHANGE_VALID' };
        if (count) return { code: 'CHANGE_COUNT' };
        return undefined;
      };
      if (endInventory) {
        return {
          status: 'error',
          suggest_id: suggest.suggest_id,
        };
      }
      if (!product) {
        throw new Error('Для закрытия check_more в любой статус кроме error необходим целевой продукт!');
      }
      if (weight) {
        // похоже это нигде не работает
        return {
          suggest_id: suggest.suggest_id,
          product_id: product.product_id,
          weight,
          valid,
          reason: isChange ? getReason(count, valid) : undefined,
        };
      }
      return {
        suggest_id: suggest.suggest_id,
        product_id: product.product_id,
        count,
        valid,
        reason: isChange ? getReason(count, valid) : undefined,
      };
    },

    async check_more(option: CheckMorePayload): Promise<any> {
      const payload: OrderDoneCheckMoreRequest = this.createCheckMorePayload(option);
      const { closeLoader } = this.showLoader(this.$gettext('Отправляем информацию о товаре на сервер'));
      try {
        await useOrders().checkMore(payload);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        closeLoader();
      }
    },
  },
});
