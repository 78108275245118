<template>
  <div class="flex flex-col absolute bg-main p-2 pt-5 rounded-t-3xl bottom-0 w-full pointer-events-auto">
    <span class="text-primary font-bold text-3xl mb-5 px-2">
      {{ $gettext('Выберите пункт') }}
    </span>
    <div class="flex flex-wrap">
      <UiButton
        v-for="config in menuConfigFiltered"
        :key="config.buttonText"
        :data-test="config.dataTest"
        class="m-1"
        background-color="secondary"
        @click="() => click(config)"
      >
        <div v-if="config.buttonSecondText" class="second-btn-text">
          {{ config.buttonSecondText }}
        </div>
        {{ config.buttonText }}
      </UiButton>
      <UiButton class="m-1" data-test="menu close-btn" background-color="primary" @click="close">
        {{ $gettext('Закрыть') }}
      </UiButton>
    </div>
  </div>
</template>

<script lang="ts">
import type { MenuItemConfig } from '@/ui/common/menu/types';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    UiButton,
  },
  props: {
    menuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    menuConfigFiltered(): MenuItemConfig[] {
      return this.menuConfig.filter(menuItem => {
        if (!menuItem.condition) {
          return true;
        }
        return menuItem.condition();
      });
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    click(config) {
      config.onClick();
      this.close();
    },
  },
});
</script>

<style lang="scss" scoped>
.second-btn-text {
  font-size: 13px;
  color: var(--text-secondary-color);
  margin-bottom: 2px;
}
</style>
