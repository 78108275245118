import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { $gettext } from '@/temp/plugins/gettext';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import { getClosedPackage } from './getClosedPackage';
import { getProductTail } from './getProductTail';

interface OptionType {
  product: Product;
  shelf: Shelf;
  count: number;
  weight?: number;
  order?: BaseOrder;
}

/**
 * Получить строку, которая разбивает количество продуктов по квантам.
 * @constructor
 */
export const getValueForView = (option: OptionType): string => {
  const { product, shelf, count, weight, order } = option;
  if (product.isProductQuant) {
    if (
      shelf.isKitchenShelf &&
      (!order ||
        ![OrderTypeEnum.hand_move, OrderTypeEnum.sale_stowage, OrderTypeEnum.kitchen_provision].includes(order.type))
    ) {
      return $gettext(`%{count} уп. %{tail} %{tail_unit}`, {
        count: String(getClosedPackage(product, count)),
        tail: String(getProductTail(product, count)),
        tail_unit: getQuantUnit(product.quant_unit, getProductTail(product, count)),
      });
    }
    if (product.quant_unit) {
      return $gettext('%{count} уп.', {
        count: String(count),
      });
    }
  }
  // Если это не компонент и саджест не на полку ПФ или квант юнит не задан, то отображаем штуки
  return getCountToView({ count, type_accounting: product.type_accounting, weight });
};
