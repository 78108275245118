import { getRequestSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import BaseOrder from '@/models/orders/BaseOrder';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import { getOrderNumber } from '@/utils';
import { uniq } from 'lodash';
import { computed } from 'vue';

export default class HandMoveOrder extends BaseOrder {
  _requiredActionsCount = computed(() => {
    if (this.status === 'request') {
      return uniq(this.required.map(r => (r as any).product_id)).length || 0;
    }
    if (this.target === 'canceled') {
      return uniq(getRequestSuggests(this.suggests).map(s => s.product_id)).length || 0;
    }
    if (this.status === 'processing') {
      return uniq(getRequestSuggests(this.suggests).map(s => s.product_id)).length || 0;
    }
    return 0;
  });

  _allActionsCount = computed(() => {
    if (this.status === 'request') {
      return uniq(this.required.map(r => (r as any).product_id)).length || 0;
    }
    if (this.target === 'canceled') {
      return uniq(this.suggests.map(s => s.product_id)).length || 0;
    }
    if (this.status === 'processing') {
      return uniq(this.suggests.map(s => s.product_id)).length || 0;
    }
    return 0;
  });

  get title() {
    return `${$gettext('Ручное перемещение')} №${getOrderNumber(this)}`;
  }

  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.totalWeight > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}
