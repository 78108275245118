<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4701_6927)">
      <mask id="mask0_4701_6927" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
        <path
          d="M0 5.76C0 3.74381 0 2.73572 0.392377 1.96563C0.737521 1.28825 1.28825 0.737521 1.96563 0.392377C2.73572 0 3.74381 0 5.76 0L6.24 0C8.25619 0 9.26428 0 10.0344 0.392377C10.7117 0.737521 11.2625 1.28825 11.6076 1.96563C12 2.73572 12 3.74381 12 5.76V6.24C12 8.25619 12 9.26428 11.6076 10.0344C11.2625 10.7118 10.7117 11.2625 10.0344 11.6076C9.26428 12 8.25619 12 6.24 12H5.76C3.74381 12 2.73572 12 1.96563 11.6076C1.28825 11.2625 0.737521 10.7118 0.392377 10.0344C0 9.26428 0 8.25619 0 6.24L0 5.76Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4701_6927)">
        <path
          d="M0 5.76C0 3.74381 0 2.73572 0.392377 1.96563C0.737521 1.28825 1.28825 0.737521 1.96563 0.392377C2.73572 0 3.74381 0 5.76 0L6.24 0C8.25619 0 9.26428 0 10.0344 0.392377C10.7117 0.737521 11.2625 1.28825 11.6076 1.96563C12 2.73572 12 3.74381 12 5.76V6.24C12 8.25619 12 9.26428 11.6076 10.0344C11.2625 10.7118 10.7117 11.2625 10.0344 11.6076C9.26428 12 8.25619 12 6.24 12H5.76C3.74381 12 2.73572 12 1.96563 11.6076C1.28825 11.2625 0.737521 10.7118 0.392377 10.0344C0 9.26428 0 8.25619 0 6.24L0 5.76Z"
          fill="#FCDA30"
        />
        <path
          d="M2.96182 2.92104L-2.47572 10.0521L-1.01538 11.7235L3.05162 6.36288L2.64578 9.2928L4.92411 10.0701L7.6366 5.64446C7.50503 6.4821 7.28753 8.37872 9.13508 8.93881C11.9865 9.80324 14.4666 4.68924 15.6323 2.07932L13.9882 1.21002C12.7192 3.88439 10.7209 6.87511 9.94129 6.65776C9.16573 6.44153 9.86724 3.93436 10.3197 2.31744L10.3267 2.29227L7.80575 1.43827L4.80354 6.36454L5.20914 3.69833L2.96182 2.92104Z"
          fill="#3D3C37"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4701_6927">
        <rect width="12" height="12" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
