<template>
  <div class="h-full p-4 bg-main box-border">
    <div class="rounded-[20px] h-full w-full flex flex-col bg-card">
      <!--Шапка-->
      <div class="flex pt-5">
        <div class="flex flex-col flex-1 items-center">
          <body3 class="mb-2" color="text-secondary">
            {{ $gettext('Раздел') }}
          </body3>
          <title4 color="text-primary" class="font-medium text-center" data-test="product shelf title">{{
            suggest && suggest.vars.product_group ? suggest.vars.product_group.name : ' - '
          }}</title4>
        </div>
        <div class="flex flex-col flex-1 items-center">
          <body3 class="mb-2" color="text-secondary">
            {{ $gettext('Количество') }}
          </body3>

          <title4
            :color="suggest && suggest.result_count ? 'lime-dark' : 'text-primary'"
            class="font-medium text-center"
            data-test="product count"
          >
            {{ count }}
          </title4>
        </div>
      </div>
      <!--Картинка-->
      <div class="relative flex flex-[2_1_0] m-8 justify-center h-48">
        <ImgFullScreen :img-src="product?.images[0]" :badges="suggest ? getImgBadge(suggest) : []" />
      </div>
      <div class="flex justify-center">
        <FragileBadge v-if="product?.fragile" class="mb-2" />
        <WeightBadge v-if="product?.isTrueWeight" :plu_code="product.plu_code" class="mb-2" />
      </div>
      <div class="flex flex-col justify-center items-center py-4 px-5">
        <body2 color="text-primary" class="font-medium text-center" data-test="product title">
          {{ product?.title }}
        </body2>
        <span v-if="suggest" class="mt-2">
          <body2 class="font-medium" color="day-textMinor">
            <SuggestPricePerCount :suggest="suggest" />
          </body2>
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ImgFullScreen } from '@/fsd/shared/ui/imgFullScreen';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import { getCountText, getCountTextFromAll } from '@/ui/common/suggest-card/formatters/count-formatter';
import FragileBadge from '@/ui/order/badges/fragile-badge.vue';
import WeightBadge from '@/ui/order/badges/weight-badge.vue';
import { computed, defineProps } from 'vue';
import { getImgBadge } from '../../tools/getImgBadge';
import SuggestPricePerCount from '../suggest-card-info/suggest-price-per-count.vue';

interface SuggestDetailsProps {
  suggest?: Suggest;
  product?: Product;
  collected: string[];
}

const props = defineProps<SuggestDetailsProps>();

const count = computed<string>(() => {
  if (props.suggest) return suggestCount(props.suggest);
  return productCount();
});

const suggestCount = (suggest: Suggest): string => {
  let result_count = suggest.result_count || props.collected.length;
  if (suggest.product?.isTrueWeight) {
    result_count = suggest.result_count || Product.weightFromBarcode(props.collected);
  }
  const count = suggest.count || 0;

  return getCountTextFromAll(!!props.suggest?.product?.isTrueWeight, result_count, count);
};

const productCount = (): string => {
  const count = props.product?.isTrueWeight ? Product.weightFromBarcode(props.collected) : props.collected.length;
  return getCountText(!!props.product?.isTrueWeight, count);
};
</script>
