<template>
  <Bar
    :order="order"
    :caption="product.title"
    :text="orderText"
    :icons="{ left: 'back' }"
    @close-click="$emit('back')"
  />
</template>

<script setup lang="ts">
import type OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Product from '@/models/Product';
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import { getOrderNumber } from '@/utils';
import { computed, defineEmits, defineProps } from 'vue';

interface HeaderRetailProps {
  order: OrderOrderRetail;
  product: Product;
}

const props = defineProps<HeaderRetailProps>();

const orderText = computed<string>(() => {
  if (!props.order) {
    return '';
  }
  return `${$gettext('Заказ')} ${getOrderNumber(props.order)}`;
});

defineEmits<{ (e: 'back'): void }>();
</script>
