import { api } from '@/fsd/data/api/api.service';
import { useUser } from '@/store/modules/user';
import { useLoader } from '@/ui/common/loader/useLoader';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const { showLoader } = useLoader();
    const router = useRouter();
    return { showLoader, router };
  },
  methods: {
    async detach(): Promise<void> {
      const { closeLoader } = this.showLoader();
      try {
        await api.order.executer({ order_id: (this as any).order.order_id, unlink: [useUser().userId] });
        this.$notification.success.micro('Вы откреплены от задания');
        this.router.push({ name: 'home' });
      } catch (error) {
        console.error(error);
      } finally {
        closeLoader();
      }
    },
  },
});
