<template>
  <div class="flex flex-col flex-1 items-center">
    <Body3 class="mb-2" :class="{ 'text-red-normal': problem }" color="text-secondary">
      <template v-if="problem"> {{ $gettext('На складе') }} </template>
      <template v-else> {{ $gettext('Кол-во') }} </template>
    </Body3>
    <div class="flex items-center">
      <title4
        color="text-primary"
        class="font-medium"
        :class="{
          'text-red-normal': problem,
          'text-lime-toxic': suggest.status === 'done',
        }"
        :data-test="`product count value ${index}`"
      >
        {{ productCountValue }}
      </title4>
      <title4 v-if="suggest?.max_count" color="text-secondary" class="ml-2">
        {{ delta }}
      </title4>
    </div>
  </div>
</template>

<script lang="ts">
import { Problem } from '@/models/orders/BaseOrder';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import Body3 from '@/ui/common/typo/body-3.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { Body3 },
  props: {
    suggest: {
      type: Object as PropType<Suggest>,
      default: () => {
        return {} as Suggest;
      },
    },
    problem: {
      type: Object as PropType<Problem>,
      default: undefined,
    },
    collected: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    productCountValue(): string {
      let totalCount = this.suggest?.count || 0;
      let count = this.collected;
      const isTrueWeightProduct = this.suggest?.product?.isTrueWeight;
      if (this.problem && this.suggest) {
        totalCount = this.suggest.count! + this.problem.count!;
        count = this.suggest.count!;
      }

      if (this.suggest?.status === SuggestStatusEnum.done) {
        count = this.suggest.result_count || this.suggest.count!;
      }

      if (isTrueWeightProduct) {
        const weight: number = this.suggest.count! || this.suggest.result_count!;
        if (weight > 1000) {
          return this.$gettext('%{count} из %{kg} кг', { count: String(count), kg: (weight / 1000).toFixed(2) });
        }
        return this.$gettext('%{count} из %{g} г', { count: String(count), g: String(weight) });
      }
      return this.$gettext('%{count} из %{all}', { count: String(count), all: String(totalCount) });
    },
    delta(): string {
      if (!this.suggest?.allowableError) {
        return '';
      }
      return this.$gettext('± %{g} г', { g: String(this.suggest.allowableError) });
    },
  },
});
</script>
