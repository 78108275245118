<template>
  <Layout>
    <template v-if="!loading" #header>
      <ProductCardHeader :product_id="product_id" />
    </template>
    <template #default>
      <div v-if="!loading" class="flex flex-col gap-2 pb-4">
        <ProductTitle :product_id="product_id" class="mx-4" />
        <ProductMainInfo :product_id="product_id" class="mx-4" />
        <template v-if="available?.length">
          <Separator />
          <ProductAvailable :product_id="product_id" class="mx-4" />
          <Separator />
          <ProductAvailableTotal :product_id="product_id" class="mx-4" />
        </template>
      </div>
      <div v-else class="h-full flex flex-col items-center justify-center">
        <LoaderIndicator class="mb-4" />
        <body1 class="mb-2">
          {{ $gettext('Загрузка') }}
        </body1>
        <caption1 class="mb-2">
          {{ $gettext('Пожалуйста, подождите') }}
        </caption1>
      </div>
    </template>
    <template v-if="!loading" #footer>
      <ProductCardFooter
        :product_id="product_id"
        @check="onCheckClick"
        @change_valid="onChangeValidClick"
        @move="onMoveClick"
        @writeoff="onWriteoffClick"
      />
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import { api } from '@/fsd/data/api/api.service';
import { useCheckProductResources } from '@/fsd/data/utils/checkResourse';
import ProductAvailable from '@/fsd/widgets/productCard/ProductAvailable.vue';
import ProductAvailableTotal from '@/fsd/widgets/productCard/ProductAvailableTotal.vue';
import ProductMainInfo from '@/fsd/widgets/productCard/ProductMainInfo.vue';
import ProductTitle from '@/fsd/widgets/productCard/ProductTitle.vue';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import productQueue from '@/services/queue/product-queue';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import IconBarcode from '@/temp/icons/icon-barcode.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import Layout from '@/ui/common/layout.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Separator from '@/ui/home/product-card/separator.vue';
import ProductCardFooter from '@/views/ProductCard/ProductCardFooter.vue';
import ProductCardHeader from '@/views/ProductCard/ProductCardHeader.vue';
import { computed, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  product_id: Product['product_id'];
}>();

const { product_id } = toRefs(props);

const router = useRouter();
const { showLoader } = useLoader();

let { loading } = useCheckProductResources(product_id.value, true);
watch(product_id, () => {
  const result = useCheckProductResources(product_id.value, true);
  loading = result.loading;
});

const product = computed(() => {
  return useProducts().productById(product_id.value);
});
watch(
  product,
  p => {
    if (!p) return;
    if (useUser().isJunior && p.underage_restricted) {
      Notifications.error.micro($gettext('ТОВАР 18+'));
      router.push({ name: 'home' });
    }
  },
  { immediate: true },
);

const available = computed(() => {
  return useProducts().availableByProductId(product_id.value);
});

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader($gettext('Запрашиваем продукт по штрихкоду'));
  try {
    const { data } = await api.barcode({ barcode });
    closeLoader();
    if (!data || !data.found.length) {
      Notifications.error.micro($gettext('Не найден штрихкод %{barcode}', { barcode }));
      return true;
    }
    const foundProducts = data.found.filter(ProductByBarcode.isProductByBarcode);
    const found = foundProducts[0];
    if (!found) return true;
    const scannedProduct = await filterMultiProductByBarcode(foundProducts, barcode);
    if (!scannedProduct) return true;

    if (useUser().experimentByName(experiments.exp_purple_hearts)) {
      const product = await productQueue.load(scannedProduct.product_id);
      // Отсканировали родителя
      if (product?.children_id) {
        await Notifications.modal({
          title: $gettext('Отсканируйте штрих-код дочернего товара'),
          text: $gettext(
            'Вы отсканировали родительский товар, для продолжения работы необходимо отсканировать код дочернего товара.',
          ),
          iconTop: {
            icon: IconBarcode,
            position: 'center',
          },
          buttonText: $gettext('Закрыть'),
        });
        return true;
      }
    }

    await router.push({ name: 'product-card', params: { product_id: scannedProduct.product_id } });
    return true;
  } catch (error) {
    closeLoader();
    Notifications.error.micro($gettext('Не найден штрихкод'));
    return true;
  }
});

const filterMultiProductByBarcode = async (found: ProductByBarcode[], barcode: string) => {
  if (found.length === 1) return found[0];
  logger.event('MULTI_PRODUCT_ON_BARCODE', barcode);
  try {
    for (const f of found) {
      const available = await useProducts().fetchAvailable(f.product_id);
      if (available && available.length > 0) return f;
    }
    Notifications.error.micro('По этому штрихкоду найдено несколько товаров, сообщите в поддержку');
    return found[0];
  } catch (error) {
    logger.error(error, { method: 'filterMultiProduct', type: 'home' });
  }
};
const onCheckClick = async () => {
  const confirmed = await Notifications.confirmCenter({
    title: $gettext('Начать задание?'),
    text: $gettext('После взятия задания в работу вам необходимо будет его выполнить'),
  });
  if (!confirmed) {
    return;
  }
  router.push({
    name: 'product-check-create',
    params: { product_id: product_id.value },
  });
};

const onChangeValidClick = () => {
  router.push({
    name: 'product-change-valid-create',
    params: { product_id: product_id.value },
  });
};

const onMoveClick = () => {
  router.push({
    name: 'product-move',
    params: { product_id: product_id.value },
  });
};
const onWriteoffClick = () => {
  router.push({
    name: 'product-writeoff',
    params: { product_id: product_id.value },
  });
};
</script>
