<template>
  <div data-test="order card">
    <OrderCard
      :type="cardType"
      :title="order.title"
      :actions="actionsCount"
      :number="docDate"
      :work-btn-disabled="toWorkBtnDisabled"
      :continue-btn-disabled="continueBtnDisabled"
      :need-work-btn="needToWorkButton"
      :status="order.status"
      :need-view-btn="needViewButton"
      :is-paused="order.isOrderPaused"
      :icons="icons"
      @continue-btn-click="continueOrder"
      @view-btn-click="viewOrder"
      @work-btn-click="workOrder"
    />
    <WaitingWindow v-if="waiting.visible.value" position="center" :timer="15" />
    <ShelfPickingRules
      v-if="shelfPickingRules.visible.value"
      :picking_method="shelfPickingRules.props.value"
      @close="shelfPickingRules.hide(false)"
      @start="shelfPickingRules.hide(true)"
    />
  </div>
</template>

<script lang="ts">
import { orderByRoverModal } from '@/fsd/entities/order/ui/order-card/additional-windows/order-by-rover-modal';
import ShelfPickingRules from '@/fsd/entities/order/ui/order-card/additional-windows/shelf-picking-rules.vue';
import WaitingWindow from '@/fsd/entities/order/ui/order-card/additional-windows/waiting-window.vue';
import OrderCard from '@/fsd/entities/order/ui/order-card/order-card.vue';
import { CardType, IconType } from '@/fsd/entities/order/ui/order-card/types';
import { useAckOrder, UseAckOrderOptions } from '@/fsd/features/order/utils/useAckOrder';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import { useComponent } from '@/hooks/useComponent';
import BaseOrder, { OrderTypeEnum, OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { isClientOrder } from '@/models/orders/ClientOrder';
import { isOrderOrder } from '@/models/orders/OrderOrder';
import OrderOrderRetail, { isOrderOrderRetail } from '@/models/orders/OrderOrderRetail';
import ShipmentOrder from '@/models/orders/ShipmentOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments, orderTypeUrls } from '@/temp/constants';
import { orderStatusesList } from '@/temp/constants/translations';
import { useLoader } from '@/ui/common/loader/useLoader';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HomeOrder',
  components: {
    ShelfPickingRules,
    OrderCard,
    WaitingWindow,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { showLoader } = useLoader();
    const waiting = useComponent();
    const shelfPickingRules = useComponent<OrderOrderRetail['shelfPickingMethod'], boolean>();
    const router = useRouter();

    return { showLoader, waiting, shelfPickingRules, router };
  },
  computed: {
    order(): BaseOrder {
      return useOrders().orderById(this.orderId) as BaseOrder;
    },
    docDate(): string {
      return this.$gettext('%{number} от %{date}', {
        number: this.orderNumber,
        date: this.order.date,
      });
    },
    orderNumber(): string {
      return this.order.attr.request_number || this.order.attr.doc_number || this.$gettext('без номера');
    },
    needToWorkButton(): boolean {
      if (this.onlyView) {
        // докумнт открытый только для просмотра, показываем из списка связанных с посылкой документов
        return false;
      }
      // приемки берем в работу только через экран просмотра
      if (this.order.type === 'acceptance') return false;
      return this.order.status === 'request';
    },
    needViewButton(): boolean {
      if (this.onlyView) {
        // докумнт открытый только для просмотра, показываем из списка связанных с посылкой документов
        return true;
      }
      const longOrderTypes = [
        'acceptance',
        'stowage',
        'sale_stowage',
        'weight_stowage',
        'check_more',
        'hand_move',
        'kitchen_provision',
        'inventory_check_more',
        'inventory_check_product_on_shelf',
        'check',
        'check_final',
      ];
      return longOrderTypes.includes(this.order.type) && this.order.status === 'request';
    },
    isPaused(): boolean {
      return this.order?.data?.isPaused;
    },
    isRover(): boolean {
      return this.order.isRover && !this.isPaused;
    },
    cardType(): CardType {
      if (this.isPaused || (isOrderOrder(this.order) && this.order.hasOnlyBlockedSuggests)) {
        return 'pause';
      }
      if (this.isRover) {
        return 'rover';
      }
      return 'default';
    },
    requestActionsCount(): string {
      switch (this.order.type) {
        case OrderTypeEnum.check_more:
        case OrderTypeEnum.inventory_check_more:
          return this.$ngettext('%{n} полка', '%{n} полок', this.order.requiredActionsCount, {
            n: String(this.order.requiredActionsCount),
          });

        case OrderTypeEnum.check_valid_regular:
        case OrderTypeEnum.check_valid_short:
        case OrderTypeEnum.writeoff_prepare_day:
        case OrderTypeEnum.order:
        case OrderTypeEnum.order_retail:
        case OrderTypeEnum.repacking:
        case OrderTypeEnum.writeoff:
          return '';
        case OrderTypeEnum.hand_move:
        case OrderTypeEnum.kitchen_provision:
          return this.$ngettext('%{n} позиция от %{date}', '%{n} позиций от %{date}', this.order.allActionsCount, {
            n: String(this.order.allActionsCount),
            date: this.order.date,
          });
        default:
          return this.$ngettext('%{n} товар', '%{n} товаров', this.order.requiredActionsCount, {
            n: String(this.order.requiredActionsCount),
          });
      }
    },
    canceledActionsCount(): string {
      switch (this.order.type) {
        case OrderTypeEnum.repacking:
          return '';
        default:
          return this.$ngettext(
            'Осталось вернуть %{request} из %{all} товар',
            'Осталось вернуть %{request} из %{all} товаров',
            this.order.allActionsCount,
            { request: String(this.order.requiredActionsCount), all: String(this.order.allActionsCount) },
          );
      }
    },
    processingActionsCount(): string {
      if (this.order.estatus === 'waiting_signal') {
        return this.$gettext('Ожидается завершение в диспетчерской');
      }
      switch (this.order.type) {
        case OrderTypeEnum.check_more:
          return this.$ngettext(
            'Осталось %{request} из %{all} полка',
            'Осталось %{request} из %{all} полок',
            this.order.allActionsCount,

            { request: String(this.order.requiredActionsCount), all: String(this.order.allActionsCount) },
          );
        case OrderTypeEnum.inventory_check_more:
          return this.$ngettext(
            'Осталось %{request} полка',
            'Осталось %{request} полок',
            this.order.requiredActionsCount,
            {
              request: String(this.order.requiredActionsCount),
            },
          );
        case OrderTypeEnum.order:
          if (isOrderOrder(this.order) && this.order.hasOnlyBlockedSuggests) {
            return this.$gettext('Блюда с кухни ещё готовятся');
          }
        // eslint-disable-next-line no-fallthrough
        case OrderTypeEnum.order_retail:
          if (isOrderOrder(this.order) && this.order.isOrderPaused) {
            return this.$gettext('Ожидайте решения поддержки');
          }
          if (
            this.order.vars.confirming_assembled_products &&
            !this.order.vars.fulfilled_conditions?.confirm_assembled_products
          ) {
            return this.$gettext('Идёт согласование');
          }
          if (this.order.vars.fulfilled_conditions?.confirm_assembled_products) {
            return this.$gettext('Согласование завершено');
          }
          if (this.order.requiredActionsCount === 0) {
            return this.$gettext('Все собрано');
          }

          return (
            this.$ngettext(
              'Осталось %{request} из %{all} товар',
              'Осталось %{request} из %{all} товаров',
              this.order.allActionsCount,
              {
                request: String(this.order.requiredActionsCount),
                all: String(this.order.allActionsCount),
              },
            ) +
            (this.order.problems?.length
              ? this.$ngettext('(%{n} проблема)', '(%{n} проблем)', this.order.problems.length, {
                  n: String(this.order.problems.length),
                })
              : '')
          );
        case OrderTypeEnum.repacking:
          return '';
        default:
          if (this.order.requiredActionsCount === 0) {
            return this.$gettext('Все собрано');
          }
          return this.$ngettext(
            'Осталось %{request} из %{all} товар',
            'Осталось %{request} из %{all} товаров',
            this.order.allActionsCount,
            {
              request: String(this.order.requiredActionsCount),
              all: String(this.order.allActionsCount),
            },
          );
      }
    },
    needCountActionInfo(): boolean {
      // Если не ордер, то показываем кол-во всегда
      if (!isOrderOrder(this.order)) return true;
      // Если документ в работе, то также показываем
      if (this.order.status === 'processing') return true;
      // Если включена очередь, то показываем для первого документа
      return useUser().experimentByName(experiments.exp_black_jack) && !this.toWorkBtnDisabled;
    },
    actionsCount(): string {
      if (!this.needCountActionInfo) return '';
      switch (true) {
        case this.order.status === 'request':
          return this.requestActionsCount;
        case this.order.target === 'canceled':
          return this.canceledActionsCount;
        case this.order.status === 'processing':
          return this.processingActionsCount;
        default:
          return this.$gettext('Все готово');
      }
    },
    toWorkBtnDisabled(): boolean {
      // кнопка взять в работу только для первого в очереди заказа
      if (!isOrderOrder(this.order)) return false;
      if (!useUser().experimentByName(experiments.exp_black_jack)) return false;
      const firstRequestOrder = useOrders().orderOrders.find(o => o.status === OrderWorkStatusEnum.request);
      if (!firstRequestOrder) return false;
      return firstRequestOrder.order_id !== this.order.order_id;
    },
    continueBtnDisabled(): boolean {
      // Блокируем кнопку продолжить для клиентских заказов если все саджесты заблокированы
      if (!isOrderOrder(this.order)) return false;
      return this.order.hasOnlyBlockedSuggests;
    },
    icons(): IconType[] {
      const result: IconType[] = [];
      if (['order', 'acceptance', 'sale_stowage'].includes(this.order.type) && this.order.hasFragile) {
        result.push('fragile');
      }
      if (
        isClientOrder(this.order) &&
        this.order.isHeavyOrder &&
        useUser().experimentByName(experiments.exp_black_jack)
      ) {
        result.push('heavy');
      }

      return result;
    },
  },
  methods: {
    async workOrder(): Promise<void> {
      // Общий случай
      const options: UseAckOrderOptions = {};
      if (this.order.status === orderStatusesList.processing) {
        this.continueOrder();
        return;
      }
      if (this.order.isControl) {
        const confirmed = await this.$notification.confirmCenter({
          title: this.$gettext('Начать задание?'),
          text: this.$gettext('Создание заданий может занимать некоторое время. На это время ТСД будет заблокирован.'),
        });
        if (!confirmed) return;
        options.beforeRequest = () => this.waiting.show();
        options.closeLoader = () => this.waiting.hide();
      }
      if (isOrderOrderRetail(this.order)) {
        const result = await this.shelfPickingRules.asyncShow(this.order.shelfPickingMethod);
        if (!result) return;
      }
      if (this.isRover && this.order.type === 'order') {
        await orderByRoverModal();
      }
      const result = await useAckOrder(this.orderId, options);
      if (result) this.continueOrder(false);
    },
    viewOrder(): void {
      rumSpaManager.makeSpaSubPage(orderTypeUrls[this.order.type] + '-view');
      this.router.push({ name: orderTypeUrls[this.order.type] + '-view', params: { order_id: this.order.order_id } });
    },
    continueOrder(createSubPage: boolean = true): void {
      if (this.order.type === 'shipment' && (this.order as ShipmentOrder).isMarketShipment) {
        createSubPage && rumSpaManager.makeSpaSubPage('market_shipment');
        this.router.push({ name: 'market_shipment', params: { order_id: this.order.order_id } });
        return;
      }
      createSubPage && rumSpaManager.makeSpaSubPage(orderTypeUrls[this.order.type]);
      this.router.push({ name: orderTypeUrls[this.order.type], params: { order_id: this.order.order_id } });
    },
  },
});
</script>
