import BaseOrder from '@/models/orders/BaseOrder';
import { getOrderTypes } from '@/temp/constants/translations';

export default class CheckMoreOrder extends BaseOrder {
  get requiredActionsCount() {
    if (this.status === 'request') {
      return this.shelves.length || 0;
    }
    return super.requiredActionsCount;
  }
  get allActionsCount() {
    if (this.status === 'request') {
      return this.shelves.length || 0;
    }
    if (this.target === 'canceled') {
      return this.suggests.filter(s => !s.product_id).length;
    }
    if (this.status === 'processing') {
      return this.suggests.filter(s => !s.product_id).length;
    }
    return 0;
  }

  get title() {
    return this.rack || getOrderTypes[this.type];
  }
}
