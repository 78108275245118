import { get } from 'lodash';
import { defineComponent } from 'vue';
import config from '../../../../../tailwind.config';

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
  },
  computed: {
    colorClass(): string {
      return `text-${this.color}`;
    },
    colorValue(): string {
      const path = this.color?.split('-');
      const colors = config.theme.extend.colors;
      const color = get(colors, path);
      return color || colors.warmGray['600'];
    },
  },
});
