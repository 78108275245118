<template>
  <Layout>
    <div v-if="!loading" class="flex flex-col gap-2 pb-4">
      <ProductTitle :product_id="suggest.product_id" class="mx-4" />
      <ProductMainInfo :product_id="suggest.product_id" class="mx-4" />
      <Separator />
      <ProductOnShelfInfo
        class="mx-4"
        :count="result.count"
        :date="result.valid"
        :product_id="suggest.product_id"
        :shelf_id="suggest.shelf_id"
      />
    </div>
    <div v-else class="h-full flex flex-col items-center justify-center">
      <LoaderIndicator class="mb-4" />
      <body1 class="mb-2">
        {{ $gettext('Загрузка') }}
      </body1>
      <caption1 class="mb-2">
        {{ $gettext('Пожалуйста, подождите') }}
      </caption1>
    </div>

    <template #footer>
      <LayoutFooter class="flex-col">
        <template v-if="currentStep === 'count'">
          <Hint class="mb-2">
            {{ isComponent ? $gettext('Введите количество целых пачек') : $gettext('Введите новое количество товара') }}
          </Hint>
          <Counter
            :value="countOnShelf"
            :min="borders[0]"
            :max="borders[1]"
            :is-next="currentStepIndex < steps.length - 1"
            :product-id="suggest.product_id"
            :need-history="false"
            @confirm="saveCount"
          />
        </template>
        <template v-if="currentStep === 'tail'">
          <Hint class="mb-2">{{ $gettext('Введите количество в открытой пачке') }}</Hint>
          <Counter
            :value="tailOnShelf"
            :min="0"
            :max="suggest.product.quants"
            :is-next="currentStepIndex < steps.length - 1"
            :need-history="false"
            @confirm="saveTail"
          />
        </template>
        <template v-if="currentStep === 'valid'">
          <DateField active-step="date" :suggest="suggest" :order="order" :mode="validType" @confirm-date="saveValid" />
        </template>
        <template v-if="steps.length === 0">
          <Hint class="mb-2">
            {{ $gettext('У этого товара нельзя изменить срок годности. Можете завершить задание') }}
          </Hint>
          <UiButton background-color="primary" @click="nextStep">
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import { useCheckProductResources } from '@/fsd/data/utils/checkResourse';
import retryCheckModal from '@/fsd/entities/modals/retryCheckModal';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import ProductMainInfo from '@/fsd/widgets/productCard/ProductMainInfo.vue';
import ProductOnShelfInfo from '@/fsd/widgets/productCard/ProductOnShelfInfo.vue';
import ProductTitle from '@/fsd/widgets/productCard/ProductTitle.vue';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useSuggests } from '@/store/modules/suggests';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import { isProductInRequired } from '@/types/product';
import Counter from '@/ui/common/counter.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import DateField from '@/ui/common/suggest-details/fields/date-field.vue';
import UiButton from '@/ui/common/ui-button.vue';
import Separator from '@/ui/home/product-card/separator.vue';
import { getFormatDate } from '@/utils';
import { erSuggestAcceptedCountModal } from '@/utils/modals';
import { RecountOptions, ValidType } from '@/views/ProductCheck/types';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

type Step = 'valid' | 'count' | 'tail';

const props = defineProps<{
  order_id: BaseOrder['order_id'];
  suggest_id: Suggest['suggest_id'];
  validType: ValidType;
}>();

const router = useRouter();
const suggest = useSuggests().getSuggest(props.order_id, props.suggest_id)!;
const order = useOrders().orderById(props.order_id)!;
const { loading } = useCheckProductResources(suggest.product_id);

const result = ref<RecountOptions>({ count: 0, tail: 0, valid: undefined });

const isComponent = computed(() => {
  if (!suggest.shelf || !suggest.product) return false;
  return suggest.shelf.isKitchenShelf && suggest.product.quants > 1;
});
const isUpdateValid = computed(() => {
  if (!order) return false;
  const curRequired = order.required.find(
    r => isProductInRequired(r) && r.shelf_id === suggest.shelf_id && r.product_id === suggest.product_id,
  );
  if (!curRequired) return false;

  return Boolean(curRequired.update_valids);
});
const steps = computed(() => {
  if (!suggest.shelf || !suggest.product) {
    return [];
  }
  const steps: Step[] = [];
  if (!isUpdateValid.value) {
    steps.push('count');
    if (isComponent.value) {
      steps.push('tail');
    }
  }
  if (suggest.conditions.need_valid) {
    steps.push('valid');
  }
  return steps;
});
const currentStepIndex = ref(0);
const currentStep = computed(() => {
  return steps.value[currentStepIndex.value];
});

const availableOnShelf = computed(() => {
  const available = useProducts().availableByProductId(suggest.product_id);
  if (!available) return undefined;
  return available.find(a => a.shelf_id === suggest.shelf_id);
});
const countOnShelf = computed(() => {
  if (!availableOnShelf.value) return 0;
  if (isComponent.value) {
    return Math.floor(availableOnShelf.value.count / availableOnShelf.value.quants);
  } else {
    return availableOnShelf.value.count || 0;
  }
});
const tailOnShelf = computed(() => {
  if (!availableOnShelf.value) return 0;
  if (isComponent.value) {
    return availableOnShelf.value.count % availableOnShelf.value.quants;
  } else {
    return 0;
  }
});
const borders = computed(() => {
  return useOrders().recountBorders.get(order.parent?.[0]) || [0, undefined];
});

const nextStep = () => {
  if (steps.value.length === 0) {
    finishSuggest();
    return;
  }
  if (currentStepIndex.value === steps.value.length - 1) {
    finishSuggest();
  } else {
    currentStepIndex.value = currentStepIndex.value + 1;
  }
};
const saveCount = async ({ value }: { value: number }) => {
  result.value.count = value;
  if (isComponent.value) {
    await Notifications.modal({
      title: $gettext('Пересчёт количества товаров в открытых упаковках'),
      text: $gettext('Теперь необходимо указать оставшееся количество товара в открытых упаковках'),
    });
  }
  nextStep();
};
const saveTail = ({ value }: { value: number }) => {
  result.value.tail = value;
  nextStep();
};
const saveValid = value => {
  result.value.valid = value;
  nextStep();
};
const toHomePage = () => {
  router.push({ name: 'home' });
};
const getTitle = () => {
  const product = suggest.product!;
  const shelf = suggest.shelf!;
  const { count, tail, valid } = result.value;
  if (isUpdateValid.value) {
    const date = getFormatDate(valid, {
      mode: props.validType,
      product,
    });
    if (props.validType === 'expirationDate') {
      return $gettext('Вы уверены, что СГ этого товара %{valid}?', {
        valid: date,
      });
    }
    return $gettext('Вы уверены, что дата производства этого товара %{valid}?', {
      valid: date,
    });
  }
  if (product.quants > 1) {
    if (isComponent.value) {
      return $ngettext(
        'Вы уверены, что на полке %{shelf} находится %{count} закрытых упаковок и %{tail} %{unit} этого товара?',
        'Вы уверены, что на полке %{shelf} находится %{count} закрытых упаковок и %{tail} %{unit} этого товара?',
        count,
        {
          count: String(count),
          shelf: shelf.title,
          tail: String(tail),
          unit: getQuantUnit(product.quant_unit, tail),
        },
      );
    }
    return $ngettext(
      'Вы уверены, что на полке %{shelf} находится %{count} упаковок(%{unit_count} %{unit}) этого товара?',
      'Вы уверены, что на полке %{shelf} находится %{count} упаковок(%{unit_count} %{unit}) этого товара?',
      count,

      {
        count: String(count),
        shelf: shelf.title,
        unit_count: String(product.quants * count),
        unit: getQuantUnit(product.quant_unit, product.quants * count),
      },
    );
  }
  return $gettext('Вы уверены, что на полке %{shelf} находится %{count} этого товара?', {
    shelf: shelf.title,
    count: getCountToView({ count, type_accounting: product.type_accounting }),
  });
};

const finishSuggest = async () => {
  if (!suggest || !suggest.product || !suggest.shelf) {
    logger.error('Не хватает ресурсов при завершении пересчета');
    toHomePage();
    AudioService.playError();
    return;
  }
  const { count, tail, valid } = result.value;
  const product = suggest.product!;
  const updateValid = isUpdateValid.value;
  const skipConfirm = steps.value.length === 0;

  const confirmed =
    skipConfirm ||
    (await Notifications.confirmBottom({
      title: getTitle(),
    }));
  if (!confirmed) return;

  const resultCount = isComponent.value ? count * product.quants + tail : count;
  const success = await useCheck(
    props.order_id,
    {
      suggest_id: props.suggest_id,
      count: !updateValid ? resultCount : undefined,
      valid: valid || suggest.valid || undefined,
    },
    {
      onRequestError: async (e, retry) => {
        const response = e.response;
        switch (response?.data?.code) {
          case 'ER_SUGGEST_ACCEPTED_COUNT':
            await erSuggestAcceptedCountModal();
            reset();
            break;
          default: {
            const confirmed = await retryCheckModal(e);
            if (!confirmed) return false;
            return retry();
          }
        }
        return false;
      },
    },
  );
  if (!success || skipConfirm) return;
  if (updateValid) {
    Notifications.success.micro($gettext('Новый СГ успешно сохранен'));
  } else {
    Notifications.success.micro($gettext('Продукт успешно пересчитан'));
  }
};

const reset = () => {
  result.value = { count: 0, tail: 0, valid: undefined };
  currentStepIndex.value = 0;
};

watch(
  () => suggest.product,
  p => {
    if (!p || p.barcode.length > 0) return;
    Notifications.modal({
      title: $gettext('Внимание у продукта отсутствует штрихкод!'),
      text: $gettext('У продукта %{title} отсутствует штрихкод, сообщите в поддержку', {
        title: p.title,
      }),
    });
  },
  { immediate: true },
);
</script>
