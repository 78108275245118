import OrderOrder, { isOrderOrder } from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import IconKitchen from '@/temp/icons/icon-kitchen.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { getOrderNumber } from '@/utils';
import { storeToRefs } from 'pinia';
import { computed, markRaw, watch } from 'vue';
import { Router } from 'vue-router';

export const useCheckReadyMeals = (router: Router) => {
  const ordersStore = useOrders();
  const userStore = useUser();
  // нас интересуют только обычные клиентские заказы
  const { orderOrders } = storeToRefs(ordersStore);
  const orders = computed(() => {
    return orderOrders.value.filter<OrderOrder>(isOrderOrder);
  });
  // смотрим, есть ли документ в котором есть разблокированный саджест с едой
  const hasReadyMeals = computed(() => {
    return orders.value.some(o => o.hasKitchenSuggests && !o.hasBlockedSuggests);
  });
  // на страничке ордеров не показываем никаких модалок.
  const isOrderPage = computed(() => {
    return router.currentRoute.value.matched.find(r => r.name === 'order');
  });
  // на главной показываем без возможности отказаться.
  const isHomePage = computed(() => {
    return Boolean(router.currentRoute.value.matched.find(r => r.name === 'home'));
  });

  const showReadyMealsConfirm = async (o: OrderOrder) => {
    const suggest = o.suggests.find(s => s.shelf?.type === 'kitchen_on_demand');
    if (!suggest) return;
    // полка точно есть, проверили выше
    const shelf_name = suggest.shelf!.title;
    //если мы на главной, то у пользователя нет выбора )
    if (isHomePage.value) {
      await Notifications.modal({
        title: $gettext('Блюда с кухни заказа %{doc_number} готовы к выдаче', { doc_number: getOrderNumber(o) }),
        text: $gettext('Заберите их с полки %{shelf_name} и продолжите сборку заказа.', { shelf_name }),
        buttonText: $gettext('Заберу сейчас'),
        iconTop: {
          icon: markRaw(IconKitchen),
          position: 'center',
        },
      });
      router.push({ name: 'order', params: { order_id: o.order_id } });
      return;
    }

    const confirmed = await Notifications.confirmCenter({
      title: $gettext('Блюда с кухни заказа %{doc_number} готовы к выдаче', { doc_number: getOrderNumber(o) }),
      text: $gettext('Заберите их с полки %{shelf_name} и продолжите сборку заказа.', { shelf_name }),
      ok: $gettext('Заберу сейчас'),
      decline: $gettext('Заберу позже'),
      iconTop: {
        icon: markRaw(IconKitchen),
        position: 'center',
      },
    });
    if (confirmed) {
      router.push({ name: 'order', params: { order_id: o.order_id } });
      //переходим в документ
    } else {
      // Больше модалок богу модалок! Женя сказал, что можно убрать, но в макетах все это есть. поэтому пока просто закоментил
      // const confirmed = await Notifications.confirmCenter({
      //   title: $gettext('Вы уверены, что хотите забрать блюда с кухни позже?'),
      //   text: $gettext('Текущее задание будет продолжено.'),
      //   ok: $gettext('Да'),
      //   decline: $gettext('Назад'),
      // });
      // if (confirmed) {
      //   Notifications.success.micro($gettext('Напомним о блюдах кухни после этого задания'));
      // } else {
      //   return showReadyMealsConfirm(o);
      // }
    }
  };

  const checkReadyMeals = () => {
    // ищем нужный ордер
    const order = orders.value.find(o => o.hasKitchenSuggests && !o.hasBlockedSuggests);
    if (!order) return;
    //если мы уже в ордере, то модалка не нужна
    if (isOrderPage.value) return;
    showReadyMealsConfirm(order);
  };

  //в реалтайме мониторим статусы готовой еды.
  watch(hasReadyMeals, value => {
    if (!value || !userStore.experimentByName(experiments.exp_free_cash_register)) return;
    checkReadyMeals();
  });

  //при переходе на главную чекаем, есть ли отложенные документы.
  watch(isHomePage, value => {
    if (!value || !userStore.experimentByName(experiments.exp_free_cash_register)) return;
    checkReadyMeals();
  });
};
