import { getRequestSuggests, getShelf2BoxSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import BaseOrder from '@/models/orders/BaseOrder';
import { useUser } from '@/store/modules/user';
import { computed } from 'vue';

export default class WeightStowageOrder extends BaseOrder {
  get requiredActionsCount() {
    if (this.target === 'canceled') {
      return getRequestSuggests(this.suggests).length;
    }
    return super.requiredActionsCount;
  }

  _allActionsCount = computed(() => {
    if (this.target === 'canceled') {
      return getShelf2BoxSuggests(this.suggests).length;
    }
    if (this.status === 'processing') {
      return new Set(this.suggests.map(s => s.product_id)).size;
    }
    return super.allActionsCount;
  });

  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.maxWeightInOrder > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}
