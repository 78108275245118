<template>
  <div class="login-container">
    <div class="login-logo">
      <LogoB2b v-if="isB2B" color="text-primary" />
      <LogoDeli v-else-if="theme === 'deli'" />
      <Logo v-else />
    </div>
    <div v-if="!deviceId" class="barcode-container">
      <InlineLoader />
      <div class="login-title">
        <Typography type="text3" color="primary-text">
          {{ $gettext('Устаналиваем соединение с устройством') }}
        </Typography>
        <br />
        <Typography type="text3" color="primary-text">
          {{ $gettext('Пожалуйста, подождите') }}
        </Typography>
      </div>
    </div>
    <div v-else-if="uiState.tryLogin" class="barcode-container">
      <InlineLoader />
      <div class="login-title">
        <Typography type="text3" color="primary-text">
          {{ $gettext('Загружаем задания.') }}
        </Typography>
        <br />
        <Typography type="text3" color="primary-text">
          {{ $gettext('Пожалуйста, подождите') }}
        </Typography>
      </div>
    </div>
    <div v-else class="barcode-container">
      <img class="logo-img" src="@/assets/img/barcode.svg" alt="" />
      <div class="login-title">
        <Typography type="text3" color="primary-text">
          {{ $gettext('Отсканируйте личный штрих-код чтобы войти') }}
        </Typography>
      </div>
    </div>
    <Version />
  </div>
</template>

<script lang="ts">
import LogoB2b from '@/assets/img/login/logo-b2b.vue';
import LogoDeli from '@/assets/img/login/logo-deli.vue';
import Logo from '@/assets/img/login/logo.vue';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { Checkin } from '@/temp/services/checkin';
import InlineLoader from '@/ui/common/inline-loader.vue';
import Typography from '@/ui/common/typography.vue';
import Version from '@/ui/home/version/version.vue';
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import requestBarcode from '../mixins/requestBarcode';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
  uiState: {
    tryLogin: boolean;
  };
}

export default defineComponent({
  name: 'Login',
  components: {
    Logo,
    LogoDeli,
    LogoB2b,
    Typography,
    InlineLoader,
    Version,
  },
  mixins: [requestBarcode],
  setup() {
    const userStore = useUser();
    const router = useRouter();
    const route = useRoute();
    const pageName = route.name as string;

    rumSpaManager.finishDataLoading(pageName);
    rumSpaManager.startDataRendering(pageName);
    return { userStore, router };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: false,
      uiState: {
        tryLogin: false,
      },
    };
  },
  computed: {
    isB2B() {
      return this.userStore.isB2B;
    },
    theme(): string {
      return this.userStore.theme;
    },
    deviceId(): string {
      return this.userStore.device;
    },
    version(): string {
      return this.userStore.version;
    },
  },
  watch: {
    deviceId: {
      handler(value, oldValue) {
        if (value && !oldValue) {
          this.uiStateNeedBarcodeRequest = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      try {
        this.uiState.tryLogin = true;
        await this.userStore.login({
          barcode,
          device: this.deviceId,
        });
        if (Checkin.checkinRequired()) {
          await this.router.push({ name: 'checkin' });
          return false;
        }
        if (Checkin.checkinStoreConfirmRequired()) {
          const storeTitle = this.userStore.options?.constants.store.title;
          const userOnTheSameStore = await Notifications.confirmCenter({
            title: storeTitle
              ? $gettext('Вы всё ещё на складе «%{storeTitle}»?', { storeTitle })
              : $gettext('Вы находитесь на том же складе?'),
          });
          if (!userOnTheSameStore) {
            await this.router.push({ name: 'checkin' });
            return false;
          }
        }
        this.router.push({ name: 'home' });
        return false;
      } catch (error) {
        console.error(error);
        this.uiState.tryLogin = false;
        return true;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: var(--main-bg);
  background-image: var(--login-bg);
  background-size: cover;
  justify-content: stretch;
}

.barcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
}

.login-title {
  text-align: center;
  margin: 23px 40px 0;
}

.login-logo {
  flex: 2 1 auto;
  display: flex;
  align-items: center;
}

.logo-img {
  path {
    fill: var(--loader_indicator-bar-color);
  }
}
</style>
