<template>
  <Layout>
    <div class="flex flex-col h-full items-center">
      <div class="mt-16 mb-10">
        <img src="./packageWithClock.png" />
      </div>
      <Title4 class="font-medium mb-4">
        {{ $gettext('Вы собрали все товары') }}
      </Title4>
      <Body2 class="text-center leading-6">
        {{
          $gettext(
            'Теперь нужно внимательно упаковать их согласно инструкции. Время упаковки не влияет на ваш показатель времени сборки.',
          )
        }}
      </Body2>
    </div>
    <template #footer>
      <LayoutFooter>
        <UiButton data-test="order-finish init next-stage-btn" @click="$emit('done')">
          {{ $gettext('Перейти к упаковке') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts">
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FinishOrderCardPackageInit',
  components: {
    Body2,
    Title4,
    LayoutFooter,
    UiButton,
    Layout,
  },
  emits: ['done'],
});
</script>
