import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import routes from '@/router/routes';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useUser } from '@/store/modules/user';
import { orderTypeUrls } from '@/temp/constants';
import { confirmNotifyId, majorErrorNotifyId } from '@/temp/constants/common';
import { notify } from '@kyvg/vue3-notification';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let prevFinalizedName: string = '';

router.beforeEach((to, from, next) => {
  const isAuthenticated = useUser().isAuthenticated;
  const loginUrl = '/login';
  ScannerService.clearPromise();

  const prevName = from.name as string;
  if (prevName && prevFinalizedName !== prevName) {
    prevFinalizedName = prevName;
    rumSpaManager.finalizeSpaData(prevName);
  }

  //  закрываем конфирмы и ошибки при смене урла
  notify.close(confirmNotifyId);
  notify.close(majorErrorNotifyId);
  if (to.path !== loginUrl && !isAuthenticated) {
    next({ path: loginUrl, query: { barcode: to.query.barcode } });
  } else if (to.path === loginUrl && isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

const needCreateSubPage = (name: string): Boolean => {
  // не создаем подстраницы в роутере, создаем в момент нажатия на кнопку
  let orderType = name;
  if (orderType.includes('-view')) {
    orderType = orderType.slice(0, -5);
  }
  return !orderTypeUrls[orderType];
};

router.afterEach(to => {
  const routeName = to.name as string;
  if (routeName && needCreateSubPage(routeName)) {
    rumSpaManager.makeSpaSubPage(routeName);
  }
});

router.onError(e => {
  console.error(e);
});
export default router;
