<template>
  <Layout>
    <div class="flex flex-col justify-end pb-6 h-full" data-test="temperature step">
      <Title4 class="text-center mx-[30px] mb-6 font-bold">
        {{ $gettext('Введите температуру кузова грузовика в котором были доставлены товары') }}
      </Title4>
      <Caption1 color="secondary" class="text-center"> {{ $gettext('от -30°C до 100°C') }}</Caption1>
    </div>
    <template #footer>
      <Keyboard placeholder="°C" type="temperature" max="100" min="-30" @input="onInputCode" />
    </template>
  </Layout>
</template>
<script lang="ts">
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import orderQueue from '@/services/queue/order-queue';
import { useOrders } from '@/store/modules/orders';
import Keyboard from '@/ui/common/keyboard/keyboard.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Temperature',
  components: {
    Caption1,
    Title4,
    Keyboard,
    Layout,
  },
  props: {
    order: {
      type: Object as PropType<AcceptanceOrder>,
      required: true,
    },
  },
  emits: ['done'],
  setup() {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();

    return { showLoader, ordersStore };
  },
  methods: {
    async onInputCode({ value }): Promise<void> {
      const { closeLoader } = this.showLoader();
      await this.sendTemperature(value);
      closeLoader();
      this.$emit('done');
    },
    async sendTemperature(temperature): Promise<void> {
      try {
        const resp = await this.ordersStore.signal({
          order_id: this.order.order_id,
          signal: 'stat',
          data: { truck_temperature: temperature },
        });
        if (resp.status === 200) {
          this.$notification.success.micro(this.$gettext('Температура была успешно сохранена'));
          await orderQueue.load(this.order.order_id);
        } else {
          this.$notification.error.micro(this.$gettext('Произошла ошибка при сохранении температуры'));
        }
      } catch (error) {
        console.error(error);
        this.$notification.error.micro(this.$gettext('Произошла ошибка при сохранении температуры'));
      }
    },
  },
});
</script>
