import { getBox2ShelfSuggests, getSuggestsByStatusAndType } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import { getOrderTypes } from '@/temp/constants/translations';
import { $gettext } from '@/temp/plugins/gettext';

export enum ControlModeEnum {
  // распродажа для своих. любая исходная полка. целевая полка распродажи. переносятся товары с 0 < СХ < списать за
  store2markdown = 'store2markdown',
  // Обычное КСГ. любая исходная полка. целевая полка списания. переносятся товары с 0 < СХ < списать за, для товаров, что не входят в ассортимент распродажи
  //  с полки распродажи переносятся товары с 0 < СХ < expired_count_sale
  store2trash = 'store2trash',
  // КСГ на полку разбор. любая исходная полка. целевая полка разбора. переносятся товары с 0 < СХ < списать за, если товары пришли от поставщика, что забирает просрочку.
  store2review = 'store2review',
  // Съешь сегодня. любая исходная полка. целевая полка распродажи. переносятся товары с 0 < СХ < expired_count_sale. продаются для внешних пользователей
  eatToday2markdown = 'eatToday2markdown',
  // списание с полки распродажи. исходная полка распродажи. целевая полка списания. переносятся товары с 0 < СХ < списать за
  markdown2trash = 'markdown2trash',
  review2trash = 'review2trash',
}

export default class ControlOrder extends BaseOrder {
  public vars: {
    mode: ControlModeEnum;
    //признак того, что саджесты на списание или уценку сгенерированы
    suggests_write_off: boolean;
  } = { mode: ControlModeEnum.store2trash, suggests_write_off: false };

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }

  get requiredActionsCount() {
    if (this.status === 'request') {
      return 0;
    }
    if (this.target === 'canceled') {
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.request, SuggestTypeEnum.box2shelf).length;
    }
    return super.requiredActionsCount;
  }

  get allActionsCount() {
    if (this.status === 'request') {
      return 0;
    }
    if (this.target === 'canceled') {
      return getBox2ShelfSuggests(this.suggests).length;
    }
    return super.allActionsCount;
  }

  get title() {
    if (this.vars.mode === ControlModeEnum.store2markdown) {
      return $gettext('Контроль товаров для распродажи');
    }
    if (this.vars.mode === ControlModeEnum.eatToday2markdown) {
      return $gettext('КСГ "Годен сегодня"');
    }
    return getOrderTypes[this.type];
  }
}

export const isControlOrder = (o: BaseOrder): o is ControlOrder => {
  return [
    OrderTypeEnum.writeoff_prepare_day,
    OrderTypeEnum.check_valid_regular,
    OrderTypeEnum.check_valid_short,
  ].includes(o.type);
};
