import { useOrders } from '@/store/modules/orders';

export const orderSorter = (a, b) => {
  switch (true) {
    case Boolean(a.status === 'processing' && b.status === 'processing'):
      return a.created - b.created;
    case a.status === 'processing':
      return -1;
    case b.status === 'processing':
      return 1;
    default:
      return a.created - b.created;
  }
};

export const queueSorter = (a, b) => {
  const placeA = useOrders().ordersQueue.indexOf(a.external_id);
  const placeB = useOrders().ordersQueue.indexOf(b.external_id);
  if (placeA !== -1 && placeB !== -1) {
    return placeA - placeB;
  }
  return orderSorter(a, b);
};
