import createWriteoffByOrder from '@/temp/pages/create-writeoff-by-order.vue';
import settings from '@/ui/home/settings/settings.vue';
import SupportChat from '@/ui/home/support-chat/support-chat.vue';
import ItemCard from '@/views/ItemCard/ItemCard.vue';
import ItemCardMove from '@/views/ItemCardMove/ItemCardMove.vue';
import Order from '@/views/Order/Order.vue';
import ProductCard from '@/views/ProductCard/ProductCard.vue';
import ProductChangeValidCreate from '@/views/ProductChangeValidCreate/ProductChangeValidCreate.vue';
import ProductCheck from '@/views/ProductCheck/ProductCheck.vue';
import ProductCheckCreate from '@/views/ProductCheckCreate/ProductCheckCreate.vue';
import ProductMove from '@/views/ProductMove/ProductMove.vue';
import ProductWriteoff from '@/views/ProductWriteoff/ProductWriteoff.vue';
import ShelfCard from '@/views/ShelfCard/ShelfCard.vue';
import acceptance from '@/views/acceptance.vue';
import acceptanceMarket from '@/views/acceptance_market.vue';
import Check from '@/views/check.vue';
import checkTrueMark from '@/views/checkTrueMark.vue';
import check_more from '@/views/check_more.vue';
import checkin from '@/views/checkin.vue';
import control from '@/views/control.vue';
import control_check from '@/views/control_check/control_check.vue';
import createAcceptance from '@/views/create/create-acceptance.vue';
import createMarketAcceptance from '@/views/create/market/create-market-acceptance.vue';
import createMarketShipment from '@/views/create/market/create-market-shipment.vue';
import hand_move from '@/views/hand_move.vue';
import Home from '@/views/home.vue';
import List from '@/views/list/list.vue';
import login from '@/views/login.vue';
import marketShipment from '@/views/market-shipment.vue';
import order_retail from '@/views/order_retail/order_retail.vue';
import refund from '@/views/refund.vue';
import repacking from '@/views/repacking.vue';
import sale_stowage from '@/views/sale_stowage.vue';
import shipment from '@/views/shipment.vue';
import shipment_rollback from '@/views/shipment_rollback.vue';
import stowage_market from '@/views/stowage_market.vue';
import viewOrder from '@/views/viewOrder.vue';
import visual_control from '@/views/visual_control.vue';
import weight_stowage from '@/views/weight_stowage.vue';
import writeoff from '@/views/writeoff.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/inventory_check_product_on_shelf/:order_id',
    name: 'inventory_check_product_on_shelf',
    component: Check,
    props: true,
  },
  {
    path: '/inventory_check_product_on_shelf/:order_id/view',
    name: 'inventory_check_product_on_shelf-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/check/:order_id',
    name: 'check',
    component: Check,
    props: true,
  },
  {
    path: '/check_final/:order_id',
    name: 'check_final',
    component: Check,
    props: true,
  },
  {
    path: '/check/:order_id/view',
    name: 'check-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/order/:order_id',
    name: 'order',
    component: Order,
    props: true,
  },
  {
    path: '/order/:order_id/view',
    name: 'order-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/list/:type',
    name: 'list',
    component: List,
    props: true,
  },
  {
    path: '/acceptance/:order_id',
    name: 'acceptance',
    component: acceptance,
    props: true,
  },
  {
    path: '/acceptance_market/:order_id',
    name: 'acceptance_market',
    component: acceptanceMarket,
    props: true,
  },
  {
    path: '/market_shipment/:order_id',
    name: 'market_shipment',
    component: marketShipment,
    props: true,
  },
  {
    path: '/acceptance/:order_id/view',
    name: 'acceptance-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/control/:order_id',
    name: 'control',
    component: control,
    props: true,
  },
  {
    path: '/control/:order_id/view',
    name: 'control-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/visual_control/:order_id',
    name: 'visual_control',
    component: visual_control,
    props: true,
  },
  {
    path: '/visual_control/:order_id/view',
    name: 'visual_control-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/sale_stowage/:order_id',
    name: 'sale_stowage',
    component: sale_stowage,
    props: true,
  },
  {
    path: '/weight_stowage/:order_id',
    name: 'weight_stowage',
    component: weight_stowage,
    props: true,
  },
  {
    path: '/stowage_market/:order_id',
    name: 'stowage_market',
    component: stowage_market,
    props: true,
  },
  {
    path: '/sale_stowage/:order_id/view',
    name: 'sale_stowage-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/weight_stowage/:order_id/view',
    name: 'weight_stowage-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/check_more/:order_id',
    name: 'check_more',
    component: check_more,
    props: true,
  },
  {
    path: '/check_more/:order_id/view',
    name: 'check_more-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/writeoff/:order_id',
    name: 'writeoff',
    component: writeoff,
    props: true,
  },
  {
    path: '/refund/:order_id',
    name: 'refund',
    component: refund,
    props: true,
  },
  {
    path: '/shipment/:order_id',
    name: 'shipment',
    component: shipment,
    props: true,
  },
  {
    path: '/shipment/:order_id/view',
    name: 'shipment-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/hand_move/:order_id',
    name: 'hand_move',
    component: hand_move,
    props: true,
  },
  {
    path: '/hand_move/:order_id/view',
    name: 'hand_move-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/shipment_rollback/:order_id',
    name: 'shipment_rollback',
    component: shipment_rollback,
    props: true,
  },
  {
    path: '/create-acceptance',
    name: 'create-acceptance',
    component: createAcceptance,
  },
  {
    path: '/create-market-acceptance',
    name: 'create-market-acceptance',
    component: createMarketAcceptance,
  },
  {
    path: '/create-market-shipment',
    name: 'create-market-shipment',
    component: createMarketShipment,
  },
  {
    path: '/create-writeoff-by-order/:system/:type/:taskId',
    name: 'create-writeoff-by-order',
    props: true,
    component: createWriteoffByOrder,
  },
  {
    path: '/repacking/:order_id',
    name: 'repacking',
    props: true,
    component: repacking,
  },
  {
    path: '/check_final/:order_id/view',
    name: 'check_final-view',
    component: viewOrder,
    props: true,
  },
  {
    path: '/control_check/',
    name: 'control_check',
    component: control_check,
  },
  {
    path: '/settings/',
    name: 'settings',
    component: settings,
  },
  {
    path: '/settings/:upgrade',
    name: 'settings-upgrade-role',
    component: settings,
    props: true,
  },
  {
    path: '/check_true_mark/',
    name: 'checkTrueMark',
    component: checkTrueMark,
  },
  {
    path: '/order_retail/:order_id',
    name: 'order_retail',
    props: true,
    component: order_retail,
  },
  {
    path: '/checkin',
    name: 'checkin',
    component: checkin,
  },
  {
    path: '/support',
    name: 'support',
    component: SupportChat,
  },
  // LAVKADEV-13182 компоненты в разработке, чтобы не увеличивать бандл. пока не добавляем их в роутс
  {
    path: '/product/:product_id',
    name: 'product-card',
    component: ProductCard,
    props: true,
  },
  {
    path: '/product/check-create/:product_id?parent_id=:parent_id?',
    name: 'product-check-create',
    component: ProductCheckCreate,
    props: true,
  },
  {
    path: '/product/change-valid-create/:product_id',
    name: 'product-change-valid-create',
    component: ProductChangeValidCreate,
    props: true,
  },
  {
    path: '/product/check/:order_id',
    name: 'check_product_on_shelf',
    component: ProductCheck,
    props: true,
  },
  {
    path: '/product/move/:product_id',
    name: 'product-move',
    component: ProductMove,
    props: true,
  },
  {
    path: '/product/writeoff/:product_id',
    name: 'product-writeoff',
    component: ProductWriteoff,
    props: true,
  },
  {
    path: '/shelf/:shelf_id',
    name: 'shelf-card',
    component: ShelfCard,
    props: true,
  },
  {
    path: '/item/:item_id',
    name: 'item-card',
    component: ItemCard,
    props: true,
  },
  {
    path: '/item-move/:item_id',
    name: 'item-card-move',
    component: ItemCardMove,
    props: true,
  },
];
export default routes;
