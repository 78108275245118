<template>
  <OrderBadge bg-color="lime-toxic" text-color="white" data-test="order true-mark-badge">
    <template #icon>
      <IconCompleted />
    </template>
    <template #title>
      {{ $gettext('Собран') }}
    </template>
  </OrderBadge>
</template>
<script lang="ts">
import IconCompleted from '@/temp/icons/icon-completed.vue';
import OrderBadge from '@/ui/order/badges/order-badge.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CompletedBadge',
  components: { IconCompleted, OrderBadge },
});
</script>
