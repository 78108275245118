<template>
  <Blur class="flex" @click="onBackdropClick">
    <div class="bg-main m-4 p-4 self-center flex flex-col rounded-2xl w-full">
      <body1 class="font-semibold mb-4">
        {{ title }}
      </body1>

      <body2 v-if="text" class="mb-4">
        {{ text }}
      </body2>
      <Counter :value="1" :min="1" :max="max" :suggest="suggest" @confirm="onInputCount" />
    </div>
  </Blur>
</template>

<script lang="ts">
import Suggest from '@/models/Suggest';
import Blur from '@/temp/ui/blur/blur.vue';
import Counter from '@/ui/common/counter.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { Blur, Counter },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
  },
  emits: ['ok', 'close'],
  methods: {
    onInputCount({ value }): void {
      this.$emit('ok', value);
      this.$emit('close');
    },
    onBackdropClick(): void {
      this.$emit('ok');
      this.$emit('close');
    },
  },
});
</script>
