import ackOrder, { AckOptions } from '@/fsd/data/utils/ackOrder';
import { RUMAckDeltasEnum, rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import { getDuration, markEnd, markStart } from '@/fsd/shared/utils/timeMarkHelper';
import BaseOrder from '@/models/orders/BaseOrder';
import ShipmentOrder from '@/models/orders/ShipmentOrder';
import { useOrders } from '@/store/modules/orders';
import { orderTypeUrls } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { useLoader } from '@/ui/common/loader/useLoader';
import { getErrorText } from '@/utils';
import { merge } from 'lodash';

export const useAckOrder = async (
  order_id?: BaseOrder['order_id'],
  options?: AckOptions,
  createSubPage: boolean = true,
) => {
  const order = useOrders().orderById(order_id)!;
  const getSubPageName = order => {
    if (order.type === 'shipment' && (order as ShipmentOrder).isMarketShipment) {
      return 'market_shipment';
    }
    return orderTypeUrls[order.type];
  };
  const subPageName = getSubPageName(order);
  createSubPage && rumSpaManager.makeSpaSubPage(subPageName);
  const { showLoader } = useLoader();

  let loader: ReturnType<typeof showLoader>;

  const defaultOptions: AckOptions = {
    onValidateError: e =>
      Notifications.modal({ title: $gettext('Произошла ошибка при взятии задания в работу'), text: e }),
    beforeRequest: () => {
      createSubPage && markStart('ack-request');
      loader = showLoader($gettext('Отправляем запрос на назначение задания'), order_id);
    },
    afterRequest: () => {
      if (createSubPage) {
        markEnd('ack-request');
        const duration = getDuration('ack-request');
        if (duration) rumSpaManager.sendDelta(subPageName, RUMAckDeltasEnum.finishAckRequest, duration);
      }
      loader?.updateLoader($gettext('Ожидаем ответ сервера'));
    },
    onRequestError: e => {
      Notifications.modal({
        title: $gettext('Произошла ошибка при взятии задания в работу'),
        text: getErrorText(e),
      });
    },
    beforeEventWaiting: () => {
      createSubPage && markStart('ack-event');
    },
    afterEventWaiting: () => {
      if (createSubPage) {
        markEnd('ack-event');
        const duration = getDuration('ack-event');
        if (duration) rumSpaManager.sendDelta(subPageName, RUMAckDeltasEnum.finishAckEventWaiting, duration);
      }
      markStart('ack-data');
    },
    onEventWaitingError: e => {
      const errorMap = {
        'Intercept order by other user': () => $gettext('Упс, ордер уже взял кто-то другой'),
      };
      Notifications.modal({
        title: $gettext('Произошла ошибка при взятии задания в работу'),
        text: errorMap[e] ? errorMap[e]() : e,
      });
    },
    afterDataLoadingWaiting: () => {
      if (createSubPage) {
        markEnd('ack-data');
        const duration = getDuration('ack-data');
        if (duration) rumSpaManager.sendDelta(subPageName, RUMAckDeltasEnum.finishAckLoadData, duration);
      }
    },
    closeLoader: () => loader?.closeLoader(),
  };

  return await ackOrder(order_id, merge({}, defaultOptions, options));
};

export type UseAckOrderOptions = AckOptions;
