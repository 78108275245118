import { ScannerService } from '@/services/scanner/scanner.service';
import { v4 as uuid } from 'uuid';
import { onBeforeUnmount, ref } from 'vue';

export const useRequestBarcode = (onBarcodeScan: (barcode: string) => Promise<boolean | void> | boolean | void) => {
  const handlerId = uuid();
  const needBarcodeRequest = ref<boolean>(true);
  const autoRequestBarcode = async () => {
    if (!needBarcodeRequest.value) return;

    const barcode = await ScannerService.requestCode(handlerId);

    needBarcodeRequest.value = (await onBarcodeScan(barcode)) ?? true;
    autoRequestBarcode();
  };
  autoRequestBarcode();

  onBeforeUnmount(() => {
    ScannerService.clearPromise(handlerId);
  });

  return { needBarcodeRequest, autoRequestBarcode };
};
