import { getBox2ShelfSuggests, getSuggestsByStatusAndType } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';

export default class VisualControlOrder extends BaseOrder {
  public vars: {
    //признак того, что саджесты на списание сгенерированы
    suggests_write_off: boolean;
    stage: string;
  } = { suggests_write_off: false, stage: '' };

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }

  get requiredActionsCount() {
    if (this.target === 'canceled') {
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.request, SuggestTypeEnum.box2shelf).length;
    }
    return super.requiredActionsCount;
  }

  get allActionsCount() {
    if (this.target === 'canceled') {
      return getBox2ShelfSuggests(this.suggests).length;
    }
    return super.allActionsCount;
  }
}
