<template>
  <Hint>
    <div class="flex justify-center items-center">
      <IconTrueMark class="w-8 h-8 mr-2" />
      <div class="flex flex-col">
        <Body3 class="font-medium">
          {{ $gettext('Нужно сканировать марку') }}
        </Body3>
        <Body3 color="text-secondary">
          {{ $gettext('Метка «Честный знак»') }}
        </Body3>
      </div>
    </div>
  </Hint>
</template>

<script>
import IconTrueMark from '@/temp/icons/icon-true-mark.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Body3 from '@/ui/common/typo/body-3.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Body3, IconTrueMark, Hint },
});
</script>
