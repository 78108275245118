<template>
  <div v-if="activeStep === 'count'">
    <template v-if="uiState.step === 'count'">
      <Hint class="my-2 mx-4">{{ hintText }}</Hint>
      <Counter
        v-if="!needKeyboardOnInputCount"
        key="1"
        :value="defaultCount"
        :min="minCount"
        :max="maxCount"
        :suggest="suggest"
        :is-next="isCounterNext || needTailStep"
        :is-next-condition="isCounterNextCondition"
        :product-id="suggest.product_id || productId"
        :need-history="needHistory"
        class="counter"
        @confirm="onInputCount"
      />
      <Keyboard
        v-if="needKeyboardOnInputCount"
        :key="1"
        :min="minCount"
        :max="maxCount"
        :product-id="suggest.product_id || productId"
        @input="onInputCount"
      />
    </template>
    <template v-else>
      <Hint class="my-2 mx-4">{{ tailStepHintText }}</Hint>
      <Counter
        v-if="!needKeyboardOnInputCount"
        key="2"
        :value="
          ['check', 'check_final'].includes(order.type)
            ? 0
            : getProductTail(product, suggest.result_count || suggest.count)
        "
        :min="0"
        :max="maxTail"
        :suggest="suggest"
        :is-next="isCounterNext"
        :is-next-condition="isCounterNextCondition"
        :product-id="suggest.product_id || productId"
        :need-history="needHistory"
        class="counter"
        @confirm="onInputTail"
      />
      <Keyboard
        v-if="needKeyboardOnInputCount"
        :key="2"
        :min="0"
        :max="product.quants"
        :product-id="suggest.product_id || productId"
        @input="onInputTail"
      />
    </template>
  </div>
</template>
<script lang="ts">
import { getClosedPackage, getProductTail } from '@/fsd/entities/product';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestTypeEnum } from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import Counter from '@/ui/common/counter.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Keyboard from '@/ui/common/keyboard/keyboard.vue';
import { calculateControlDate } from '@/ui/common/suggest-details/fields/utils';
import { defineComponent, PropType } from 'vue';

interface Data {
  model: {
    count: number;
    tail: number;
  };
  uiState: {
    step: 'count' | 'tail';
  };
}

// компонет возвращает кол-во в пачках для обычных полок и в квантах для компонентных
export default defineComponent({
  components: {
    Counter,
    Hint,
    Keyboard,
  },
  props: {
    activeStep: {
      type: String,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    isCounterNext: {
      type: Boolean,
      required: true,
    },
    initialValue: {
      type: Number,
      default: undefined,
    },
    mode: {
      type: String as PropType<'productionDate' | 'expirationDate'>,
      default: 'expirationDate',
    },
    productId: {
      type: String,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    min: {
      type: Number,
      default: undefined,
    },
  },
  emits: ['confirmInput'],
  setup() {
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const userStore = useUser();

    return { shelvesStore, productsStore, userStore };
  },
  data(): Data {
    return {
      model: {
        count: 0,
        tail: 0,
      },
      uiState: {
        step: 'count',
      },
    };
  },
  computed: {
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.suggest.shelf_id)!;
    },
    product(): Product {
      return this.productsStore.productById(this.productId || this.suggest.product_id)!;
    },
    needKeyboardOnInputCount(): boolean {
      return (
        ['check_more', 'inventory_check_product_on_shelf', 'inventory_check_more'].includes(this.order.type) &&
        !this.order.vars.third_party_assistance &&
        this.product.type_accounting !== 'true_weight'
      );
    },
    needHistory(): boolean {
      return (
        ['inventory_check_product_on_shelf', 'inventory_check_more'].includes(this.order.type) &&
        Boolean(this.order.vars.third_party_assistance)
      );
    },
    defaultCount(): number {
      if (this.initialValue) {
        if (this.shelf.isKitchenShelf) {
          return getClosedPackage(this.product, this.initialValue);
        }
        return this.initialValue;
      }
      if (['check', 'check_final'].includes(this.order.type)) {
        if (this.shelf.isKitchenShelf) {
          return getClosedPackage(this.product, this.minCount || 0);
        }
        return this.minCount || 0;
      }
      if (this.order.type === 'writeoff') {
        if (this.shelf.isKitchenShelf) {
          return getClosedPackage(this.product, this.suggest.result_count || this.suggest.count!);
        }
        return this.suggest.result_count || this.suggest.count!;
      }
      if (['refund', 'part_refund', 'hand_move', 'kitchen_provision', 'shipment_rollback'].includes(this.order.type)) {
        return this.suggest.count!;
      }
      if ('sale_stowage' === this.order.type && this.suggest.conditions.max_count) {
        if (this.suggest.product?.children_id?.length) {
          return 1;
        }
        return this.suggest.count!;
      }
      return this.minCount;
    },
    maxCount(): number | undefined {
      if (this.max && isFinite(this.max)) {
        return this.max;
      }
      if (
        this.userStore.experimentByName(experiments.exp_not_more_than_total_price) &&
        this.suggest.max_count &&
        !this.suggest.conditions.max_count
      ) {
        return this.suggest.max_count;
      }
      if (this.suggest.conditions.all && !this.suggest.conditions.max_count) {
        return undefined;
      }
      if (
        this.suggest.conditions.all &&
        this.suggest.conditions.max_count &&
        !['kitchen_trash'].includes(this.shelf?.type)
      ) {
        return this.suggest.count! || undefined;
      }
      if (
        [OrderTypeEnum.hand_move, OrderTypeEnum.kitchen_provision].includes(this.order.type) &&
        this.suggest.type === SuggestTypeEnum.box2shelf
      ) {
        // мы предполагаем, что тут всегда целые пачки
        return this.suggest.count!;
      }
      if (this.shelf.isKitchenShelf) {
        return getClosedPackage(this.product, this.suggest.count!);
      }
      // бек однажды прислал null в count. каунтер после этого сошел с ума(
      return this.suggest.count! || undefined;
    },
    minCount(): number {
      if (this.min !== undefined) return this.min;
      switch (this.order.type) {
        case 'check_more':
        case 'inventory_check_more':
          if (this.needTailStep) {
            return 0;
          }
          return 1;
        case 'acceptance':
        case 'sale_stowage':
        case 'refund':
        case 'hand_move':
        case 'kitchen_provision':
        case 'part_refund':
          if (this.suggest.conditions.all === false) {
            return this.suggest.count!;
          }
          return 1;
        default:
          if (this.suggest.conditions.all === false) {
            if (this.needTailStep) {
              return 0;
            }
            return this.suggest.count!;
          }
          return 0;
      }
    },
    isControl(): boolean {
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.order.type);
    },
    tailStepHintText(): string {
      return this.$gettext('Сколько %{quant_unit} находится в открытой упаковке ?', {
        quant_unit: getQuantUnit(this.product.quant_unit, 10),
      });
    },
    hintText(): string {
      if (
        ['check', 'check_more', 'check_final'].includes(this.order.type) &&
        this.shelf.isKitchenShelf &&
        this.product.isProductQuant
      ) {
        return this.$gettext('Введите количество целых упаковок товара');
      }
      if (this.isControl) {
        if (this.suggest.type === 'box2shelf') {
          return this.$gettext('Верните указанное количество товаров на полку');
        }
        let date = calculateControlDate({
          mode: this.mode,
          order: this.order,
          product: this.product,
          shelf: this.shelf,
        });
        if (this.mode === 'expirationDate') {
          return this.$gettext('Отберите товар у которого срок годности ≤ %{date}', {
            date: date,
          });
        } else {
          return this.$gettext('Отберите товар у которого дата изготовления ≤ %{date}', {
            date: date,
          });
        }
      }
      const hintTexts = {
        default: this.$gettext('Введите количество товара'),
        acceptance: {
          default: this.$gettext('Сколько товаров принимаете?'),
          true_weight: this.$gettext('Сколько товара принимаете в кг?'),
        },
        refund: {
          default: this.$gettext('Сколько товара фактически есть?'),
          shelf2box: this.$gettext('Заберите указанное количество товаров с полки'),
        },
        part_refund: {
          default: this.$gettext('Сколько товара фактически есть?'),
          shelf2box: this.$gettext('Заберите указанное количество товаров с полки'),
        },
        shipment_rollback: this.$gettext('Сколько товара фактически есть?'),
        stowage: this.$gettext('Сколько товара фактически есть?'),
        sale_stowage: {
          default: this.$gettext('Сколько товаров вы хотите разместить сейчас?'),
          true_weight: this.$gettext('Сколько товара размещаете в кг?'),
          trash: this.$gettext('Сколько товара списываете?'),
        },
        control: this.$gettext('Сколько товаров списываете?'),
        repacking: {
          trash2box: this.$gettext('Сколько товаров списываете?'),
          repacking2box: this.$gettext('Сколько товаров размещаете?'),
        },
        check_more: this.$gettext('Сколько товаров на полке?'),
        inventory_check_more: this.$gettext('Сколько товаров на полке?'),
        inventory_check_product_on_shelf: this.$gettext('Сколько товаров на полке?'),
        visual_control: this.$gettext('Сколько товаров с таким дефектом вы видите?'),
        hand_move: this.$gettext('Сколько товаров вы хотите сейчас переместить?'),
        kitchen_provision: this.$gettext('Сколько товаров вы хотите сейчас переместить?'),
      };

      const result = hintTexts[this.order.type] || hintTexts['default'];
      if (typeof result === 'object') {
        return (
          result[this.product?.type_accounting] ||
          result[this.suggest?.vars.stage!] ||
          result[this.suggest.type] ||
          result['default'] ||
          hintTexts.default
        );
      }
      return result;
    },
    needTailStep(): boolean {
      return (
        this.shelf.isKitchenShelf &&
        this.product.isProductQuant &&
        ![OrderTypeEnum.hand_move, OrderTypeEnum.sale_stowage, OrderTypeEnum.kitchen_provision].includes(
          this.order.type,
        )
      );
    },
    maxTail(): number {
      if (!this.suggest.conditions.max_count) {
        return this.product.quants;
      }
      return Math.min(this.product.quants, this.suggest.count! - this.model.count * this.product.quants);
    },
  },
  methods: {
    getProductTail,
    async onInputCount({ value }: { value: number }): Promise<void> {
      // обработчик ввода целого количества!
      if (
        this.order &&
        this.order.type === 'sale_stowage' &&
        this.order.vars?.stage === 'trash' &&
        !this.suggest.conditions.max_count &&
        this.suggest.count! < value
      ) {
        const confirmed = await this.$notification.confirmBottom({
          title: this.$gettext('Вы собираетесь списать больше чем в задании?'),
        });
        if (!confirmed) {
          return;
        }
      }
      if (!this.needTailStep) {
        // возвращаем целое количество, если не нужно вводить хвост
        this.finish(value);
        return;
      }

      this.model.count = value;
      this.$notification.modal({
        title: this.$gettext('Пересчёт количества товаров в открытых упаковках'),
        text: this.$gettext('Теперь необходимо указать оставшееся количество товара в открытых упаковках'),
      });
      this.uiState.step = 'tail';
    },
    finish(value: number): void {
      // тут проверка на введенное кол-во
      const needCheck =
        this.userStore.experimentByName(experiments.exp_limit_exceeded) &&
        ['sale_stowage'].includes(this.order.type) &&
        this.product.quants > 1 &&
        this.suggest.vars.source !== 'signal';
      if (needCheck) {
        const max = this.product.quants * this.suggest.count!;
        if (value > max) {
          this.$notification.modal({
            title: this.$gettext('Введенное количество превышает допустимое'),
          });
          return;
        }
      }
      this.$emit('confirmInput', value);
    },
    onInputTail({ value }: { value: number }): void {
      // в ф-ю прилетает "хвост"
      this.finish(this.model.count * this.product.quants + value);
    },
    isCounterNextCondition(value): boolean {
      if (this.needTailStep && this.uiState.step === 'count') {
        return true;
      }
      if (this.isControl) {
        return value !== this.suggest.count;
      }
      return true;
    },
  },
});
</script>
<style scoped>
.counter {
  margin: 0 16px 16px;
  width: auto;
}
</style>
