<template>
  <div class="page-container">
    <Layout>
      <template #header>
        <Bar @close-click="router.back" />
      </template>
      <ItemCardInfo :item="item" />
      <div class="item-info-wrapper">
        <div class="shelf-item">
          <InfoRow v-if="destShelf" :title="$gettext('Перемещаем на')" :value="destShelf.title" />
        </div>
      </div>

      <template #footer>
        <LayoutFooter>
          <div>
            <Hint class="mb-2">{{ hintText }}</Hint>
            <UiButton :disabled="!destShelf" data-test="finish move btn" @click="onFinishButtonClick">
              {{ $gettext('Завершить') }}
            </UiButton>
          </div>
        </LayoutFooter>
      </template>
    </Layout>
  </div>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Item, { AvailableItem } from '@/models/Item';
import Shelf from '@/models/Shelf';
import { useItems } from '@/store/modules/items';
import { useShelves } from '@/store/modules/shelves';
import { errorMessages, shelfTypes } from '@/temp/constants/translations';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import UiButton from '@/ui/common/ui-button.vue';
import InfoRow from '@/ui/home/product-card/common/info-row.vue';
import { notify } from '@/utils';
import { errorHandler } from '@/utils/errorHandler';
import { subscribeOnOrderStatus } from '@/utils/subscribeOnOrder';
import ItemCardInfo from '@/views/ItemCard/ItemCardInfo.vue';
import { computed, defineProps, ref } from 'vue';

import { loadItemResources } from '@/fsd/data/utils/checkResourse';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import uuidv1 from 'uuid/v1';
import { useRouter } from 'vue-router';
const { showLoader } = useLoader();
const router = useRouter();
const itemsStore = useItems();
const shelvesStore = useShelves();

interface ItemMoveProps {
  item_id: string;
}

const props = defineProps<ItemMoveProps>();

const destShelf = ref<Shelf | undefined>(undefined);

const available = computed<AvailableItem>(() => itemsStore.availableById(props.item_id)[0]);

const item = computed<Item>(() => itemsStore.itemById(props.item_id));

const confirmText = computed<string>(() => {
  if (destShelf.value) {
    return $gettext('Вы уверены, что разместили посылку на полке %{shelf}?', {
      shelf: destShelf.value.title,
    });
  }
  return '';
});

const hintText = computed<string>(() => {
  if (!destShelf.value) {
    return $gettext('Отсканируйте полку на которую перемещаете посылку');
  }
  return $gettext('Разместите товар на полке');
});

const createMoveOrder = async (): Promise<void> => {
  const payload = {
    order_id: uuidv1(),
    move: [
      {
        item_id: item.value.item_id,
        count: 1,
        src_shelf_id: available.value.shelf_id,
        dst_shelf_id: destShelf.value!.shelf_id,
      },
    ],
  };
  const { closeLoader } = showLoader($gettext('Выполняем перемещение товаров'));
  try {
    const { data } = await api.order.move(payload);
    const moveOrderId = data.order.order_id;
    const cbSuc = (data, unSub) => {
      unSub();
      closeLoader();
      Notifications.success.micro($gettext('Перемещение успешно завершено'));
      loadItemResources(item.value.item_id);
      toHomePage();
    };
    const cbFail = (data, unSub) => {
      unSub();
      closeLoader();
      notify.error(errorMessages.ER_MOVING, errorMessages.ER_MOVING_DETAILS);
      toHomePage();
    };
    subscribeOnOrderStatus({ order_id: moveOrderId, cbSuc, cbFail });
  } catch (error: any) {
    closeLoader();
    errorHandler(error);
    destShelf.value = undefined;
    notify.error(errorMessages.ER_MOVING, errorMessages.ER_MOVING_DETAILS);
    toHomePage();
  }
};

const onFinishButtonClick = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: confirmText.value,
  });
  if (confirmed) {
    await createMoveOrder();
  }
};

const toHomePage = () => {
  return router.push({ name: 'home' });
};

const checkDestShelfIsValid = (shelf: Shelf): boolean => {
  return shelf.type === 'parcel';
};

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  let shelf;
  try {
    shelf = await shelvesStore.getShelfByBarcode(barcode);
  } catch (e) {
    return true;
  } finally {
    closeLoader();
  }
  const valid = checkDestShelfIsValid(shelf);

  if (!valid) {
    Notifications.error.micro(
      $gettext('Нельзя перенести посылку на %{destShelf}', {
        destShelf: shelfTypes(shelf.type),
      }),
    );
    return true;
  }
  destShelf.value = shelf;
  return false;
});
</script>

<style lang="scss" scoped>
.page-container {
  flex-grow: 1;
  overflow: hidden;
}

.item-info-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: linear-gradient(#ffffff 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 50%) 0 100%,
    linear-gradient(rgba(0, 0, 0, 0), rgba(117, 115, 111, 0.16)) 0 100%;
  background: var(--main-bg);
  background-repeat: no-repeat;
  background-attachment: local, local, scroll;
  background-size: 100% 30px, 100% 30px, 100% 16px;
}
</style>
