<template>
  <div class="flex w-full">
    <div class="bg-purple-normal rounded-full w-14 h-14 flex items-center justify-center">
      <IconFragile color="white" />
    </div>
  </div>
</template>

<script lang="ts">
import IconFragile from '@/temp/icons/icon-fragile.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FragileBadge',
  components: { IconFragile },
});
</script>
