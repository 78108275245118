<template>
  <div
    class="flex items-center justify-between px-4 rounded-2xl w-full h-14"
    :class="{ 'bg-day-cardDivider': suggest.status !== 'done', 'bg-green-lite': suggest.status === 'done' }"
  >
    <Body2 class="text-warmGray-600" :class="{ 'text-red-normal': problem }">
      <template v-if="problem"> {{ $gettext('На складе') }} </template>
      <template v-else> {{ $gettext('Количество') }} </template>
    </Body2>
    <div class="flex items-center">
      <Body1 class="font-bold" :color="colorText" :data-test="`product count value`">
        {{ productCountValue }}
      </Body1>
      <title4 v-if="suggest?.max_count" color="text-warmGray-600" class="ml-2">
        {{ delta }}
      </title4>
    </div>
  </div>
</template>

<script lang="ts">
import { Problem } from '@/models/orders/BaseOrder';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import Body1 from '@/ui/common/typo/body-1.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { Body1, Body2 },
  props: {
    suggest: {
      type: Object as PropType<Suggest>,
      default: () => {
        return {} as Suggest;
      },
    },
    problem: {
      type: Object as PropType<Problem>,
      default: undefined,
    },
    collected: {
      type: Number,
      required: true,
    },
  },
  computed: {
    colorText(): string {
      if (this.problem) return 'red-normal';
      return this.suggest.status === 'done' ? 'lime-dark' : 'warmGray-600';
    },
    productCountValue(): string {
      let totalCount = this.suggest?.count || 0;
      let count = this.collected;
      const isTrueWeightProduct = this.suggest?.product?.isTrueWeight;
      if (this.problem && this.suggest) {
        totalCount = this.suggest.count! + this.problem.count!;
        count = this.suggest.count!;
      }

      if (this.suggest?.status === SuggestStatusEnum.done) {
        count = this.suggest.result_count || this.suggest.count!;
      }

      if (isTrueWeightProduct) {
        const weight: number = this.suggest.count! || this.suggest.result_count!;
        if (weight > 1000) {
          return this.$gettext('%{count} из %{kg} кг', { count: String(count), kg: (weight / 1000).toFixed(2) });
        }
        return this.$gettext('%{count} из %{g} г', { count: String(count), g: String(weight) });
      }

      return this.$gettext('%{count} из %{all}', { count: String(count), all: String(totalCount) });
    },
    delta(): string {
      if (!this.suggest?.allowableError) {
        return '';
      }
      return this.$gettext('± %{g} г', { g: String(this.suggest.allowableError) });
    },
  },
});
</script>
