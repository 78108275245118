/**
 * Сервис для получения информации о приложении Yandex.Polka
 */

import { PlatformService } from '@/fsd/data/api/platform.service';
import { logger } from '@/temp/plugins/logs';
import { scanner } from '@lavka/capacitor-scanner-plagin';

export const VersionService = {
  async getApkVersion(): Promise<string> {
    if (PlatformService.isCapacitor()) {
      return scanner.version().then(({ version }) => version);
    }
    return new Promise((resolve, reject) => {
      // @ts-expect-error
      if (!window.cordova?.getAppVersion) {
        logger('Не удалось получить доступ к плагину cordova.getAppVersion');
        resolve('');
      }
      // @ts-expect-error
      window.cordova.getAppVersion.getVersionNumber(resolve, reject);
    });
  },
};
