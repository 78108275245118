<template>
  <OrderBadge bg-color="red-dark" text-color="white" data-test="order special-package-badge">
    <template #icon>
      <IconHeart color="white" />
    </template>
    <template #title>
      {{ $gettext('ТОВАР 18+') }}
    </template>
  </OrderBadge>
</template>
<script lang="ts">
import IconHeart from '@/temp/icons/icon-heart.vue';
import OrderBadge from '@/ui/order/badges/order-badge.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SpecialPackageBadge',
  components: { IconHeart, OrderBadge },
});
</script>
