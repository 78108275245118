<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :caption="caption"
        :text="controlBarText"
        need-progress
        :total="suggests.length"
        :progress-config="progressConfig"
        :order="order"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <div class="h-full bg-day-cardDivider box-border">
        <div class="bg-main border-b rounded-2xl">
          <Hint class="m-4">
            {{ $gettext('Вскройте лоты из списка и отсканируйте все посылки') }}
          </Hint>
        </div>

        <div v-if="order.lots.length > 0" class="bg-main border rounded-2xl mt-2 cursor-pointer">
          <div class="flex justify-between items-center p-4" data-test="lot section toggle" @click="lotsSection.toggle">
            <body1 class="font-bold">
              {{ $gettext('Лоты') }}
            </body1>
            <IconChevron
              class="transition-all"
              :class="{ '-rotate-90': lotsSection.visible.value, 'rotate-90': !lotsSection.visible.value }"
            />
          </div>
          <template v-if="lotsSection.visible.value">
            <div v-for="lot in order.lots" :key="lot">
              <ProductCard class="mb-2" data-test="product-card lot" :need-menu="false" :product-id="lot" />
            </div>
          </template>
        </div>

        <div v-if="suggestsForView.length > 0" class="flex flex-col bg-main border rounded-2xl mt-2">
          <body1 class="font-bold m-4">
            {{ $gettext('Посылки') }}
          </body1>
          <dynamic-scroller :items="suggestsForView" key-field="suggest_id" :min-item-size="112">
            <template #default="{ item: suggest, index, active }">
              <dynamic-scroller-item
                :item="suggest"
                :active="active"
                :size-dependencies="[suggest.status]"
                :data-index="index"
              >
                <ProductCard
                  :key="suggest.product_id"
                  class="mb-2"
                  data-test="product-card item"
                  :product-id="suggest.product_id"
                  :need-menu="checkNeedMenu(suggest.suggest_id)"
                  :badge-data="getBadgeData(suggest)"
                  :menu-config="suggestMenuConfig(suggest.suggest_id)"
                  @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
                  @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
                >
                  <template v-if="suggest.result_count" #info>
                    <div class="flex">
                      <caption1 class="w-1/4" color="day-textMinor">
                        {{ $gettext('Полка') }}
                      </caption1>
                      <caption1 class="ml-4"> {{ suggest.shelf?.title || '-' }}</caption1>
                    </div>
                    <div v-if="suggest.item && TYPE_PACKAGE[suggest.item.typePackageItem]" class="flex">
                      <caption1 class="w-1/4" color="day-textMinor">{{ $gettext('Упаковка') }}</caption1>
                      <caption1 class="ml-4">{{ TYPE_PACKAGE[suggest.item.typePackageItem] }}</caption1>
                    </div>
                    <div v-if="suggest.item && SIZE_PACKAGE[suggest.item.sizePackageItem]" class="flex">
                      <caption1 class="w-1/4" color="day-textMinor">{{ $gettext('Размер') }}</caption1>
                      <caption1 class="ml-4">{{ SIZE_PACKAGE[suggest.item.sizePackageItem] }}</caption1>
                    </div>
                  </template>
                </ProductCard>
              </dynamic-scroller-item>
            </template>
          </dynamic-scroller>
        </div>
      </div>

      <div v-if="suggestDetails.visible.value" class="w-screen h-screen z-10 fixed top-0 left-0 bg-white">
        <SuggestDetails
          :order-id="order.order_id"
          :suggest-id="suggestDetails.props.value"
          @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
          @cancel="suggestDetails.hide"
        />
      </div>
      <StowageMarketDifferences
        v-if="differences.visible.value"
        :progress-config="progressConfig"
        :no-item-suggests="noItemSuggests"
        :returned-item-suggests="returnedItemSuggests"
        @close="differences.hide"
      />
    </template>

    <template #footer>
      <LayoutFooter>
        <template v-if="uiStateFilter === 'all'">
          <FilterMenu :menu-config="filterMenuConfig" />
          <SliderButton data-test="stowage_market finish btn" :disabled="!allSuggestDone" @slide-complete="finish">
            {{ $gettext('Завершить') }}
          </SliderButton>
        </template>
        <UiButton v-else @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import withFilter from '@/mixins/withFilter';
import Item, { TypePackageClass } from '@/models/Item';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import StowageMarketOrder from '@/models/orders/StowageMarketOrder';
import { AudioService } from '@/services/audio.service';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { defaultSourceFormatLong } from '@/temp/constants/dateFormat';
import IconChevron from '@/temp/icons/icon-chevron.vue';
import Bar from '@/ui/common/bar/bar.vue';
import { ProgressConfig } from '@/ui/common/bar/types';
import FilterMenu, { FilterMenuItemConfig } from '@/ui/common/filter-menu/filter-menu.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ProductCard, { BadgeData } from '@/ui/common/product-card/product-card.vue';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import { SIZE_PACKAGE, TYPE_PACKAGE } from '@/utils/packageClassItem';
import S3storageHelper, { MethodsEnum } from '@/utils/s3storageHelper';
import commonMixin from '@/views/common/commonMixin';
import StowageMarketDifferences from '@/views/stowage_market/stowage-market-differences.vue';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiStateFilter: 'all' | 'done' | 'request' | 'noProduct';
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'StowageMarket',
  components: {
    PageLayout,
    StowageMarketDifferences,
    IconChevron,
    ProductCard,
    LayoutFooter,
    Hint,
    Bar,
    UiButton,
    FilterMenu,
    SuggestDetails,
    SliderButton,
  },
  mixins: [requestBarcode, withFilter, commonMixin],
  setup(props) {
    const { showLoader } = useLoader();
    const itemsStore = useItems();
    const ordersStore = useOrders();
    const userStore = useUser();
    const router = useRouter();

    const suggestDetails = useComponent<string>();
    const lotsSection = useComponent(true);
    const differences = useComponent();

    interface BadPickItem {
      barcode: string;
      time: string;
    }

    interface BadPickData {
      store_id: string;
      order_id: string;
      scans: BadPickItem[];
    }

    const badPicks = new S3storageHelper<BadPickData, BadPickItem>({
      defaultData: { store_id: userStore.storeId, order_id: props.order_id, scans: [] },
      s3Folder: `stowage_items_wrong_scans/${dayjs().format('YYYY_MM_DD')}`,
      s3FileName: `${props.order_id}.json`,
      method: MethodsEnum.upload,
      autoSaveToS3: true,
      updateAdaptor: (data, item) => {
        data.scans.push(item);
        return data;
      },
      validator: data => {
        return data.order_id === props.order_id;
      },
    });

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      userStore,
      itemsStore,
      suggestDetails,
      lotsSection,
      badPicks,
      ordersStore,
      differences,
      SIZE_PACKAGE,
      TYPE_PACKAGE,
      router,
    };
  },
  data(): Data {
    return {
      uiStateFilter: 'all',
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    order(): StowageMarketOrder {
      return this.ordersStore.orderById(this.order_id) as StowageMarketOrder;
    },
    progressConfig(): ProgressConfig[] {
      return [
        {
          count: this.fullCompletedSuggests.length,
          color: 'green',
        },
        {
          count: this.noProductSuggests.length,
          color: 'red',
        },
      ];
    },
    filterMenuConfig(): FilterMenuItemConfig[] {
      const menuConfig = [
        {
          buttonText: this.$gettext('Не отсканирована'),
          color: 'secondary',
          count: this.requestSuggests.length,
          onClick: () => this.setFilter('request'),
        },
        {
          buttonText: this.$gettext('Нельзя принять'),
          color: 'red',
          count: this.noProductSuggests.length,
          onClick: () => this.setFilter('noProduct'),
        },
        {
          buttonText: this.$gettext('Отксанирована'),
          color: 'green',
          count: this.fullCompletedSuggests.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
      ];
      return menuConfig;
    },
    fullCompletedSuggests(): Suggest[] {
      return this.suggests.filter(s => s.status === 'done' && s.result_count);
    },
    suggests(): Suggest[] {
      if (!this.order) return [];
      return this.order.suggests;
    },
    suggestsForView(): Suggest[] {
      return this.filteredSuggests.slice(0, 50);
    },
    caption(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Размещение посылок');
        case 'done':
          return this.$gettext('Отксанирована');
        case 'request':
          return this.$gettext('Не отсканирована');
        case 'noProduct':
          return this.$gettext('Нельзя принять');
        default:
          return this.$gettext('Размещение посылок');
      }
    },
    controlBarText(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Осталось %{request} из %{all} посылок', {
            request: String(this.requestSuggests.length),
            all: String(this.suggests.length),
          });
        case 'done':
        case 'request':
        case 'noProduct':
          return this.$ngettext(
            '%{filtered} посылка с таким статусом',
            '%{filtered} посылок с таким статусом',
            this.filteredSuggests.length,
            { filtered: String(this.filteredSuggests.length) },
          );
      }
      return '';
    },
    suggestMenuConfig(): (suggest_id: string) => MenuItemConfig[] {
      return suggest_id => {
        const menuConfig: MenuItemConfig[] = [];
        const suggest = this.order!.suggestById(suggest_id);
        if (!suggest) return menuConfig;
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отсутстует'),
          buttonSecondText: this.$gettext('Посылка отсутствует'),
          dataTest: 'missing item btn',
          onClick: () => useBox2Shelf(this.order_id, { suggest_id, count: 0 }),
          condition: () => checkConditions(suggest, 'all', true) && suggest.status === 'request',
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    noItemSuggests(): Suggest[] {
      return this.suggests.filter(s => s.status === 'done' && s.result_count === 0);
    },
    returnedItemSuggests(): Suggest[] {
      return this.suggests.filter(s => s.status === 'done' && s.shelf?.type === 'parcel_returned');
    },
    needSignAct(): boolean {
      return Boolean(this.noItemSuggests.length || this.returnedItemSuggests.length);
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      const { closeLoader } = this.showLoader();
      try {
        const item = await this.itemsStore.getItemByBarcode(barcode);
        closeLoader();
        if (!item) {
          this.badPicks.updateModel({ barcode, time: dayjs().format(defaultSourceFormatLong) });
          AudioService.playError();
          await this.$notification.modal({
            title: this.$gettext('Пожалуйста, убедитесь, что вы правильно отсканировали штрих-код посылки.'),
            text: this.$gettext(
              'Если штрих-код уже был отсканирован, положите данную посылку на полку "Возврат в Маркет".',
            ),
          });
          // место для бедпика
          return true;
        }
        const suggest = this.order?.getSuggestsByProductId(item.item_id)[0];
        if (!suggest) {
          this.badPicks.updateModel({ barcode, time: dayjs().format(defaultSourceFormatLong) });
          AudioService.playError();
          await this.$notification.modal({
            title: this.$gettext('Пожалуйста, убедитесь, что вы правильно отсканировали штрих-код посылки.'),
            text: this.$gettext(
              'Если штрих-код уже был отсканирован, положите данную посылку на полку "Возврат в Маркет".',
            ),
          });
          // место для бедпика
          return true;
        }
        await this.suggestDetails.asyncShow(suggest.suggest_id);
        return true;
      } catch (e) {
        closeLoader();
        AudioService.playError();
        await this.$notification.modal({
          title: this.$gettext('Пожалуйста, убедитесь, что вы правильно отсканировали штрих-код посылки.'),
          text: this.$gettext(
            'Если штрих-код уже был отсканирован, положите данную посылку на полку "Возврат в Маркет".',
          ),
        });
        console.error(e);
        return true;
      }
    },
    async savePackageClassItem(item_id: Item['item_id'], package_class: TypePackageClass): Promise<boolean> {
      const { closeLoader } = this.showLoader();
      try {
        await this.itemsStore.savePackageClassItem(item_id, package_class);
        closeLoader();
        return true;
      } catch (error) {
        closeLoader();
        return false;
      }
    },
    async closeActiveSuggest(option: Pick<Model, 'reason'> & { shelf_id: string }, suggest: Suggest): Promise<boolean> {
      if (suggest.isDone && (!option.shelf_id || suggest.shelf_id === option.shelf_id) && suggest.result_count) {
        // саджест уже закрыт полка совпадает и кол-во есть, запрос не нужен.
        return true;
      }
      if (suggest.type === 'box2shelf') {
        //если нужно выбрать другую полку
        if (suggest.shelf_id !== option.shelf_id) {
          const result = await useBox2Shelf(this.order_id, {
            suggest_id: suggest.suggest_id,
            status: 'error',
            reason: { code: 'LIKE_SHELF', shelf_id: option.shelf_id },
          });
          if (!result) return false;
        }
        const result = await useBox2Shelf(this.order_id, {
          suggest_id: suggest.suggest_id,
          count: 1,
          reason: option.reason
            ? {
                code: option.reason,
                count: 1,
              }
            : undefined,
        });
        if (!result) return false;
      } else {
        const result = await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          count: 1,
        });
        if (!result) return false;
      }
      return true;
    },
    async finishActiveSuggest(
      option: Pick<Model, 'reason'> & { shelf_id: string; package_class: TypePackageClass },
      suggest_id: string,
    ) {
      if (!this.order) return;
      const suggest = this.order.suggestById(suggest_id);
      if (!suggest) return;
      const exp_package_class = this.userStore.experimentByName(experiments.exp_package_class);

      let packageClassSaved = true;
      if (exp_package_class && option.package_class) {
        packageClassSaved = await this.savePackageClassItem(suggest.product_id, option.package_class);
      }

      const result = await this.closeActiveSuggest({ shelf_id: option.shelf_id, reason: option.reason }, suggest);

      if (!result) return;
      if (!packageClassSaved) {
        this.$notification.modal({
          title: this.$gettext('Посылка была размещена без указанного типа и размера упаковки'),
        });
      }
      this.suggestDetails.hide();
    },
    async finish(): Promise<void> {
      if (!this.order) return;
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что разместили все посылки?'),
        text: this.$gettext('Убедитесь, что вы разместили все посылки.'),
      });

      if (!confirmed) return;
      this.uiStateNeedBarcodeRequest = false;
      if (this.order.vars.lot_item_ids && this.needSignAct) {
        await this.$notification.modal({
          title: this.$gettext('Подтвердите расхождения'),
          text: this.$gettext('Убедитесь, что все статусы правильные.'),
          buttonText: this.$gettext('К акту  расхождений'),
        });
        const actConfirm = await this.differences.asyncShow();
        if (!actConfirm) return;
      }
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    checkNeedMenu(suggest_id: string): boolean {
      const menu = this.suggestMenuConfig(suggest_id);
      return menu.filter(item => !item.condition || item.condition()).length > 0;
    },
    getBadgeData(s: Suggest): BadgeData | undefined {
      if (s.status === SuggestStatusEnum.request) {
        return undefined;
      }
      if (s.result_count === 1) {
        return {
          color: 'lime-toxic',
          text: this.$gettext('Готово'),
        };
      }
      if (s.result_count === 0) {
        return {
          color: 'red-normal',
          text: this.$gettext('Нет'),
        };
      }
    },
  },
});
</script>
