import {
  getBlockedSuggests,
  getBox2ShelfSuggests,
  getDoneSuggests,
  getEmptyDoneSuggests,
  getFullDoneSuggests,
  getPartDoneSuggests,
  getRequestSuggests,
} from '@/fsd/entities/suggest/tools/suggestsFilters';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { getDefaultProgressConfig } from '@/ui/common/bar/getDefaultProgressConfig';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import { defineComponent } from 'vue';

interface Data {
  uiStateFilter: 'all' | 'done' | 'request' | 'part' | 'noProduct' | string;
}

export default defineComponent({
  data(): Data {
    return {
      uiStateFilter: 'all',
    };
  },
  computed: {
    order(): BaseOrder {
      return {} as BaseOrder;
    },
    suggests(): Suggest[] {
      if (!this.order || !this.order.suggests) {
        return [];
      }
      return this.order.suggests;
    },
    completedSuggests(): Suggest[] {
      return getDoneSuggests(this.suggests);
    },
    fullCompletedSuggests(): Suggest[] {
      return getFullDoneSuggests(this.suggests);
    },
    partiallyCompletedSuggests(): Suggest[] {
      return getPartDoneSuggests(this.suggests);
    },
    noProductSuggests(): Suggest[] {
      return getEmptyDoneSuggests(this.suggests);
    },
    requestSuggests(): Suggest[] {
      return getRequestSuggests(this.suggests);
    },
    blockedSuggests(): Suggest[] {
      return getBlockedSuggests(this.suggests);
    },
    filteredSuggests(): Suggest[] {
      switch (this.uiStateFilter) {
        case 'all':
          return this.suggests;
        case 'done':
          return this.fullCompletedSuggests;
        case 'request':
          return this.requestSuggests;
        case 'part':
          return this.partiallyCompletedSuggests;
        case 'noProduct':
          return this.noProductSuggests;
      }
      return this.suggests;
    },
    filterMenuConfig(): MenuItemConfig[] {
      const menuConfig = [
        {
          buttonText: this.$gettext('Не отсканированные'),
          color: 'secondary',
          count: this.requestSuggests.length,
          onClick: () => this.setFilter('request'),
        },
        {
          buttonText: this.$gettext('Нет'),
          color: 'red',
          count: this.noProductSuggests.length,
          onClick: () => this.setFilter('noProduct'),
        },
        {
          buttonText: this.$gettext('Почти'),
          color: 'orange',
          count: this.partiallyCompletedSuggests.length,
          onClick: () => {
            this.setFilter('part');
          },
        },
        {
          buttonText: this.$gettext('Готово'),
          color: 'green',
          count: this.fullCompletedSuggests.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
      ];
      return menuConfig;
    },
    progressConfig(): any {
      if (!this.order) return;
      if (this.order.type === 'order') {
        if (this.order.target === 'canceled') {
          return [
            {
              count: getBox2ShelfSuggests(this.fullCompletedSuggests).length,
              color: 'green',
            },
          ];
        }
      }
      return getDefaultProgressConfig(this.order.suggests);
    },
  },
  methods: {
    setFilter(filter): void {
      this.uiStateFilter = filter;
    },
  },
});
