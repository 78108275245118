<template>
  <Layout>
    <div class="p-4" data-test="exp sten gustaf thulin confirm assembled products">
      <Title2 class="font-bold block mb-2.5">
        {{ $gettext('Надо проверить возможность оплаты заказа клиентом') }}
      </Title2>
      <Body2>
        {{
          $gettext(
            'В процессе сборки заказа изменилась его стоимость — необходимо убедиться, что клиент сможет его оплатить.',
          )
        }}
      </Body2>
      <img class="mt-3 mx-auto" src="@/assets/img/bottle-bags.png" />
    </div>

    <template #footer>
      <LayoutFooter>
        <UiButton
          data-test="next-stage-btn"
          :is-disabled="hasCheck && !confirmed"
          @click="sendConfirmAssembledProductsSignal"
        >
          {{ confirmed ? $gettext('Завершить') : $gettext('Проверить') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { useConfirmAssembledProducts } from '@/fsd/data/order/useConfirmAssembledProducts';
import { useSubscribeOnOrder } from '@/fsd/data/utils/subscribeOnOrder';
import ClientOrder from '@/models/orders/ClientOrder';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps } from 'vue';

const hasCheck = computed<boolean>(() => {
  return props.order.vars.confirming_assembled_products;
});
const confirmed = computed<boolean>(() => {
  return props.order.vars.fulfilled_conditions?.confirm_assembled_products;
});
const props = defineProps<{ order: ClientOrder }>();
const emit = defineEmits(['done']);

const { confirmAssembledProducts } = useConfirmAssembledProducts();
const sendConfirmAssembledProductsSignal = async () => {
  // Если есть успешная проверка, сразу шлем эмит.
  if (confirmed.value) {
    return emit('done');
  }
  const success = await confirmAssembledProducts(props.order);
  if (!success) return;
  // ждем завершения проверки и эмитим дан
  await useSubscribeOnOrder(props.order.order_id)(o => {
    if (!o) return true;
    return o.vars.fulfilled_conditions?.confirm_assembled_products;
  });
  emit('done');
};
</script>
