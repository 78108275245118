<template>
  <Layout>
    <div class="flex flex-col items-center justify-center mt-4">
      <title4 class="font-medium">
        <template v-if="uiState.step === 'special_package'"> {{ $gettext('Товары 18+') }} </template>
        <template v-if="uiState.step === 'fragile'"> {{ $gettext('Хрупкие товары') }} </template>
        <template v-if="uiState.step === 'other'"> {{ $gettext('Остальные товары') }} </template>
      </title4>
    </div>

    <div class="px-4 mt-4">
      <Hint v-if="uiState.step === 'fragile'" custom-bg-color="#F9E6FF" custom-ears-color="#F5D1FF" class="mb-2">
        <span class="text-purple-dark">
          {{ $gettext('Сложите их в отдельный пакет. Не ставьте ничего сверху на этот пакет') }}
        </span>
      </Hint>

      <Hint
        v-if="uiState.step === 'special_package'"
        custom-bg-color="#FFBA8F66"
        custom-ears-color="#FFBA8F"
        class="mb-2"
      >
        <span class="text-red-dark">
          {{ $gettext('Сложите их в непрозрачный пакет') }}
        </span>
      </Hint>

      <Hint v-if="uiState.step === 'other'" class="mb-2">
        {{ $gettext('Разложите их по пакетам согласно инструкции') }}
      </Hint>
      <Hint v-if="uiState.step === 'other'" class="mb-2">
        {{ $gettext('Положите бытовую химию в отдельный пакет') }}
      </Hint>
    </div>

    <div v-for="suggest in activeSuggests" :key="suggest.suggest_id">
      <ProductCard class="product" :product-id="suggest.product_id" :order-id="order.order_id">
        <template #info>
          <Row data-test="suggest card count row" :label="$gettext('Количество')" :value="getCount(suggest)" />
        </template>
      </ProductCard>
    </div>
    <template #footer>
      <div class="bg-main py-3 px-4 flex shadow-footer">
        <UiButton data-test="order-sort next-btn" @click="onReadyClick">
          {{ $gettext('Продолжить') }}
        </UiButton>
      </div>
    </template>
  </Layout>
</template>

<script lang="ts">
import OrderOrder from '@/models/orders/OrderOrder';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import ProductCard from '@/ui/common/product-card/product-card.vue';
import Row from '@/ui/common/suggest-card/row.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

interface Data {
  uiState: {
    step: 'fragile' | 'special_package' | 'other';
  };
}

export default defineComponent({
  name: 'SortProductCard',
  components: {
    UiButton,
    Layout,
    Hint,
    ProductCard,
    Row,
  },
  props: {
    order: {
      type: Object as PropType<OrderOrder>,
      required: true,
    },
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
  },
  emits: ['ready-click'],
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  data(): Data {
    return {
      uiState: {
        step: 'fragile',
      },
    };
  },
  computed: {
    activeSuggests(): Suggest[] {
      switch (this.uiState.step) {
        case 'special_package':
          return this.suggests.filter(s => {
            if (s.vars.mode === 'item') return false;
            return s.product?.special_package;
          });
        case 'fragile':
          return this.suggests.filter(s => {
            if (s.vars.mode === 'item') return false;
            return s.product?.fragile && !s.product.special_package;
          });
        case 'other':
        default:
          return this.suggests.filter(s => {
            if (s.vars.mode === 'item') return true;
            return !s.product?.fragile && !s.product?.special_package;
          });
      }
    },
  },
  mounted() {
    if (!this.order.hasFragile && !this.order.hasSpecialPackageProducts) {
      //  нет хрупкого и 18+. можно выходить
      this.$emit('ready-click');
    }
    if (
      this.suggests.some(s => {
        if (s.vars.mode === 'item') return false;
        return s.product?.fragile && !s.product.special_package;
      })
    ) {
      this.uiState.step = 'fragile';
    } else this.uiState.step = 'special_package';
  },
  methods: {
    getCount(suggest: Suggest): string {
      if (suggest.product?.isTrueWeight) {
        const g = suggest.result_count! % 1000;
        const kg = (suggest.result_count! - g) / 1000;
        if (kg) {
          return this.$gettext('%{kg} кг %{g} г', { kg: String(kg), g: String(g) });
        } else {
          return this.$gettext('%{g} г', { g: String(g) });
        }
      }
      return this.$gettext('%{count} шт.', { count: String(suggest.count) });
    },
    onReadyClick(): void {
      if (this.uiState.step === 'fragile') {
        if (this.order.hasSpecialPackageProducts) {
          this.uiState.step = 'special_package';
        } else {
          this.uiState.step = 'other';
        }
        return;
      }
      if (this.uiState.step === 'special_package') {
        this.uiState.step = 'other';
        return;
      }
      this.$emit('ready-click');
    },
  },
});
</script>

<style scoped>
.product.product {
  @apply bg-warmGray-50;
}
</style>
