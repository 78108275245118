<template>
  <div class="click-wrap">
    <div
      v-if="isTrueWeight"
      class="click-container px-4"
      :class="{ disabled }"
      data-test="counter value"
      @click="showCalc"
    >
      <div class="grow text-base accent-warmGray-600 flex justify-between items-center h-14">
        <Body2 v-if="!count" class="text-day-textMinor">
          {{ needHistory ? $gettext('Вес товара, в г') : $gettext('Вес товара, в кг') }}
        </Body2>
        <template v-else>
          {{ count / 1000 }}
          <Body2 class="text-day-textMinor">{{ weightToView }}</Body2>
        </template>
      </div>
    </div>
    <div v-else class="click-container" :class="{ disabled }">
      <button
        data-test="counter minus"
        class="click-button"
        :disabled="disabledMinus"
        @click="onMouseClick(-1)"
        @touchstart="onStart(-1)"
        @touchend="onStop()"
        @touchcancel="onStop()"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="5" y="11" width="14" height="2" />
        </svg>
      </button>
      <div class="divider" />
      <div class="click-info">
        <div>
          <Typography type="text4" :color="disabled ? 'gray5' : 'secondary-text'">
            {{ label }}
          </Typography>
        </div>
        <div class="count-container" data-test="counter calc-activator" @click="showCalc">
          <Typography data-test="counter value" type="label1" :color="disabled ? 'secondary-text' : 'primary-text'">
            {{ count }}
          </Typography>
        </div>
      </div>
      <div class="divider" />
      <button
        data-test="counter plus"
        class="click-button"
        :disabled="disabledPlus"
        @click="onMouseClick(1)"
        @touchstart="onStart(1)"
        @touchend="onStop()"
        @touchcancel="onStop()"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="5" y="11" width="14" height="2" />
          <rect x="11" y="19" width="14" height="2.001" transform="rotate(-90 11 19)" />
        </svg>
      </button>
    </div>
    <UiButton
      v-if="!customBtn"
      data-test="counter continue"
      is-icon
      :is-disabled="disabled"
      @click="onButtonCountConfirm"
    >
      <img v-if="!next" :class="{ disabled: disabled, 'icon-check': true }" src="./input/img/check.svg" alt="" />
      <img v-if="next" :class="{ disabled: disabled, 'icon-check': true }" src="./input/img/next.svg" alt="" />
    </UiButton>
    <UiButton
      v-else
      background-color="secondary"
      data-test="counter continue"
      :is-hold="isHold"
      :timeout="timeoutForBtn"
      :is-disabled="disabled"
      @click="onButtonCountConfirm"
    >
      <slot />
    </UiButton>
    <Calc v-if="calc.visible.value" :is-true-weight="isTrueWeight" :min="min" :max="max" @close="calc.hide" />
    <CalcWithHistory v-if="calcWithHistory.visible.value" :min="min" :max="max" @close="calcWithHistory.hide" />
  </div>
</template>

<script lang="ts">
import { useComponent } from '@/hooks/useComponent';
import requestBarcode from '@/mixins/requestBarcode';
import Product, { TypeAccountingEnum } from '@/models/Product';
import type Suggest from '@/models/Suggest';
import { AudioService } from '@/services/audio.service';
import { ModeService } from '@/services/mode.service';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useProducts } from '@/store/modules/products';
import CalcWithHistory from '@/ui/common/keyboard/calc-with-history.vue';
import Calc from '@/ui/common/keyboard/calc.vue';
import { getWeightToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import Body2 from '@/ui/common/typo/body-2.vue';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
  count: number;
  isClickStarted: boolean;
  clickIntense: number;
  clickIncrease: number;
  timerId?: number;
  hasClickCount: boolean;
}

export default defineComponent({
  name: 'Counter',
  components: {
    Body2,
    Typography,
    UiButton,
    Calc,
    CalcWithHistory,
  },
  mixins: [requestBarcode],
  props: {
    label: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    min: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    isNext: {
      type: Boolean,
      default: false,
    },
    isNextCondition: {
      type: Function,
      default: () => () => true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: undefined,
    },
    customBtn: {
      type: Boolean,
      default: false,
    },
    timeoutForBtn: {
      type: Number,
      default: 0,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
    needHistory: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm'],
  setup() {
    const productsStore = useProducts();
    const calc = useComponent();
    const calcWithHistory = useComponent();

    return { productsStore, calc, calcWithHistory };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: false,
      count: this.value,
      isClickStarted: false,
      clickIntense: 1,
      clickIncrease: 1,
      timerId: undefined,
      hasClickCount: false,
    };
  },
  computed: {
    isHold(): boolean {
      return !ModeService.isDevelopment();
    },
    product(): Product | undefined {
      if (this.productId) {
        return this.productsStore.productById(this.productId);
      } else if (this.suggest) {
        return this.suggest.product;
      }
      return undefined;
    },
    isTrueWeight(): boolean {
      return this.product?.type_accounting === TypeAccountingEnum.true_weight;
    },
    weightToView(): string {
      return getWeightToView(this.count);
    },
    next(): boolean {
      if (this.isNext) {
        return this.isNextCondition(this.count);
      }
      return false;
    },
    disabledPlus(): boolean {
      return this.disabled || (Number.isFinite(this.max) && this.count >= this.max);
    },
    disabledMinus(): boolean {
      return this.disabled || (Number.isFinite(this.min) && this.count <= this.min);
    },
    holdPoint(): number | null {
      if (!this.suggest || this.suggest.conditions?.max_count) return null;
      return this.suggest.count;
    },
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal) {
        this.count = val;
      }
    },
    disabled: {
      handler(val) {
        this.uiStateNeedBarcodeRequest = !!(!val && this.productId);
      },
      immediate: true,
    },
  },
  unmounted() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
  methods: {
    async showCalc(): Promise<void> {
      if (this.needHistory) {
        // needHistory определяет какой калькулятор будет использоваться
        this.count = Number(await this.calcWithHistory.asyncShow());
      } else {
        const value = await this.calc.asyncShow();
        this.count = this.isTrueWeight ? value! * 1000 : Number(value);
      }
    },
    onMouseClick(direction: number): void {
      if (ModeService.isDevelopment()) {
        this.onClick(direction);
      }
    },
    onClick(direction: number): void {
      if (this.disabled) {
        return;
      }

      const newCount = this.count + Math.floor(direction * this.clickIncrease);

      if (this.holdPoint && newCount >= this.holdPoint && this.holdPoint > this.count) {
        this.count = this.holdPoint;
        setTimeout(() => {
          this.onStop();
        }, 0);
        return;
      }

      if (newCount >= this.min && newCount <= this.max) {
        this.count = newCount;
      }
      if (newCount > this.max) {
        this.count = this.max;
        this.clickIncrease = 1;
      }
      if (newCount < this.min) {
        this.count = this.min;
        this.clickIncrease = 1;
      }
    },
    onStart(direction: number): void {
      if (!this.hasClickCount) {
        this.onClick(direction);
        this.hasClickCount = true;
      }
      if (!this.timerId)
        this.timerId = setTimeout(() => {
          if (this) this.timerId = undefined;
          if (this?.hasClickCount) {
            this.onClick(direction);
            if (this.clickIntense <= 4) {
              this.clickIntense *= 1.15;
            }
            if (this.clickIncrease <= 10) {
              this.clickIncrease *= 1.03;
            }
            this.onStart(direction);
          }
        }, 400 / this.clickIntense);
    },
    onStop(): void {
      this.hasClickCount = false;
      this.clickIntense = 1;
      this.clickIncrease = 1;
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = undefined;
      }
    },
    onButtonCountConfirm(): void {
      this.uiStateNeedBarcodeRequest = false;
      this.$emit('confirm', { value: this.count });
    },
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      if (this.product?.barcode.includes(barcode)) {
        this.onClick(1);
      } else {
        AudioService.playError();
      }
      return true;
    },
  },
});
</script>

<style lang="scss" scoped>
.click-button {
  background: transparent;
  align-items: center;
  border: 0;
  display: flex;
  height: 52px;
  width: 52px;
  justify-content: center;
  margin: 0;
  padding: 0;
  user-select: none;

  &:disabled {
    svg {
      fill: var(--text-secondary-color);
    }
  }

  svg {
    fill: var(--text-primary-color);
  }
}

.divider {
  width: 1px;
  height: 20px;
  background: var(--text-primary-color);
  opacity: 0.3;
}

.click-wrap {
  width: 100%;
  display: flex;
}

.click-container {
  background: var(--counter-bg);
  border-radius: 16px;
  display: flex;
  width: 100%;
  margin-right: 8px;
  align-items: center;
  overflow: hidden;

  &.disabled {
    opacity: 0.6;
  }
}

.click-info {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
}

.icon-check {
  &.disabled {
    path {
      fill: #c4c2be;
    }
  }
}

.count-container {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: var(--main-bg);
  justify-content: center;
}
</style>
