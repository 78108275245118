<template>
  <Layout>
    <template v-if="!loading" #header>
      <ProductWriteoffHeader
        :has-tail="Boolean(tailOnSrc)"
        :current-step="currentStep"
        @writeoff-tail="writeoffTail"
        @writeoff-all="writeoffAll"
      />
    </template>
    <template #default>
      <div v-if="!loading" class="flex flex-col gap-2 pb-4">
        <ProductTitle :product_id="product_id" class="mx-4" />
        <ProductMainInfo v-if="currentStep !== 'reason'" :product_id="product_id" class="mx-4" />
        <template v-if="available?.length && currentStep === 'src'">
          <Separator />
          <ProductAvailable :product_id="product_id" class="mx-4" />
          <Separator />
          <ProductAvailableTotal :product_id="product_id" class="mx-4" />
        </template>
        <!--Выбрали исходную полку-->
        <template v-if="currentStep !== 'src' && currentStep !== 'reason' && availableOnSrc">
          <Separator />
          <div class="flex flex-col mx-4 gap-6">
            <InfoRow v-if="srcShelf" :title="$gettext('Полка')" :value="srcShelf.title" />
            <template v-if="availableOnSrc">
              <template v-if="isComponents">
                <InfoRow :title="$gettext('Закрытых упаковок на полке')" :value="String(closedPackageOnSrc)" />
                <InfoRow v-if="tailOnSrc" :title="$gettext('В открытой упаковке')" :value="tailViewValue" />
              </template>
              <InfoRow v-else :title="$gettext('На полке')" :value="getCountView(availableOnSrc.count)" />

              <InfoRow :title="$gettext('Резерв')" :value="getCountView(availableOnSrc.reserved)" />
            </template>

            <InfoRow
              v-if="writeoffPayload.count !== undefined"
              :title="$gettext('Списываем')"
              :value="writeoffCountTitle"
            />
            <InfoRow
              v-if="writeoffPayload.tail !== undefined"
              :title="$gettext('Списываем')"
              :value="writeoffQuantTitle"
            />
          </div>
        </template>
        <RadioGroup
          v-if="currentStep === 'reason'"
          v-model="writeoffPayload.reason"
          :items="productWriteoffReasons"
          class="p-4"
        />
      </div>
      <div v-else class="h-full flex flex-col items-center justify-center">
        <LoaderIndicator class="mb-4" />
        <body1 class="mb-2">
          {{ $gettext('Загрузка') }}
        </body1>
        <caption1 class="mb-2">
          {{ $gettext('Пожалуйста, подождите') }}
        </caption1>
      </div>
    </template>
    <template v-if="!loading" #footer>
      <LayoutFooter class="flex-col">
        <template v-if="currentStep === 'src'">
          <Hint class="mb-2">
            {{ $gettext('Отсканируйте нужную полку') }}
          </Hint>
          <Counter disabled :button-label="$gettext('Продолжить')" value="1" is-next />
        </template>
        <template v-if="currentStep === 'count'">
          <Hint class="mb-2">
            {{
              product.quant_unit && product.quants > 1
                ? $gettext('Сколько упаковок хотите списать')
                : $gettext('Сколько товаров хотите списать')
            }}
          </Hint>
          <Counter
            :button-label="$gettext('Продолжить')"
            :min="isComponents ? 0 : 1"
            :max="max"
            :value="isComponents ? 0 : 1"
            :product-id="product_id"
            is-next
            @confirm="setCount"
          />
        </template>
        <template v-if="currentStep === 'tail'">
          <Hint class="mb-2">
            {{
              isComponents && product.quant_unit === 'unit'
                ? $gettext('Сколько шт хотите списать?')
                : $gettext('Сколько %{quant_unit} из открытой упаковки вы хотите списать?', {
                    quant_unit: getQuantUnit(product.quant_unit, 10),
                  })
            }}
          </Hint>
          <Counter
            :button-label="$gettext('Продолжить')"
            :value="writeoffPayload.count ? 0 : 1"
            :min="writeoffPayload.count ? 0 : 1"
            :max="tailOnSrc || availableOnSrc?.count"
            :product-id="product_id"
            is-next
            @confirm="setTail"
          />
        </template>
        <template v-if="currentStep === 'reason'">
          <Hint class="mb-2">
            {{ $gettext('Какая причина списания') }}
          </Hint>
          <UiButton data-test="product card writeoff stage btn" :disabled="!writeoffPayload.reason" @click="nextStep">
            {{ $gettext('Перейти к списанию') }}
          </UiButton>
        </template>
        <template v-if="currentStep === 'dest'">
          <Hint class="mb-2">
            {{ $gettext('Сканируйте полку списания') }}
          </Hint>
          <UiButton disabled>
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
        <template v-if="currentStep === 'finish'">
          <Hint class="mb-2">
            {{ $gettext('Разместите товар на полке') }}
          </Hint>
          <UiButton data-test="finish writeoff btn" @click="nextStep">
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import { api } from '@/fsd/data/api/api.service';
import { useCheckProductResources } from '@/fsd/data/utils/checkResourse';
import InfoRow from '@/fsd/widgets/productCard/InfoRow.vue';
import ProductAvailable from '@/fsd/widgets/productCard/ProductAvailable.vue';
import ProductAvailableTotal from '@/fsd/widgets/productCard/ProductAvailableTotal.vue';
import ProductMainInfo from '@/fsd/widgets/productCard/ProductMainInfo.vue';
import ProductTitle from '@/fsd/widgets/productCard/ProductTitle.vue';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import OrderEvent from '@/models/events/OrderEvent';
import { OrderTypeEnum } from '@/models/orders/BaseOrder';
import Product, { TypeAccountingEnum } from '@/models/Product';
import Shelf from '@/models/Shelf';
import { isAxiosError } from '@/models/typeGuards';
import shelfQueue from '@/services/queue/shelf-queue';
import { OrderMoveRequest } from '@/services/requests';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { shelfTypes } from '@/temp/constants/translations';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import IconInformer from '@/temp/icons/icon-informer.vue';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import RadioGroup from '@/temp/ui/inputs/radio-group/radio-group.vue';
import { TrashReasonsEnum } from '@/types/TrashReasonsEnum';
import Counter from '@/ui/common/counter.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { getCountToView, getWeightToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import UiButton from '@/ui/common/ui-button.vue';
import Separator from '@/ui/home/product-card/separator.vue';
import { productWriteoffReasons } from '@/ui/home/product-card/writeoff-reasons';
import { subscribeOnOrderStatus } from '@/utils/subscribeOnOrder';
import ProductWriteoffHeader from '@/views/ProductWriteoff/ProductWriteoffHeader.vue';
import { Step } from '@/views/ProductWriteoff/types';
import uuidv1 from 'uuid/v1';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

interface WriteoffPayload {
  count: number;
  tail: number;
  reason: TrashReasonsEnum;
  src_shelf_id: Shelf['shelf_id'];
  dst_shelf_id: Shelf['shelf_id'];
}

const props = defineProps<{
  product_id: Product['product_id'];
}>();
const { product_id } = props;

const router = useRouter();
const { loading } = useCheckProductResources(product_id);
const { showLoader } = useLoader();

const writeoffPayload = ref<Partial<WriteoffPayload>>({});
const currentStepIndex = ref(0);
const available = computed(() => {
  return useProducts().availableByProductId(product_id);
});
const availableOnSrc = computed(() => {
  if (!available.value) return;
  return available.value.find(a => a.shelf_id === writeoffPayload.value.src_shelf_id);
});
const closedPackageOnSrc = computed(() => {
  if (!availableOnSrc.value || !product.value) return 0;
  return ~~(availableOnSrc.value.count / product.value.quants);
});
const tailOnSrc = computed(() => {
  if (!availableOnSrc.value || !product.value) return 0;
  return availableOnSrc.value.count % product.value.quants;
});
const tailViewValue = computed(() => {
  if (!product.value) return '0';
  if (!product.value.quant_unit) return String(tailOnSrc.value);
  return `${tailOnSrc.value} ${getQuantUnit(product.value.quant_unit)}`;
});
const srcShelf = computed(() => {
  return useShelves().shelfById(writeoffPayload.value.src_shelf_id);
});
const product = computed(() => {
  return useProducts().productById(product_id);
});
const steps: Step[] = ['src', 'count', 'tail', 'reason', 'dest', 'finish'];
const currentStep = computed(() => {
  return steps[currentStepIndex.value];
});
const max = computed(() => {
  if (!availableOnSrc.value || !product.value) return undefined;
  const available = availableOnSrc.value;
  const availableCount = available.is_components ? Math.floor(available.count / available.quants) : available.count;

  if (useUser().isJunior) {
    const maxWeight = useUser().getMaxWeightForOrder(OrderTypeEnum.move);
    const maxCount = Math.floor(maxWeight / product.value.weight);
    return Math.min(maxCount, maxWeight);
  }
  return availableCount;
});
const isComponents = computed(() => {
  if (!product.value || !srcShelf.value) return false;
  return product.value.quants > 1 && srcShelf.value.type === 'kitchen_components';
});
const writeoffCountTitle = computed(() => {
  const count = writeoffPayload.value.count;
  if (!count || !product.value) return '-';
  const quant_unit = product.value.quant_unit;
  if (isComponents.value && quant_unit) {
    if (quant_unit === 'unit') return $gettext('%{count} шт.', { count: String(count) });
    return $gettext('%{count} уп.', { count: String(count) });
  } else {
    return getCountToView({
      type_accounting: product.value.type_accounting,
      count,
    });
  }
});
const writeoffQuantTitle = computed(() => {
  const tail = writeoffPayload.value.tail;
  if (!tail || !product.value) return '-';
  if (!product.value.quant_unit)
    return $ngettext('%{count} порций', '%{count} порций', tail, {
      count: String(tail),
    });
  return `${tail} ${getQuantUnit(product.value.quant_unit)}`;
});
const nextStep = (step?: Step) => {
  if (currentStepIndex.value === steps.length - 1) {
    createWriteoff();
  } else {
    if (step) {
      const stepIdx = steps.findIndex(s => s === step);
      if (stepIdx !== -1) currentStepIndex.value = stepIdx;
      else throw 'wrong step name';
    } else {
      currentStepIndex.value = currentStepIndex.value + 1;
    }
    if (currentStep.value === 'count') {
      if (!product.value) {
        return;
      }
      //   Проверяем, что нужно вводить кол-во
      // для товара, что измеряется в штуках, а не в пачках хотим чтобы пользователь сразу вводил именно кванты
      if (product.value.quant_unit === 'unit' && isComponents.value) {
        nextStep();
      }
    }
    if (currentStep.value === 'tail') {
      if (!product.value || !srcShelf.value) {
        Notifications.error.micro($gettext('Произошла ошибка при загрузке полки'));
        toHomePage();
        return;
      }
      //   Проверяем, что нужно вводить хвост из открытой пачки
      if (!isComponents.value) {
        nextStep();
        return;
      }
    }
    if (currentStep.value === 'dest') {
      getDestShelf();
    }
  }
};
const toHomePage = () => {
  router.push({ name: 'home' });
};
const setSrcShelf = (shelf_id: Shelf['shelf_id']) => {
  writeoffPayload.value.src_shelf_id = shelf_id;
  nextStep();
};
const setDestShelf = (shelf_id: Shelf['shelf_id']) => {
  writeoffPayload.value.dst_shelf_id = shelf_id;
  nextStep();
};
const setCount = ({ value }) => {
  writeoffPayload.value.count = parseInt(value, 10);
  if (isComponents.value) {
    Notifications.modal({
      title: $gettext('Списание товаров из открытых упаковок'),
      text: $gettext('Теперь необходимо указать сколько списываете товаров из открытых упаковок'),
    });
  }
  nextStep();
};
const setTail = ({ value }) => {
  writeoffPayload.value.tail = parseInt(value, 10);
  nextStep();
};
const checkAvailable = async () => {
  if (!available.value || !available.value.length) {
    //  ошибка, нельзя списать то, чего нет
    toHomePage();
    return;
  }
  if (available.value.length === 1) {
    //  Остатки есть только на одной полке.
    const shelf_id = available.value[0].shelf_id;
    const shelf = await shelfQueue.load(shelf_id);
    const valid = await validateSrcShelf(shelf);
    if (!valid) {
      toHomePage();
      return;
    }
    setSrcShelf(shelf_id);
    return;
  }
  getSrcShelf();
};
const validateSrcShelf = async (s: Shelf) => {
  if (!product.value) return false;
  if (s.status === 'disabled') {
    await Notifications.modal({
      title: $gettext('Полка (ячейка), с которой вы берете продукт, отключена'),
    });
    return false;
  }

  if (!['store', 'markdown', 'office', 'kitchen_components', 'kitchen_on_demand', 'repacking'].includes(s.type)) {
    await Notifications.modal({
      title: $gettext('С полки %{type} невозможно ничего перенести', {
        type: shelfTypes(s.type),
      }),
      iconTop: {
        icon: IconInformer,
        position: 'center',
      },
    });
    return false;
  }
  const availableOnShelf = available.value!.find(a => a.shelf_id === s.shelf_id);
  if (!availableOnShelf) {
    Notifications.error.micro($gettext('Отсканирована неверная полка. На полке отсутствует данный товар.'));
    return false;
  }
  return true;
};
const validateDestShelf = async (dest: Shelf) => {
  const source = srcShelf.value;
  if (!source) {
    logger.error('Перемещение. Не выбрана полка источник при проверке полки назначения');
    await Notifications.modal({
      title: $gettext('Что-то с сетью, попробуйте ещё раз'),
    });
    toHomePage();
    return false;
  }
  let valid = dest.type === 'trash';
  if (source.type === 'kitchen_components') {
    valid = dest.type === 'kitchen_trash';
  }
  if (!valid) {
    await Notifications.modal({
      title: $gettext('С полки %{srcShelf} перенести на %{destShelf} нельзя', {
        srcShelf: shelfTypes(source.type),
        destShelf: shelfTypes(dest.type),
      }),
    });
    return false;
  }
  return true;
};
const getSrcShelf = () => {
  useRequestBarcode(async barcode => {
    const { closeLoader } = showLoader($gettext('Ищем полку по ШК'));
    try {
      await useShelves().getShelfByBarcode(barcode);
      closeLoader();
    } catch (error) {
      closeLoader();
      Notifications.error.micro($gettext('Не найден штрихкод'));
      return true;
    }
    const shelf = useShelves().shelfByBarcode(barcode);
    if (!shelf) {
      Notifications.error.micro($gettext('Не найден штрихкод'));
      return true;
    }
    const valid = await validateSrcShelf(shelf);
    if (!valid) return true;
    setSrcShelf(shelf.shelf_id);
    return false;
  });
};
const getDestShelf = () => {
  useRequestBarcode(async barcode => {
    const { closeLoader } = showLoader($gettext('Ищем полку по ШК'));
    try {
      await useShelves().getShelfByBarcode(barcode);
      closeLoader();
    } catch (error) {
      closeLoader();
      Notifications.error.micro($gettext('Не найден штрихкод'));
      return true;
    }
    const shelf = useShelves().shelfByBarcode(barcode);
    if (!shelf) {
      Notifications.error.micro($gettext('Не найден штрихкод'));
      return true;
    }
    const valid = await validateDestShelf(shelf);
    if (!valid) return true;
    setDestShelf(shelf.shelf_id);
    return false;
  });
};
const createWriteoff = async () => {
  if (!availableOnSrc.value || !product.value) {
    return;
  }

  const p = product.value;
  const count = writeoffPayload.value.count || 0;
  const tail = writeoffPayload.value.tail || 0;

  let title = $gettext('Вы уверены, что разместили товар на полку списания?');
  if (p.type_accounting === TypeAccountingEnum.true_weight && count) {
    title = $gettext('Вы уверены, что хотите списать %{weightPart} %{product}?', {
      weightPart: String(getWeightToView(count)),
      product: p.title,
    });
  }
  if (p.quants > 1 && p.quant_unit) {
    const quantsTotal = count * p.quants + tail;
    switch (true) {
      case p.quant_unit === 'unit':
        title = $gettext('Вы уверены, что разместили %{count} шт этого товара на полку списания?', {
          count: String(quantsTotal),
        });
        break;
      case count === 0:
        title = $gettext(
          'Вы уверены, что разместили открытую упаковку этого товара(%{unit_count} %{unit}) на полку списания?',

          {
            unit_count: String(quantsTotal),
            unit: getQuantUnit(p.quant_unit),
          },
        );
        break;
      default:
        title = $ngettext(
          'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полку списания?',
          'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полку списания?',
          count,
          {
            count: String(count),
            unit_count: String(quantsTotal),
            unit: getQuantUnit(p.quant_unit),
          },
        );
    }
  }
  const confirmed = await Notifications.confirmBottom({ title });
  if (!confirmed) return;

  const calculateCount = () => {
    let count: number = writeoffPayload.value.count || 0;
    const quants = product.value?.quants || 1;
    if (availableOnSrc.value?.is_components) {
      count = count * quants;
    }
    count = count + (writeoffPayload.value.tail || 0);
    return count;
  };

  const countForMove = calculateCount();

  const payload: OrderMoveRequest = {
    order_id: uuidv1(),
    move: [
      {
        product_id: props.product_id,
        count: countForMove,
        src_shelf_id: writeoffPayload.value.src_shelf_id!,
        dst_shelf_id: writeoffPayload.value.dst_shelf_id!,
        reason: {
          code: writeoffPayload.value.reason!,
        },
      },
    ],
  };
  const { closeLoader } = showLoader($gettext('Выполняем списание товаров'));
  try {
    const { data } = await api.order.move(payload);
    const order_id = data.order.order_id;
    const cbSuc = (data: OrderEvent, unSub) => {
      unSub();
      closeLoader();
      Notifications.success.micro($gettext('Списание успешно выполнено'));
      toHomePage();
    };
    const cbFail = (data: OrderEvent, unSub) => {
      unSub();
      closeLoader();
      const text = `event: \nstatus: ${data.status} \nestatus: ${data.estatus} `;
      Notifications.modal({ title: $gettext('Произошла ошибка при проведении списания'), text });
      toHomePage();
    };
    subscribeOnOrderStatus({ order_id, cbSuc, cbFail });
  } catch (error: unknown) {
    closeLoader();
    if (isAxiosError(error)) {
      const response = error.response;
      switch (response?.data.details?.errors[0]?.code) {
        case 'ER_COUNT_OR_RESERVE': {
          Notifications.modal({
            title: $gettext('Нельзя переместить товар'),
            text:
              $gettext('Товар зарезервирован или вы пытаетесь переместить больше, чем есть на остатках') +
              '\nER_COUNT_OR_RESERVE',
          });
          break;
        }
        default: {
          Notifications.modal({
            title: $gettext('Произошла ошибка при проведении списания'),
            text: `status: ${response?.status},
              code: ${response?.data.code},
              message: ${response?.data.message}`,
          });
        }
      }
    } else {
      Notifications.modal({
        title: $gettext('Что-то с сетью, попробуйте ещё раз'),
        text: String(error),
      });
      toHomePage();
    }
  }
};
const getCountView = (count: number) => {
  if (!product.value) return '';
  return getCountToView({
    type_accounting: product.value.type_accounting,
    count,
  });
};
const writeoffTail = () => {
  if (!availableOnSrc.value || !product.value) return;
  writeoffPayload.value.count = 0;
  writeoffPayload.value.tail = availableOnSrc.value.count % product.value.quants;
  nextStep('reason');
};
const writeoffAll = () => {
  if (!availableOnSrc.value || !product.value) return;
  if (isComponents.value) {
    writeoffPayload.value.count = ~~(availableOnSrc.value.count / product.value.quants);
    writeoffPayload.value.tail = availableOnSrc.value.count % product.value.quants;
  } else {
    writeoffPayload.value.count = availableOnSrc.value.count;
  }
  nextStep('reason');
};

onMounted(() => {
  if (loading.value) {
    const unWatchLoading = watch(loading, l => {
      if (l) return;
      unWatchLoading();
      //   ждем завершения загрузки
      checkAvailable();
    });
  } else {
    checkAvailable();
  }
});
</script>
