<template>
  <div class="h-full flex">
    <FinishOrderCardPackageInit v-if="currentStep === 'init'" @done="nextStep" />
    <UltimaPackage v-if="currentStep === 'UltimaPackage'" :suggests="suggests" :packing="packing" @done="nextStep" />
    <SelectBagsCountStage
      v-if="currentStep === 'SelectBagsCount'"
      :packing="packing"
      :suggests="suggests"
      :order_id="order.order_id"
      :has-next-step="stepIndex < steps.length - 1"
      @done="nextStep"
    />
    <ConfirmAssembledProducts v-if="currentStep === 'ConfirmAssembledProducts'" :order="order" @done="nextStep" />
    <FindRover v-if="currentStep === 'FindRover'" :order="order" @done="nextStep" />
    <SortProductCard v-if="currentStep === 'sort'" :order="order" :suggests="suggests" @ready-click="nextStep" />
    <StatusCard
      v-if="currentStep === 'finish'"
      :order_id="order.order_id"
      :suggests="suggests"
      :packing="packing"
      :has-problem="hasProblem"
      @finish="nextStep"
    />
    <RoverCard v-if="currentStep === 'rover'" :order="order" @ready-click="$emit('done')" />
    <FinishOrderCardQRCode v-if="currentStep === 'qrcode'" :order="order" @done="$emit('done')" />
  </div>
</template>

<script lang="ts">
import ConfirmAssembledProducts from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/ConfirmAssembledProducts.vue';
import FindRover from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/FindRover.vue';
import SelectBagsCountStage from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/SelectBags/SelectBagsCountStage.vue';
import UltimaPackage from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/UltimaPackage.vue';
import FinishOrderCardPackageInit from '@/fsd/widgets/order/finish-order/finish-order-card/finish-order-card-package-init.vue';
import FinishOrderCardQRCode from '@/fsd/widgets/order/finish-order/finish-order-card/finish-order-card-qrcode.vue';
import RoverCard from '@/fsd/widgets/order/finish-order/rover-card/rover-card.vue';
import SortProductCard from '@/fsd/widgets/order/finish-order/sort-product-card/sort-product-card.vue';
import StatusCard from '@/fsd/widgets/order/finish-order/status-card/status-card.vue';
import OrderOrder from '@/models/orders/OrderOrder';
import Suggest from '@/models/Suggest';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getItem } from '@/utils/localStorageHelper';
import { defineComponent, PropType } from 'vue';

enum StepEnum {
  SelectBagsCount = 'SelectBagsCount',
  UltimaPackage = 'UltimaPackage',
  ConfirmAssembledProducts = 'ConfirmAssembledProducts',
  FindRover = 'FindRover',
  init = 'init',
  sort = 'sort',
  finish = 'finish',
  rover = 'rover',
  qrcode = 'qrcode',
}

interface Data {
  stepIndex: number;
}

export default defineComponent({
  components: {
    FinishOrderCardQRCode,
    SelectBagsCountStage,
    UltimaPackage,
    FindRover,
    ConfirmAssembledProducts,
    RoverCard,
    StatusCard,
    SortProductCard,
    FinishOrderCardPackageInit,
  },
  props: {
    order: {
      type: Object as PropType<OrderOrder>,
      required: true,
    },
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
    packing: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
    hasProblem: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['done'],
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  data(): Data {
    return { stepIndex: 0 };
  },
  computed: {
    steps() {
      const steps: StepEnum[] = [];
      const expStenGustafThulin = this.userStore.experimentByName(experiments.exp_sten_gustaf_thulin);
      const expCheckQr = this.userStore.experimentByName(experiments.exp_check_qr);

      if (expStenGustafThulin) {
        if (
          this.suggests.some(s => s.isItemSuggest && s.item?.isUltimaParcel) &&
          this.packing.some(p => p.isUltimaPackage)
        ) {
          steps.push(StepEnum.UltimaPackage);
        }
        steps.push(StepEnum.SelectBagsCount);
      } else {
        steps.push(StepEnum.init);
        steps.push(StepEnum.sort);
        steps.push(StepEnum.finish);
      }

      if (this.order.isRover) {
        if (expStenGustafThulin) {
          steps.push(StepEnum.FindRover);
        }
        steps.push(StepEnum.rover);
      }

      if (expStenGustafThulin && this.order.conditions.confirm_assembled_products) {
        steps.push(StepEnum.ConfirmAssembledProducts);
      }

      if (expCheckQr) {
        steps.push(StepEnum.qrcode);
      }

      return steps;
    },
    currentStep() {
      return this.steps[this.stepIndex];
    },
  },
  mounted() {
    if (this.order && getItem('roverScan', this.order.order_id)) {
      const roverIndex = this.steps.findIndex(step => step === StepEnum.rover);
      if (roverIndex !== -1) {
        this.stepIndex = roverIndex;
      }
    }
  },
  methods: {
    nextStep() {
      if (this.stepIndex >= this.steps.length - 1) {
        // это был последний шаг
        this.$emit('done');
        return;
      }
      this.stepIndex++;
    },
  },
});
</script>
