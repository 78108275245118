<template>
  <svg width="24" height="24" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.91479 3.00007H13.0001C13.2762 3.00007 13.5001 3.22393 13.5001 3.50007C13.5001 3.77621 13.2762 4.00007 13.0001 4.00007H12.9222C12.9362 4.02252 12.9489 4.04662 12.9599 4.07235L14.9028 8.60611C15.1856 9.26597 14.7015 10 13.9836 10H11.0167C10.2988 10 9.81474 9.26593 10.0976 8.60605L12.0408 4.07232C12.0518 4.0466 12.0644 4.02252 12.0785 4.00007H9.91474C9.79495 4.33892 9.55686 4.62189 9.2501 4.79934V12.5997H10.8001C11.1867 12.5997 11.5001 12.9131 11.5001 13.2997C11.5001 13.6863 11.1867 13.9997 10.8001 13.9997H8.52455L8.51293 14L8.5001 14.0001C8.49192 14.0001 8.48377 13.9999 8.47565 13.9997H6.2001C5.8135 13.9997 5.5001 13.6863 5.5001 13.2997C5.5001 12.9131 5.8135 12.5997 6.2001 12.5997H7.7501L7.7501 4.79932C7.44335 4.62187 7.20527 4.33891 7.08549 4.00007H4.92216C4.93622 4.02252 4.94887 4.04662 4.9599 4.07235L6.90278 8.60611C7.18556 9.26597 6.70153 10 5.98362 10H5.5011H5.50011H3.50011H3.49913H3.0167C2.29877 10 1.81474 9.26593 2.09757 8.60605L4.04076 4.07232C4.05178 4.0466 4.06442 4.02252 4.07848 4.00007H4.0001C3.72396 4.00007 3.5001 3.77621 3.5001 3.50007C3.5001 3.22393 3.72396 3.00007 4.0001 3.00007H7.08544C7.29134 2.41744 7.84698 2 8.50011 2C9.15325 2 9.70889 2.41744 9.91479 3.00007ZM3.44466 8.00151L4.50028 5.53859L5.55574 8.00152C5.53733 8.00051 5.51878 8 5.50011 8H3.50011C3.48151 8 3.46302 8.00051 3.44466 8.00151ZM12.5003 5.53859L11.4448 8.00126C11.4631 8.00027 11.4815 7.99976 11.5 7.99976H13.5C13.5187 7.99976 13.5372 8.00028 13.5556 8.00129L12.5003 5.53859Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
  },
});
</script>
