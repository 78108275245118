<template>
  <div>
    <ScaleContainer :need-scale="needMenu || needScale" class="py-1 px-4" @long-press="openSuggestMenu">
      <div
        class="rounded-2xl bg-warmGray-75 flex overflow-x-hidden pt-4 pr-4 pb-5 items-start"
        :data-test="`product-card container ${productId}`"
      >
        <div class="relative mx-4" style="width: 52px; height: 52px">
          <ImageWrapper :img-src="imgSrc" :luminosity="isGrayscale" :grayscale="isGrayscale">
            <Badge
              v-if="badgeData"
              class="absolute !h-5 rotate-[-4deg]"
              :color="badgeData.color"
              :data-test="`suggest-card badge ${badgeData.color}`"
            >
              {{ badgeData.text }}
            </Badge>
          </ImageWrapper>
        </div>

        <div class="flex-auto flex flex-col">
          <body-2 class="font-medium mb-3 break-all">
            {{ title }}
          </body-2>
          <div class="flex">
            <Tag
              v-if="product && product.fragile"
              data-test="product card badge"
              bg-color="purple-high"
              color="purple-dark"
              bg-opacity="40"
            >
              {{ $gettext('хрупкий товар') }}
            </Tag>
            <Tag
              v-if="product && product.special_package"
              data-test="product card special_package badge"
              bg-color="red-dark"
              color="white"
              :class="{ 'ml-1': product.fragile }"
            >
              {{ $gettext('ТОВАР 18+') }}
            </Tag>
          </div>

          <slot name="info" />
        </div>
      </div>
    </ScaleContainer>
    <Teleport to="#root">
      <SuggestMenu
        v-if="suggestMenu.visible.value"
        :menu-config="menuConfig"
        @close="() => suggestMenu.executeAndHide($emit, 'close-suggest-menu')"
    /></Teleport>
  </div>
</template>

<script lang="ts">
import Tag from '@/fsd/shared/ui/tag/tag.vue';
import { useComponent } from '@/hooks/useComponent';
import Item from '@/models/Item';
import Product from '@/models/Product';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import Badge from '@/temp/ui/badge/badge.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import SuggestMenu from '@/ui/common/menu/suggest-menu.vue';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScaleContainer from '@/ui/common/scale-container/scale-container.vue';
import { defineComponent, PropType } from 'vue';

export interface BadgeData {
  text: string;
  color: string;
}

export default defineComponent({
  components: { ScaleContainer, ImageWrapper, Badge, Tag, SuggestMenu },
  props: {
    productId: {
      type: String,
      default: '',
    },
    isGrayscale: {
      type: Boolean,
      default: false,
    },
    badgeData: {
      type: Object as PropType<BadgeData>,
      default: undefined,
    },
    needMenu: {
      type: Boolean,
      default: false,
    },
    needScale: {
      type: Boolean,
      default: false,
    },
    menuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      default: () => [],
    },
  },
  emits: ['long-press', 'open-suggest-menu', 'close-suggest-menu'],
  setup() {
    const itemsStore = useItems();
    const productsStore = useProducts();
    const suggestMenu = useComponent();

    return { itemsStore, productsStore, suggestMenu };
  },
  computed: {
    product(): Product | undefined {
      return this.productsStore.productById(this.productId);
    },
    item(): Item | undefined {
      return this.itemsStore.itemById(this.productId);
    },
    imgSrc(): string | undefined {
      if (this.item) {
        return this.item.imgSrc;
      }
      return this.product?.images[0] || 'empty';
    },
    title(): string {
      return (this.item ? this.item?.title : this.product?.title) || '';
    },
  },
  methods: {
    openSuggestMenu(): void {
      if (this.needMenu) {
        this.suggestMenu.show();
        this.$emit('open-suggest-menu');
      }
      this.$emit('long-press');
    },
  },
});
</script>
