export const timeBeforeCourierForCoffee = 120000; // 2 min

export const productCacheLifeTime = 10800; // 3 h

//  если после выполнения саджеста в заказе на полке останется меньше, то нужно спросить остатки
export const availableForSherlock = 2;

// id для модалки плагина Notify, чтобы зная его, была возможность принудительно закрывать модалку
export const modalNotifyId = 1;
export const confirmNotifyId = 2;
export const majorErrorNotifyId = 3;

export const cameraBarcodeActionId = 4;

// разница клиента со временем на беке в секундах, после которой мы начинаем просить проверить время у пользователя
export const possibleTimeOffset = 600; // 10 min
