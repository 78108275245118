import { BaseModel } from 'sjs-base-model';

enum UserRoleEnum {
  tv_device = 'tv_device',
  guest = 'guest',
  authen_guest = 'authen_guest',
  courier = 'courier',
  executer = 'executer',
  barcode_executer = 'barcode_executer',
  stocktaker = 'stocktaker',
  vice_store_admin = 'vice_store_admin',
  store_admin = 'store_admin',
  kitchen_manager = 'kitchen_manager',
  dc_admin = 'dc_admin',
  monitoring = 'monitoring',
  support = 'support',
  support_ro = 'support_ro',
  support_it = 'support_it',
  support_courier = 'support_courier',
  supervisor = 'supervisor',
  head_supervisor = 'head_supervisor',
  expansioner_ro = 'expansioner_ro',
  expansioner = 'expansioner',
  category_manager = 'category_manager',
  content_manager = 'content_manager',
  city_head = 'city_head',
  employee_audit = 'employee_audit',
  chief_audit = 'chief_audit',
  provider = 'provider',
  courier_manager = 'courier_manager',
  courier_company_admin = 'courier_company_admin',
  courier_admin = 'courier_admin',
  company_admin = 'company_admin',
  company_admin_ro = 'company_admin_ro',
  chief_manager = 'chief_manager',
  analyst = 'analyst',
  admin_ro = 'admin_ro',
  admin = 'admin',
}

enum SuperRoleEnum {
  authen_guest = 'authen_guest',
  courier = 'courier',
  executer = 'executer',
  barcode_executer = 'barcode_executer',
  stocktaker = 'stocktaker',
  vice_store_admin = 'vice_store_admin',
  store_admin = 'store_admin',
  kitchen_manager = 'kitchen_manager',
  dc_admin = 'dc_admin',
  monitoring = 'monitoring',
  support = 'support',
  support_ro = 'support_ro',
  support_it = 'support_it',
  support_courier = 'support_courier',
  supervisor = 'supervisor',
  head_supervisor = 'head_supervisor',
  expansioner_ro = 'expansioner_ro',
  expansioner = 'expansioner',
  category_manager = 'category_manager',
  content_manager = 'content_manager',
  chief_manager = 'chief_manager',
  city_head = 'city_head',
  provider = 'provider',
  courier_manager = 'courier_manager',
  courier_company_admin = 'courier_company_admin',
  courier_admin = 'courier_admin',
  employee_audit = 'employee_audit',
  chief_audit = 'chief_audit',
  company_admin = 'company_admin',
  company_admin_ro = 'company_admin_ro',
  analyst = 'analyst',
  admin_ro = 'admin_ro',
  admin = 'admin',
}

enum UserStatusEnum {
  active = 'active',
  disabled = 'disabled',
}

enum UserProviderEnum {
  guest = 'guest',
  yandex = 'yandex',
  yandexteam = 'yandexteam',
  google = 'google',
  test = 'test',
  test_fake = 'test_fake',
  internal = 'internal',
}

export enum UserLanguageEnum {
  ru_RU = 'ru_RU',
  en_EN = 'en_EN',
  he_HE = 'he_HE',
  fr_FR = 'fr_FR',
  ar_SA = 'ar_SA',
  uz_UZ = 'uz_UZ',
  tr_TR = 'tr_TR',
  az_AZ = 'az_AZ',
  es_419 = 'es_419',
  kk_KZ = 'kk_KZ',
}

export default class User extends BaseModel {
  public user_id: string = '';
  public role: UserRoleEnum = UserRoleEnum.barcode_executer;
  public status: UserStatusEnum = UserStatusEnum.active;
  public provider: UserProviderEnum = UserProviderEnum.guest;
  public provider_user_id: number | string = '';
  public email: string = '';
  public phone: string = '';
  public nick: string = '';
  public fullname: string = '';
  public employee_number: string = '';
  public store_id: string = '';
  public company_id: string = '';
  public provider_id: string = '';
  public vars: object = {};
  public created: string = '';
  public updated: string = '';
  public device: string[] = [];
  public barcode: string = '';
  public qrcode: string = '';
  public user_assign: string = '';
  public stores_allow: string[] = [];
  public clusters_allow: string[] = [];
  public super_role: SuperRoleEnum = SuperRoleEnum.barcode_executer;
  public lang: UserLanguageEnum = UserLanguageEnum.ru_RU;
  public role_editable: boolean = false;

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }
}
