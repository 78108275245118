<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="controlBarCaption"
        :menu-config="suggestBarMenuConfig"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <RoverHatch v-if="roverHatch.visible.value" :order="order" @close-hatch="roverHatch.hide" />

      <div v-else>
        <div
          v-if="order.isRover"
          class="bg-blue-light rounded-2xl my-2 mx-4 py-2 px-4 flex flex-row justify-between items-center"
          @click="showRoverModal"
        >
          <IconRoverMini class="w-12" />
          <div class="flex flex-col justify-center">
            <body2 class="text-center font-medium">
              {{ roverName }}
            </body2>
            <caption1 class="text-center font-medium">
              {{ $gettext('Возврат с ровером') }}
            </caption1>
          </div>
          <Chevron />
        </div>
        <Hint class="my-2 mx-4">{{ hintText }}</Hint>

        <SuggestCard
          v-for="suggest in requestSuggests"
          :key="suggest.suggest_id"
          :suggest="suggest"
          :order="order"
          :suggest-menu-config="suggestMenuConfig(suggest)"
          doc-type="refund"
          need-menu
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />

        <SuggestCard
          v-for="suggest in completed"
          :key="suggest.suggest_id"
          :suggest="suggest"
          :order="order"
          doc-type="refund"
        />

        <SuggestDetails
          v-if="suggestDetails.visible.value"
          :suggest-id="suggestDetails.props.value.suggest_id"
          :order-id="order.order_id"
          @cancel="suggestDetails.hide"
          @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
        />

        <ScanShelf v-if="scanShelf.visible.value" @scanned="scanShelf.hide" />
      </div>
    </template>

    <template #footer>
      <LayoutFooter v-if="!roverHatch.visible.value">
        <UiButton data-test="finish refund btn" :disabled="!allSuggestDone" @click="onFinish">
          {{ $gettext('Завершить') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useSubscribeOnOrder } from '@/fsd/data/utils/subscribeOnOrder';
import retryBox2ShelfModal from '@/fsd/entities/modals/retryBox2ShelfModal';
import retryShelf2BoxModal from '@/fsd/entities/modals/retryShelf2BoxModal';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getDoneSuggests, getRequestSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import IconRoverMini from '@/fsd/shared/icons/rover/icon-rover-mini.vue';
import iconRover from '@/fsd/shared/icons/rover/icon-rover.vue';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import Item from '@/models/Item';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import RefundOrder from '@/models/orders/RefundOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { ERR } from '@/temp/constants/errors';
import Chevron from '@/temp/icons/chevron.vue';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import RoverHatch from '@/ui/refund/rover-hatch/rover-hatch.vue';
import { checkConditions } from '@/utils/checkConditions';
import commonMixin from '@/views/common/commonMixin';
import { defineComponent, markRaw } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiState: {
    lastScannedBarcode: string;
  };
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'Refund',
  components: {
    IconRoverMini,
    PageLayout,
    Chevron,
    LayoutFooter,
    Bar,
    Hint,
    SuggestDetails,
    UiButton,
    SuggestCard,
    RoverHatch,
    ScanShelf,
  },
  mixins: [requestBarcode, requestProductCode, commonMixin],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const ordersStore = useOrders();
    const roverHatch = useComponent<void, { order: RefundOrder }>();
    const suggestDetails = useComponent<Suggest>();
    const scanShelf = useComponent<void, Shelf>();
    const router = useRouter();

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      shelvesStore,
      productsStore,
      ordersStore,
      roverHatch,
      suggestDetails,
      scanShelf,
      router,
    };
  },
  data(): Data {
    return {
      uiState: {
        lastScannedBarcode: '',
      },
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    order(): RefundOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as any as RefundOrder;
    },
    stage(): string {
      return this.order?.vars?.stage || 'store';
    },
    stageSuggests(): Suggest[] {
      if (this.stage === 'canceling') {
        return this.suggests.filter(suggest => suggest.vars.stage === 'canceling');
      }
      if (this.stage === 'trash') {
        return this.suggests.filter(suggest => suggest.vars.stage === 'trash');
      }
      //нет поля vars.stage
      return this.suggests.filter(
        suggest =>
          suggest.vars.stage === 'store' ||
          suggest.vars.stage === 'trash_kitchen' ||
          suggest.vars.stage === 'trash_true_mark',
      );
    },
    completed(): Suggest[] {
      return getDoneSuggests(this.stageSuggests);
    },
    stageSuggestsByProductId(): (product_id: string) => Suggest[] {
      return product_id => {
        return this.stageSuggests.filter(suggest => suggest.product_id === product_id);
      };
    },
    controlBarCaption(): string {
      if (this.stage === 'store') {
        return this.$gettext('Возврат заказа № %{doc_number}', {
          doc_number: (this.order && this.order.attr.doc_number) || '',
        });
      }
      if (this.stage === 'canceling') {
        return this.$gettext('Отмена возврата заказа');
      }
      return this.$gettext('Списание');
    },
    controlBarText(): string {
      return this.$gettext('Осталось %{request} из %{all} товаров', {
        request: String(this.requestSuggests.length),
        all: String(this.suggests.length),
      });
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        if (!suggest) return menuConfig;
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => {
            useBox2Shelf(this.order_id, { suggest_id: suggest.suggest_id, count: 0 });
          },
          condition: () => checkConditions(suggest, 'all', true),
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    confirmTitle(): string {
      if (this.stage === 'store') {
        return this.$gettext('Вы уверены, что разместили все товары?');
      }
      if (this.stage === 'trash') {
        return this.$gettext('Вы точно вернули товары обратно на обычные полки или на полку «Списание»?');
      }

      return this.$gettext('Вы уверены, что разместили все товары?');
    },
    hintText(): string {
      if (this.stage === 'trash' && this.stageSuggests.length === 0) {
        return this.$gettext('Отсутствуют товары для списания, можно завершать задание');
      }
      return this.$gettext('Отсканируйте товар');
    },
    roverName(): string {
      return this.$gettext('Ровер %{name}', { name: this.order?.courier?.name || '' });
    },
    suggestBarMenuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];

      const openHatchBtn: MenuItemConfig = {
        buttonText: this.$gettext('Открыть крышку ровера'),
        onClick: () => this.openHatch(),
      };

      if (this.order?.isRover) {
        menuConfig.push(openHatchBtn);
      }

      return menuConfig;
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const { product, item, barcode } = await this.requestProductCode({ checkSuggests: true });
      this.uiState.lastScannedBarcode = barcode;
      if (!product && !item) {
        return true;
      }
      return await this.selectProduct(product! || item!);
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    async finishActiveSuggest({ count = 0, reason }: Pick<Model, 'count' | 'reason'>, suggest: Suggest): Promise<any> {
      if (!suggest) return;
      const order = this.order;

      if (suggest.type === 'box2shelf') {
        const result = await useBox2Shelf(
          this.order_id,
          {
            suggest_id: suggest.suggest_id,
            count,
            reason: reason
              ? {
                  code: reason,
                  count,
                }
              : undefined,
            true_mark: suggest.conditions.need_true_mark ? this.uiState.lastScannedBarcode : undefined,
          },
          {
            onRequestError: async (e, retry) => {
              const response = e.response;
              switch (response?.data?.code) {
                case ERR.ER_SUGGEST_WRONG_TRUE_MARK:
                  this.$notification.error.micro(this.$gettext('Этой марки нет в возврате'));
                  this.suggestDetails.hide();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED:
                  this.$notification.error.micro(this.$gettext('Это марка уже была использована в этом задании'));
                  this.suggestDetails.hide();
                  break;
                case ERR.ER_SUGGEST_INVALID_TRUE_MARK:
                  this.$notification.error.micro(this.$gettext('Нужно было сканировать марку'));
                  this.suggestDetails.hide();
                  break;
                default: {
                  const confirmed = await retryBox2ShelfModal(e);
                  if (!confirmed) return false;
                  return retry();
                }
              }
              return false;
            },
          },
        );
        if (!result) return;
      } else {
        const result = await useShelf2Box(
          this.order_id,
          {
            suggest_id: suggest.suggest_id,
            count,
            true_mark: suggest.conditions.need_true_mark ? this.uiState.lastScannedBarcode : undefined,
          },
          {
            onRequestError: async (e, retry) => {
              const response = e.response;
              switch (response?.data?.code) {
                case ERR.ER_SUGGEST_WRONG_TRUE_MARK:
                  this.$notification.error.micro(this.$gettext('Этой марки нет в возврате'));
                  this.suggestDetails.hide();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED:
                  this.$notification.error.micro(this.$gettext('Это марка уже была использована в этом задании'));
                  this.suggestDetails.hide();
                  break;
                case ERR.ER_SUGGEST_INVALID_TRUE_MARK:
                  this.$notification.error.micro(this.$gettext('Нужно было сканировать марку'));
                  this.suggestDetails.hide();
                  break;
                default: {
                  const confirmed = await retryShelf2BoxModal(e);
                  if (!confirmed) return false;
                  return retry();
                }
              }
              return false;
            },
          },
        );
        if (!result) return;
      }
      this.suggestDetails.hide();

      if (this.suggests.length === this.completed.length) {
        const { closeLoader } = this.showLoader(this.$gettext('Ожидаем создания заданий на списание'), this.order_id);
        await this.waitGenerateSuggests(order);
        closeLoader();
      }
    },
    async waitGenerateSuggests(order): Promise<void> {
      //проверить есть ли саджесты на полку списания
      if (!this.order?.suggests) return;

      for (let i = 0; i < this.order.suggests.length; i++) {
        const shelf = await this.shelvesStore.getShelfById(this.order.suggests[i].shelf_id);
        if (shelf?.type === 'trash') {
          return;
        }
      }
      if (this.order.version > order.version && this.order.estatus === 'waiting') {
        return;
      }
      const oldVersion = order.version;
      await useSubscribeOnOrder(this.order_id)(o => {
        if (!o) return true;
        if (o.version > oldVersion && o.estatus === 'waiting') {
          return true;
        }
        return false;
      });
    },
    async onFinish(): Promise<void> {
      const confirm = await this.$notification.confirmBottom({
        title: this.confirmTitle,
      });
      if (!confirm) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    async selectSuggest(suggest): Promise<void> {
      if (suggest.status === 'done' && !suggest.isEditable) {
        this.$notification.error.micro(this.$gettext('Данное задание уже выполнено!'));
        throw 'suggest already complete and not editable';
      }
      await this.suggestDetails.asyncShow(suggest);
    },

    async selectProduct(product: Product | Item): Promise<boolean> {
      //@ts-expect-error
      const stageSuggests = this.stageSuggestsByProductId(product.product_id || product.item_id);
      const requestStageSuggests = getRequestSuggests(stageSuggests);

      if (stageSuggests.length === 0) {
        this.$notification.error.micro(this.$gettext('%{barcode} нет в задании', { barcode: product.barcode[0] }));
        return true;
      }
      if (requestStageSuggests.length === 0) {
        this.$notification.error.micro(this.$gettext(`Товар уже размещен`));
        return true;
      }
      if (requestStageSuggests.length === 1) {
        await this.selectSuggest(requestStageSuggests[0]);
        return true;
      }
      if ('true_mark' in product && product.true_mark) {
        const suggest = requestStageSuggests.find(item => item.product_id === product.product_id);
        await this.selectSuggest(suggest);
        return true;
      }
      const shelf = await this.scanShelf.asyncShow();
      if (!shelf) return true;
      const suggest = requestStageSuggests.find(item => item.shelf_id === shelf.shelf_id);
      if (!suggest) {
        this.$notification.error.micro(this.$gettext('Отсканирована неверная полка'));
        return this.selectProduct(product);
      }
      await this.selectSuggest(suggest);
      return true;
    },
    async showRoverModal(): Promise<void> {
      await this.$notification.modal({
        title: this.$gettext('Этот заказ доставлялся ровером %{roverName}', {
          roverName: this.order?.courier?.name || '',
        }),
        text: this.$gettext(
          `• найдите ровер %{roverName} возле склада\n• если ровер есть, то откройте крышку ровера нажав «...» в правом верхнем углу`,
          {
            roverName: this.order?.courier?.name || '',
          },
        ),
        iconTop: {
          icon: markRaw(iconRover),
          position: 'center',
        },
        buttonText: this.$gettext('Понятно'),
      });
    },
    async openHatch() {
      this.roverHatch.show();
      const { closeLoader } = this.showLoader();
      try {
        await api.order.signal({
          order_id: this.order!.order_id,
          signal: 'rover_open_hatch',
        });
        this.$notification.success.micro(this.$gettext('Сигнал на открытие крышки отправлен'));
      } catch (e) {
        console.error(e);
        this.$notification.error.micro(this.$gettext('Произошла ошибка при открытии ровера'));
        throw e;
      } finally {
        closeLoader();
      }
    },
  },
});
</script>
