import { getShelf2BoxSuggests, getSuggestsByStatusAndType } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import itemQueue from '@/services/queue/item-queue';
import { $gettext } from '@/temp/plugins/gettext';
import { computed } from 'vue';

interface Conditions {
  is_agree: boolean;
}

export default class StowageMarketOrder extends BaseOrder {
  public type: OrderTypeEnum.stowage_market = OrderTypeEnum.stowage_market;
  public conditions: Conditions = { is_agree: true };

  constructor(data: any) {
    super(data);
    this.conditions = data.conditions;
  }

  get requiredActionsCount() {
    if (this.target === 'canceled') {
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.request, SuggestTypeEnum.shelf2box).length;
    }
    return super.requiredActionsCount;
  }

  _allActionsCount = computed(() => {
    if (this.target === 'canceled') {
      return getShelf2BoxSuggests(this.suggests).length;
    }
    if (this.status === 'processing') {
      return new Set(this.suggests.map(s => s.product_id)).size;
    }
    return super.allActionsCount;
  });

  async loadProducts() {
    await super.loadProducts();
    try {
      if (this.vars.lot_item_ids) {
        await itemQueue.loadMany(Object.keys(this.vars.lot_item_ids));
      }
    } catch (e) {
      console.error(e);
    }
  }

  get title() {
    if (this.target === 'canceled') {
      return $gettext('Отмена размещения');
    }

    return $gettext('Размещение посылки');
  }

  get lots(): string[] {
    // этот геттер возвращает список названий мешков в которых приехали посылки. нужно только для дизайна.
    if (this.vars.lot_item_ids) {
      return Object.keys(this.vars.lot_item_ids);
    }
    return [];
  }
}
