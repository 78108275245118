<template>
  <div class="w-screen h-screen z-10 fixed top-0 left-0 bg-white">
    <Layout>
      <template #header>
        <Bar
          :caption="$gettext('Сообщить о проблеме')"
          :text="$gettext('Честный Знак')"
          :icons="{ left: 'back' }"
          @close-click="$emit('close')"
        />
      </template>
      <template #default>
        <Hint class="my-2 mx-4">
          {{ $gettext('Отсканируйте марку Честного Знака') }}
        </Hint>
        <SuggestCard :suggest="suggest" doc-type="onlyTitle" />
        <div class="bg-warmGray-50 m-4 p-4 rounded-2xl flex flex-col">
          <Body2 class="font-medium border-b pb-4">
            {{ $gettext('Отчёт об ошибке') }}
          </Body2>
          <Caption2 v-if="!trueMarkErrorCode" class="m-10 text-day-textMinor text-center">
            {{ $gettext('Отсканируйте марку Честного Знака для создания отчёта об ошибке') }}
          </Caption2>
          <template v-else>
            <div class="py-2 border-b flex flex-col">
              <Caption2 class="text-day-textMinor">
                {{ $gettext('Описание ошибки') }}
              </Caption2>
              <caption1>
                {{ errorTitle }}
              </caption1>
            </div>
            <div class="mt-2 flex flex-col">
              <Caption2 class="text-day-textMinor">
                {{ $gettext('Код ошибки') }}
              </Caption2>
              <caption1>
                {{ trueMarkErrorCode }}
              </caption1>
            </div>
          </template>
        </div>
      </template>

      <template #footer>
        <LayoutFooter class="flex-col">
          <div
            v-if="!trueMarkErrorCode"
            class="flex items-center justify-between h-14 mx-4 border-b"
            data-test="wrong_mark"
            @click="createReport"
          >
            <IconWarning />
            <div class="flex flex-col">
              <Body2>
                {{ $gettext('Не могу отсканировать марку') }}
              </Body2>
              <caption1 class="text-day-textMinor">
                {{ $gettext('Не сканируется или отсутствует') }}
              </caption1>
            </div>
            <Chevron />
          </div>
          <UiButton
            data-test="order report-menu pause-btn"
            class="w-full"
            background-color="primary"
            :is-disabled="!Boolean(trueMarkErrorCode)"
            @click="createReport"
          >
            {{ $gettext('Написать в чат поддержки') }}
          </UiButton>
        </LayoutFooter>
      </template>
    </Layout>
  </div>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { checkTrueMarkErrorsCode } from '@/temp/constants/translations/checkTrueMarkErrorsCode';
import Chevron from '@/temp/icons/chevron.vue';
import IconWarning from '@/temp/icons/icon-warning.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption2 from '@/ui/common/typo/caption-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps, ref } from 'vue';

const { showLoader } = useLoader();
const productsStore = useProducts();

interface ReportMessage {
  message: string;
  chatMetaData: {
    order_id: BaseOrder['order_id'];
    product_id: Suggest['product_id'];
    doc_number: BaseOrder['external_id'];
    error_code: string;
  };
}

interface TrueMarkReportProps {
  suggest: Suggest;
  order: BaseOrder;
}

const props = defineProps<TrueMarkReportProps>();
const emits = defineEmits<{
  (e: 'report', report: ReportMessage): void;
  (e: 'close'): void;
}>();

const trueMarkErrorCode = ref<string>('');

const errorTitle = computed<string>(() => checkTrueMarkErrorsCode[trueMarkErrorCode.value]);

const product = computed<Product | undefined>(() => props.suggest.product);

const reportMessage = computed<string>(() => {
  if (trueMarkErrorCode.value) {
    return $gettext(
      `Здравствуйте! Проблема сборки товара с маркой ЧЗ: ошибка %{error_code}\n Товар: %{product_id} %{product_title}\n Заказ: %{doc_number}`,
      {
        product_id: product.value?.external_id || '',
        product_title: product.value?.title || '',
        doc_number: props.order.attr.doc_number,
        error_code: trueMarkErrorCode.value,
      },
    );
  } else {
    return $gettext(
      `Здравствуйте! Проблема сборки товара с маркой ЧЗ: отсутствует/не читается марка\n Товар: %{product_id} %{product_title}\n Заказ: %{doc_number}`,
      {
        product_id: product.value?.external_id || '',
        product_title: product.value?.title || '',
        doc_number: props.order.attr.doc_number,
      },
    );
  }
});

const createReport = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Отправить сообщение в чат поддержки?'),
  });
  if (!confirmed) {
    return;
  }
  emits('report', {
    message: reportMessage.value,
    chatMetaData: {
      order_id: props.order.order_id,
      product_id: props.suggest.product_id,
      doc_number: props.order.external_id,
      error_code: trueMarkErrorCode.value || 'INVALID_TRUE_MARK',
    },
  });
};

useRequestBarcode(async trueMark => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(trueMark);
    if (product && product.product_id === product?.product_id) {
      await api.check_true_mark({ true_mark: trueMark, order_id: props.order.order_id });
      Notifications.success.micro($gettext('Марка Честный Знак сканируется без ошибок'));
    } else {
      Notifications.modal({
        title: $gettext('Отсканирована марка от другого продукта'),
      });
    }
    return true;
  } catch (error: any) {
    if (error?.isAxiosError && error?.response) {
      const errCode = error.response.data.code;
      if (errCode === 'ER_INVALID_TRUE_MARK') {
        Notifications.modal({
          title: checkTrueMarkErrorsCode.ER_INVALID_TRUE_MARK,
        });
        return true;
      } else {
        trueMarkErrorCode.value = error.response.data.code;
        return false;
      }
    } else {
      Notifications.error.micro($gettext('Произошла ошибка при сканировании Марки, повторите попытку'));
      return true;
    }
  } finally {
    closeLoader();
  }
});
</script>
