<template>
  <Layout>
    <template #header>
      <OrderRetailHeaderMore :order="order" :product="product" @back="$emit('close')" />
    </template>
    <template #default>
      <SuggestDetails :collected="barcodes" :product="product" />
    </template>
    <template #footer>
      <OrderRetailFooterMore @add-more="moreProduct" />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { SuggestDetails } from '@/fsd/entities/suggest';
import { MoreProductSuggestProps } from '@/fsd/features/suggest';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { default as OrderOrderRetail } from '@/models/orders/OrderOrderRetail';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import OrderRetailFooterMore from './order-retail-footer-more.vue';
import OrderRetailHeaderMore from './order-retail-header-more.vue';

const productsStore = useProducts();
const ordersStore = useOrders();
const { showLoader } = useLoader();

const barcodes = ref<string[]>([]);
const suggest = ref<Suggest>();

interface RetailMoreProduct {
  data: MoreProductSuggestProps;
  order: OrderOrderRetail;
}

const props = defineProps<RetailMoreProduct>();

const emits = defineEmits<{
  (e: 'suggestDone', suggest: Suggest, barcodes: string[]): void;
  (e: 'close'): void;
}>();

const product = computed<Product>(() => {
  return productsStore.productById(props.data.product_id)!;
});

const count = computed<number>(() => {
  return product.value.isTrueWeight ? Product.weightFromBarcode(barcodes.value) : barcodes.value.length;
});

onMounted(() => {
  barcodes.value.push(props.data.barcode);
});

const finishSuggest = async (suggest?: Suggest) => {
  if (!suggest) return;
  emits('suggestDone', suggest, barcodes.value);
};

const moreProduct = async () => {
  const confirm = await Notifications.confirmCenter({
    title: $gettext('Этого товара нет в заказе'),
    text: $gettext('Вы уверены, что хотите добавить этот товар в заказ?'),
    ok: $gettext('Добавить'),
  });
  if (!confirm) {
    return;
  }
  const { closeLoader } = showLoader();

  try {
    await ordersStore.signal({
      order_id: props.order.order_id,
      signal: 'more_product',
      data: { product_id: props.data.product_id, count: count.value },
    });
    // стартовая ревизия заказа + 1
    const startedOrderRevision = props.order.revision + 1;
    await new Promise<void>(resolve => {
      const unWatch = watch(
        () => props.order.suggests,
        suggests => {
          for (const s of suggests) {
            if (s.status === 'request' && s.product_id === product.value.product_id) {
              suggest.value = s;
              unWatch();
              resolve();
            }
          }
          return;
        },
      );
    });
    await new Promise<void>(resolve => {
      const unWatch = watch(
        () => props.order.estatus,
        status => {
          // чтобы не словить waiting, который не успел смениться на следующий статус
          // проверяем ревизию ордера, она должна быть больше стартовой + 1
          if (status === 'waiting' && startedOrderRevision !== props.order.revision) {
            resolve();
            unWatch();
            return;
          }
        },
        { immediate: true },
      );
    });
    await finishSuggest(suggest.value);
    emits('close');
  } catch (e) {
    console.error(e);
  } finally {
    closeLoader();
  }
};

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    if (props.data.product_id !== product.product_id) {
      Notifications.error.micro($gettext('Отсканирован другой товар'));
      return true;
    }
    barcodes.value.push(barcode);
    return true;
  } catch (error) {
    Notifications.error.micro($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});
</script>
