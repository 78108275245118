import SuggestEvent from '@/models/events/SuggestEvent';
import { Signal } from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import itemQueue from '@/services/queue/item-queue';
import productQueue from '@/services/queue/product-queue';
import shelfQueue from '@/services/queue/shelf-queue';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useSuggests } from '@/store/modules/suggests';
import { defaultSourceFormatLong } from '@/temp/constants/dateFormat';
import dayjs from 'dayjs';
import { BaseModel } from 'sjs-base-model';

export enum SuggestTagEnum {
  //   sampling - этот товар добавлен в список в рамках семплинга (добавление вилок к салатам например)
  sampling = 'sampling',
  // sample - добавляется ко всем семплам
  sample = 'sample',
  // packaging - этот товар добавлен в список в рамках упаковки (добавление пакета в которые положат товары)
  packaging = 'packaging',
  // ultima_package - упаковка Ultima ( пакетный саджест, следовательно идет вместе с тегом packaging )
  ultima_package = 'ultima_package',
  kitchen = 'kitchen',
}

interface Conditions {
  //   У раздела condition есть типы (поле type):
  // and - значит все условия должны быть соблюдены (значение по умолчанию)
  // or - одно из условий должно быть соблюдено
  type?: 'and' | 'or';
  // valid - срок годности должен быть ниже или равен указанного в этом поле.
  valid?: string;
  // all - товаров может быть меньше или больше указанного в подсказке - необходимо собрать все. При этом при закрытии подсказки поле count может быть больше указанного в подсказке.
  all?: boolean;
  // editable (значение по умолчанию - true) - информирует фронт о том, что данный саджест можно исправить (редактировать) после закрытия. Есть ряд случаев, когда саджесты нельзя исправлять. Например если заказ собирался, затем пришла отмена - и его надо разложить назад на полки. В этом случае саджесты выполненные ранее уже нельзя исправить.
  editable?: boolean;
  cancelable?: boolean;
  // error (значение по умолчанию - true) - информирует фронт о том, что данный саджест можно закрывать в статус error.
  error?: boolean;
  //   tags - массив признаков для того чтобы фронт мог понимать что это за саджест:
  tags: SuggestTagEnum[];
  need_valid?: boolean;
  // запрет закрывать в большое количество
  max_count?: boolean;
  trash_reason?: boolean;
  //  флаг сигнализирующий о  том что в саджесте весовой продукт, необходимо дать отсканировать все продукты, по каждому скану может прийти тот же продукт с новым весом
  need_weight?: boolean;
  //  флаг сигнализирующий, что необходимо разобрать продукты по весу. то есть если после скана пришел продукт с весом 300,  то в саджест можно будет закрыть только все продукты с весом 300, прочие весовые группы нужно будет принять отдельно
  weight_aggregate?: boolean;
  // Является ли продукт помеченным честным знаком
  need_true_mark: boolean;
}

interface Reason {
  code: string;
  count?: number;
}

export enum SuggestTypeEnum {
  check = 'check',
  shelf2box = 'shelf2box',
  box2shelf = 'box2shelf',
  check_more = 'check_more',
}

export enum SuggestStatusEnum {
  request = 'request',
  done = 'done',
  error = 'error',
  failed = 'failed',
  // Этот статус существует только при сборке готовой еды
  // Саджест находится в нём, пока повара готовят еду
  blocked = 'blocked',
  // Этот статус существует только при сборке готовой еды
  // Это означает, что эту позицию повар готовить не будет и собирать ее не надо
  canceled = 'canceled',
}

enum SuggestVarsStageEnum {
  visual_control = 'visual_control',
  store = 'store',
  trash = 'trash',
  stowage = 'stowage',
  trash_kitchen = 'trash_kitchen',
  canceling = 'canceling',
  trash_true_mark = 'trash_true_mark',
  write_off = 'write_off',
}

export default class Suggest extends BaseModel {
  public suggest_id: string = '';
  public order_id: string = '';
  public type: SuggestTypeEnum = SuggestTypeEnum.check;
  public shelf_id: string = '';
  // в поле продукт_ид может быть продукт или посылка
  public product_id: string = '';
  public status: SuggestStatusEnum = SuggestStatusEnum.request;
  public conditions: Conditions = { tags: [], need_true_mark: false };
  public revision: number = 0;
  public vars: {
    mode: 'item' | 'product' | 'office';
    stage?: SuggestVarsStageEnum;
    // hand-move
    target?: 'canceled';
    // признак того, что саджест создан через сигнал more_product
    source?: 'signal';
    //  специфично для приемок маркета
    group?: string;
    price: string;
    barcodes?: {
      [key: string]: number;
    };
    more_product?: boolean;
    //  специфично для размещений маркета
    lot_id?: string;
    // id сигнала
    sigid?: string;
    // раздел продукта, для которого создан саджест (пока используется только в order retail)
    product_group?: {
      group_id?: string;
      name?: string;
    };
  } = { mode: 'product', price: '0' };

  public count: number | null = null;
  public max_count: number | null = null;
  public min_count: number | null = null;
  public weight: number | null = null;
  public valid: string | null = null;

  public result_count: number | null = null;
  public result_weight: number | null = null;
  public result_valid: string | null = null;

  public order: number = 0;
  public created: number = 0;
  public updated: number = 0;

  public reason: Reason | undefined = undefined;

  // признак того, что данный саджест не существует в учетной системе
  public virtual: boolean = false;

  private _self: Suggest | undefined = undefined;

  constructor(data: any) {
    super();
    this.update(data);
  }

  update(data) {
    if (typeof data.created === 'string') {
      data.created = dayjs(data.created, defaultSourceFormatLong).unix();
    }
    if (typeof data.updated === 'string') {
      data.updated = dayjs(data.updated, defaultSourceFormatLong).unix();
    }
    return super.update(data);
  }

  updateFromEvent = (event: SuggestEvent) => {
    if (!this.self) return;
    this.self.status = event.status ?? this.self.status;
    this.self.product_id = event.product_id ?? this.self.product_id;
    this.self.shelf_id = event.shelf_id ?? this.self.shelf_id;
    this.self.count = event.count ?? this.self.count;
    this.self.result_count = event.result_count ?? this.self.result_count;
    this.self.valid = event.valid ?? this.self.valid;
    this.self.result_valid = event.result_valid ?? this.self.result_valid;
    this.self.revision = event.revision ?? this.self.revision;
  };

  static isSuggest = (item): item is Suggest => {
    return item.suggest_id;
  };

  get isItemSuggest(): boolean {
    return this.vars.mode === 'item';
  }

  get isProductSuggest(): boolean {
    return this.vars.mode !== 'item';
  }

  loadProduct = async (): Promise<any> => {
    if (!this.product_id) return;
    if (this.isItemSuggest) {
      return itemQueue.load(this.product_id);
    } else {
      return productQueue.load(this.product_id);
    }
  };

  loadShelf = async (): Promise<any> => {
    if (this.shelf_id) {
      return shelfQueue.load(this.shelf_id);
    }
  };
  loadResources = async (): Promise<boolean> => {
    return (await Promise.allSettled([this.loadProduct(), this.loadShelf()])).every(i => i.status === 'fulfilled');
  };
  get self() {
    if (this._self) {
      return this._self;
    }
    const self = useSuggests().getSuggest(this.order_id, this.suggest_id);
    if (!self) return undefined;
    this._self = self;
    return this._self;
  }
  get product() {
    return useProducts().productById(this.product_id);
  }
  get item() {
    return useItems().itemById(this.product_id);
  }
  get shelf() {
    return useShelves().shelfById(this.shelf_id);
  }

  get isLoaded(): boolean {
    const hasShelf = this.shelf_id ? Boolean(this.shelf) : true;
    if (this.type === 'check_more') {
      return hasShelf;
    }
    const hasProduct = this.isItemSuggest ? Boolean(this.item) : Boolean(this.product);

    return hasProduct && hasShelf;
  }

  get imageSrc() {
    if (this.isItemSuggest) {
      return this.item?.imgSrc || 'empty';
    }
    return this.product?.images[0] || 'empty';
  }
  get allowableError() {
    if (!this.count || !this.max_count || (!this.min_count && this.min_count !== 0)) return 0;
    const delA = this.max_count - this.count;
    const delB = this.count - this.min_count;
    return Math.min(delA, delB);
  }

  // возвращает сигнал, с помощью которого был создан саджест на замену с parent_suggest_id
  get parentSignal(): Signal | undefined {
    const order = useOrders().orderById(this.order_id);

    if (order) {
      return order.signals.find(signal => {
        if (signal.sigid === this.vars.sigid && signal.data.parent_suggest_id) {
          return signal;
        }
      });
    }

    return;
  }

  get isPackaging() {
    return this.conditions.tags.includes(SuggestTagEnum.packaging);
  }

  get isSample() {
    return this.conditions.tags.includes(SuggestTagEnum.sampling);
  }
  get isUltimaPackage() {
    return this.conditions.tags.includes(SuggestTagEnum.ultima_package);
  }

  get isEditable() {
    return this.conditions.editable;
  }

  get isBlocked() {
    return this.status === 'blocked';
  }

  get isCanceled() {
    return this.status === 'canceled';
  }

  get isDone() {
    return this.status === 'done';
  }

  // true для саджестов, которые не требуют дальнейшей работы над собой
  get isCompleted() {
    return this.isCanceled || this.isDone;
  }

  // true для саджестов, которые были созданы сигнала с parent_suggest_id
  get isChildSuggest() {
    return !!this.parentSignal;
  }

  get parentSuggest(): Suggest | undefined {
    const signal = this.parentSignal;

    if (signal) {
      const parent_id: Suggest['suggest_id'] = signal.data.parent_suggest_id;
      return useSuggests().getSuggest(this.order_id, parent_id);
    }

    return;
  }

  // Собрали продукт в необходимое количество
  get isFull(): boolean {
    const resultCount = this.result_count || 0;
    const count = this.count || 0;
    const minCount = this.min_count || 0;
    const maxCount = this.max_count || 0;

    if (this.product?.isTrueWeight) {
      return resultCount >= minCount && resultCount <= maxCount;
    } else {
      return resultCount === count;
    }
  }

  // Собрали продукт в количестве менее необходимого
  get isPartial(): boolean {
    const resultCount = this.result_count || 0;
    const count = this.count || 0;
    const minCount = this.min_count || 0;

    if (this.product?.isTrueWeight) {
      if (resultCount && resultCount < minCount) return true;
    } else {
      if (resultCount && resultCount < count) return true;
    }

    return false;
  }

  // Собрали продукт в количестве более необходимого
  get isOver(): boolean {
    return !!this.result_count && !this.isFull && !this.isPartial;
  }

  get price(): string {
    return this.vars.price || '0';
  }

  // Получить массив всех баркодов, которые мы отсканили и отправили на бекенд
  get barcodes(): string[] {
    const result: string[] = [];
    const barcodes = this.vars.barcodes;
    if (barcodes) {
      Object.keys(barcodes).forEach(barcode => {
        const count = barcodes[barcode];
        if (this.product?.isTrueWeight) {
          const weightCode = Product.weightFromBarcode(barcode);
          const countBarcodes = count / weightCode;
          for (let i = 0; i < countBarcodes; i++) {
            result.push(barcode);
          }
        } else {
          for (let i = 0; i < count; i++) {
            result.push(barcode);
          }
        }
      });
    }
    return result;
  }

  // получить все дочерние саджесты
  get allChildrenSuggest(): Suggest[] {
    const order = useOrders().orderById(this.order_id);
    const result: Suggest[] = [];

    order?.signals.forEach(signal => {
      if (signal.data.parent_suggest_id === this.suggest_id) {
        order.suggests.forEach(suggest => {
          if (signal.sigid === suggest.vars.sigid) {
            result.push(suggest);
          }
        });
      }
    });

    return result;
  }

  // получить все дочерние саджесты, гдк result_count > 0
  get fullChildrenSuggest(): Suggest[] {
    return this.allChildrenSuggest.filter(suggest => {
      if (suggest.result_count) return true;
    });
  }

  // получить все незакрытые дочерние саджесты
  get nonClosedChildrenSuggest(): Suggest[] {
    return this.allChildrenSuggest.filter(suggest => !suggest.isDone);
  }
}
