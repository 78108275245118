<template>
  <Sector class="rounded-t-none pt-0">
    <div>
      <Title2 class="font-bold">{{ $gettext('Аккуратно упакуйте товары') }}</Title2>
      <br />
      <Body2 color="day-textMinor">
        {{
          $gettext('Хрупкое кладите сверху, товары для взрослых в непрозрачный пакет, а бытовую химию — в отдельный.')
        }}
      </Body2>
    </div>
  </Sector>

  <ProductGroupsByType :suggests="suggests" />
</template>

<script lang="ts">
import Sector from '@/fsd/shared/ui/sector/sector.vue';
import ProductGroupsByType from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/SortingStage/ProductGroupsByType.vue';
import Suggest from '@/models/Suggest';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SortingStage',
  components: { Body2, Sector, Title2, ProductGroupsByType },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
  },
});
</script>
