<template>
  <div class="h-full flex flex-col bg-main overflow-scroll custom-scroll">
    <div class="flex flex-col h-full p-4 bg-main box-border rounded-t-3xl overflow-scroll custom-scroll">
      <!--Картинка-->
      <div>
        <div class="relative flex flex-[2_1_0] m-8 justify-center h-48" :class="{ 'm-4 h-16': uiState.needSherlockV1 }">
          <div class="w-full h-full">
            <ImageWrapper :img-src="suggest.imageSrc || product?.images[0]" :grayscale="isBox2Shelf">
              <div class="h-12 top-1/2 -translate-y-2/4 z-10 w-full absolute">
                <Badge
                  v-if="isCompleted"
                  class="-rotate-12"
                  color="lime-toxic"
                  data-test="order suggest-completed badge"
                >
                  {{ !order.isCanceled ? $gettext('Собран') : $gettext('Отсканировано') }}
                </Badge>
                <Badge
                  v-if="order.isOrderPaused"
                  class="-rotate-12"
                  color="red-normal"
                  data-test="order product-card pause-badge"
                >
                  {{ $gettext('Обрабатывается оператором') }}
                </Badge>
                <Badge
                  v-if="isProblem && !order.isOrderPaused"
                  class="-rotate-12"
                  color="red-normal"
                  data-test="order product-card pause-badge"
                >
                  {{ $gettext('Ждите обработки оператором') }}
                </Badge>
                <OrderProductCardBadge v-if="isBox2Shelf && suggest?.status === 'request'">
                  <template #text>
                    {{
                      suggest.shelf.type === 'trash'
                        ? $gettext('Положите товар на полку Списание')
                        : $gettext('Верните этот товар обратно')
                    }}
                  </template>
                </OrderProductCardBadge>
                <OrderProductCardBadge v-if="suggest.isBlocked" data-test="product block badge">
                  <template #text>
                    {{ $gettext('Продукт ещё не готов') }}
                  </template>
                </OrderProductCardBadge>
                <OrderProductCardBadge v-if="suggest.isCanceled" data-test="product canceled badge">
                  <template #text>
                    {{ $gettext('Продукт отменён') }}
                  </template>
                </OrderProductCardBadge>
              </div>
            </ImageWrapper>
          </div>
        </div>
      </div>

      <div v-if="!uiState.needSherlockV1" class="flex flex-wrap justify-items-start px-1">
        <PackageClassBadge v-if="item?.packageClass" :package_class="item.packageClass" class="mb-2" />
        <UltimaBadge v-if="item?.isUltimaParcel" class="mb-2" />
        <FragileBadge v-if="product?.fragile" class="mb-2 h-8" />
        <SpecialPackageBadge v-if="product?.special_package" class="mb-2" />
        <TrueMarkBadge v-if="isTrueMark" class="mb-2 h-8" />
        <WeightBadge v-if="product?.isTrueWeight" :plu_code="product.plu_code" class="mb-2 h-8" />
        <CompletedBadge v-if="isCompleted" class="mb-2 h-8" />
      </div>
      <div class="py-4 px-2">
        <Body1 color="text-primary" class="font-bold text-center" data-test="product title">
          {{ title }}
        </Body1>
      </div>
      <div v-if="!uiState.needSherlockV1" class="mt-auto py-2">
        <OrderNewProductCardCount :collected="collected.count" :suggest="suggest" :problem="problem" />
      </div>
      <SherlockCounter
        v-if="!isProblem"
        :suggest="suggest"
        :order_id="orderId"
        :is-visible="true"
        @input="onInputSherlockV1"
        @need-sherlock="toggleSherlock"
      />
    </div>
    <LayoutFooter v-if="needCollectButton">
      <UiButton
        :is-disabled="isDisabled"
        :background-color="'secondary'"
        data-test="collect low_weight"
        @click="collect"
      >
        <template #icon>
          <Check />
          {{ $gettext('Собрано') }}
        </template>
      </UiButton>
    </LayoutFooter>
  </div>
</template>

<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import OrderProductCardBadge from '@/fsd/widgets/order/RequiredCard/order-product-card-badge.vue';
import OrderNewProductCardCount from '@/fsd/widgets/order/order-with-shelves-chain/order-new-product-card-count.vue';
import {
  finishCheckTrueMark,
  finishRequestBarcode,
  finishSherlock,
  sendRequestBarcodeTime,
  startAssembling,
  startRequestBarcode,
  startSherlock,
} from '@/fsd/widgets/order/rum/useMeasureAssemblingPosition';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import Item from '@/models/Item';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { Problem } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { modalNotifyId } from '@/temp/constants/common';
import Check from '@/temp/icons/check.vue';
import { logger } from '@/temp/plugins/logs';
import { TrueMark } from '@/temp/services/true-mark';
import Badge from '@/temp/ui/badge/badge.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body1 from '@/ui/common/typo/body-1.vue';
import UiButton from '@/ui/common/ui-button.vue';
import CompletedBadge from '@/ui/order/badges/completed-badge.vue';
import FragileBadge from '@/ui/order/badges/fragile-badge.vue';
import PackageClassBadge from '@/ui/order/badges/package-class-badge.vue';
import SpecialPackageBadge from '@/ui/order/badges/special-package-badge.vue';
import TrueMarkBadge from '@/ui/order/badges/true-mark-badge.vue';
import UltimaBadge from '@/ui/order/badges/ultima-badge.vue';
import WeightBadge from '@/ui/order/badges/weight-badge.vue';
import { needLessWeightModal } from '@/utils/modals';
import { notify } from '@kyvg/vue3-notification';
import { PropType, defineComponent } from 'vue';
import SherlockCounter from '../../../../ui/order/sherlock-counter.vue';

interface Data {
  uiState: {
    needSherlockV1: boolean;
  };
  uiStateNeedBarcodeRequest: boolean;
  collected: {
    count: number;
    weight: number;
    barcodes: string[];
  };
  true_mark?: string;
}

export default defineComponent({
  name: 'NewProductCard',
  components: {
    OrderProductCardBadge,
    UltimaBadge,
    OrderNewProductCardCount,
    WeightBadge,
    SpecialPackageBadge,
    Body1,
    CompletedBadge,
    FragileBadge,
    TrueMarkBadge,
    PackageClassBadge,
    Badge,
    ImageWrapper,
    SherlockCounter,
    LayoutFooter,
    UiButton,
    Check,
  },
  mixins: [requestBarcode, requestProductCode],
  props: {
    orderId: {
      type: String,
      required: true,
    },
    problem: {
      type: Object as PropType<Problem>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: () => {
        return {} as Suggest;
      },
    },
  },
  emits: ['input-sherlock-count', 'finish', 'unblock'],
  setup() {
    const { showLoader } = useLoader();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const ordersStore = useOrders();

    return { showLoader, itemsStore, productsStore, ordersStore };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: false,
      uiState: {
        needSherlockV1: false,
      },
      collected: {
        count: 0,
        weight: 0,
        barcodes: [],
      },
      true_mark: undefined,
    };
  },
  computed: {
    order(): OrderOrder {
      return this.ordersStore.orderById(this.orderId) as OrderOrder;
    },
    title(): string {
      if (!this.suggest) {
        return this.product?.title || '';
      }
      if (this.suggest.vars && this.suggest.vars.mode === 'item') {
        return this.item!.title;
      } else {
        return this.product!.title;
      }
    },
    isCompleted(): boolean {
      return !this.isProblem && (this.suggest.status === 'done' || this.suggest.count === this.collected.count);
    },
    isBox2Shelf(): boolean {
      return this.suggest.type === 'box2shelf';
    },
    product(): Product | undefined {
      return this.productsStore.productById(this.suggest.product_id);
    },
    item(): Item | undefined {
      return this.itemsStore.itemById(this.suggest.product_id);
    },
    isProblem(): boolean {
      return !!this.problem;
    },
    isTrueMark(): boolean {
      return this.suggest?.conditions?.need_true_mark;
    },
    isDisabled(): boolean {
      return !this.collected.count;
    },
    needCollectButton(): boolean | undefined {
      return (
        this.suggest.product &&
        this.suggest.product.isTrueWeight &&
        this.suggest.status !== 'done' &&
        this.suggest.type === 'shelf2box'
      );
    },
  },
  watch: {
    // сделал разблокировку скана таким образом,
    // тк это показалось самым безопасным костылём
    'suggest.status'(newValue: SuggestStatusEnum, oldValue: SuggestStatusEnum) {
      if (newValue === 'request' && oldValue === 'blocked') {
        this.uiStateNeedBarcodeRequest = true;
      }
      if (newValue === 'done') {
        this.collected.count = this.suggest.count || 0;
      } else {
        this.collected.count = 0;
      }
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const onScanBarcode = async () => {
        if (this.isProblem) {
          return Promise.resolve(false);
        }
        if (this.order.isOrderPaused) {
          await this.$notification.modal({
            title: this.$gettext('Обрабатывается оператором'),
            text: this.$gettext('Данный заказ обрабатывается оператором, пожалуйста подождите'),
          });
          return Promise.resolve(false);
        }
        if (this.uiStateNeedBarcodeRequest) {
          startRequestBarcode(this.suggest?.suggest_id);
        }
        return Promise.resolve(this.uiStateNeedBarcodeRequest);
      };

      const { product, item, barcode } = await this.requestProductCode({ suggest: this.suggest, onScanBarcode });

      finishRequestBarcode(this.suggest?.suggest_id);
      notify.close(modalNotifyId);

      if (this.order.isOrderPaused) {
        return true;
      }

      if (!this.uiStateNeedBarcodeRequest) {
        return false;
      }

      // проверяем марку, если это чз
      if (this.isTrueMark) {
        if (this.suggest?.type === 'shelf2box') {
          const { closeLoader } = this.showLoader();
          try {
            const response = await TrueMark.check({ true_mark: barcode, order_id: this.orderId });
            finishCheckTrueMark(response?.duration || 0);
            this.true_mark = barcode;
          } catch {
            return true;
          } finally {
            closeLoader();
          }
        } else {
          this.true_mark = barcode;
        }
      }

      if (this.product?.isTrueWeight) {
        const weight = Product.weightFromBarcode(barcode);
        if (!weight) {
          this.$notification.error.micro(this.$gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
          return true;
        }
        if (!this.suggest.max_count) {
          if (weight !== this.suggest.count) {
            this.$notification.error.micro(this.$gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
            return true;
          }
          this.collected.barcodes.push(barcode);
          this.collected.count = weight;
          this.finishSuggest();
          return false;
        }
        if (this.collected.count + weight > this.suggest.max_count) {
          const delta = this.collected.count + weight - this.suggest.max_count!;
          needLessWeightModal(delta.toString());
          return true;
        }
        this.collected.count += weight;
        this.collected.barcodes.push(barcode);
        if (this.collected.count < this.suggest.min_count!) {
          return true;
        }
        this.finishSuggest();
        return false;
      }
      // посылка
      if (this.suggest.vars?.mode === 'item') {
        if (this.item!.item_id === item?.item_id) {
          this.updateCollected(barcode);
          //  Проверить собранное и добавить или закрыть саджест и отменить запрос баркода
        } else {
          this.$notification.error.micro(this.$gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
        }
        return true;
      }
      // продукт
      if (product?.product_id === this.product!.product_id) {
        // весовой продукт
        if (product.type_accounting === 'weight') {
          const weight = Product.weightFromBarcode(barcode);
          return this.updateCollected(barcode, weight);
        }
        // обычный продукт
        return this.updateCollected(barcode);
      }
      // ничего не подошло
      this.$notification.error.micro(this.$gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
      return true;
    },
    updateCollected(barcode: string, weight: number = 0): boolean {
      this.collected.count += 1;
      this.collected.barcodes.push(barcode);
      if (weight) this.collected.weight = this.collected.weight + weight;
      if (this.product?.isTrueWeight) {
        this.finishSuggest();
        return false;
      }
      if (this.suggest.count === this.collected.count) {
        this.finishSuggest();
        return false;
      }
      return true;
    },
    clearCollectedAndRequestBarcodeOnProblem(): void {
      this.collected.count = 0;
      this.collected.weight = 0;
      this.collected.barcodes = [];
      this.uiStateNeedBarcodeRequest = true;
    },
    async sendShortFallSignal(): Promise<void> {
      try {
        await api.order.signal({
          order_id: this.order.order_id,
          signal: 'shortfall',
          data: { suggest_id: this.suggest.suggest_id!, source: 'sherlock' },
        });
      } catch (e) {
        logger.error(e, { method: 'signal', type: 'api', source: 'product-card.vue' });
      }
    },
    async onInputSherlockV1(count: number): Promise<void> {
      const available = await this.productsStore.fetchAvailable(this.suggest.product_id);
      const availableOnShelf = available?.find(item => item.shelf_id === this.suggest!.shelf_id);
      // @ts-expect-error pinia
      if (availableOnShelf.count !== count) {
        this.sendShortFallSignal();
        //прячем окошко с вводом
        if (count < this.suggest.count!) {
          this.uiStateNeedBarcodeRequest = false;
        }
        finishSherlock();
        this.$emit('input-sherlock-count', count);
      }
    },
    finishSuggest() {
      sendRequestBarcodeTime(this.suggest?.suggest_id);
      this.$emit('finish', {
        suggest: this.suggest,
        weight: this.collected.weight,
        true_mark: this.true_mark,
        barcodes: this.collected.barcodes,
        count: this.collected.count,
      });
      this.collected.count = 0;
    },
    toggleSherlock(value: boolean) {
      // костыль для старта сборки
      startAssembling();
      if (value) {
        this.uiStateNeedBarcodeRequest = false;
        this.uiState.needSherlockV1 = true;
        startSherlock();
      } else {
        this.uiStateNeedBarcodeRequest = true;
        this.uiState.needSherlockV1 = false;
      }
    },
    async collect(): Promise<void> {
      const confirm = await this.$notification.confirmCenter({
        title: this.$gettext('Весь товар собран?'),
        text: this.$gettext('Убедитесь, что этого товара больше нет.'),
        ok: this.$gettext('Да, весь'),
        decline: this.$gettext('Отмена'),
      });
      if (!confirm) return;
      this.finishSuggest();
    },
    startRequestBarcode() {
      this.uiStateNeedBarcodeRequest = true;
    },
    stopRequestBarcode() {
      this.uiStateNeedBarcodeRequest = false;
    },
  },
});
</script>

<style scoped>
.custom-scroll::-webkit-scrollbar {
  display: none;
}
</style>
