import { getSuggestsByStatus } from '@/fsd/entities/suggest/tools/suggestsFilters';
import Item, { ItemInRequired } from '@/models/Item';
import Product, { TagsEnum, TypeAccountingEnum } from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import OrderEvent from '@/models/events/OrderEvent';
import { isString } from '@/models/typeGuards';
import itemQueue from '@/services/queue/item-queue';
import productQueue from '@/services/queue/product-queue';
import shelfQueue from '@/services/queue/shelf-queue';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useSuggests } from '@/store/modules/suggests';
import { defaultSourceFormatLong } from '@/temp/constants/dateFormat';
import { getOrderTypes } from '@/temp/constants/translations';
import { $gettext } from '@/temp/plugins/gettext';
import { ProductInRequired, isProductInRequired } from '@/types/product';
import { getFormatDate } from '@/utils';
import dayjs from 'dayjs';
import { BaseModel } from 'sjs-base-model';
import { computed, unref } from 'vue';

export enum OrderWorkStatusEnum {
  request = 'request',
  processing = 'processing',
}

export type OrderStatus = OrderTargetEnum | OrderWorkStatusEnum;

export enum OrderTargetEnum {
  complete = 'complete',
  failed = 'failed',
  canceled = 'canceled',
}

export enum OrderEstatusEnum {
  waiting = 'waiting',
  done = 'done',
  // специфично для контролек, означает, что ордер ждет завершения подсчета из админки
  waiting_signal = 'waiting_signal',
  // статус говорящий, что сигнал на проверку трансфер акта отправлен
  check_transfer_act = 'check_transfer_act',
  // специфично для отмены клиенского заказа
  waiting_subs_cancel = 'waiting_subs_cancel',
}

export enum OrderTypeEnum {
  // # Рабочий процесс
  order = 'order', //заказ клиента
  order_retail = 'order_retail', //заказ клиента в магазине
  acceptance = 'acceptance', //приемка товара
  acceptance_market = 'acceptance_market', // приемка посылок
  shipment = 'shipment', //отгрузка товара
  shipment_rollback = 'shipment_rollback', //отмена отгрузки товара
  stowage_market = 'stowage_market', // раскладка посылок
  sale_stowage = 'sale_stowage', // раскладка начинающая продажи
  weight_stowage = 'weight_stowage', // раскладка весового товара
  move = 'move', //перемещение товаров
  writeoff = 'writeoff', //списание товара
  check = 'check', // инвентаризация
  check_final = 'check_final', // инвентаризация
  check_product_on_shelf = 'check_product_on_shelf', //инвентаризация продукта на полке
  check_more = 'check_more', //слепая инвентаризация
  // # Контроль сроков годности
  writeoff_prepare_day = 'writeoff_prepare_day', //  списание однодневных товаров
  check_valid_short = 'check_valid_short', // списание краткосрочных товаров
  check_valid_regular = 'check_valid_regular', // списание долгосрочных товаров
  visual_control = 'visual_control', //списание товара по внешнему виду
  refund = 'refund', // возврат товаров order
  part_refund = 'part_refund', // частичный возврат товаров
  // # Инвентаризация
  inventory = 'inventory', //базовый ордер
  inventory_check_more = 'inventory_check_more', // слепая проверка
  inventory_check_product_on_shelf = 'inventory_check_product_on_shelf', // проверка изменившихся товаров
  // # Прочее
  collect = 'collect', // накопление списка товаров
  hand_move = 'hand_move',
  repacking = 'repacking', // перефасовка
  control_check = 'control_check', // контроль точности комплектации

  kitchen_provision = 'kitchen_provision', // снабжение кухни. то же самое, что и hand_move, но больше приоритет в карусели
}

enum OrderSourceEnum {
  dispatcher = 'dispatcher', // из диспетчерской
  tsd = 'tsd', // создан полкой
  eda = 'eda', // из яндекседы
  '1c' = '1c', // из 1С
  integration = 'integration', // интеграция
  external = 'external', // внешний
  woody = 'woody', // интеграция с woody
  tristero = 'tristero', // интеграция посылок
  internal = 'internal', // внутренние процессы
  zoho = 'zoho', // интеграция с zoho
}

export enum OrderStageEnum {
  trash = 'trash',
  stowage = 'stowage',
  all_done = 'all_done',
  store = 'store',
  complete = 'complete',
  trash2box = 'trash2box',
  trash2shelf = 'trash2shelf',
  repacking2box = 'repacking2box',
  repacking2shelf = 'repacking2shelf',
}

export interface Problem {
  type:
    | 'low'
    | 'shelf_not_found'
    | 'product_not_found'
    | 'empty_products'
    | 'empty_shelves'
    | 'api_call_failure'
    | 'stock_found_for_item';
  // - low                   # недостаточно товара
  // - shelf_not_found       # полка не найдена
  // - product_not_found     # продукт не найден
  // - empty_products        # пустая секция products (при созд ордера)
  // - empty_shelves         # пустая секция shelves (при созд ордера)
  // - api_call_failure      # ошибка похода в апишку
  product_id?: string;
  // description: Идентификатор продукта для `low`, `product_not_found`
  shelf_id?: string;
  // description: Идентификатор полки для `low`, `shelf_not_found`
  count?: number;
  // description: Количество продукта для `low`
  reserve?: number;
  stock_id?: string;
  item_id?: string;
  shelf_type?: string;
}

export interface Signal {
  sent: string;
  sigid: string;
  type: 'stat' | 'confirm_assembled_products' | 'complete_final_stage';
  data: any;
  done: any;
}

export type ShelfPickingMethod = 'delete_items' | 'replace_items';

export default class BaseOrder extends BaseModel {
  //Идентификатор ордера
  public order_id: string = '';
  //Идентификатор склада
  public store_id: string = '';
  //Внешний идентификатор ордера
  public external_id: string = '';
  //Родительские ордера
  public parent: string[] = [];
  //Статус ордера
  public status: OrderStatus = OrderWorkStatusEnum.request;
  //Целевой статус
  public target: OrderTargetEnum = OrderTargetEnum.complete;
  //Подстатус
  public estatus: OrderEstatusEnum = OrderEstatusEnum.waiting;
  //Тип ордера
  public type: OrderTypeEnum = OrderTypeEnum.order;
  //Исполнители на ордере
  public users: string[] = [];
  //Список товаров требующихся клиенту
  public required: (ProductInRequired | ItemInRequired)[] = [];
  //Список проблем с товарами
  public problems: Problem[] = [];
  //Список полок заказа
  public shelves: string[] = [];
  //Список продуктов в документе
  public products: string[] = [];
  public version: number = 0;
  public revision: number = 0;
  public created: number = 0;
  public updated: string = '';
  public serial: number = 0;
  public attr: {
    doc_date: string;
    contractor?: string;
    doc_number: string;
    request_id?: string;
    request_date?: string;
    request_number?: string;
    request_type?: 'move_order';
    maybe_rover?: boolean;
    trust_code?: string;
    acceptance_mode?: 'pre-check';
    is_pickup?: boolean;
    returnable_supplier?: boolean;
    client_comment?: string;
    shelf_picking_method?: ShelfPickingMethod;
  } = { doc_date: '', doc_number: '' };
  //Общая стоимость всех товаров в ордере
  public total_price?: string;
  //Кто создал
  public user_id: string = '';

  public signals: Signal[] = [];
  public courier:
    | {
        //  Идентификатор курьера во внешней системе
        external_id: string;
        // Имя курьера
        name?: string;
        // Имя ровера 2
        vin?: string;
        // Имя ровера 1
        taxi_driver_uuid?: string;
        type: 'human' | 'rover';
        //   Телефон курьера
        phone?: string;
        // Когда прибудет за заказом
        arrival_time: string;
        //  Идентификаторы заказов связанных по id курьера
        related_orders: string[];
      }
    | undefined = undefined;
  public vars:
    | {
        tag?: TagsEnum | 'mixed' | 'parcel';
        addition_tag?: 'perishable' | 'parcel' | 'office';
        need_transfer_act?: boolean;
        stage?: OrderStageEnum;

        suggest_mode?: 'done';
        third_party_assistance?: boolean;
        reserve?: boolean;
        mode?: string;
        // специфично для приемок из маркета
        transfer_act?: {
          status?: 'CREATED' | 'CANCELLED' | 'CLOSED';
        };
        transfer_act_diff?: {
          barcode: string;
          item_id: string | null;
          status: 'transferred_and_not_received' | 'not_transferred_and_received';
        }[];
        // для весового размещения. определяет то, какой именно баркод сканирует пользователь(родитель/ребенок)
        scan_unit_child_product?: boolean;
        market_orders?: any;
        fulfilled_conditions?: {
          confirm_assembled_products?: boolean;
        };
        market_courier?: any;
        confirming_assembled_products?: any;
        lot_item_ids?: { [key: string]: string[] };
        total_order_weight?: number;
      }
    | any = {};
  //эни нужен пока не распилим варсы по разным типам документов
  public data: any = {};
  // источник документа
  public source: OrderSourceEnum = OrderSourceEnum.tsd;

  // при типизации этого свойства у нас разлетаются типы и не получается пользоваться полиморфизмом
  protected _self: any = undefined;

  constructor(data: any) {
    super();
    this.update(data);
  }

  update(data) {
    if (data.created) {
      if (typeof data.created === 'string') {
        data.created = dayjs(data.created, defaultSourceFormatLong).unix();
      }
    }
    return super.update(data);
  }

  updateFromEvent(event: OrderEvent) {
    // не самое удобное приседание. но без него никак.
    // вся реактивность во вью 3 работает через прокси, соответственно объект может себя мутировать ТОЛЬКО через
    // свой же экземпляр обернутый прокси.
    // на подумать: а может сразу после записи с стор взять и проставить в каждую модель ссылку на свое прокси ??
    this.self.status = event.status || this.self.status;
    this.self.estatus = event.estatus || this.self.estatus;
    this.self.version = event.version || this.self.version;
    this.self.revision = event.revision || this.self.revision;
    this.self.users = event.users || this.self.users;

    // в ивентах нет таргета, поэтому обновим его вручную
    if (event.status === 'canceled' && this.target === 'complete') {
      this.self.target = OrderTargetEnum.canceled;
    }

    return this.self;
  }

  async loadProducts() {
    try {
      const promises: Promise<any>[] = [];
      if (this.required.length) {
        promises.push(productQueue.loadMany(this.orderProductsId));
        promises.push(itemQueue.loadMany(this.orderItemsId));
      }
      if (this.problems.length) {
        const product_ids = this.problems.map(s => s.product_id).filter(isString);
        promises.push(productQueue.loadMany(product_ids));
      }
      await Promise.allSettled(promises);
    } catch (e) {
      console.error('order-model loadProducts', e);
    }
  }

  get orderShelvesId() {
    if (this.status === 'processing' && this.suggests.length) {
      return this.suggests.map(s => s.shelf_id).filter(isString);
    }
    return [...new Set([...this.required.map(r => r.shelf_id), ...(this.shelves || [])])].filter(isString);
  }

  get orderProductsId() {
    if (this.status === 'processing' && this.suggests.length) {
      return this.suggests.filter(s => s.isProductSuggest).map(s => s.product_id);
    }
    return this.required
      .filter(isProductInRequired)
      .map(s => s.product_id)
      .filter(Boolean);
  }

  get orderItemsId() {
    if (this.status === 'processing' && this.suggests.length) {
      return this.suggests.filter(s => s.isItemSuggest).map(s => s.product_id);
    }
    return this.required
      .filter(Item.isItemInRequired)
      .map(s => s.item_id)
      .filter(Boolean);
  }

  loadShelves = async () => {
    try {
      const promises: Promise<any>[] = [];
      const shelves_id = this.orderShelvesId;
      if (shelves_id.length) {
        promises.push(shelfQueue.loadMany(shelves_id));
      }
      await Promise.allSettled(promises);
    } catch (e) {
      console.error('order-model loadProducts', e);
    }
  };

  get self(): this {
    // геттер, для изменения данных изнутри модельки, позволяет оповещать внешнее прокси об изменении данных
    if (this._self) {
      return this._self;
    }
    this._self = useOrders().orderById(this.order_id)!;
    return this._self;
  }

  get isControl() {
    return ['check_valid_regular', 'writeoff_prepare_day', 'check_valid_short'].includes(this.type);
  }

  protected _loadedProducts = computed(() => {
    let product_ids: string[] = [];
    if (this.status === OrderWorkStatusEnum.processing && this.suggests.length) {
      product_ids = this.suggests.map(s => s.product_id);
    } else if (this.required?.length) {
      product_ids = this.required.filter(isProductInRequired).map(s => s.product_id);
    }
    return product_ids
      .map(id => useProducts().products.get(id))
      .filter(Product.isProduct)
      .filter(Boolean);
  });

  get loadedProducts() {
    return unref<Product[]>(this._loadedProducts);
  }

  get hasFragile() {
    return this.loadedProducts.some(p => p.fragile);
  }

  suggestById(suggest_id: string): Suggest | undefined {
    return useSuggests().getSuggest(this.order_id, suggest_id);
  }

  //  Отображение количества заданий в документе для карточки заказа
  // базовая логика здесь все остальное в расширенных модельках
  protected _requiredActionsCount = computed(() => {
    if (this.status === 'request') {
      return this.required.length || 0;
    }
    if (this.target === 'canceled') {
      return getSuggestsByStatus(this.suggests, [SuggestStatusEnum.request, SuggestStatusEnum.blocked]).length;
    }
    if (this.status === 'processing') {
      return getSuggestsByStatus(this.suggests, [SuggestStatusEnum.request, SuggestStatusEnum.blocked]).length;
    }
    return 0;
  });

  get requiredActionsCount() {
    return unref<number>(this._requiredActionsCount);
  }

  protected _allActionsCount = computed(() => {
    if (this.status === 'request') {
      return this.required.length || 0;
    }
    if (this.target === 'canceled') {
      return this.suggests.length;
    }
    if (this.status === 'processing') {
      return this.suggests.length;
    }
    return 0;
  });

  get allActionsCount() {
    return unref<number>(this._allActionsCount);
  }

  // дата документа с форматированием
  get date() {
    const date = this.attr.request_date || this.attr.doc_date;
    if (date) {
      return getFormatDate(date);
    }
    return $gettext('без даты');
  }

  // общая логика сортировки саджестов. для изменения переопредели метод в наследнике.
  protected sorter(suggests: Suggest[]): Suggest[] {
    const statusSorter = (a: Suggest, b: Suggest): -1 | 0 | 1 => {
      switch (true) {
        case a.status === b.status:
          return 0;
        case a.status === 'request':
          return -1;
        case b.status === 'request':
          return 1;
        default:
          return 0;
      }
    };
    const shelfOrderSorter = (a: Suggest, b: Suggest): number => {
      if (!a.shelf || !b.shelf) return 0;
      return a.shelf.order - b.shelf.order;
    };
    const updateTimeSorter = (a: Suggest, b: Suggest): number => {
      return b.updated - a.updated;
    };
    return suggests.sort((a, b) => {
      return statusSorter(a, b) || shelfOrderSorter(a, b) || updateTimeSorter(a, b);
    });
  }

  protected _suggest = computed(() =>
    this.sorter(Array.from(useSuggests().suggestsByOrderId(this.order_id)?.values() || [])),
  );

  get suggests() {
    return unref<Suggest[]>(this._suggest);
  }

  getSuggestsByProductId(product_id: string) {
    return this.suggests.filter(s => s.product_id === product_id);
  }

  get title() {
    return getOrderTypes[this.type];
  }

  get contractor() {
    return this.attr.contractor || '-';
  }

  get rack() {
    const shelfId = this.shelves[0] || this.required[0].shelf_id;
    if (shelfId) {
      const shelf = useShelves().shelfById(shelfId);
      return shelf?.rack;
    }
  }

  get isCanceled() {
    return this.target === 'canceled';
  }

  get isRover() {
    return this.courier?.type === 'rover';
  }

  // признак того, что пользователь выполняет этот ордер
  get isActiveOrder() {
    return this.status !== 'request';
  }

  get hasAdultProducts() {
    if (!this.loadedProducts.length) return true;
    return this.loadedProducts.some(p => p.underage_restricted);
  }

  get hasSpecialPackageProducts() {
    return this.loadedProducts.some(p => p.special_package);
  }

  get isAvailableOrderType() {
    return useOrders().isAvailableOrderType(this.type);
  }

  get totalWeight() {
    return this.required.reduce((acc, i) => {
      if (isProductInRequired(i)) {
        const product = useProducts().productById(i.product_id);
        return acc + (product?.weight ?? 0) * (i.count ?? 0);
      }
      const item = useItems().itemById(i.item_id);
      return acc + (item?.weight ?? 0);
    }, 0);
  }

  get maxWeightInOrder() {
    return this.required.reduce((acc, i) => {
      if (isProductInRequired(i)) {
        const product = useProducts().productById(i.product_id);
        if (product?.type_accounting === TypeAccountingEnum.true_weight) {
          return acc > 1 ? acc : 1;
        }
        const productWeight = product?.weight ?? 0;
        return acc > productWeight ? acc : productWeight;
      }
      const item = useItems().itemById(i.item_id);
      const itemWeight = item?.weight || 0;
      return acc > itemWeight ? acc : itemWeight;
    }, 0);
  }

  get isAvailableForJunior() {
    return this.isAvailableOrderType && !this.hasAdultProducts;
  }
}
