import Logger from '@/fsd/shared/utils/logger';
import { logger } from '@/temp/plugins/logs';
import { AxiosResponse } from 'axios';

enum UrlsForLogEnum {}
export interface ApiLog {
  url: string;
  duration: number;
  start: number;
}
const sender = (logs: ApiLog[]) => {
  logger.error('status/api', {
    additional: {
      data: logs,
    },
  });
};
const filter = (log: ApiLog) => {
  return log.url in UrlsForLogEnum;
};

const apiLogger = new Logger<ApiLog>(sender, filter);

const getUrlForLogger = (url: string): string => {
  return url.split('?')[0];
};
export default (resp: AxiosResponse) => {
  const url = getUrlForLogger(resp.config.url!);
  if (resp.duration > 10000) {
    logger.error('status/internet', {
      additional: {
        url,
        duration: resp.duration,
      },
    });
  }
  apiLogger.push({
    start: resp.config.metadata?.startTime || Date.now(),
    duration: resp.duration || 0,
    url,
  });
};
