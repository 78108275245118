<template>
  <div
    v-if="uiState.needSherlockV1"
    class="sherlock-counter-container grow-0 shrink-0 basis-auto overflow-hidden"
    @touchstart.stop
  >
    <Typography color="white" type="h4" margin="0 12px 16px" class="sherlock-title" align="center">
      <template v-if="uiState.needScan">{{ $gettext('Отсканируйте продукт') }}</template>
      <template v-else>{{ $gettext('Какое количество этого товара на полке?') }}</template>
    </Typography>
    <Counter
      :disabled="counterDisabled"
      :min="0"
      :max="undefined"
      :timeout-for-btn="5"
      custom-btn
      class="custom-counter"
      :product-id="suggest.product_id"
      @confirm="onInputCountConfirm"
    >
      {{ $gettext('Готово') }}
    </Counter>
  </div>
</template>

<script lang="ts">
import { checkBarcodeBySuggest } from '@/fsd/widgets/order/RequiredCard/useCollectOrder';
import requestBarcode from '@/mixins/requestBarcode';
import Suggest, { SuggestTagEnum } from '@/models/Suggest';
import OrderOrder from '@/models/orders/OrderOrder';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { availableForSherlock, experiments } from '@/temp/constants';
import { logger } from '@/temp/plugins/logs';
import Counter from '@/ui/common/counter.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Typography from '@/ui/common/typography.vue';
import { PropType, defineComponent } from 'vue';

interface Data {
  uiState: {
    needScan: boolean;
    alreadyAckStock: boolean;
    needSherlockV1: boolean;
  };
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'SherlockCounter',
  components: {
    Counter,
    Typography,
  },
  mixins: [requestBarcode],
  props: {
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    order_id: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['need-sherlock', 'input'],
  setup() {
    const { showLoader, closeComponentLoaders } = useLoader();
    const ordersStore = useOrders();
    const productsStore = useProducts();
    const userStore = useUser();

    return { showLoader, closeComponentLoaders, ordersStore, productsStore, userStore };
  },
  data(): Data {
    return {
      uiState: {
        needScan: true,
        alreadyAckStock: false,
        needSherlockV1: false,
      },
      uiStateNeedBarcodeRequest: false,
    };
  },
  computed: {
    order(): OrderOrder {
      return this.ordersStore.orderById(this.order_id) as OrderOrder;
    },
    counterDisabled(): boolean {
      return this.uiState.needScan;
    },
    sherlockMustSkip(): boolean {
      const hasInSignals = Boolean(this.order.signals?.find(s => s.data?.suggest_id === this.suggest.suggest_id));
      //если уже был запрос остатков от кладовщика
      //или запрос есть в сигналах документа или выключен эксперимент
      //или это не "взять с полки" или это готовая еда, то НЕ спрашиваем остатки
      return (
        this.uiState.alreadyAckStock ||
        hasInSignals ||
        !this.userStore.experimentByName(experiments.exp_sherlock) ||
        this.suggest.type !== 'shelf2box' ||
        this.suggest.conditions.tags?.includes(SuggestTagEnum.kitchen)
      );
    },
  },
  watch: {
    isVisible: {
      handler(visible) {
        if (this.suggest?.status === 'request' && visible) {
          this.checkNeedSherlockV1();
        }
      },
      immediate: true,
    },
  },
  methods: {
    onInputCountConfirm({ value }): void {
      this.uiState.needSherlockV1 = false;
      this.uiState.alreadyAckStock = true;
      this.$emit('need-sherlock', false);
      this.$emit('input', value);
    },
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);

      const valid = await checkBarcodeBySuggest(barcode, this.suggest);
      if (!valid) {
        return true;
      }
      this.uiState.needScan = false;
      return false;
    },
    async sendTime(): Promise<void> {
      try {
        const startTime = Date.now();
        await this.waitInputSherlockV1();
        // ждем пока юзер не введем остатки и мы не спрячем попап
        const endTime = Date.now();
        const time = endTime - startTime;
        //отправить событие с кол-вом в главный компонент. он уже решит, что нужно делать
        await this.ordersStore.signal({
          order_id: this.order.order_id,
          signal: 'stat',
          data: { [this.suggest.suggest_id]: { duration: time } },
        });
      } catch (error) {
        logger.error(error, { method: 'signal', source: 'sherlock-counter.vue' });
      }
    },
    async waitInputSherlockV1(): Promise<void> {
      return new Promise(resolve => {
        const unWatch = this.$watch('uiState.needSherlockV1', newValue => {
          if (!newValue) {
            unWatch();
            resolve();
          }
        });
      });
    },
    //Шерлок v1 запрашиваем ДО сборки позиции
    async checkNeedSherlockV1(): Promise<void> {
      if (this.sherlockMustSkip) {
        this.$emit('need-sherlock', false);
        return;
      }
      try {
        this.$emit('need-sherlock', true);
        this.uiStateNeedBarcodeRequest = true;

        // спросить о количестве остатков, если available<count
        const available = await this.productsStore.fetchAvailable(this.suggest.product_id);
        const availableOnShelf = available?.find(item => item.shelf_id === this.suggest.shelf_id);
        const sherlockCount = this.userStore.sherlockBorder || availableForSherlock;
        //спрашиваем остатки только если остатки есть, остатков больше чем минимальное кол-во для запроса и после выполнения саджеста остатков станет меньше
        if (
          availableOnShelf &&
          availableOnShelf.count > sherlockCount &&
          availableOnShelf.count - this.suggest.count! <= sherlockCount
        ) {
          this.uiState.needSherlockV1 = true;
          // показываем окошко с  вводом кол-ва
          this.sendTime();
          //  начинаем считать и отправляем время шерлока
        } else {
          this.uiState.alreadyAckStock = true;
          this.$emit('need-sherlock', false);
        }
      } catch (e) {
        console.error(e);
        this.$emit('need-sherlock', false);
      }
    },
  },
});
</script>

<style lang="scss">
.sherlock-counter-container {
  background: #302f2d;
  border-radius: 20px 20px 0 0;
  padding: 16px 12px;
}

.custom-counter {
  flex-wrap: wrap;

  .click-container {
    margin: 0 0 8px 0;
  }
}

.sherlock-title {
  //текут стили из карусельки
  white-space: normal;
}
</style>
