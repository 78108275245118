import BaseOrder from '@/models/orders/BaseOrder';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';

// обратная совместимость

export default class AcceptanceOrder extends BaseOrder {
  get title() {
    return `${$gettext('Приёмка')} ${this.contractor}`;
  }
  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.maxWeightInOrder > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}
