<template>
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.44444 0C0.6467 0 0 0.6467 0 1.44444V7C0 9.973 2.16229 12.441 5 12.917V18H2C1.44772 18 1 18.4477 1 19C1 19.5523 1.44772 20 2 20H10C10.5523 20 11 19.5523 11 19C11 18.4477 10.5523 18 10 18H7V12.917C9.83771 12.441 12 9.973 12 7V1.44444C12 0.6467 11.3533 0 10.5556 0H7.66667L9 3L7 6L8 9L5 6L6 3L4 0H1.44444Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
  },
});
</script>
