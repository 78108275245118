<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="captionText"
        need-progress
        :progress-config="progressConfig"
        :total="suggests.length"
        :menu-config="menuConfig"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <div class="flex flex-col h-full">
        <Hint v-if="filteredSuggests.length !== 0" class="my-2 mx-4">{{ $gettext('Отсканируйте товар') }}</Hint>
        <SuggestCardContainer
          doc-type="shipment"
          :order="order"
          :suggest-menu-config="suggestMenuConfig"
          :suggests="
            filteredSuggests.map(s => {
              s.need_menu = s.status === 'request' || s.result_count > 0;
              return s;
            })
          "
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />
      </div>
      <SuggestDetails
        v-if="suggestDetails.visible.value"
        :suggest-id="suggestDetails.props.value.suggest_id"
        :order-id="order.order_id"
        @cancel="suggestDetails.hide"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
      />

      <ScanShelf v-if="scanShelf.visible.value" @scanned="scanShelf.hide" />
      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>

    <template #footer>
      <LayoutFooter>
        <FilterMenu v-if="uiStateFilter === 'all'" :menu-config="filterMenuConfig" />
        <UiButton
          v-if="uiStateFilter === 'all'"
          data-test="shipment footer finish-btn"
          :disabled="!allSuggestDone"
          @click="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </UiButton>
        <UiButton v-if="uiStateFilter !== 'all'" @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getBox2ShelfSuggests, getShelf2BoxSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import detach from '@/mixins/detach';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import withFilter from '@/mixins/withFilter';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import ShipmentOrder from '@/models/orders/ShipmentOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import Bar from '@/ui/common/bar/bar.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { getOrderNumber } from '@/utils';
import { checkConditions } from '@/utils/checkConditions';
import { checkPermit } from '@/utils/checkPermit';
import { needUpgradeRoleModal } from '@/utils/modals';
import commonMixin from '@/views/common/commonMixin';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
  uiStateFilter: 'all' | 'done' | 'request' | 'part' | 'noProduct';
}

export default defineComponent({
  name: 'Shipment',
  components: {
    PageLayout,
    LayoutFooter,
    SuggestCardContainer,
    Bar,
    Hint,
    UiButton,
    SuggestDetails,
    FilterMenu,
    ShareOrder,
    ScanShelf,
  },
  mixins: [requestProductCode, requestBarcode, withFilter, detach, commonMixin],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const productsStore = useProducts();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const scanShelf = useComponent<void, Shelf>();
    const router = useRouter();

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      ordersStore,
      productsStore,
      shareOrder,
      suggestDetails,
      scanShelf,
      router,
    };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
      uiStateFilter: 'all',
    };
  },
  computed: {
    order(): ShipmentOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as any as ShipmentOrder;
    },
    suggests(): Suggest[] {
      if (!this.order) return [];
      if (this.order.target === 'canceled') {
        return getBox2ShelfSuggests(this.order.suggests);
      }
      return getShelf2BoxSuggests(this.order.suggests);
    },
    contractorName(): string {
      if (!this.order || !this.order.attr.contractor) {
        return '';
      }
      return this.order.attr.contractor;
    },
    controlBarText(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Осталось %{request} из %{all} товаров', {
            request: String(this.requestSuggests.length),
            all: String(this.suggests.length),
          }) + this.contractorName
            ? `<br/>${this.contractorName}`
            : '';
        case 'done':
        case 'request':
        case 'part':
        case 'noProduct':
          return this.$ngettext(
            '%{filtered} товар с таким статусом',
            '%{filtered} товаров с таким статусом',
            this.filteredSuggests.length,

            { filtered: String(this.filteredSuggests.length) },
          );
      }
      return this.contractorName;
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => this.finishActiveSuggest({ count: 0 }, suggest),
          condition: () => {
            return checkConditions(suggest, 'all', true) && (suggest.status === 'request' || suggest.result_count! > 0);
          },
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    captionText(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return `${this.$gettext('Отгрузка')} ${getOrderNumber(this.order!)}`;
        case 'done':
          return this.$gettext('Товары со статусом “Готово”');
        case 'request':
          return this.$gettext('Не отсканированные товары');
        case 'part':
          return this.$gettext('Товары со статусом “Почти”');
        case 'noProduct':
          return this.$gettext('Товары со статусом “Нет”');
      }
      return this.$gettext('Отгрузка');
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];

      const detachBtn: MenuItemConfig = {
        buttonText: this.$gettext('Открепиться от задания'),
        onClick: () => this.detach(),
        condition: () => Boolean(this.order?.users && this.order.users.length >= 2),
      };
      menuConfig.push(detachBtn);

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        onClick: () => this.showShareOrder(),
        dataTest: 'bar menu-item share-order-btn',
      };
      menuConfig.push(shareOrderBtn);

      const completeAllSuggests: MenuItemConfig = {
        buttonText: this.$gettext('Отгрузить все'),
        onClick: () => this.completeSuggests(),
        dataTest: 'bar menu-item complete-suggests-btn',
      };
      menuConfig.push(completeAllSuggests);

      return menuConfig;
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const { product, item } = await this.requestProductCode({ checkSuggests: true });
      return await this.selectProduct(product || item);
    },
    async selectProduct(product): Promise<boolean> {
      const productSuggests = this.order!.suggests!.filter(
        s => (s.product_id === product.product_id || s.product_id === product.item_id) && s.status === 'request',
      )!;

      if (productSuggests.length === 1) {
        const shelf2BoxSuggest = productSuggests[0];
        await this.selectSuggest(shelf2BoxSuggest);
        return true;
      }
      if (productSuggests.length === 0) {
        this.$notification.error.micro(this.$gettext('Этот товар уже отгружен'));
        return true;
      }
      const shelf = await this.scanShelf.asyncShow();
      if (!shelf) return true;
      const suggest = productSuggests.find(item => item.shelf_id === shelf.shelf_id);
      if (suggest) {
        await this.selectSuggest(suggest);
        return true;
      } else {
        this.$notification.error.micro(this.$gettext('Отсканирована неверная полка'));
        return this.selectProduct(product);
      }
    },
    async selectSuggest(suggest: Suggest): Promise<void> {
      if (suggest.vars.mode === 'item') {
        await this.finishActiveSuggest({ count: 1 }, suggest);
      } else {
        await this.suggestDetails.asyncShow(suggest);
      }
    },
    toHomePage(): Promise<any> {
      return this.router.push({ name: 'home' });
    },
    async finishActiveSuggest({ count }: Pick<Model, 'count'>, suggest: Suggest): Promise<any> {
      if (!suggest) return;
      if (count === suggest.result_count) {
        this.suggestDetails.hide();
        return;
      }
      if (suggest.vars?.mode !== 'item') {
        const confirmed = await this.$notification.confirmCenter({
          title: this.$gettext('Вы уверены, что отгрузили все товары?'),
        });
        if (!confirmed) return;
      }

      if (suggest.type === 'shelf2box') {
        const result = await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
        if (!result) return;
      } else {
        const result = await useBox2Shelf(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
        if (!result) return;
      }
      this.suggestDetails.hide();
    },
    async finishOrder(): Promise<void> {
      const confirm = await this.$notification.confirmCenter({
        title: this.$gettext('Вы уверены, что отгрузили все товары?'),
        ok: this.$gettext('Да, все готово'),
      });
      if (!confirm) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
    setFilter(filter): void {
      this.uiStateFilter = filter;
    },
    async completeSuggests(): Promise<void> {
      if (!this.order) return;
      //  проверить пермит
      const hasPermit = checkPermit('order_signals', 'complete_suggests');
      if (!hasPermit) {
        const result = await needUpgradeRoleModal();
        if (result) {
          this.router.push({ name: 'settings-upgrade-role', params: { upgrade: 'true' } });
        }
        return;
      }
      const { closeLoader } = this.showLoader(
        this.$gettext('Отправляем сигнал на отгрузку всех позиций'),
        this.order_id,
      );
      try {
        //  выполнить запрос
        await this.ordersStore.signal({
          order_id: this.order.order_id,
          signal: 'complete_suggests',
        });
      } catch (e) {
        console.error(e);
      } finally {
        closeLoader();
      }
    },
  },
});
</script>
