<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :caption="controlBarCaption"
        :text="controlBarText"
        need-progress
        :total="suggests.length"
        :progress-config="progressConfig"
        :order="order"
        :menu-config="menuConfig"
        @close-click="toHomePage"
      />
    </template>
    <template #default>
      <div class="flex flex-col h-full">
        <Hint v-if="filteredSuggests.length > 0" class="my-2 mx-4">
          {{ $gettext('Отсканируйте товар') }}
        </Hint>
        <SuggestCardContainer
          doc-type="sale_stowage"
          :order="order"
          :suggest-menu-config="suggestMenuConfig"
          :suggests="
            suggestsForView.map(s => {
              s.need_menu = checkNeedMenu(s);
              return s;
            })
          "
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />
      </div>
      <SuggestDetails
        v-if="suggestDetails.visible.value"
        :key="uiState.suggestDetailsKey"
        :order-id="order.order_id"
        :suggest-id="suggestDetails.props.value.suggest_id"
        :product-id="uiState.childrenId"
        :barcode="uiState.barcode"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
        @cancel="closeSuggestDetails"
      />
      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>
    <template #footer>
      <LayoutFooter>
        <template v-if="uiStateFilter === 'all'">
          <FilterMenu :menu-config="filterMenuConfig" />
          <UiButton data-test="sale_stowage finish btn" :is-disabled="!allSuggestDone" @click="finish">
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
        <UiButton v-else @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getDoneSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import detach from '@/mixins/detach';
import more_product from '@/mixins/more_product';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import withFilter from '@/mixins/withFilter';
import Product, { TagsEnum, TypeAccountingEnum } from '@/models/Product';
import { ShelfTypeEnum } from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import SaleStowageOrder from '@/models/orders/SaleStowageOrder';
import { AudioService } from '@/services/audio.service';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { modalNotifyId } from '@/temp/constants/common';
import { shelfTagsInStowage } from '@/temp/constants/translations';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import IconBarcode from '@/temp/icons/icon-barcode.vue';
import { logger } from '@/temp/plugins/logs';
import Bar from '@/ui/common/bar/bar.vue';
import { ProgressConfig } from '@/ui/common/bar/types';
import FilterMenu, { FilterMenuItemConfig } from '@/ui/common/filter-menu/filter-menu.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import commonMixin from '@/views/common/commonMixin';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiStateFilter: 'all' | 'done' | 'request' | 'part';
  uiStateNeedBarcodeRequest: boolean;
  uiState: {
    barcode?: string;
    suggestDetailsKey: number;
    childrenId: string | undefined;
  };
}

export default defineComponent({
  name: 'SaleStowage',
  components: {
    PageLayout,
    LayoutFooter,
    SuggestCardContainer,
    Hint,
    Bar,
    UiButton,
    FilterMenu,
    SuggestDetails,
    ShareOrder,
  },
  mixins: [requestProductCode, requestBarcode, withFilter, detach, more_product, commonMixin],
  setup(props) {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const shelvesStore = useShelves();
    const userStore = useUser();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const router = useRouter();

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      ordersStore,
      itemsStore,
      productsStore,
      shelvesStore,
      userStore,
      shareOrder,
      suggestDetails,
      router,
    };
  },
  data(): Data {
    return {
      uiStateFilter: 'all',
      uiStateNeedBarcodeRequest: true,
      uiState: {
        barcode: undefined,
        suggestDetailsKey: 0,
        childrenId: undefined,
      },
    };
  },
  computed: {
    order(): SaleStowageOrder {
      return this.ordersStore.orderById(this.order_id) as SaleStowageOrder;
    },
    tag(): TagsEnum | 'empty' | 'mixed' | 'parcel' {
      return this.order?.vars?.tag || 'empty';
    },
    progressConfig(): ProgressConfig[] | undefined {
      if (!this.order) return;
      if (this.order.vars.stage === 'stowage') {
        return [
          {
            count: this.fullCompletedSuggests.length,
            color: 'blue',
          },
        ];
      } else {
        return [
          {
            count: this.fullCompletedSuggests.length,
            color: 'green',
          },
          {
            count: this.partiallyCompletedSuggests.length,
            color: 'orange',
          },
          {
            count: this.noProductSuggests.length,
            color: 'red',
          },
        ];
      }
    },
    filterMenuConfig(): FilterMenuItemConfig[] {
      if (this.order && this.order.vars.stage === 'stowage') {
        return [
          {
            buttonText: this.$gettext('Не отсканированные'),
            color: 'secondary',
            count: this.requestSuggests.length,
            onClick: () => this.setFilter('request'),
          },
          {
            buttonText: this.$gettext('В продаже'),
            color: 'blue',
            count: this.fullCompletedSuggests.length,
            onClick: () => {
              this.setFilter('done');
            },
          },
        ];
      } else {
        return [
          {
            buttonText: this.$gettext('Не отсканированные'),
            color: 'secondary',
            count: this.requestSuggests.length,
            onClick: () => this.setFilter('request'),
          },
          {
            buttonText: this.$gettext('Нет'),
            color: 'red',
            count: this.noProductSuggests.length,
            onClick: () => this.setFilter('noProduct'),
          },
          {
            buttonText: this.$gettext('Почти'),
            color: 'orange',
            count: this.partiallyCompletedSuggests.length,
            onClick: () => {
              this.setFilter('part');
            },
          },
          {
            buttonText: this.$gettext('Готово'),
            color: 'green',
            count: this.fullCompletedSuggests.length,
            onClick: () => {
              this.setFilter('done');
            },
          },
        ];
      }
    },
    fullCompletedSuggests(): Suggest[] {
      if (this.order && this.order.vars.stage === 'stowage') {
        return getDoneSuggests(this.suggests);
      } else {
        return this.suggests.filter(s => s.status === 'done' && s.result_count && s.count && s.count <= s.result_count);
      }
    },
    suggests(): Suggest[] {
      if (!this.order) return [];
      return this.order.suggests;
    },
    suggestsForView(): Suggest[] {
      return this.filteredSuggests.slice(0, 50);
    },
    controlBarCaption(): string {
      switch (this.uiStateFilter) {
        case 'all':
          if (this.tag === 'mixed') {
            return this.$gettext('Размещение');
          }
          return this.$gettext('Размещение в %{tag}', {
            tag: shelfTagsInStowage[this.tag],
          });
        case 'done':
          return this.$gettext('Товары со статусом “Готово”');
        case 'request':
          return this.$gettext('Товары со статусом “Нет”');
        case 'part':
          return this.$gettext('Товары со статусом “Почти”');
        default:
          return this.$gettext('Размещение в %{tag}', { tag: shelfTagsInStowage[this.tag] });
      }
    },
    controlBarText(): string {
      if (this.order?.suggests) {
        return this.$ngettext(
          'Осталось %{request} из %{all} наименование',
          'Осталось %{request} из %{all} наименований',
          this.suggests.length,

          {
            request: String(this.requestSuggests.length),
            all: String(this.suggests.length),
          },
        );
      }
      return '';
    },
    suggestMenuConfig(): (suggest?: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        if (!suggest) return menuConfig;
        // кнопка закрывает саджест в ноль
        // должна быть доступна только для доверительных приемок
        // для недоверительных приемок ее быть не должно!!!(синк с Женей от 30.11.22)
        //  если в размещении ничего не разместить, то я не смогу понять, что размещение было доверительным,
        //  поэтому показываем кнопку на этапе списания всегда (этапа списания больше нет - будет ли проблема?)
        if (this.order!.isTrustStowage) {
          const noProductBtn: MenuItemConfig = {
            buttonText: this.$gettext('Отметить как отсутствующий'),
            dataTest: 'missing item btn',
            onClick: () => useBox2Shelf(this.order_id, { suggest_id: suggest.suggest_id, count: 0 }),
            condition: () => checkConditions(suggest, 'all', true) && suggest.status === 'request',
          };
          menuConfig.push(noProductBtn);
        }
        const openDetailsBtn: MenuItemConfig = {
          buttonText: this.$gettext('Разместить товар'),
          onClick: () => this.showSuggestDetails(suggest),
          dataTest: 'open-details btn',
          condition: () => {
            return (
              suggest.product?.type_accounting === TypeAccountingEnum.true_weight &&
              this.userStore.experimentByName(experiments.exp_weighty_touch)
            );
          },
        };
        menuConfig.push(openDetailsBtn);
        return menuConfig;
      };
    },

    confirmFinishTitle(): string {
      if (this.tag === 'mixed') {
        return this.$gettext('Вы уверены, что разместили все товары?');
      }
      return this.$gettext('Вы уверены, что разместили все товары в %{tag}?', {
        tag: shelfTagsInStowage[this.tag],
      });
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];

      const detachBtn: MenuItemConfig = {
        buttonText: this.$gettext('Открепиться от задания'),
        onClick: () => this.detach(),
        condition: () => Boolean(this.order?.users && this.order.users.length >= 2),
      };
      menuConfig.push(detachBtn);

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        onClick: () => this.showShareOrder(),
      };
      menuConfig.push(shareOrderBtn);

      return menuConfig;
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      let { product, item, allResults, barcode } = await this.requestProductCode({ checkSuggests: false });
      if (!product && !item) {
        this.$notification.error.micro(this.$gettext('Не удалось отсканировать баркод, попробуйте еще раз'));
        return true;
      }
      if (!this.order || !this.order.suggests) {
        return true;
      }

      if (this.userStore.experimentByName(experiments.exp_purple_hearts)) {
        const productsWithChildren: Product[] | undefined = allResults
          ?.filter(Product.isProduct)
          .filter(product => product.children_id);
        if (productsWithChildren?.length) {
          this.$notification.modal({
            title: this.$gettext('Отсканируйте штрих-код дочернего товара'),
            text: this.$gettext(
              'Вы отсканировали родительский товар, для продолжения работы необходимо отсканировать код дочернего товара.',
            ),
            iconTop: {
              icon: IconBarcode,
              position: 'center',
            },
            buttonText: this.$gettext('Закрыть'),
          });

          return true;
        }
      }

      let suggest = this.findNeededSuggest(allResults);

      this.$notify.close(modalNotifyId);

      // выше мы получаем саджест со статусом request, но каким-то образом на проде кладовщик отсканил посылку и мы прошли дальше со статусом done
      if (suggest && suggest.status === 'done' && !suggest.isEditable && item) {
        await this.$notification.modal({
          title: this.$gettext('Посылка уже размещена на %{shelf_title} полке', {
            shelf_title: this.shelvesStore.shelfById(suggest.shelf_id)?.title || '',
          }),
        });
        return true;
      }
      if (!suggest && product) {
        if (this.order.isTrustStowage) {
          if (allResults && allResults.length > 1) {
            for (const p of allResults) {
              const available = await this.productsStore.fetchAvailable((p as Product).product_id);
              // @ts-expect-error pinia
              if (available.length > 0) {
                return await this.sendMoreProductSignal(p as Product);
              }
            }
            this.$notification.modal({
              title: this.$gettext('Внимание!'),
              text: this.$gettext('Нельзя добавить товар с дубликатами в ШК, обратитесь в поддержку'),
            });

            AudioService.playError();
            return true;
          }
          return await this.sendMoreProductSignal(product);
        } else {
          this.$notification.error.micro(this.$gettext('Продукта нет в задании'));
          logger.event('MULTI_PRODUCT_ON_BARCODE', barcode);
          return true;
        }
      }
      if (product?.parent_id) {
        this.uiState.childrenId = product.product_id;
      } else {
        this.uiState.childrenId = undefined;
      }

      if (allResults!.length > 0) {
        product = allResults!.find(p => (p as Product).product_id === suggest!.product_id)! as Product;
      }
      if (suggest?.conditions.need_weight || product?.type_accounting === TypeAccountingEnum.true_weight) {
        this.uiState.barcode = barcode;
      }

      await this.suggestDetails.asyncShow(suggest as Suggest);
      return true;
    },
    findNeededSuggest(products): Suggest | undefined {
      const suggestsWithProduct = this.requestSuggests.filter(s => {
        return products.find(p => {
          return p.item_id === s.product_id || p.product_id === s.product_id || s.product_id === p.parent_id;
        });
      });
      return suggestsWithProduct.find(s => {
        if (this.order!.target === 'complete') {
          return s.type === 'box2shelf';
        } else {
          return s.type === 'shelf2box';
        }
      });
    },
    async onGenerateMoreProduct(product: Product): Promise<void> {
      //Загрузка ресурсов
      const suggest = this.order!.suggests!.find(s => {
        return s.product_id === product.product_id && s.status === 'request';
      });
      this.suggestDetails.asyncShow(suggest as Suggest);
    },
    async finishActiveSuggest(
      option: Pick<Model, 'count' | 'reason' | 'date' | 'weight'> & { shelf_id: string; product_id?: string },
      suggest: Suggest,
    ) {
      option.count = option.count || 0;
      const shelf = this.shelvesStore.shelfById(option.shelf_id);
      const activeProduct = !suggest || suggest.isItemSuggest ? undefined : suggest.product;
      const getTitle = () => {
        if (activeProduct?.quants && activeProduct?.quants > 1 && activeProduct.quant_unit) {
          return this.$ngettext(
            'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полке %{shelf}?',
            'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полке %{shelf}?',
            option.count || 0,
            {
              count: String(option.count),
              shelf: String(shelf?.title),
              unit_count: String(activeProduct?.quants * (option.count || 0)),
              unit: getQuantUnit(activeProduct?.quant_unit),
            },
          );
        }
        return this.$gettext('Вы уверены, что разместили %{count} этого товара на полке %{shelf}?', {
          count: getCountToView({ count: option.count, type_accounting: activeProduct?.type_accounting }),
          shelf: String(this.shelvesStore.shelfById(option.shelf_id)?.title),
        });
      };
      const getText = () => {
        if (shelf?.type === ShelfTypeEnum.trash)
          return this.$gettext('После подтверждения размещенный товар попадет на полку с типом "Списание"');
        if (shelf?.type !== ShelfTypeEnum.review)
          return this.$gettext('После подтверждения размещенный товар будет сразу же доступен для продажи');
        return '';
      };
      const confirmed = await this.$notification.confirmBottom({
        title: getTitle(),
        text: getText(),
        ok: this.$gettext('Да'),
        decline: this.$gettext('Нет'),
      });
      if (!confirmed) return;
      // Если саджеста нет(например в результате отмены док-та), то просто закрываем карточку.
      if (!suggest) {
        this.closeSuggestDetails();
        return;
      }
      const start = Date.now();
      const isChangeShelf = suggest.type === 'box2shelf' && suggest.shelf_id !== option.shelf_id;
      if (suggest.type === 'box2shelf') {
        //если нужно выбрать другую полку
        if (isChangeShelf) {
          const result = await useBox2Shelf(this.order_id, {
            suggest_id: suggest.suggest_id,
            status: 'error',
            reason: {
              code: 'LIKE_SHELF',
              shelf_id: option.shelf_id,
            },
          });
          if (!result) return;
        }
        const result = await useBox2Shelf(this.order_id, {
          suggest_id: suggest.suggest_id,
          valid: option.date,
          count: option.count ?? suggest.count,
          weight: option.weight,
          product_id: option.product_id,
          reason: option.reason
            ? {
                code: option.reason,
                count: option.count,
              }
            : undefined,
        });
        if (!result) return;
      } else {
        const result = await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          valid: option.date,
          count: option.count ?? suggest.count,
          weight: option.weight,
        });
        if (!result) return;
      }
      const end = Date.now();
      const duration = end - start;
      let type = 'slow_sale_stowage_close_suggest';
      if (duration <= 2000) {
        type = 'fast_sale_stowage_close_suggest';
      } else if (duration <= 5000) {
        type = 'average_sale_stowage_close_suggest';
      }
      logger.event(type, duration, {
        additional: { isChangeShelf, suggest_id: suggest.suggest_id },
      });
      this.closeSuggestDetails();
    },
    closeSuggestDetails(): void {
      this.suggestDetails.hide();
      this.uiStateNeedBarcodeRequest = true;
      this.uiState.barcode = undefined;
      this.uiState.childrenId = undefined;
      this.uiState.suggestDetailsKey++;
    },
    /*
     *  Костыль: добавили параметр confirmed, который будет отвечать - нужно ли вызывать попап подтверждения перед завершением или нет
     */
    async finish(): Promise<void> {
      if (!this.order) return;
      const confirmed = await this.$notification.confirmBottom({
        title: this.confirmFinishTitle,
        isHold: true,
        ok: this.$gettext('Да'),
        decline: this.$gettext('Нет'),
      });

      if (!confirmed) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    async showSuggestDetails(suggest): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.suggestDetails.asyncShow(suggest);
      this.uiStateNeedBarcodeRequest = true;
    },
    onCancelMoreProduct(): void {
      this.uiStateNeedBarcodeRequest = true;
    },
    checkNeedMenu(suggest: Suggest): boolean {
      const menu = this.suggestMenuConfig(suggest);
      return menu.filter(item => !item.condition || item.condition()).length > 0;
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
  },
});
</script>
