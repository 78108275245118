<template>
  <svg class="rounded-full" width="40" height="40" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" fill="#2A2928" />
    <path
      d="M7.05242 10.3183C8.56436 10.3183 9.57232 9.55345 9.57232 8.01454V4.11719H9.05034V8.00543C9.05034 9.15278 8.45637 9.74467 7.5204 9.74467C6.22445 9.74467 5.99046 8.89782 5.99046 8.02364V4.11719H4.47852V8.05096C4.47852 9.25295 5.24349 10.3183 7.05242 10.3183Z"
      fill="white"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
