<template>
  <div ref="container" class="flex flex-col">
    <Title4 class="font-bold mb-4 text-left" color="primary"> О заказе </Title4>
    <div v-if="order.isReplaceItemsMethod" class="pb-3 border-b border-day-line">
      <ClientPhone :order="order" />
    </div>
    <div class="flex mt-3 pb-3 border-b border-day-line">
      <div class="flex flex-col flex-1">
        <body2>
          {{ orderNumber }}
        </body2>
        <caption1 color="day-textMinor">
          {{ $gettext('Номер заказа') }}
        </caption1>
      </div>
      <IconCopyLarge @click="copy" />
    </div>
    <div class="flex mt-3 pb-3">
      <div class="flex flex-col flex-1">
        <body2>
          {{ $gettext('Обратиться в поддержку') }}
        </body2>
        <caption1 color="day-textMinor">
          {{ $gettext('Если есть проблемы с заказом') }}
        </caption1>
      </div>
      <IconSupport @click="showChat" />
    </div>
    <div class="flex mt-6">
      <UiButton background-color="secondary" @click="emits('btnClick')">
        {{ $gettext('Закрыть') }}
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ClientPhone } from '@/fsd/features/client';
import IconCopyLarge from '@/fsd/shared/icons/icon-copy-large.vue';
import IconSupport from '@/fsd/shared/icons/icon-support.vue';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { getOrderNumber } from '@/utils';
import { computed, defineEmits, defineProps } from 'vue';
import { useRouter } from 'vue-router';

interface MenuModalProps {
  order: OrderOrderRetail;
}

const userStore = useUser();
const router = useRouter();

const props = defineProps<MenuModalProps>();
const emits = defineEmits<{ (e: 'btnClick'): void }>();

const orderNumber = computed<string>(() => {
  return getOrderNumber(props.order);
});

const copy = () => {
  navigator.clipboard.writeText(orderNumber.value);
};

const showChat = () => {
  userStore.setChatMetaData({
    order_id: props.order.order_id,
    doc_number: props.order.external_id,
  });
  router.push({ name: 'support' });
  emits('btnClick');
};
</script>
