import { api } from '@/fsd/data/api/api.service';
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { AudioService } from '@/services/audio.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments, permits } from '@/temp/constants';
import { ProductInRequired } from '@/types/product';
import { useLoader } from '@/ui/common/loader/useLoader';
import { AxiosError } from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { showLoader } = useLoader();
    return { showLoader };
  },
  computed: {
    order(): BaseOrder | undefined {
      return useOrders().orderById(this.order_id);
    },
    suggests(): Suggest[] {
      return this.order?.suggests || [];
    },
    confirmMoreProductTitle(): (product: Product) => string {
      return product => {
        if (!this.order) {
          return '';
        }
        if (this.order.vars.stage === 'stowage') {
          return this.$gettext('Этой позиции нет в списке. Все равно разместить?');
        }
        if (product) {
          const suggestsWithProduct = this.order.suggests?.filter(s => s.product_id === product.product_id) || [];
          const trashStageSuggests = suggestsWithProduct.find(s => s.vars.stage === 'trash');
          if (trashStageSuggests) {
            return this.$gettext('Вы уже списали этот товар - вы уверены, что хотите списать еще ?');
          }
        }
        return this.$gettext('Этой позиции нет в списке. Все равно списать?');
      };
    },
    confirmMoreProductText(): string {
      if (!this.order) {
        return '';
      }
      if (this.order.vars.stage === 'stowage') {
        return this.$gettext('Кажется, что эту позицию никто не заказывал, но её все равно можно разместить');
      }
      return this.$gettext('Кажется, что эту позицию никто не заказывал, но её все равно можно списать');
    },
  },
  methods: {
    async sendMoreProductSignal(product: Product): Promise<boolean> {
      if (!this.order) {
        return false;
      }
      // проверка соответствия размещаемого продукта зоне размещения
      if (this.order.vars.tag !== 'mixed' && this.order.vars.tag != product.tags[0]) {
        const confirmed = await this.$notification.confirmCenter({
          title: this.$gettext('Данный товар не соответствует зоне размещения'),
          text: this.$gettext('Вы точно хотите продолжить?'),
        });
        if (!confirmed) return true;
      } else {
        const confirmed = await this.$notification.confirmCenter({
          title: this.confirmMoreProductTitle(product),
          text: this.confirmMoreProductText,
          isHold: true,
        });
        if (!confirmed) return true;
      }
      const { closeLoader, updateLoader } = this.showLoader();
      try {
        await this.more_product(product);
        updateLoader(this.$gettext('Ожидаем создания задания на размещение'));
        await this.waitGenerateSuggestForProduct(product);
        await this.onGenerateMoreProduct(product);
        return false;
      } catch (e) {
        console.error(e);
        return true;
      } finally {
        closeLoader();
      }
    },
    // true- можно добавить продукт, false - нельзя, undefined - хз, пусть еще раз стукнется в ручку
    async checkContractor(product: Product, order_id: string): Promise<boolean | undefined> {
      if (!product) return false;
      //ищем добавляемый продукт в required/suggests документа, если есть - то разрешаем добавлять без запросов
      //смотрим включен ли эксп на складе, если нет, то разрешаем добавлять все, что угодно
      //ф-я дергается при создании пересчета, когда ордера еще нет
      if (
        this.order?.type === 'sale_stowage' &&
        (this.order?.required.find(r => (r as ProductInRequired)?.product_id === product.product_id) ||
          this.order?.suggests?.find(s => s.product_id === product.product_id) ||
          !useUser().experimentByName(experiments.exp_schrodinger) ||
          !useUser().permitByName(permits.tsd_check_contractor))
      )
        return true;
      try {
        await api.check_contractor({
          order_id,
          product_id: product.product_id,
        });
        // если 200 код, то разрешаем добавлять
        return true;
      } catch (e: any) {
        if (e.isAxiosError) {
          const axiosError = e as AxiosError;
          if (axiosError.response?.data.code === 'ER_NOT_FOUND') {
            //если ответ ER_NOT_FOUND, то это значит, что добавить в размещение этот товар нельзя - чистим продукт, закрываем попап
            this.$notification.error.micro(
              this.$gettext('Неверный поставщик для товара %{product}', {
                product: product.title,
              }),
            );
            return false;
          }
        }
        //если это рандомная ошибка, то даем возможность отправить запрос еще раз
        this.$notification.error.micro(this.$gettext('Произошла ошибка при проверке товара в ассортименте поставщика'));
        return undefined;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async onGenerateMoreProduct(product: Product): Promise<void> {},
    async more_product(product: Product): Promise<void> {
      if (!this.order) {
        return;
      }
      if (product.parent_id && product.type_accounting === 'weight') {
        // Используем хук не в setup, так как из-за миксина возможна коллизия имён
        // @ts-expect-error pinia
        product = await useProducts().getProductById(product.parent_id);
      }
      try {
        await useOrders().signal({
          order_id: this.order.order_id,
          signal: 'more_product',
          data: { product_id: product.product_id, count: 1 },
        });
      } catch (e: any) {
        if (e.response.data.code === 'ER_CONTRACTOR_ASSORTMENT_CHECK') {
          AudioService.playError();
          this.$notification.modal({
            title: this.$gettext('Данный товар отсутствует в ассортименте поставщика'),
          });
        }
        if (e.response.data.code === 'ER_STOWAGE_PRECHECK') {
          AudioService.playError();
          this.$notification.modal({
            title: this.$gettext('Данный товар уже добавлен в приемку'),
          });
        }
        throw e;
      }
    },
    //ф-я ожидаем появления саджеста в статусе реквестс переданным продуктом
    async waitGenerateSuggestForProduct(product: Product): Promise<void> {
      //проверить есть ли саджесты на полку списания
      if (!this.suggests) return;

      const isCreatedSuggest = s => {
        if (product.type_accounting === 'weight') {
          return s.product_id === product.parent_id && s.status === 'request';
        } else {
          return s.product_id === product.product_id && s.status === 'request';
        }
      };

      return new Promise(resolve => {
        let needWatch = false;
        let unWatch = () => {
          needWatch = true;
        };
        const handler = suggests => {
          for (const s of suggests) {
            if (isCreatedSuggest(s)) {
              resolve();
              unWatch();
              return;
            }
          }
        };
        handler((this as any).suggests);
        if (!needWatch) {
          unWatch = this.$watch('suggests', handler);
        }
      });
    },
    onCancelMoreProduct() {},
  },
});
