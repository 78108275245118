import { logger } from '@/temp/plugins/logs';

export const markStart = (name: string) => {
  performance.clearMarks(name + '.end');
  performance.mark(name + '.start');
};

export const markEnd = (name: string) => {
  performance.mark(name + '.end');
};

export const getDuration = (name: string) => {
  try {
    const start = performance.getEntriesByName(name + '.start', 'mark')[0];
    if (!start) return undefined;
    const end = performance.getEntriesByName(name + '.end', 'mark')[0];
    if (!end) markEnd(name);
    const duration = performance.measure(name, name + '.start', name + '.end')?.duration;
    performance.clearMeasures(name);
    performance.clearMarks(name + '.end');
    performance.clearMarks(name + '.start');
    return duration;
  } catch (e) {
    logger.error(e);
  }
};
