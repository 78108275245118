<template>
  <ClientOrderSuggestCardContainer>
    <!--Шапка-->
    <div class="flex flex-col items-center mt-5">
      <Body3 class="mb-2" color="text-secondary">
        {{ $gettext('Полка') }}
      </Body3>
      <Title4 color="text-primary" class="font-medium text-center" data-test="product shelf title">
        {{ suggest?.shelf?.title }}
      </Title4>
    </div>
    <!--Картинка-->
    <div class="relative flex flex-[2_1_0] justify-center m-4" :class="imgSize === 'small' ? 'h-16' : 'h-48'">
      <div class="w-full h-full">
        <ImageWrapper :img-src="suggest.imageSrc" />
      </div>
    </div>
    <Body2 color="text-primary" class="font-medium text-center py-4 px-5 overflow-hidden" data-test="product title">
      {{ suggest?.product?.title }}
    </Body2>
    <slot />
  </ClientOrderSuggestCardContainer>
</template>

<script lang="ts" setup>
import ClientOrderSuggestCardContainer from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardContainer.vue';
import Suggest from '@/models/Suggest';
import OrderOrder from '@/models/orders/OrderOrder';
import { useSuggests } from '@/store/modules/suggests';
import { $gettext } from '@/temp/plugins/gettext';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import { computed } from 'vue';

const props = defineProps<{
  suggest_id: Suggest['suggest_id'];
  order_id: OrderOrder['order_id'];
  imgSize: 'big' | 'small';
}>();

const suggest = computed(() => {
  return useSuggests().getSuggest(props.order_id, props.suggest_id);
});
</script>
