import type { Config } from 'tailwindcss';

export default {
  content: ['./src/**/*.{js,jsx,ts,tsx,vue}'],
  theme: {
    extend: {
      textColor: {
        primary: 'var(--text-primary-color)',
        secondary: 'var(--text-secondary-color)',
        title: 'var(--text-title-color)',
        'secondary-title': 'var(--text-secondary-title-color)',
        'sub-title': 'var(--text-sub-title-color)',
        'disabled-btn': 'var(--disabled-btn-text_color)',
      },
      backgroundColor: {
        main: 'var(--main-bg)',
        card: 'var(--card-bg)',
        element: 'var(--element-bg)',
        accent: 'var(--primary-btn-bg)',
        'control-minor': 'var(--control-minor)',
        'control-main': 'var(--control-main)',
        ultima: 'var(--ultima-bg)',
      },
      boxShadow: {
        footer: '0px -4px 20px rgba(0, 0, 0, 0.12)',
        'top-12': '0px -4px 20px 0px rgba(0, 0, 0, 0.12)',
      },
      colors: {
        text: {
          primary: 'var(--text-primary-color)',
          secondary: 'var(--text-secondary-color)',
        },
        background: {
          main: 'var(--main-bg)',
          card: 'var(--card-bg)',
        },
        element: 'var(--element-bg)',
        blue: {
          toxic: '#0596FA',
          normal: '#265CFF',
          dark: '#0668BE',
          high: '#F9E6FF',
          light: '#F4F7FF',
          medium: '#80D8F8',
          info: '#E2F2FF',
        },
        lime: {
          toxic: '#48C600',
          dark: '#239808',
        },
        purple: {
          dark: '#8822D0',
          high: '#EBB6FA',
          light: '#F9E6FF',
          normal: '#C044F2',
        },
        green: {
          normal: '#1CC052',
          high: '#8AF284',
          toxicDark: '#00945E',
          toxic: '#00CA50',
          lite: '#e6f6dd',
        },
        red: {
          normal: '#F5523A',
          high: '#FFBA8F',
          toxic: '#FA3E2C',
          dark: '#CC2D32',
        },
        orange: {
          normal: '#FA9214',
          high: '#FCD45F',
          toxic: '#FC9000',
          dark: '#D46408',
        },
        warmGray: {
          50: '#FAF9F7',
          75: '#F8F7F5',
          500: '#42413E',
          550: '#302F2D',
          600: '#21201F',
        },
        coldGray: {
          500: '#3F4042',
        },
        black: '#000000',
        white: '#FFF',
        yellow: {
          dark: '#E6BC26',
        },
        amber: {
          high: '#FCE45E',
        },
        day: {
          textMinor: '#9E9B98',
          line: '#D2D0CC',
          backgroundInvert: '#302F2D',
          textInvert: '#FFFFFF',
          control: '#FCE000',
          controlMinor: '#F1F0ED',
          error: '#FA3E2C',
          cardDivider: '#F5F4F2',
        },
        cardBackground: {
          day: '#F8F7F5',
          pause: '#FBE0C5',
        },
        // блок с токенами
        semantic: {
          bg: {
            main: 'var(--main-bg)',
            minor: 'var(--minor-bg)',
            'control-minor': 'var(--control-minor-bg)',
            invert: 'var(--invert-bg)',
            waiting: 'var(--waiting-bg)',
            planned: 'var(--planned-bg)',
            progress: 'var(--progress-bg)',
            success: 'var(--success-bg)',
            custom: 'var(--custom-bg)',
            error: 'var(--error-bg)',
          },
          text: {
            main: 'var(--main-text)',
            minor: 'var(--minor-text)',
            invert: 'var(--invert-text)',
            'on-error': 'var(--on-error-text)',
            'on-control': 'var(--on-control-text)',
            'on-control-minor': 'var(--on-control-minor-text)',
            waiting: 'var(--waiting-text)',
            planned: 'var(--planned-text)',
            progress: 'var(--progress-text)',
            success: 'var(--success-text)',
            custom: 'var(--custom-text)',
            error: 'var(--error-text)',
            'custom-link': 'var(--link-text)',
          },
          error: {
            main: 'var(--main-error)',
            minor: 'var(--minor-error)',
          },
        },
      },
    },
  },
  plugins: [],
  safelist: [{ pattern: /bg-.+/ }, { pattern: /text-.+/ }],
} satisfies Config;
