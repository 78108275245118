<template>
  <div v-if="visibleSuggest" class="h-full flex flex-col">
    <ClientOrderSuggestCardWrapper
      ref="productRef"
      :key="'suggest_id' in visibleSuggest ? visibleSuggest.suggest_id : visibleSuggest.product_id"
      :is-visible="true"
      :order_id="order_id"
      :problem="isProblem"
      :index="index"
      :suggest="visibleSuggest"
      @finish="payload => $emit('finish', payload)"
      @input-sherlock-count="count => $emit('input-sherlock-count', count)"
    />
    <LayoutFooter v-if="suggestsCount > 1" class="grid grid-cols-2 gap-x-1 items-center">
      <Caption1 v-if="!prevSuggest" class="text-center" color="day-textMinor"> Открыт первый товар из списка</Caption1>
      <ProductSwipeButton
        v-if="prevSuggest"
        :suggest="prevSuggest"
        side="left"
        class="px-3 bg-warmGray-75 rounded-2xl h-14"
        @click="$emit('prev')"
      />
      <ProductSwipeButton
        v-if="nextSuggest"
        :suggest="nextSuggest"
        side="right"
        class="px-3 bg-warmGray-75 rounded-2xl h-14"
        @click="$emit('next')"
      />
      <Caption1 v-if="!nextSuggest" class="text-center" color="day-textMinor">
        Открыт последний товар из списка
      </Caption1>
    </LayoutFooter>
  </div>
</template>

<script lang="ts" setup>
import ClientOrderSuggestCardWrapper from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardWrapper.vue';
import Suggest from '@/models/Suggest';
import { Problem } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import ProductCard from '@/ui/order/product-card/product-card.vue';
import ProductSwipeButton from '@/ui/order/product-swipe-button.vue';
import { ref } from 'vue';

const props = defineProps<{
  order_id: OrderOrder['order_id'];
  isProblem?: Problem;
  index: number;
  visibleSuggest: Suggest;
  prevSuggest: Suggest;
  nextSuggest: Suggest;
  suggestsCount: number;
}>();

defineEmits(['input-sherlock-count', 'finish', 'prev', 'next']);
const productRef = ref<InstanceType<typeof ProductCard>>();
defineExpose({
  clearCollectedAndRequestBarcodeOnProblem: () => productRef.value?.clearCollectedAndRequestBarcodeOnProblem(),
  startRequestBarcode: productRef.value?.startRequestBarcode,
  stopRequestBarcode: productRef.value?.stopRequestBarcode,
});
</script>
