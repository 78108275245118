import { BarcodeLog } from '@/devtools/sections/barcodes/barcodeLog';
import { EventLog } from '@/devtools/sections/events/eventLog';
import { RequestLog } from '@/devtools/sections/requests/requestLog';
import { SizedArray } from '@/devtools/store/sizedArray';
import { ModeService } from '@/services/mode.service';
import { defineStore } from 'pinia';

export const useDevtoolsStore = defineStore('devtools', {
  state: () => ({
    barcodes: new SizedArray<BarcodeLog>(20),
    requests: new SizedArray<RequestLog>(50),
    events: new SizedArray<EventLog>(50),
    devtoolsEnabled: ModeService.isDevelopment(),
  }),
  actions: {
    pushBarcode(...args: ConstructorParameters<typeof BarcodeLog>) {
      this.barcodes.unshift(new BarcodeLog(...args));
    },
    pushRequest(...args: ConstructorParameters<typeof RequestLog>) {
      this.requests.unshift(new RequestLog(...args));
    },
    pushEvent(...args: ConstructorParameters<typeof EventLog>) {
      this.events.unshift(new EventLog(...args));
    },
    activateDevtools() {
      this.devtoolsEnabled = true;
    },
  },
});
