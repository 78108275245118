<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <OrderHeader
        ref="header"
        :order_id="order_id"
        :suggest_id="visibleSuggest.suggest_id"
        @start-barcode-request="startBarcodeRequest"
        @stop-barcode-request="stopBarcodeRequest"
      />
    </template>
    <template #default>
      <!-- Сборка позиций  -->
      <template v-if="filteredSuggests.length || order.target === 'canceled'">
        <!-- Обычная карточка продукта, отрисованы все карточки сразу, переключаем по свайпу -->
        <Swiper
          v-if="!expUntouchable && !expSusanin"
          :key="suggests.length"
          :space-between="0"
          @swiper="onSwiper"
          @slide-change="onIndexChange"
        >
          <SwiperSlide
            v-for="(suggest, index) in visibleSuggests"
            :key="'suggest_id' in suggest ? suggest.suggest_id : suggest.product_id"
            :data-index="index"
            :data-test="`order visible-card ${visibleProductIndex}`"
          >
            <ClientOrderSuggestCardWrapper
              ref="productsRef"
              :is-visible="visibleProductIndex === index"
              :order_id="order.order_id"
              :index="index"
              :suggest="suggest"
              :problem="isProblem"
              @finish="completeSuggest"
              @input-sherlock-count="checkNeedReportAfterSherlock"
            />
          </SwiperSlide>
        </Swiper>
        <!-- Вместо свайпа добавляем кнопки переключения продуктов вперед - назад-->
        <OrderSwipe
          v-if="expSusanin"
          ref="orderSwipeRef"
          :order_id="order.order_id"
          :index="visibleProductIndex"
          :next-suggest="nextSuggest"
          :prev-suggest="prevSuggest"
          :suggests-count="visibleSuggests.length"
          :visible-suggest="visibleSuggest"
          :is-problem="isProblem"
          @next="next"
          @prev="prev"
          @finish="completeSuggest"
          @input-sherlock-count="checkNeedReportAfterSherlock"
        />
        <!-- Вместо свайпа добавляем кнопки переключения продуктов вперед - назад и показываем  навигацию по полкам-->
        <div v-if="expUntouchable && !expSusanin" class="h-full flex flex-col bg-card">
          <ShelvesChain :suggests="visibleSuggests" :index="visibleProductIndex" @choose-suggest="chooseSuggest" />
          <NewProductCard
            v-if="visibleSuggest"
            :key="visibleSuggest.suggest_id"
            ref="newProductCardRef"
            :data-test="`product card ${visibleProductIndex}`"
            class="mt-1.5"
            :order-id="order.order_id"
            :suggest="visibleSuggest"
            :problem="isProblem"
            @finish="completeSuggest"
            @input-sherlock-count="checkNeedReportAfterSherlock"
          />
        </div>
      </template>
      <FinishOrderCard
        v-if="isOrderDone"
        :order="order"
        :suggests="suggests"
        :has-problem="problems.length > 0"
        :packing="order.packingSuggests"
        @done="finishOrder"
      />
    </template>
  </PageLayout>
</template>

<script lang="ts">
import retryBox2ShelfModal from '@/fsd/entities/modals/retryBox2ShelfModal';
import retryShelf2BoxModal from '@/fsd/entities/modals/retryShelf2BoxModal';
import erSuggestInvalidTrueMarkModal from '@/fsd/entities/modals/true_mark/order/erSuggestInvalidTrueMarkModal';
import erSuggestTrueMarkAlreadyTakenModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkAlreadyTakenModal';
import erSuggestTrueMarkConsumedModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkConsumedModal';
import erSuggestTrueMarkDuplicatedModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkDuplicatedModal';
import erSuggestTrueMarkInAnotherOrder from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkInAnotherOrderModal';
import erSuggestTrueMarkNotInCurrentOrderModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkNotInCurrentOrderModal';
import erSuggestTrueMarkRequiredModal from '@/fsd/entities/modals/true_mark/order/erSuggestTrueMarkRequiredModal';
import erSuggestWrongProductTrueMark from '@/fsd/entities/modals/true_mark/order/erSuggestWrongProductTrueMarkModal';
import erSuggestWrongTrueMarkModal from '@/fsd/entities/modals/true_mark/order/erSuggestWrongTrueMarkModal';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import {
  getBox2ShelfSuggests,
  getRequestSuggests,
  getSuggestsByStatus,
} from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { prepareBarcodes, useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import ClientOrderSuggestCardWrapper from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardWrapper.vue';
import FinishOrderCard from '@/fsd/widgets/order/finish-order/finish-order-card.vue';
import NewProductCard from '@/fsd/widgets/order/order-with-shelves-chain/new-product-card.vue';
import ShelvesChain from '@/fsd/widgets/order/order-with-shelves-chain/shelves-chain.vue';
import {
  finishAssembling,
  finishDoneRequest,
  finishEventWaiting,
} from '@/fsd/widgets/order/rum/useMeasureAssemblingPosition';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import Item from '@/models/Item';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { OrderTargetEnum, Problem } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { AudioService } from '@/services/audio.service';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { ERR } from '@/temp/constants/errors';
import IconCoffee from '@/temp/icons/icon-coffee.vue';
import IconKitchen from '@/temp/icons/icon-kitchen.vue';
import { logger } from '@/temp/plugins/logs';
import { useLoader } from '@/ui/common/loader/useLoader';
import OrderSwipe from '@/ui/order/order-swipe.vue';
import ProductCard from '@/ui/order/product-card/product-card.vue';
import { getOrderNumber } from '@/utils';
import { deleteItem } from '@/utils/localStorageHelper';
import OrderHeader from '@/views/Order/OrderHeader.vue';
import { Swiper as SwiperClass } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { defineComponent, markRaw, ref } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  swiper?: SwiperClass;
}

export default defineComponent({
  name: 'Order',
  components: {
    ClientOrderSuggestCardWrapper,
    OrderHeader,
    PageLayout,
    FinishOrderCard,
    NewProductCard,
    ShelvesChain,
    OrderSwipe,
    Swiper,
    SwiperSlide,
  },
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader, closeComponentLoaders } = useLoader();
    const ordersStore = useOrders();
    const shelvesStore = useShelves();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const userStore = useUser();
    const router = useRouter();

    const productsRef = ref<InstanceType<typeof ProductCard>[]>();
    const orderSwipeRef = ref<InstanceType<typeof OrderSwipe>>();
    const newProductCardRef = ref<InstanceType<typeof NewProductCard>>();
    const header = ref<InstanceType<typeof OrderHeader>>();

    const visibleProductIndex = ref<number>(0);

    useHandleOrderStatus(props.order_id, {
      cancel: () => {
        visibleProductIndex.value = 0;
      },
    });

    return {
      showLoader,
      closeComponentLoaders,
      ordersStore,
      shelvesStore,
      itemsStore,
      productsStore,
      userStore,
      productsRef,
      orderSwipeRef,
      newProductCardRef,
      visibleProductIndex,
      router,
      header,
    };
  },
  data(): Data {
    return {
      swiper: undefined,
    };
  },
  computed: {
    order(): OrderOrder {
      return this.ordersStore.orderById(this.order_id) as OrderOrder;
    },
    expUntouchable(): boolean {
      return this.userStore.experimentByName('exp_untouchable');
    },
    expSusanin(): boolean {
      return this.userStore.experimentByName('exp_susanin');
    },
    problems(): Problem[] {
      if (!this.order) {
        return [];
      }
      return this.order.problems.filter(p => p.product_id);
    },
    suggests(): Suggest[] {
      if (!this.order || !this.order.suggests) {
        return [];
      }
      return this.order.suggests.filter(suggest => !suggest.isPackaging);
    },
    box2ShelfSuggests(): Suggest[] {
      return getBox2ShelfSuggests(this.suggests);
    },
    visibleSuggests(): Suggest[] {
      //отображаемые саджесты
      if (this.isCanceled) {
        return this.suggests.filter(s => s.status === 'request' || s.type === 'box2shelf');
      }
      return this.suggests;
    },
    visibleSuggest(): Suggest {
      return this.visibleSuggests[this.visibleProductIndex];
    },
    product(): Product {
      return this.productsStore.productById(this.visibleSuggest.product_id)!;
    },
    prevSuggest(): Suggest | undefined {
      if (this.visibleProductIndex > 0) {
        return this.visibleSuggests[this.visibleProductIndex - 1];
      } else {
        return undefined;
      }
    },
    nextSuggest(): Suggest | undefined {
      return this.visibleSuggests[this.visibleProductIndex + 1];
    },
    isProblem(): Problem | undefined {
      return (!this.isCanceled && this.problemById(this.visibleSuggest.product_id)) || undefined;
    },
    firstRequestSuggestIndex(): number {
      const idx = this.visibleSuggests.findIndex(s => {
        if ('status' in s && s.status) {
          return s.status === 'request';
        }
        return true;
      });
      if (~idx) return idx;
      return 0;
    },
    isOrderDone(): boolean {
      return Boolean(
        this.order && this.filteredSuggests.length === 0 && this.order.target === OrderTargetEnum.complete,
      );
    },
    requestedBox2ShelfSuggest(): Suggest[] {
      return getRequestSuggests(this.box2ShelfSuggests);
    },
    suggestsInProgress() {
      return getSuggestsByStatus(this.suggests, [SuggestStatusEnum.request, SuggestStatusEnum.blocked]);
    },
    isCanceled(): boolean {
      return Boolean(this.order?.isCanceled);
    },
    problemById(): (product_id?: string) => Problem | undefined {
      return product_id => {
        if (!product_id) return undefined;
        return this.problems.find(suggest => suggest.product_id === product_id);
      };
    },
    activeProduct(): Product | undefined {
      if (!this.visibleSuggest) return undefined;
      return this.productsStore.productById(this.visibleSuggest.product_id);
    },
    activeItem(): Item | undefined {
      if (!this.visibleSuggest || !Suggest.isSuggest(this.visibleSuggest) || this.visibleSuggest.vars.mode !== 'item')
        return undefined;
      return this.itemsStore.itemById(this.visibleSuggest.product_id);
    },
    productCardRef():
      | {
          clearCollectedAndRequestBarcodeOnProblem: () => void;
          stopRequestBarcode: () => void;
          startRequestBarcode: () => void;
        }
      | undefined {
      switch (true) {
        case this.expSusanin:
          return this.orderSwipeRef;
        case this.expUntouchable:
          return this.newProductCardRef;
        default:
          return this.productsRef?.[this.visibleProductIndex];
      }
    },
    hasReadyMeals(): boolean {
      for (const s of this.suggests) {
        if (s && s.shelf_id) {
          const shelf = this.shelvesStore.shelfById(s.shelf_id);
          if (shelf?.type === 'kitchen_on_demand') {
            return true;
          }
        }
      }
      return false;
    },
    filteredSuggests(): Suggest[] {
      return this.suggests.filter(suggest => !suggest.isCompleted);
    },
  },
  watch: {
    'order.problems': {
      handler(value, oldValue) {
        if (value && oldValue && value.length !== oldValue.length) {
          this.visibleProductIndex = 0;
        }
      },
    },
    order: {
      handler(val, oldVal) {
        if (!val && oldVal?.target !== 'canceled') {
          this.closeComponentLoaders();
        }
      },
    },
    visibleSuggest: {
      handler(val: Suggest) {
        if (!val) {
          this.visibleProductIndex = this.firstRequestSuggestIndex;
          return;
        }
        // Если сейчас заблокированный саджест и готовых для сборки саджестов больше нет, то показываем пользователю модалку и выкдываем на главную
        if (val.status === SuggestStatusEnum.blocked && this.order.hasOnlyBlockedSuggests) {
          this.readyMealsModal();
        }
      },
    },
  },
  mounted(): void {
    this.visibleProductIndex = this.firstRequestSuggestIndex;
  },
  methods: {
    slideTo(index: number) {
      this.swiper?.slideTo(index);
    },
    onSwiper(swiper: SwiperClass) {
      this.swiper = swiper;
      this.slideTo(this.firstRequestSuggestIndex);
    },
    handleOrderStatusComplete(): void {
      //   сбрасываю хендлер,
    },
    toHomePage(): Promise<any> {
      return this.router.push({ name: 'home' });
    },
    clearProductCard(): void {
      this.productCardRef?.clearCollectedAndRequestBarcodeOnProblem();
    },
    chooseSuggest(index): void {
      this.visibleProductIndex = index;
    },
    prev(): void {
      this.visibleProductIndex -= 1;
    },
    next(): void {
      this.visibleProductIndex += 1;
    },
    async finishOrder(): Promise<void> {
      if (this.order.isOrderPaused) {
        this.$notification.error.micro(this.$gettext('Данный заказ обрабатывается оператором, пожалуйста подождите'));
        return;
      }
      try {
        const orderId = this.order_id;
        const order = this.order;
        const result = await useEndOrder(this.order_id);
        if (!result) {
          return;
        }
        deleteItem('roverScan', orderId);
        await this.toHomePage();
        if (order.hasCoffee) {
          await this.$notification.modal({
            title: this.$gettext('Не забудьте про готовые напитки заказа %{number}', {
              number: getOrderNumber(order),
            }),
            titleAlign: 'text-left',
            text: this.$gettext('Заберите их у кофемашины и соберите в заказ.'),
            textAlign: 'text-left',
            iconTop: {
              icon: markRaw(IconCoffee),
              position: 'center',
            },
            buttonText: this.$gettext('Понятно'),
            // класс, для отображения модалки поверх других модальных окон
            class: '!z-[1100]',
            dataTest: 'order-number-reminder',
          });
        } else {
          await this.$notification.modal({
            title: this.$gettext('Заказ %{number}', {
              number: getOrderNumber(order),
            }),
            titleAlign: 'text-center',
            class: '!z-[1100]',
            dataTest: 'order-number-reminder',
          });
        }
      } catch (error) {
        logger.error(error, { method: 'finishOrder', type: 'order' });
      }
    },

    onIndexChange({ activeIndex }: SwiperClass): void {
      this.visibleProductIndex = activeIndex;
    },
    showNextProduct(): void {
      if (!this.productCardRef) return;
      //найти не выполненный саджест и перейти на него.
      //сначала ищем ближайший следующий. потом первый невыполненный
      let nextIndex = this.visibleSuggests
        .slice(this.visibleProductIndex + 1)
        .findIndex(s => (s as Suggest).status === 'request' || (s as Suggest).status === 'blocked');
      if (nextIndex === -1) {
        nextIndex = this.visibleSuggests.findIndex(s => (s as Suggest).status === 'request');
      } else {
        nextIndex += this.visibleProductIndex + 1;
      }
      if (nextIndex !== -1) {
        this.visibleProductIndex = nextIndex;
        this.slideTo(nextIndex);
      } else {
        //   прекратить запрос ШК
        this.productCardRef?.stopRequestBarcode();
      }
    },
    checkNeedReportAfterSherlock(count): void {
      // Если на полке НЕ учетное кол-во и товара может не хватать для сборки
      if (count < this.visibleSuggest.count!) {
        this.header.showReportMenu();
      }
    },
    async completeSuggest({
      suggest,
      weight,
      count,
      true_mark = undefined,
      barcodes,
    }: {
      suggest: Suggest;
      weight: number | undefined;
      count: number | null;
      true_mark: string | undefined;
      barcodes: string[];
    }): Promise<void> {
      suggest = suggest || (this.visibleSuggest as Suggest);
      count = count || suggest.count!;
      if (suggest.type === 'box2shelf') {
        const result = await useBox2Shelf(
          this.order_id,
          {
            suggest_id: suggest.suggest_id,
            weight,
            count,
            true_mark,
            barcodes: this.order.conditions.confirm_assembled_products ? prepareBarcodes(barcodes) : undefined,
          },
          {
            onRequestError: async (e, retry) => {
              const response = e.response;
              switch (response?.data?.code) {
                case ERR.ER_SUGGEST_TRUE_MARK_REQUIRED:
                  erSuggestTrueMarkRequiredModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_INVALID_TRUE_MARK:
                  erSuggestInvalidTrueMarkModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED:
                  erSuggestTrueMarkDuplicatedModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_CONSUMED:
                  erSuggestTrueMarkConsumedModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK:
                  erSuggestWrongProductTrueMark();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER:
                  erSuggestTrueMarkInAnotherOrder();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN:
                  erSuggestTrueMarkAlreadyTakenModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_WRONG_TRUE_MARK:
                  erSuggestWrongTrueMarkModal(suggest.type);
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER:
                  erSuggestTrueMarkNotInCurrentOrderModal();
                  this.clearProductCard();
                  break;
                default: {
                  const confirmed = await retryBox2ShelfModal(e);
                  if (!confirmed) return false;
                  return retry();
                }
              }
              return false;
            },
            measureRequest: finishDoneRequest,
            measureEvent: finishEventWaiting,
          },
        );
        if (!result) return;
      } else {
        const result = await useShelf2Box(
          this.order_id,
          {
            suggest_id: suggest.suggest_id,
            weight,
            count,
            true_mark,
            barcodes: this.order.conditions.confirm_assembled_products ? prepareBarcodes(barcodes) : undefined,
          },
          {
            onRequestError: async (e, retry) => {
              AudioService.playError();
              const response = e.response;
              switch (response?.data?.code) {
                case ERR.ER_SUGGEST_TRUE_MARK_REQUIRED:
                  erSuggestTrueMarkRequiredModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_INVALID_TRUE_MARK:
                  erSuggestInvalidTrueMarkModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_DUPLICATED:
                  erSuggestTrueMarkDuplicatedModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_CONSUMED:
                  erSuggestTrueMarkConsumedModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK:
                  erSuggestWrongProductTrueMark();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER:
                  erSuggestTrueMarkInAnotherOrder();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN:
                  erSuggestTrueMarkAlreadyTakenModal();
                  this.clearProductCard();
                  break;
                case ERR.ER_SUGGEST_WRONG_TRUE_MARK:
                  erSuggestWrongTrueMarkModal(suggest.type);
                  this.clearProductCard();
                  break;
                default: {
                  const confirmed = await retryShelf2BoxModal(e);
                  if (!confirmed) return false;
                  return retry();
                }
              }
              return false;
            },
            measureRequest: finishDoneRequest,
            measureEvent: finishEventWaiting,
          },
        );
        if (!result) return;
        if (suggest.conditions.need_true_mark) {
          this.$notification.success.micro(this.$gettext('Теперь положите этот товар в корзину и возьмите другой'));
        }
      }
      // все успешно. можем закрывать измерение по саджесту
      finishAssembling();
      this.showNextProduct();
    },
    async readyMealsModal() {
      await this.$notification.modal({
        title: this.$gettext('Блюда с кухни ещё готовятся'),
        text: this.$gettext(
          'Пока вы можете выполнять другие задания, а этот заказ поставлен на паузу. Мы сообщим, когда блюда будут готовы.',
        ),
        iconTop: {
          icon: markRaw(IconKitchen),
          position: 'center',
        },
        backdropClick: false,
      });
      this.toHomePage();
    },
    startBarcodeRequest() {
      if (!this.productCardRef) return;
      this.productCardRef.startRequestBarcode();
    },
    stopBarcodeRequest() {
      if (!this.productCardRef) return;
      this.productCardRef.stopRequestBarcode();
    },
  },
});
</script>

<style scoped>
/* НИКОГДА И НЕ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ НЕ ТРОГАТЬ ЭТОТ КЛАСС */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slider img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
