import { Log } from '@/devtools/log';
import { AxiosResponse } from 'axios';

export class NetworkLog extends Log {
  public statusCode: AxiosResponse['status'];
  public responseBody: AxiosResponse['data'];
  public requestBody: AxiosResponse['config']['data'];
  constructor(axiosResponse: AxiosResponse) {
    super();
    this.statusCode = axiosResponse.status;
    this.responseBody = axiosResponse.data;
    this.requestBody = axiosResponse.config.data;
  }
}
