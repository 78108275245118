<template>
  <div v-if="maxBagsCount > 0" class="bg-warmGray-50 rounded-[20px] py-4 px-2">
    <div class="flex items-center">
      <ImageWrapper :img-src="packingSuggest.imageSrc" class="picture" />
      <Body2 class="font-medium">
        {{ packingSuggest.product.title }}
      </Body2>
    </div>

    <div class="flex justify-between gap-2 mt-3 bags-count-group">
      <BagsCountItem v-model="bagsCount" data-test="bags-count-item-0" :name="packingSuggest.product_id" value="0" />
      <BagsCountItem v-model="bagsCount" data-test="bags-count-item-1" :name="packingSuggest.product_id" value="1" />
      <BagsCountItem
        v-model="bagsCount"
        data-test="bags-count-item-2"
        :max-bags-count="maxBagsCount"
        :name="packingSuggest.product_id"
        value="2"
      />
    </div>
    <div class="flex justify-between gap-2 mt-3 bags-count-group">
      <BagsCountItem
        v-model="bagsCount"
        data-test="bags-count-item-3"
        :max-bags-count="maxBagsCount"
        :name="packingSuggest.product_id"
        value="3"
      />
      <BagsCountItem
        v-model="bagsCount"
        data-test="bags-count-item-more"
        :max-bags-count="maxBagsCount"
        :name="packingSuggest.product_id"
        :value="bagsCount > 4 ? String(bagsCount) : '4'"
      />
      <BagsCountItem
        v-model="bagsCount"
        :max-bags-count="maxBagsCount"
        :name="packingSuggest.product_id"
        value="+"
        @click="showKeyboard"
      />
    </div>
  </div>

  <Blur v-if="keyboard.visible.value" class="flex flex-col justify-end">
    <Keyboard class="bg-white pt-3 rounded-[20px]" type="number" min="0" @input="selectBagsCountFromKeyboard">
      <div v-if="keyboard.props.value" class="px-4 pb-3 flex flex-col">
        <title4 class="mb-1 font-bold">{{ $gettext('Количество пакетов') }}</title4>
        <Body2 color="day-textMinor">
          {{
            $gettext('%{title} · Не больше %{count} шт.', {
              title: packingSuggest.product.title,
              count: String(Math.min(keyboard.props.value.count, maxBagsCount)),
            })
          }}
        </Body2>
      </div>
    </Keyboard>
  </Blur>
</template>

<script lang="ts">
import { CollectedPackingSuggests } from '@/fsd/widgets/order/finish-order/closePackingSuggests/useClosePackingSuggests';
import BagsCountItem from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/SelectBags/BagsCountItem.vue';
import { useComponent } from '@/hooks/useComponent';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { Notifications } from '@/temp/plugins/notification';
import Blur from '@/temp/ui/blur/blur.vue';
import { AvailableProduct } from '@/types/product';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import Keyboard from '@/ui/common/keyboard/keyboard.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import { defineComponent, PropType, Ref, ref } from 'vue';

interface Data {
  bagsCount: Ref;
}

export default defineComponent({
  components: {
    BagsCountItem,
    Blur,
    Body2,
    ImageWrapper,
    Keyboard,
  },
  props: {
    packingSuggest: { type: Object as PropType<Suggest>, required: true },
    collected: { type: Map as PropType<CollectedPackingSuggests>, required: true },
    maxBagsCount: { type: Number, required: true },
  },
  setup() {
    const { showLoader } = useLoader();
    const keyboard = useComponent<AvailableProduct | undefined>();

    return { keyboard, showLoader };
  },
  data(): Data {
    return { bagsCount: ref(String(this.collected.get(this.packingSuggest.suggest_id))) };
  }, // Когда у нас меняется bagsCount, мы хотим обновлять collected, чтобы потом закрыть эти саджесты

  watch: {
    bagsCount: {
      handler(count) {
        this.collected.set(this.packingSuggest.suggest_id, Number(count));
      },
    },
  },
  methods: {
    async showKeyboard(): Promise<void> {
      let available = useProducts().availableByProductId(this.packingSuggest.product_id);
      if (!available) {
        const { closeLoader } = this.showLoader();
        try {
          available = await useProducts().fetchAvailable(this.packingSuggest.product_id);
          closeLoader();
        } catch (e) {
          closeLoader();
        }
      }
      const availableOnShelf = available?.find(item => item.shelf_id === this.packingSuggest.shelf_id);
      await this.keyboard.asyncShow(availableOnShelf);
    },
    async selectBagsCountFromKeyboard({ value }): Promise<void> {
      if ((this.keyboard.props.value && this.keyboard.props.value.count < value) || this.maxBagsCount < value) {
        Notifications.modal({
          title: this.$gettext('Вы указали слишком много пакетов'),
          text: this.$gettext('Можно ввести не больше %{count} шт.', {
            count: String(Math.min(this.keyboard.props.value?.count || this.maxBagsCount, this.maxBagsCount)),
          }),
        });
        return;
      }
      this.bagsCount = String(value);
      this.keyboard.hide();
    },
  },
});
</script>

<style scoped>
.picture {
  position: relative;
  box-sizing: border-box;
  margin: 0 16px;
  width: 52px;
  height: 52px;
}
</style>
