<template>
  <div class="flex justify-between">
    <Body1>
      {{ $gettext('Общий остаток') }}
    </Body1>
    <Body1 data-test="product card available-all row">
      {{ availableAll }}
    </Body1>
  </div>
</template>

<script lang="ts" setup>
import { getValueForView } from '@/fsd/entities/product';
import Product from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { computed, toRefs } from 'vue';

const props = defineProps<{ product_id: Product['product_id'] }>();
const { product_id } = toRefs(props);

const shelvesStore = useShelves();
const productsStore = useProducts();

const product = computed(() => {
  return productsStore.productById(product_id?.value);
});
const available = computed(() => {
  return productsStore.availableByProductId(product_id?.value);
});
const availableAll = computed(() => {
  if (!product.value || !available.value) return '';
  const componentsShelf = available.value.find(a => {
    return !!shelvesStore.shelfById(a.shelf_id)?.isKitchenShelf;
  })?.shelf_id;
  const shelf = available.value.find(a => {
    return !shelvesStore.shelfById(a.shelf_id)?.isKitchenShelf;
  })?.shelf_id;
  const count = available.value.reduce((acc, value) => {
    if (shelvesStore.shelfById(value.shelf_id)?.isKitchenShelf) {
      return acc + value.count;
    }
    return acc + value.count * value.quants;
  }, 0);
  return getValueForView({
    product: product.value,
    shelf: shelvesStore.shelfById(componentsShelf || shelf)!,
    count,
  });
});
</script>
