import { CB, waitDataChange } from '@/fsd/shared/utils/subscribe';
import BaseOrder, { Signal } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { computed } from 'vue';

export const useSubscribeOnOrder = <T extends BaseOrder = BaseOrder>(order_id: BaseOrder['order_id']) => {
  const order = computed<T | undefined>(() => {
    return useOrders().orderById(order_id) as T;
  });
  const subscribeOnOrder = (cb: CB<T | undefined>) => {
    return waitDataChange(order, cb);
  };
  return subscribeOnOrder;
};

export const useSubscribeOnOrderStatus = (order_id: BaseOrder['order_id']) => {
  const status = computed<BaseOrder['status'] | undefined>(() => {
    return useOrders().orderById(order_id)?.status;
  });
  const subscribeOnOrderStatus = (cb: CB<BaseOrder['status'] | undefined>) => {
    return waitDataChange(status, cb);
  };
  return subscribeOnOrderStatus;
};

export const useSubscribeOnOrderSignal = (order_id: BaseOrder['order_id']) => {
  const signals = computed<BaseOrder['signals'] | undefined>(() => {
    return useOrders().orderById(order_id)?.signals;
  });

  // Извлекаем последний сигнал с переданным типом и отдаем его в кб
  const subscribeOnOrderSignal = (cb: CB<Signal | undefined>, signalType: Signal['type']) => {
    const handler: CB<BaseOrder['signals'] | undefined> = signals => {
      if (!signals || !signals.length) return cb(undefined);
      const signal = signals.filter(s => s.type === signalType).at(-1);
      return cb(signal);
    };
    return waitDataChange(signals, handler);
  };
  return subscribeOnOrderSignal;
};
