<template>
  <Layout>
    <div class="flex flex-col items-stretch px-4">
      <Title4 class="font-medium text-center mb-1">
        {{ title }}
      </Title4>
      <div v-if="order.isRover" class="bg-blue-light my-2 py-3 px-4 rounded-2xl flex flex-row justify-between">
        <img class="w-12" src="../rover-card/img/rover.png" />
        <div class="flex flex-col mr-12 justify-center">
          <body2 class="text-center font-medium">
            {{ roverName }}
          </body2>
          <Caption1 class="text-center font-medium">
            {{ $gettext('Найдите его на улице') }}
          </Caption1>
        </div>
      </div>
      <div
        v-if="order.attr.is_pickup"
        class="mb-2 bg-blue-light flex items-center justify-center rounded-2xl h-12 px-4"
        data-test="status card is-pickup"
      >
        <Caption1 class="font-medium">
          {{ $gettext('Этот заказ заберут самовывозом') }}
        </Caption1>
      </div>
      <Hint v-if="hasReadyMeals" class="mb-2">
        {{ $gettext('Не забудьте взять с кухни готовую еду и упаковать ее') }}
      </Hint>
      <Hint class="mb-2">{{ $gettext('Положите бытовую химию в отдельный пакет') }}</Hint>
      <Hint v-if="order.hasSpecialPackageProducts" class="mb-2">
        {{ $gettext('Положите Товары 18+ в непрозрачный пакет') }}
      </Hint>
      <Hint v-if="hasPackingSuggests" class="mb-2">{{ $gettext('Отсканируйте пакеты и наклейки') }}</Hint>
      <Hint v-if="waitingRover" class="mb-2">{{ $gettext("Ждем прибытия Rover'а") }}</Hint>
    </div>
    <SuggestCard
      v-for="suggest in packing"
      :key="suggest.suggest_id"
      :suggest="suggest"
      doc-type="pack"
      :collected="getCollected(suggest.suggest_id)"
    />
    <template #footer>
      <LayoutFooter>
        <ProductList :suggests="suggests" />
        <SliderButton
          v-if="needConfirmAssembledProducts"
          class="flex-auto"
          data-test="order status-card confirm-signal"
          :disabled="!isOrderClosable || hasActiveConfirmAssembledSignal"
          @slide-complete="sendConfirmAssembledProductsSignal"
        >
          {{ $gettext('Согласовать оплату') }}
        </SliderButton>
        <template v-else>
          <UiButton
            v-if="order.isRover"
            class="flex-auto"
            data-test="order status-card rover-stage-btn"
            :is-disabled="!isOrderClosable"
            background-color="backgroundInvert"
            @click="finishRover"
          >
            {{ $gettext('Открыть Rover') }}
          </UiButton>
          <UiButton v-else class="flex-auto" data-test="complete btn" :is-disabled="!isOrderClosable" @click="finish">
            <template v-if="order.isOrderPaused"> {{ $gettext('Обрабатывается оператором') }}</template>
            <template v-else-if="hasProblem"> {{ $gettext('Ждите обработки оператором') }}</template>
            <template v-else> {{ $gettext('Завершить') }}</template>
          </UiButton>
        </template>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { useConfirmAssembledProducts } from '@/fsd/data/order/useConfirmAssembledProducts';
import { getRequestSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useOpenRoverHatch } from '@/fsd/features/openRoverHatch/useOpenRoverHatch';
import ProductList from '@/fsd/widgets/product-list/';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Suggest from '@/models/Suggest';
import OrderOrder from '@/models/orders/OrderOrder';
import { AudioService } from '@/services/audio.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { $gettext } from '@/temp/plugins/gettext';
import { ISuggestError, Notifications } from '@/temp/plugins/notification';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { getOrderNumber } from '@/utils';
import { AxiosResponse } from 'axios';
import { computed, defineEmits, defineProps, ref, watch } from 'vue';

const { showLoader } = useLoader();
const shelvesStore = useShelves();
const ordersStore = useOrders();
const productsStore = useProducts();
const { confirmAssembledProducts } = useConfirmAssembledProducts();
const { openRoverHatch } = useOpenRoverHatch();

interface StatusCardProps {
  order_id: string;
  suggests: Suggest[];
  packing: Suggest[];
  hasProblem?: boolean;
}

const props = defineProps<StatusCardProps>();

const emits = defineEmits<{ (e: 'finish'): void }>();

const hasReadyMeals = ref<boolean>(false);
const collected = ref<Record<string, number>>(
  props.packing.reduce((acc, value) => ({ ...acc, [value.suggest_id]: 0 }), {}),
);

watch(
  () => props.suggests,
  (newValue, oldValue) => {
    const newRequestCount = newValue.filter(s => s.status === 'request').length;
    const oldRequestCount = oldValue.filter(s => s.status === 'request').length;
    if (newRequestCount > oldRequestCount) {
      clearCollected();
    }
  },
  { deep: true },
);

const order = computed<OrderOrder>(() => ordersStore.orderById(props.order_id) as OrderOrder);

const roverName = computed<string>(() => $gettext('Ровер %{name}', { name: order.value?.courier?.name || '' }));

const orderPartNumber = computed<number>(() => {
  if (order.value.courier) {
    const order_ids = [...order.value.courier.related_orders, order.value.order_id].sort();
    const idx = order_ids.findIndex(id => id === order.value.order_id);
    return idx + 1;
  }
  return 0;
});

const title = computed<string>(() => {
  if (order.value?.courier?.related_orders.length! >= 2) {
    const part = orderPartNumber.value;
    return $gettext('%{part}-ая часть мультизаказа %{number} готова', {
      part: String(part),
      number: getOrderNumber(order.value),
    });
  }
  return $gettext('Пакеты и сэмплы');
});

const waitingRover = computed<boolean>(() => Boolean(order.value?.attr?.maybe_rover) && !order.value?.courier);

const hasPackingSuggests = computed<boolean>(() => {
  return props.packing.filter(s => s.status === 'request').length > 0;
});

const canCloseWithPacking = computed<boolean>(() => {
  if (hasPackingSuggests.value) {
    const count = props.packing.reduce((sum, suggest) => sum + getCollected(suggest.suggest_id), 0);
    return count > 0;
  }
  return true;
});

const isOrderClosable = computed<boolean>(() => {
  return canCloseWithPacking.value && !waitingRover.value && !order.value?.isOrderPaused && !props.hasProblem;
});

const packingBarcodes = computed<string[]>(() => {
  return props.packing.reduce((total, suggest) => {
    return total.concat(productsStore.productById(suggest.product_id)!.barcode);
  }, [] as string[]);
});

const needConfirmAssembledProducts = computed<boolean>(() => {
  return (
    order.value.conditions.confirm_assembled_products &&
    !order.value.vars?.fulfilled_conditions?.confirm_assembled_products
  );
});

const hasActiveConfirmAssembledSignal = computed<boolean>(() => !!order.value.vars.confirming_assembled_products);

const getCollected = (suggestId): number => {
  return collected.value[suggestId] || 0;
};

const clearCollected = (): void => {
  collected.value = {};
};

const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  if (packingBarcodes.value.includes(barcode)) {
    const suggest = props.packing.find(suggest =>
      productsStore.productById(suggest.product_id)!.barcode.includes(barcode),
    )!;
    collected.value[suggest.suggest_id] = collected.value[suggest.suggest_id] + 1;
    return true;
  } else {
    AudioService.playError();
    return true;
  }
});

const checkHasReadyMeals = async (): Promise<void> => {
  for (const s of props.suggests) {
    const shelf = await shelvesStore.getShelfById(s.shelf_id);
    if (shelf?.type === 'kitchen_on_demand') {
      hasReadyMeals.value = true;
      return;
    }
  }
};

const endPackingSuggests = async (): Promise<void> => {
  if (order.value.packingSuggests.length === 0) {
    return;
  }
  const requestSuggests = getRequestSuggests(order.value.packingSuggests);
  if (requestSuggests.length === 0) {
    return;
  }
  const suggests = requestSuggests.map(s => {
    return {
      ...s,
      count: collected.value?.[s.suggest_id] || 0,
    };
  });

  const promises: Promise<AxiosResponse>[] = [];
  for (const s of suggests) {
    const payload = {
      suggest_id: s.suggest_id,
      count: s.count,
    };
    const promise = ordersStore.shelf2box(payload);
    promises.push(promise);
  }
  const { closeLoader } = showLoader(undefined, props.order_id);
  // responses:Promise<{status:'fulfilled'|'rejected',value:any,reason:any}>
  const responses = await Promise.allSettled<Promise<AxiosResponse>[]>(promises);
  closeLoader();
  const allSuccess = responses.every(response => response.status === 'fulfilled' && response.value.status === 200);
  if (allSuccess) {
    return;
  }
  const products = suggests
    .filter((item, idx) => {
      //@ts-expect-error
      return responses[idx].status === 'rejected' || responses[idx].value.status !== 200;
    })
    .map(s => {
      return {
        label: productsStore.productById(s.product_id)?.title!,
        count: s.count,
      };
    });
  const majorErrorConfig: ISuggestError = {
    title: $gettext('Не удалось отправить информацию на сервер'),
    reason: 'Network error',
    body: '',
    onClose: () => {},
    onRepeat: () => {
      endPackingSuggests();
    },
    operation: $gettext('Взять с полки'),
    products: products,
  };
  Notifications.error.major.suggest(majorErrorConfig);
};

const finish = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({ title: $gettext('Завершить заказ?') });
  if (!confirmed) return;
  await endPackingSuggests();
  needBarcodeRequest.value = false;
  emits('finish');
};

const finishRover = async (): Promise<void> => {
  await openRoverHatch(order.value);
  await endPackingSuggests();
  needBarcodeRequest.value = false;
  emits('finish');
};

const sendConfirmAssembledProductsSignal = async (): Promise<void> => {
  await endPackingSuggests();
  await confirmAssembledProducts(order.value);
};

checkHasReadyMeals();
if (!hasPackingSuggests.value) {
  needBarcodeRequest.value = false;
}
</script>
