import { BaseModel } from 'sjs-base-model';

export enum QuantUnitEnum {
  milligram = 'milligram',
  gram = 'gram',
  kilogram = 'kilogram',
  milliliter = 'milliliter',
  centiliter = 'centiliter',
  liter = 'liter',
  unit = 'unit',
}

export enum TagsEnum {
  refrigerator = 'refrigerator',
  freezer = 'freezer',
  freezer2_2 = 'freezer2_2',
  domestic = 'domestic',
  freezer24 = 'freezer24',
  safe = 'safe',
}

export enum TypeAccountingEnum {
  weight = 'weight',
  unit = 'unit',
  true_weight = 'true_weight',
}

export interface Defect {
  image: string;
  title?: string;
  desc?: string;
}

export enum ScheduleTypeEnum {
  kitchens = 'kitchens',
  coffee = 'coffee',
}

export default class Product extends BaseModel {
  public product_id: string = '';
  public external_id: string = '';
  public barcode: string[] = [];
  public title: string = '';
  public description: string = '';
  public images: string[] = [];
  public visual_control_images: string[] = [];
  public visual_control_defects: Defect[] = [];
  public tags: TagsEnum[] = [];
  public write_off_before: number = 0;
  // срок годности
  public valid: number | null = null;
  public quants: number = 1;
  // определяет тип квантов
  public quant_unit?: QuantUnitEnum = undefined;

  public true_mark: boolean = false;
  public parent_id?: string = undefined;
  public children_id?: string[] = undefined;
  public upper_weight_limit?: number = undefined;
  public lower_weight_limit?: number = undefined;
  public type_accounting: TypeAccountingEnum = TypeAccountingEnum.unit;
  // признак того, является ли товар хрупким
  public fragile: boolean = false;
  public logistic_tags: string[] = [];

  public components: { product_id: Product['product_id']; count: number }[][] = [];

  // Для лебазара
  // Код для выбора весового товара, чтобы взвесить его и опсчитать финальную стоимость
  // Чтобы это поле появилось в датасете, надо создавать его в vars.imported.plu_code
  public plu_code: string | null = '';
  // Поле определяющее за какое кол-во дней необходимо перемещать товар на полку распрадажи для "Съешь сегодня"
  public expired_count_sale: number | null = null;
  public schedule_type?: ScheduleTypeEnum = undefined;

  public weight: number = 0;
  // признак того. что данный продукт является 18+ НЕ ПУТАТЬ СО special_package !
  public underage_restricted: boolean = false;

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }

  static isProduct(any: any): any is Product {
    return any instanceof Product;
  }

  // Означает, что продукт квантовый
  get isProductQuant(): boolean {
    return Boolean(this?.quants > 1);
  }

  get isTrueWeight(): boolean {
    return this.type_accounting === TypeAccountingEnum.true_weight;
  }

  get domestic() {
    return this.tags.includes(TagsEnum.domestic);
  }

  // Признак того, что продукт необходимо упаковать в непрозрачный пакет, является косвенным признаком того, что товар является 18+, но не любой товар 18+ обладает этим признаком
  get special_package() {
    return this.logistic_tags.includes('special_package');
  }

  static weightFromBarcode(barcode: string | string[]): number {
    const barcodes = Array.isArray(barcode) ? barcode : [barcode];

    return barcodes.reduce((acc, barcode) => {
      if (barcode.length !== 13 && barcode.length !== 18) {
        return acc;
      }
      // Если полка в обычной стране, то баркод 13-символьный, 8-12 вес, 13 контрольный
      // Если полка в Израиле, то баркод 18-символьный, 13-17 вес, 18 контрольный
      return acc + +barcode.slice(-6, -1);
    }, 0);
  }
}
