import { api } from '@/fsd/data/api/api.service';
import ClientOrder from '@/models/orders/ClientOrder';
import orderQueue from '@/services/queue/order-queue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { BaseError } from '@/types/baseErrorResponse';
import { useLoader } from '@/ui/common/loader/useLoader';
import { AxiosError } from 'axios';

export function useConfirmAssembledProducts() {
  const { showLoader } = useLoader();

  const confirmAssembledProducts = async (order: ClientOrder) => {
    const { closeLoader } = showLoader();
    try {
      await api.order.signal({
        order_id: order.order_id,
        signal: 'confirm_assembled_products',
      });
    } catch (e: any) {
      const response = (e as AxiosError<BaseError>)['response'];
      if (response?.data.code !== 'ER_CONFLICT' || response?.data.message !== 'Already confirmed') {
        Notifications.error.micro($gettext('Произошла ошибка при отправке сигнала'));
        closeLoader();
        return false;
      }
    }
    order.vars.confirming_assembled_products = true;
    await orderQueue.load(order.order_id);
    closeLoader();
    return true;
  };

  return { confirmAssembledProducts };
}
