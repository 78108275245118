import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import { microNotify } from '@/utils';

export const checkConditions = (
  suggest: Suggest,
  operation: 'cancelable' | 'editable' | 'all' | 'need_valid',
  silent = false,
): boolean => {
  if (!suggest.conditions) {
    return true;
  }
  if (suggest.conditions[operation] === true) {
    return true;
  }
  if (suggest.conditions[operation] === false) {
    if (!silent) {
      const errors = {
        cancelable: $gettext('Невозможно отменить данное задание'),
        editable: $gettext('Невозможно отредактировать данное задание'),
        all: $gettext('Невозможно указать другое кол-во товара'),
        need_valid: $gettext('Невозможно указать другой срок годности'),
      };
      microNotify.error(errors[operation]);
    }
    return false;
  }
  return true;
};
