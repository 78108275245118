import { getShelf2BoxSuggests, getSuggestsByStatusAndType } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';

export default class RefundOrder extends BaseOrder {
  get requiredActionsCount() {
    if (this.target === 'canceled') {
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.request, SuggestTypeEnum.shelf2box).length;
    }
    return super.requiredActionsCount;
  }

  get allActionsCount() {
    if (this.target === 'canceled') {
      return getShelf2BoxSuggests(this.suggests).length;
    }
    return super.allActionsCount;
  }
}
