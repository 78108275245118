<template>
  <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :fill="colorValue"
      d="M10.7333 2C10.3283 2 10 2.32832 10 2.73333V5.26667C10 5.67167 10.3283 6 10.7333 6H13.2667C13.6717 6 14 5.67168 14 5.26667V2.73333C14 2.32833 13.6717 2 13.2667 2H10.7333ZM11.3333 4.66667V3.33333H12.6667V4.66667H11.3333ZM2.73333 10C2.32833 10 2 10.3283 2 10.7333L2 13.2667C2 13.6717 2.32832 14 2.73333 14H5.26667C5.67167 14 6 13.6717 6 13.2667V10.7333C6 10.3283 5.67168 10 5.26667 10H2.73333ZM3.33333 12.6667L3.33333 11.3333H4.66667V12.6667H3.33333ZM2 2.73333C2 2.32832 2.32833 2 2.73333 2H5.26667C5.67168 2 6 2.32833 6 2.73333V5.26667C6 5.67168 5.67167 6 5.26667 6H2.73333C2.32832 6 2 5.67167 2 5.26667L2 2.73333ZM3.33333 3.33333V4.66667H4.66667V3.33333H3.33333ZM7.66667 10C7.48257 10 7.33333 10.1492 7.33333 10.3333V13.6667C7.33333 13.8508 7.48257 14 7.66667 14H8.33333C8.51743 14 8.66667 13.8508 8.66667 13.6667V10.3333C8.66667 10.1492 8.51743 10 8.33333 10H7.66667ZM7.33333 2.33333C7.33333 2.14924 7.48257 2 7.66667 2H8.33333C8.51743 2 8.66667 2.14924 8.66667 2.33333V7.66667V8.33333C8.66667 8.51743 8.51743 8.66667 8.33333 8.66667H7.66667H2.33333C2.14924 8.66667 2 8.51743 2 8.33333L2 7.66667C2 7.48257 2.14924 7.33333 2.33333 7.33333H7.33333V2.33333ZM10.3333 7.33333C10.1492 7.33333 10 7.48257 10 7.66667V8.33333V11C10 11.1841 10.1492 11.3333 10.3333 11.3333H11C11.1841 11.3333 11.3333 11.1841 11.3333 11V8.66667H13.6667C13.8508 8.66667 14 8.51743 14 8.33333V7.66667C14 7.48257 13.8508 7.33333 13.6667 7.33333H11H10.3333ZM12.6667 10.3333C12.6667 10.1492 12.8159 10 13 10H13.6667C13.8508 10 14 10.1492 14 10.3333V13V13.6667C14 13.8508 13.8508 14 13.6667 14H13H10.3333C10.1492 14 10 13.8508 10 13.6667V13C10 12.8159 10.1492 12.6667 10.3333 12.6667H12.6667V10.3333Z"
    />
  </svg>
</template>

<script>
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
  },
});
</script>
