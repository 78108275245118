<template>
  <div class="px-4 pb-4 pt-2">
    <div class="mb-2">
      <Hint>{{ hintText }}</Hint>
    </div>
    <Counter
      v-if="!keyboard"
      :value="initialValue"
      :disabled="disabled"
      :min="min"
      :max="max"
      :is-next="isCounterNext"
      :product-id="productId"
      @confirm="onInputCount"
    />
    <Keyboard v-if="keyboard" :min="min" :max="max" :product-id="productId" @input="onInputCount" />
  </div>
</template>
<script lang="ts">
import Product from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import Counter from '@/ui/common/counter.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Keyboard from '@/ui/common/keyboard/keyboard.vue';
import { defineComponent } from 'vue';

// компонет возвращает кол-во в пачках для обычных полок и в квантах для компонентных
export default defineComponent({
  components: {
    Counter,
    Hint,
    Keyboard,
  },
  props: {
    isCounterNext: {
      type: Boolean,
      default: false,
    },
    keyboard: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: Number,
      default: undefined,
    },
    productId: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: undefined,
    },
    min: {
      type: Number,
      default: undefined,
    },
  },
  emits: ['confirmInput'],
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  computed: {
    product(): Product {
      return this.productsStore.productById(this.productId)!;
    },
  },
  methods: {
    async onInputCount({ value }: { value: number }): Promise<void> {
      this.$emit('confirmInput', value);
    },
  },
});
</script>
