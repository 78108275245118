<template>
  <Layout>
    <div ref="container" class="flex flex-col h-full w-full">
      <Hint class="m-4">
        {{ $gettext('Покажите QR-код на кассе') }}
      </Hint>
      <QrcodeVue :value="'515&' + order.external_id" :size="width" :margin="8" />
    </div>
    <template #footer>
      <LayoutFooter>
        <SliderButton data-test="order-finish qrcode next-stage-btn" @slide-complete="$emit('done')">
          {{ $gettext('Завершить сборку') }}
        </SliderButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import QrcodeVue from 'qrcode.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'FinishOrderCardQRCode',
  components: { SliderButton, Hint, LayoutFooter, Layout, QrcodeVue },
  props: {
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
  },
  emits: ['done'],
  data() {
    return {
      width: 200,
    };
  },
  mounted() {
    this.setWidth();
  },
  methods: {
    setWidth() {
      this.width = (this.$refs.container as HTMLDivElement).clientWidth;
    },
  },
});
</script>
