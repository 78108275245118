export const errorMessagesToIgnore = [
  /ResizeObserver loop limit exceeded/,
  /Scanner rejected/,
  // ошибка связаанная с качеством сети. возникает если гет запрос длится более 5 сек
  /timeout of 5000ms exceeded/,
  // мне кажется, что это всякие метрики срут нам в логи. решил заглушить. ибо все ошибки апи мы и так логируем
  /Unhandled rejection: Failed to fetch/,
  // ошибки сети, нам нет нужды логировать отсутствие интернета на лавках
  /Network Error/,
  /custom-error-filter/,
  /<h1>502 Bad Gateway<\/h1>/, // Когда балансеру при раскатке грустно, он отвечает такой html'кой
  /timeout exceeded/, // Ошибка от axiosa, если запрос висит дольше таймаута. Возникает при грустном интернете
  /useComponent:unmounted/, // Если какой либо из компонентов отображаемых по данному хуку не успевает закрыться до onUnmounted родителя, мы получаем данную ошибку.
  // по умолчанию ивент сервис должен обрывать соединение через 15 секунд после его установление, но если он не сделает это самостоятельно, то полка самостоятельно оборвет его через 20с.
  // игнорируем подобные сообщения
  // LAVKADEV-12958 снова логируем на время расследования
  // /timeout of 20000ms exceeded/,
];
