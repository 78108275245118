import BaseOrder from '@/models/orders/BaseOrder';
import { getOrderTypes } from '@/temp/constants/translations';

export default class CheckOrder extends BaseOrder {
  get title() {
    if (this.type === 'inventory_check_product_on_shelf' && this.rack) {
      return this.rack;
    }
    return getOrderTypes[this.type];
  }
}
