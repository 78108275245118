<template>
  <Bar
    :caption="caption"
    :text="text"
    :icons="{ left: 'back', right: type }"
    :custom-menu="type !== 'none'"
    @close-click="emits('close')"
    @menu-click="menuClick"
  />
</template>
<script setup lang="ts">
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import { getOrderNumber } from '@/utils';
import { computed, defineEmits, defineProps } from 'vue';

interface RetailHeaderDetails {
  order: OrderOrderRetail;
  suggest: Suggest;
  collected: number;
}

const caption = computed<string>(() => {
  if (!props.suggest || !props.suggest.product) {
    return '';
  }
  return props.suggest.product.title;
});

const text = computed<string>(() => {
  if (!props.order) {
    return '';
  }
  return `${$gettext('Заказ')} ${getOrderNumber(props.order)}`;
});

const type = computed<'remove' | 'menu' | 'none'>(() => {
  if (props.suggest.status === 'done' && !props.suggest.result_count) {
    return 'none';
  } else if (props.collected) {
    return 'menu';
  }
  return 'remove';
});

const props = defineProps<RetailHeaderDetails>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'openSuggestMenu'): void;
  (e: 'removeSuggest'): void;
}>();

const menuClick = () => {
  if (type.value === 'remove') emits('removeSuggest');
  if (type.value === 'menu') emits('openSuggestMenu');
};
</script>
