import AcceptanceMarketOrder from '@/models/orders/AcceptanceMarketOrder';
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';
import CheckMoreOrder from '@/models/orders/CheckMoreOrder';
import CheckOrder from '@/models/orders/CheckOrder';
import CheckProductOnShelfOrder from '@/models/orders/CheckProductOnShelfOrder';
import ControlCheckOrder from '@/models/orders/ControlCheckOrder';
import ControlOrder from '@/models/orders/ControlOrder';
import HandMoveOrder from '@/models/orders/HandMoveOrder';
import KitchenProvisionOrder from '@/models/orders/KitchenProvisionOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import OrderRetailOrder from '@/models/orders/OrderOrderRetail';
import RefundOrder from '@/models/orders/RefundOrder';
import RepackingOrder from '@/models/orders/RepackingOrder';
import SaleStowageOrder from '@/models/orders/SaleStowageOrder';
import ShipmentOrder from '@/models/orders/ShipmentOrder';
import ShipmentRollbackOrder from '@/models/orders/ShipmentRollbackOrder';
import StowageMarketOrder from '@/models/orders/StowageMarketOrder';
import VisualControlOrder from '@/models/orders/VisualControlOrder';
import WeightStowageOrder from '@/models/orders/WeightStowageOrder';
import WriteoffOrder from '@/models/orders/WriteoffOrder';

export const createOrder = (data: BaseOrder): BaseOrder => {
  switch (data.type) {
    case OrderTypeEnum.acceptance:
      if (data.vars.market_orders) {
        return new AcceptanceMarketOrder(data);
      }
      return new AcceptanceOrder(data);
    case OrderTypeEnum.acceptance_market:
      return new AcceptanceMarketOrder(data);
    case OrderTypeEnum.check_more:
    case OrderTypeEnum.inventory_check_more:
      return new CheckMoreOrder(data);
    case OrderTypeEnum.check:
    case OrderTypeEnum.check_final:
    case OrderTypeEnum.inventory_check_product_on_shelf:
      return new CheckOrder(data);
    case OrderTypeEnum.check_product_on_shelf:
      return new CheckProductOnShelfOrder(data);
    case OrderTypeEnum.control_check:
      return new ControlCheckOrder(data);
    case OrderTypeEnum.writeoff_prepare_day:
    case OrderTypeEnum.check_valid_short:
    case OrderTypeEnum.check_valid_regular:
      return new ControlOrder(data);
    case OrderTypeEnum.hand_move:
      return new HandMoveOrder(data);
    case OrderTypeEnum.kitchen_provision:
      return new KitchenProvisionOrder(data);
    case OrderTypeEnum.order:
      return new OrderOrder(data);
    case OrderTypeEnum.refund:
    case OrderTypeEnum.part_refund:
      return new RefundOrder(data);
    case OrderTypeEnum.repacking:
      return new RepackingOrder(data);
    case OrderTypeEnum.sale_stowage:
      if (data.vars.tag === 'parcel') {
        return new StowageMarketOrder(data);
      }
      return new SaleStowageOrder(data);
    case OrderTypeEnum.stowage_market:
      return new StowageMarketOrder(data);
    case OrderTypeEnum.shipment:
      return new ShipmentOrder(data);
    case OrderTypeEnum.shipment_rollback:
      return new ShipmentRollbackOrder(data);
    case OrderTypeEnum.visual_control:
      return new VisualControlOrder(data);
    case OrderTypeEnum.weight_stowage:
      return new WeightStowageOrder(data);
    case OrderTypeEnum.writeoff:
      return new WriteoffOrder(data);
    case OrderTypeEnum.order_retail:
      return new OrderRetailOrder(data);
    default:
      return new BaseOrder(data);
  }
};
