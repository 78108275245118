<template>
  <!--Во время загрузки остатков показываем заглушку-->
  <ClientOrderSuggestCardSherlockInfo
    v-if="sherlockStatus === 'maybe'"
    :suggest_id="suggest.suggest_id"
    :order_id="order_id"
    img-size="big"
    :data-test="`product card ${index}`"
  />
  <!--Если нужен шерлок - показываем карточку-->
  <ClientOrderSuggestCardSherlock
    v-if="sherlockStatus === 'need'"
    :suggest_id="suggest.suggest_id"
    :order_id="order_id"
    :index="index"
    :is-visible="isVisible"
    @input-sherlock-count="onSherlock"
  />
  <ClientOrderSuggestCardClassic
    v-if="sherlockStatus === 'skip'"
    ref="productsRef"
    :is-visible="isVisible"
    :order_id="order_id"
    :index="index"
    :suggest="suggest"
    :problem="problem"
    @finish="arg => emits('finish', arg)"
  />
</template>
<script setup lang="ts">
import ClientOrderSuggestCardClassic from '@/fsd/widgets/order/RequiredCard/ClassicCard/ClientOrderSuggestCardClassic.vue';
import ClientOrderSuggestCardSherlock from '@/fsd/widgets/order/RequiredCard/sherlock/ClientOrderSuggestCardSherlock.vue';
import ClientOrderSuggestCardSherlockInfo from '@/fsd/widgets/order/RequiredCard/sherlock/ClientOrderSuggestCardSherlockInfo.vue';
import { useSherlock } from '@/fsd/widgets/order/RequiredCard/sherlock/useSherlock';
import Suggest from '@/models/Suggest';
import { Problem } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { ref, watch } from 'vue';

const props = defineProps<{
  isVisible: boolean;
  order_id: OrderOrder['order_id'];
  index: number;
  suggest: Suggest;
  problem?: Problem;
}>();
const productsRef = ref();
const emits = defineEmits(['finish', 'input-sherlock-count']);
defineExpose({
  clearCollectedAndRequestBarcodeOnProblem: () => productsRef.value?.clearCollectedAndRequestBarcodeOnProblem(),
  startRequestBarcode: () => {
    if (productsRef.value) {
      productsRef.value.uiStateNeedBarcodeRequest = true;
    }
  },
  stopRequestBarcode: () => {
    if (productsRef.value) {
      productsRef.value.uiStateNeedBarcodeRequest = true;
    }
  },
});

const { checkNeedSherlock, sherlockStatus } = useSherlock(props.order_id, props.suggest.suggest_id);

const onSherlock = count => {
  sherlockStatus.value = 'skip';
  emits('input-sherlock-count', count);
};

// проверяем нужен ли шерлок когда показываем карточку
watch(
  () => props.isVisible,
  val => {
    if (!val) return;
    if (sherlockStatus.value !== 'maybe') return;
    checkNeedSherlock();
  },
  { immediate: true },
);
</script>
