<template>
  <PageLayout :order_id="order_id" data-test="market_shipment page">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="caption"
        need-progress
        :progress-config="progressConfig"
        :total="suggests.length"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <Hint v-if="uiStateFilter === 'all'" class="my-2 mx-4">{{ hintText }}</Hint>
      <Hint v-if="uiDifferent?.some(i => !i.item_id) && uiStateFilter === 'different'" class="my-2 mx-4 h-min">
        {{ hintDifferentText }}
      </Hint>

      <DynamicScroller
        v-if="filteredSuggests.length > 0"
        :items="filteredSuggests"
        key-field="suggest_id"
        :min-item-size="112"
        class="h-full"
        list-class="h-full"
      >
        <template #default="{ item, index, active }">
          <DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item.status]">
            <ProductCard
              :key="item.product_id"
              class="my-2"
              data-test="product card"
              :product-id="item.product_id"
              :need-menu="true"
              :badge-data="getBadgeData(item)"
              :menu-config="suggestMenuConfig(item)"
              @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
              @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
            >
              <template #info>
                <div class="flex">
                  <Caption1 color="day-textMinor">
                    {{ $gettext('Полка') }}
                  </Caption1>
                  <Caption1 class="ml-4"> {{ item.shelf.title }}</Caption1>
                </div>
              </template>
            </ProductCard>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>

      <EmptyList
        v-if="
          (uiStateFilter !== 'all' && uiStateFilter !== 'different' && filteredSuggests.length === 0) ||
          (uiStateFilter === 'different' && uiDifferent.length === 0)
        "
      />
    </template>

    <template #footer>
      <LayoutFooter>
        <template v-if="uiStateFilter === 'all'">
          <FilterMenu :menu-config="filterMenuConfig" />
          <UiButton
            v-if="uiState.step === 'create'"
            data-test="create-transfer btn"
            :is-disabled="!allSuggestDone"
            @click="createTransferAct"
          >
            {{ $gettext('Завершить возврат') }}
          </UiButton>
          <UiButton v-else data-test="check-transfer btn" background-color="primary" @click="checkTransferAct">
            {{ $gettext('Проверить и подписать акт') }}
          </UiButton>
        </template>
        <UiButton v-else data-test="back btn" @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getBox2ShelfSuggests, getShelf2BoxSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import withFilter from '@/mixins/withFilter';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import ShipmentOrder from '@/models/orders/ShipmentOrder';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { logger } from '@/temp/plugins/logs';
import Bar from '@/ui/common/bar/bar.vue';
import EmptyList from '@/ui/common/empty-list.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ProductCard, { BadgeData } from '@/ui/common/product-card/product-card.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import commonMixin from '@/views/common/commonMixin';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface DifferentItem {
  barcode: string;
  item_id?: string | null;
  status: 'transferred_and_not_received' | 'not_transferred_and_received';
}

interface Data {
  uiState: {
    step: 'create' | 'sign';
  };
  uiStateNeedBarcodeRequest: boolean;
  uiStateFilter: 'all' | 'done' | 'request' | 'part' | 'noProduct' | 'different';
  uiDifferent: DifferentItem[] | undefined;
}

export default defineComponent({
  name: 'MarketShipment',
  components: {
    PageLayout,
    LayoutFooter,
    Caption1,
    ProductCard,
    Bar,
    Hint,
    UiButton,
    FilterMenu,
    EmptyList,
  },
  mixins: [requestProductCode, requestBarcode, withFilter, commonMixin],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const itemsStore = useItems();
    const router = useRouter();

    useHandleOrderStatus(props.order_id);

    return { showLoader, ordersStore, itemsStore, router };
  },
  data(): Data {
    return {
      uiState: {
        step: 'create',
      },
      uiDifferent: [],
      uiStateNeedBarcodeRequest: true,
      uiStateFilter: 'all',
    };
  },
  computed: {
    order(): ShipmentOrder {
      return this.ordersStore.orderById(this.order_id) as any as ShipmentOrder;
    },
    suggests(): Suggest[] {
      if (!this.order) return [];
      if (this.order.target === 'canceled') {
        return getBox2ShelfSuggests(this.order.suggests);
      }
      return getShelf2BoxSuggests(this.order.suggests);
    },
    filteredSuggests(): Suggest[] {
      switch (this.uiStateFilter) {
        case 'all':
          return this.suggests;
        case 'done':
          return this.fullCompletedSuggests;
        case 'request':
          return this.requestSuggests;
        case 'noProduct':
          return this.noProductSuggests;
        case 'different':
          return this.suggests.filter(s => this.uiDifferent?.some(item => item.item_id === s.product_id));
      }
      return this.suggests;
    },
    controlBarText(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Осталось %{request} из %{all} посылок', {
            request: String(this.requestSuggests.length),
            all: String(this.suggests.length),
          });
        case 'done':
        case 'request':
        case 'noProduct':
          return this.$ngettext(
            '%{filtered} посылка с таким статусом',
            '%{filtered} посылок с таким статусом',
            this.filteredSuggests.length,
            { filtered: String(this.filteredSuggests.length) },
          );
        case 'different':
          return this.$ngettext(
            '%{filtered} посылка с таким статусом',
            '%{filtered} посылок с таким статусом',
            this.uiDifferent!.length,
            { filtered: String(this.uiDifferent!.length) },
          );
        default:
          return '';
      }
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => {
            this.finishActiveSuggest(0, suggest);
          },
          dataTest: 'no-product btn',
          condition: () => {
            return checkConditions(suggest, 'all', true);
          },
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    caption(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Возврат Маркета');
        case 'done':
          return this.$gettext('Отксанирована');
        case 'request':
          return this.$gettext('Не отсканирована');
        case 'noProduct':
          return this.$gettext('Нельзя принять');
        case 'different':
          return this.$gettext('Расхождения');
      }
      return this.$gettext('Возврат Маркета');
    },
    filterMenuConfig(): MenuItemConfig[] {
      const menuConfig = [
        {
          buttonText: this.$gettext('Не отсканирована'),
          color: 'secondary',
          count: this.requestSuggests.length,
          onClick: () => this.setFilter('request'),
        },
        {
          buttonText: this.$gettext('Нельзя принять'),
          color: 'red',
          count: this.noProductSuggests.length,
          onClick: () => this.setFilter('noProduct'),
        },
        {
          buttonText: this.$gettext('Отксанирована'),
          color: 'green',
          count: this.fullCompletedSuggests.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
        {
          buttonText: this.$gettext('Расхождения с курьером'),
          color: 'green',
          count: this.uiDifferent?.length,
          onClick: () => {
            this.setFilter('different');
          },
        },
      ];
      return menuConfig;
    },
    hintText(): string {
      return this.$gettext('Отканируйте штрих-код каждой посылки, которую вы возвращаете');
    },
    hintDifferentText(): string {
      return (
        this.$gettext(
          'Курьер отсканировал лишние штрих-коды, сверьте посылки с курьером, затем попросите отменить акт и создать новый.',
        ) + this.uiDifferent?.filter(i => !i.item_id).map(i => `\n${i.barcode}`)
      );
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const result = await this.requestProductCode({ checkSuggests: true });
      if (!result.findSuggest) {
        return true;
      }

      const suggest = result.findSuggest!;
      await this.finishActiveSuggest(1, suggest);

      return true;
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    async finishActiveSuggest(count: number, suggest: Suggest): Promise<void> {
      if (!suggest) {
        this.$notification.error.micro(this.$gettext('Произошла ошибка при выполнении задания'));
        logger.error('empty active suggest', {
          type: 'code',
          page: 'shipment',
          method: 'finishActiveSuggest',
          message: 'empty active suggest',
        });
        return;
      }
      if (suggest.result_count === 1 && count === 1) {
        await this.$notification.modal({
          title: this.$gettext('Посылка уже отсканирована'),
        });
        return;
      }
      const activeItem = this.itemsStore.itemById(suggest.product_id);
      if (count !== 0) {
        await this.$notification.success.micro(
          this.$gettext('Отсканирована посылка %{item}', {
            item: activeItem!.title,
          }),
        );
      } else {
        const confirmed = await this.$notification.confirmBottom({
          title: this.$gettext('Вы уверены, что посылка %{item} отсутствует?', {
            item: activeItem!.title,
          }),
        });
        if (!confirmed) return;
      }
      if (this.uiDifferent?.length) {
        this.uiDifferent = this.uiDifferent?.filter(item => item.item_id !== suggest.product_id);
      }

      if (suggest.type === 'shelf2box') {
        await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
      } else {
        await useBox2Shelf(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
      }
    },
    setFilter(filter): void {
      this.uiStateFilter = filter;
    },
    async createTransferAct(): Promise<void> {
      if (this.noProductSuggests.length !== 0) {
        const confirmed = await this.$notification.confirmBottom({
          title: this.$gettext('Вы уверены, что посылок больше не осталось?'),
          text: this.$gettext('Убедитесь, что посылок, которые нужно вернуть, больше не осталось.'),
        });
        if (!confirmed) {
          return;
        }
      }

      this.uiState.step = 'sign';
      await this.$notification.modal({
        title: this.$gettext('Попросите курьера создать акт'),
        text: this.$gettext(
          'После того, как курьер создаст акт, его необходимо будет подписать и задание будет завершено.',
        ),
      });
    },
    async checkTransferAct(): Promise<void> {
      const { closeLoader } = this.showLoader();
      try {
        await this.ordersStore.signal({ order_id: this.order.order_id, signal: 'edoc' });
        const lastVersion = this.order.version;
        await new Promise<void>(resolve => {
          const unWatch = this.$watch(
            'order',
            newValue => {
              if (newValue.signals[newValue.signals.length - 1]?.done && lastVersion < newValue.version) {
                unWatch();
                resolve();
              }
            },
            { deep: true },
          );
        });

        if (this.order?.problems.some(problem => problem.type === 'api_call_failure')) {
          this.$notification.modal({
            title: this.$gettext('Сервис "Трансфер-акт" недоступен'),
            text: this.$gettext('Повторите попытку снова.'),
          });
          return;
        }
        if (!this.order?.vars.transfer_act) {
          this.$notification.modal({
            title: this.$gettext('Акт ещё не создан'),
            text: this.$gettext('Попросите курьера его создать или подождите.'),
          });
        }
        if (this.order.vars.transfer_act?.status === 'CANCELLED') {
          this.$notification.modal({
            title: this.$gettext('Акт отменен курьером'),
            text: this.$gettext('Вы можете досканировать найденные посылки или отметить отсутствующие.'),
          });
        }
        if (this.order.vars.transfer_act?.status === 'CLOSED') {
          closeLoader();
          await this.$notification.modal({
            title: this.$gettext('Акт успешно создан и подписан'),
            titleAlign: 'center',
            text: this.$gettext('Документ будет завершен'),
          });
          return this.finishOrder();
        }
        if (
          this.order.vars.transfer_act_diff &&
          this.order.vars.transfer_act_diff.length > 0 &&
          this.order.vars.transfer_act?.status === 'CREATED'
        ) {
          this.$notification.modal({
            title: this.$gettext('В акте есть расхождения'),
            text: this.$gettext('Сверьте посылки с курьером, затем попросите отменить акт и создать новый.'),
          });
          this.uiDifferent = this.order.vars.transfer_act_diff;
          this.setFilter('different');
        }
      } catch (e) {
        console.error(e);
      } finally {
        closeLoader();
      }
    },
    getBadgeData(s: Suggest): BadgeData | undefined {
      if (s.status === SuggestStatusEnum.request) {
        return undefined;
      }
      if (this.uiDifferent?.length) {
        const status = this.uiDifferent?.find(d => d.item_id === s.product_id)?.status;
        if (status) {
          return {
            color: 'red-normal',
            text: status === 'transferred_and_not_received' ? this.$gettext('Лишнее') : this.$gettext('Нет'),
          };
        }
      }
      if (s.result_count === 1) {
        return {
          color: 'lime-toxic',
          text: this.$gettext('Готово'),
        };
      }
      if (s.result_count === 0) {
        return {
          color: 'red-normal',
          text: this.$gettext('Нет'),
        };
      }
    },

    async finishOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
  },
});
</script>
