<template>
  <Overlay :need-close-on-backdrop-click="false">
    <div class="h-screen overflow-hidden pointer-events-auto">
      <Layout>
        <template #header>
          <Bar :caption="$gettext('Списание')" @close-click="close" />
        </template>
        <div v-if="!product" class="flex flex-col h-full items-center">
          <Typography type="h2" align="center" margin="0 22px 8px">
            {{ $gettext('Отсканируйте товар, который вы хотите вернуть') }}
          </Typography>
          <div class="flex flex-1 items-center">
            <img src="@/assets/img/scan.svg" alt="" />
          </div>
        </div>

        <template v-if="product">
          <ProductCardProductInfo :product="product" :need-show-info="false" />
          <Row
            v-if="uiState.selectedOrder"
            :label="orderType(uiState.selectedOrder)"
            :second-label="orderNumber(uiState.selectedOrder)"
            :value="orderDate(uiState.selectedOrder)"
            :second-value="$gettext('Дата списания')"
          />
          <Row
            v-if="uiState.selectedPortion"
            :label="trashReasons[uiState.selectedPortion.reason_code]"
            :value="portionView(uiState.selectedPortion.count)"
          />
          <Row v-if="uiState.count" :label="$gettext('Количество')" :value="portionView(count)" />
          <Hint v-if="['order', 'portion'].includes(uiState.step)" class="m-4">{{ hintText }}</Hint>
          <template v-if="uiState.step === 'order'">
            <Row
              v-for="(order, index) in uiState.orders"
              :key="order.order_id"
              :label="orderType(order)"
              :second-label="orderNumber(order)"
              :value="orderDate(order)"
              :second-value="$gettext('Дата списания')"
              :data-test="`order-row ${index}`"
              @click="selectOrder(order)"
            />
          </template>
          <template v-if="uiState.step === 'portion'">
            <Row
              v-for="(portion, index) in portionsFromOrder(uiState.selectedOrder)"
              :key="portion.stock_id"
              :label="trashReasons[portion.reason_code]"
              :value="portionView(portion.count)"
              :data-test="`portion-row ${index}`"
              @click="selectPortion(portion)"
            />
          </template>
        </template>
        <template #footer>
          <div class="bg-main max-w-full">
            <Hint v-if="['count', 'tail', 'shelf'].includes(uiState.step)" class="m-4">{{ hintText }}</Hint>
            <template v-if="!product">
              <div class="p-4">
                <UiButton background-color="secondary" @click="close">
                  {{ $gettext('Завершить') }}
                </UiButton>
              </div>
            </template>
            <template v-if="uiState.step === 'count'">
              <Counter
                :key="uiState.step"
                class="px-4 pb-4"
                :value="shelf.isKitchenShelf ? 0 : 1"
                :min="shelf.isKitchenShelf ? 0 : 1"
                :max="
                  shelf.isKitchenShelf
                    ? getClosedPackage(product, uiState.selectedPortion && uiState.selectedPortion.count)
                    : uiState.selectedPortion && uiState.selectedPortion.count
                "
                :is-next="true"
                @confirm="onInputCount"
              />
            </template>
            <template v-if="uiState.step === 'tail'">
              <Counter
                :key="uiState.step"
                class="px-4 pb-4"
                :value="0"
                :min="0"
                :max="
                  Math.min(
                    product.quants,
                    ((uiState.selectedPortion && uiState.selectedPortion.count) || 0) -
                      (uiState.count || 0) * product.quants,
                  )
                "
                :is-next="true"
                @confirm="onInputTail"
              />
            </template>
            <template v-if="uiState.step === 'shelf'">
              <div class="px-4 pb-4">
                <UiButton
                  data-test="return-from-writeoff finish btn"
                  :is-disabled="!uiState.dst_shelf_id"
                  @click="returnFromWriteoff"
                >
                  {{ $gettext('Завершить') }}
                </UiButton>
              </div>
            </template>
          </div>
        </template>
      </Layout>
    </div>
  </Overlay>
</template>

<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { getClosedPackage, getValueForView } from '@/fsd/entities/product';
import requestBarcode from '@/mixins/requestBarcode';
import Product from '@/models/Product';
import Shelf, { ShelfTypeEnum } from '@/models/Shelf';
import { OrderMoveRequest } from '@/services/requests';
import { OrderFromLoadOrder } from '@/services/response';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { getDefaultLocalFormat } from '@/temp/constants/dateFormat';
import { getOrderTypes, shelfTypes } from '@/temp/constants/translations';
import { trashReasons } from '@/temp/constants/translations/trashReasons';
import IconInformer from '@/temp/icons/icon-informer.vue';
import { logger } from '@/temp/plugins/logs';
import { IOrderError } from '@/temp/plugins/notification';
import { Portion } from '@/types/portion';
import { AvailableProduct } from '@/types/product';
import Bar from '@/ui/common/bar/bar.vue';
import Counter from '@/ui/common/counter.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Row from '@/ui/common/row/row.vue';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import ProductCardProductInfo from '@/ui/home/product-card/product-card-product-info.vue';
import { subscribeOnOrderStatus } from '@/utils/subscribeOnOrder';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import uuidv1 from 'uuid/v1';

enum StepsEnum {
  order = 'order',
  portion = 'portion',
  count = 'count',
  tail = 'tail',
  shelf = 'shelf',
}

interface Data {
  uiState: {
    step: StepsEnum;
    product_id: string;
    portions: Portion[];
    selectedPortion?: Portion;
    selectedOrder?: OrderFromLoadOrder;
    orders: OrderFromLoadOrder[];
    dst_shelf_id?: string;
    count?: number;
    tail?: number;
  };
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'ReturnFromWriteoff',
  components: {
    UiButton,
    Row,
    Typography,
    Layout,
    Hint,
    Counter,
    ProductCardProductInfo,
    Bar,
    Overlay,
  },
  mixins: [requestBarcode],
  props: {
    shelfId: {
      type: String,
      default: '',
    },
  },
  emits: ['finish'],
  setup() {
    const { showLoader } = useLoader();
    const productsStore = useProducts();
    const shelvesStore = useShelves();

    return { showLoader, productsStore, shelvesStore };
  },
  data(): Data {
    return {
      uiState: {
        product_id: '',
        portions: [],
        orders: [],
        selectedPortion: undefined,
        selectedOrder: undefined,
        step: StepsEnum.order,
        dst_shelf_id: undefined,
        count: undefined,
        tail: undefined,
      },
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    count(): number {
      if (!this.shelf.isKitchenShelf) {
        return this.uiState.count || 0;
      }
      return (this.uiState.count || 0) * this.product.quants + (this.uiState.tail || 0);
    },
    portionView(): (count: number) => string {
      return (count: number) => getValueForView({ product: this.product, shelf: this.shelf, count });
    },
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.shelfId)!;
    },
    product(): Product {
      return this.productsStore.productById(this.uiState.product_id)!;
    },
    orderType(): (order: OrderFromLoadOrder) => string {
      return (order: OrderFromLoadOrder) => {
        return getOrderTypes[order.type];
      };
    },
    orderDate(): (order: OrderFromLoadOrder) => string {
      return (order: OrderFromLoadOrder) => {
        return dayjs(order.created).format(getDefaultLocalFormat());
      };
    },
    orderNumber(): (order: OrderFromLoadOrder) => string {
      return (order: OrderFromLoadOrder) => {
        return order.attr.request_number || order.attr.doc_number || this.$gettext('без номера');
      };
    },
    portionsFromOrder(): (order?: OrderFromLoadOrder) => Portion[] {
      return order => {
        if (!order) return [];
        return this.uiState.portions!.filter(p => p.order_id === order.order_id);
      };
    },
    hintText(): string {
      if (this.uiState.step === 'order') {
        return this.$gettext('Выберите документ списания');
      }
      if (this.uiState.step === 'portion') {
        return this.$gettext('Выберите причину списания');
      }
      if (this.uiState.step === 'count') {
        return this.$gettext('Сколько товара хотите вернуть?');
      }
      if (this.uiState.step === 'tail') {
        return this.$gettext('Сколько товара в открытой упаковке?');
      }
      if (this.uiState.step === 'shelf') {
        return this.$gettext('Отсканируйте полку на которую перемещаете товар');
      }
      return '';
    },
    trashReasons(): any {
      return trashReasons;
    },
    available(): (product_id: string) => AvailableProduct | undefined {
      return product_id => this.shelvesStore.availableById(this.shelfId).find(a => a.product_id === product_id);
    },
    needEnterTail(): boolean {
      return this.shelf.isKitchenShelf && this.product.isProductQuant && this.product.quant_unit !== 'unit';
    },
  },
  methods: {
    getClosedPackage,
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      const { closeLoader } = this.showLoader();
      try {
        const product = await this.productsStore.getProductByBarcode(barcode);
        closeLoader();
        if (!product) {
          this.$notification.error.micro(this.$gettext('Не найден штрихкод'));
          return true;
        }
        const availableProduct = this.available(product.product_id);
        if (!availableProduct || availableProduct?.reserved) {
          this.$notification.error.micro(this.$gettext('Товар зарезервирован в задании на списание'));
          return true;
        }
        this.uiState.product_id = product.product_id;
        return await this.loadPortions();
      } catch (error) {
        closeLoader();
        this.$notification.error.micro(this.$gettext('Не найден штрихкод'));
        return true;
      }
    },
    async requestShelf(): Promise<Shelf> {
      try {
        const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
        const shelf = await useShelves().getShelfByBarcode(barcode);
        if (shelf) {
          return shelf;
        }
        this.$notification.error.micro(this.$gettext('Не найден штрихкод %{barcode}', { barcode }));
        return await this.requestShelf();
      } catch (error) {
        console.error(error);
        this.$notification.error.micro(this.$gettext('Не найден штрихкод'));
        return await this.requestShelf();
      }
    },
    async loadPortions(): Promise<boolean> {
      const { closeLoader } = this.showLoader();
      try {
        const { data } = await api.order.get_portions({
          product_id: this.uiState.product_id,
          shelf_id: this.shelfId,
        });
        const order_ids = [...new Set(data.portions.map(p => p.order_id))];
        if (order_ids.length === 0) {
          this.$notification.error.micro(this.$gettext('Данный товар отсутствует на полке списания!'));
          this.reset();
          return true;
        }
        const { data: loadOrderData } = await api.order.load_order({
          order_ids,
        });

        this.uiState.portions = data.portions;
        this.uiState.orders = loadOrderData.orders;
        return false;
      } catch (error) {
        logger.error(error, { method: 'loadPortions', type: 'return_from_writeoff' });
        this.$notification.error.micro(
          this.$gettext('Произошла ошибка при загрузке документов о списании товаров. Попробуйте еще раз'),
        );
        this.reset();
        return true;
      } finally {
        closeLoader();
      }
    },
    close(): void {
      this.reset();
      this.$emit('finish');
    },
    selectOrder(order: OrderFromLoadOrder): void {
      this.uiState.selectedOrder = order;
      this.uiState.step = StepsEnum.portion;
    },
    selectPortion(portion: Portion): void {
      this.uiState.selectedPortion = portion;
      this.uiState.step = StepsEnum.count;
    },
    async onInputCount({ value }: { value: number }): Promise<void> {
      this.uiState.count = value;
      if (this.needEnterTail) {
        this.uiState.step = StepsEnum.tail;
        this.$notification.modal({
          title: this.$gettext('Пересчёт количества товаров в открытых упаковках'),
          text: this.$gettext('Теперь необходимо указать оставшееся количество товара в открытых упаковках'),
        });
        return;
      }
      this.uiState.step = StepsEnum.shelf;
      const shelf = await this.requestShelf();

      // Запрещаем возврат со списания на полки Уценка и Посылки для всех ролей
      if (shelf.type === 'markdown' || shelf.type === 'parcel') {
        this.$notification.modal({
          title: this.$gettext('С полки %{srcShelf} перенести на полку %{destShelf} нельзя', {
            srcShelf: shelfTypes(ShelfTypeEnum.trash),
            destShelf: shelfTypes(shelf.type),
          }),
          iconTop: {
            icon: IconInformer,
            position: 'center',
          },
        });
        this.uiState.dst_shelf_id = undefined;
        this.uiState.step = StepsEnum.count;
        return;
      }
      this.uiState.dst_shelf_id = shelf.shelf_id;
    },
    async onInputTail({ value }: { value: number }): Promise<void> {
      this.uiState.tail = value;
      this.uiState.step = StepsEnum.shelf;
      const shelf = await this.requestShelf();
      this.uiState.dst_shelf_id = shelf.shelf_id;
    },
    async returnFromWriteoff(): Promise<void> {
      const confirm = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что хотите вернуть товар?'),
        text: [this.product.title, this.portionView(this.count)].join(', '),
        ok: this.$gettext('Да'),
        decline: this.$gettext('Нет'),
      });
      if (!confirm) return;
      const payload: OrderMoveRequest = {
        order_id: uuidv1(),
        move: [
          {
            product_id: this.product.product_id,
            count: this.count,
            src_shelf_id: this.shelfId,
            dst_shelf_id: this.uiState.dst_shelf_id!,
            move_order_id: this.uiState.selectedOrder!.order_id,
            stock_id: this.uiState.selectedPortion!.stock_id,
            reason: {
              code: this.uiState.selectedPortion!.reason_code,
            },
          },
        ],
      };
      const { closeLoader } = this.showLoader(this.$gettext('Выполняем перемещение товаров'));
      try {
        const { data } = await api.order.move(payload);
        const moveOrderId = data.order.order_id;
        const cbSuc = (data, unSub) => {
          unSub();
          closeLoader();
          this.$notification.success.micro(this.$gettext('Возврат со списания успешно выполнен'));
          this.close();
        };
        const cbFail = (order, unSub) => {
          unSub();
          closeLoader();
          logger.error(data, { method: 'returnFromWriteoff', type: 'return_from_writeoff' });
          const majorErrorConfig: IOrderError = {
            title: this.$gettext('Ошибка возврата со списания'),
            reason: '',
            body: '',
            onClose: () => {},
            onRepeat: () => this.returnFromWriteoff(),
          };
          this.$notification.error.major.order(majorErrorConfig);
        };
        subscribeOnOrderStatus({ order_id: moveOrderId, cbSuc, cbFail });
      } catch (error: any) {
        closeLoader();
        logger.error(error, { method: 'returnFromWriteoff', type: 'return_from_writeoff' });
        if (!error.isAxiosError) return;
        const response = (error as AxiosError).response;
        const majorErrorConfig: IOrderError = {
          title: this.$gettext('Ошибка завершения документа'),
          reason: response?.status.toString() || 'Network error',
          body: response?.data.message || '',
          onClose: () => {},
          onRepeat: () => this.returnFromWriteoff(),
        };
        this.$notification.error.major.order(majorErrorConfig);
      }
    },
    reset(): void {
      this.uiState.selectedOrder = undefined;
      this.uiState.count = undefined;
      this.uiState.selectedPortion = undefined;
      this.uiState.step = StepsEnum.order;
      this.uiState.product_id = '';
      this.uiState.dst_shelf_id = undefined;
      this.uiState.portions = [];
      this.uiState.orders = [];
    },
  },
});
</script>
