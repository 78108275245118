import { getShelf2BoxSuggests, getSuggestsByStatusAndType } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { useUser } from '@/store/modules/user';
import { shelfTagsInStowage } from '@/temp/constants/translations';
import { $gettext } from '@/temp/plugins/gettext';
import { computed } from 'vue';

interface Conditions {
  is_agree: boolean;
}

export default class SaleStowageOrder extends BaseOrder {
  public conditions: Conditions = { is_agree: true };

  constructor(data: any) {
    super(data);
    this.conditions = data.conditions;
  }

  get isTrustStowage(): boolean {
    return this.conditions.is_agree;
  }

  get requiredActionsCount() {
    if (this.target === 'canceled') {
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.request, SuggestTypeEnum.shelf2box).length;
    }
    return super.requiredActionsCount;
  }

  _allActionsCount = computed(() => {
    if (this.target === 'canceled') {
      return getShelf2BoxSuggests(this.suggests).length;
    }
    if (this.status === 'processing') {
      return new Set(this.suggests.map(s => s.product_id)).size;
    }
    return super.allActionsCount;
  });

  get title() {
    if (this.target === 'canceled') {
      return $gettext('Отмена размещения');
    }
    if (this.vars?.tag === 'parcel') {
      return $gettext('Размещение посылки');
    }
    //если размещение на разные типы полок
    if (this.vars?.tag === 'mixed' || !this.vars.tag) {
      return $gettext('Размещение');
    }
    if (this.vars?.addition_tag === 'perishable') {
      return $gettext('Скоропортящаяся еда');
    }
    return $gettext('%{orderType} в %{shelfType}', {
      orderType: $gettext('Размещение'),
      shelfType: shelfTagsInStowage[this.vars.tag],
    });
  }

  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.maxWeightInOrder > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}
