<template>
  <div class="flex h-screen bg-white pointer-events-auto" data-test="create-writeoff-by-order root">
    <Layout>
      <template #header>
        <Bar
          :caption="$gettext('Сканирование')"
          :text="text"
          :icons="{ left: 'close' }"
          :menu-config="[]"
          @close-click="onCloseClick"
        />
      </template>
      <template #default>
        <template v-if="required.length">
          <div class="px-4 py-2">
            <Hint>{{ $gettext('Отсканируйте расходник на списание') }}</Hint>
          </div>
          <SuggestCard
            v-for="(item, index) in required"
            :key="item.product_id + item.shelf_id"
            doc-type="create-writeoff-by-order-item"
            :suggest="item"
            :suggest-menu-config="suggestMenuConfig(item)"
            :collected="item.count"
            card-type="product"
            :need-menu="true"
            :data-index="index"
            @open-suggest-menu="needBarcodeRequest = false"
            @close-suggest-menu="needBarcodeRequest = true"
          />
        </template>
        <template v-else>
          <div class="flex flex-col justify-center items-center p-4 h-full">
            <span class="text-3xl font-bold text-center mb-16">
              {{ $gettext('Отсканируйте расходник на списание') }}
            </span>
            <img class="empty-img" src="@/assets/img/scan.svg" alt="" />
          </div>
        </template>
      </template>
      <template #footer>
        <div class="flex py-3 px-4">
          <UiButton
            data-test="create-writeoff-by-order finish-btn"
            :is-disabled="required.length === 0"
            @click="createWriteoff"
          >
            {{ $gettext('Завершить') }}
          </UiButton>
        </div>
      </template>
    </Layout>
    <ProductDetails
      v-if="productDetails.visible.value && productDetails.props.value"
      :required="productDetails.props.value"
      @close="productDetails.hide"
      @finish="req => productDetails.executeAndHide(addProductToRequired, req)"
      @remove="req => productDetails.executeAndHide(deleteActiveItem, req)"
    />
    <ScanShelf v-if="scanShelf.visible.value" @scanned="scanShelf.hide" />
  </div>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useComponent } from '@/hooks/useComponent';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import { useProducts } from '@/store/modules/products';
import ProductDetails from '@/temp/pages/product-details.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import { BaseError } from '@/types/baseErrorResponse';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { AxiosError } from 'axios';
import { computed, defineProps, ref, withDefaults } from 'vue';

import uuidv1 from 'uuid/v1';
import { useRouter } from 'vue-router';

const { showLoader } = useLoader();
const productsStore = useProducts();
const productDetails = useComponent<Required>();
const scanShelf = useComponent<void, Shelf>();
const router = useRouter();

interface Required {
  count: number;
  shelf_id: Shelf['shelf_id'];
  product_id: Product['product_id'];
}

interface CreateWriteoffProps {
  taskId: string;
  system: string;
  type: string;
}
const props = withDefaults(defineProps<CreateWriteoffProps>(), {
  taskId: '',
  system: '',
  type: '',
});

const required = ref<Required[]>([]);

const suggestMenuConfig = computed<(required: Required) => MenuItemConfig[]>(() => {
  return required => {
    const menuConfig: MenuItemConfig[] = [];
    const deleteBtn: MenuItemConfig = {
      buttonText: $gettext('Удалить из списка сканирования'),
      onClick: () => {
        deleteActiveItem(required);
      },
      dataTest: 'delete-item btn',
    };
    menuConfig.push(deleteBtn);

    return menuConfig;
  };
});

const text = computed<string>(() => $gettext('Списание по заявке %{doc_number}', { doc_number: props.taskId }));

const checkTask = async (): Promise<void> => {
  const { closeLoader } = showLoader();
  try {
    await api.repair_task.load({ external_id: props.taskId, source: props.system });
  } catch (e: any) {
    // обработка ошибок. показать ошибку сети или сказать, что данной таски нет
    logger.error(e, { page: 'create-writeoff-by-order', method: 'checkTask' });
    if (e?.isAxiosError && e?.response) {
      // ошибка получения заявки
      const response = (e as AxiosError<BaseError>)['response'];
      if (response?.status) {
        Notifications.modal({
          title: $gettext('Произошла ошибка при получении заявки на списание'),
          text: `status: ${response.status},
              code: ${response.data.code},
              message: ${response.data.message}`,
        });
        required.value = [];
        router.back();
        return;
      }
    }
    // ошибка сети
    Notifications.error.micro($gettext('Произошла ошибка при получении заявки на списание. Попробуйте еще раз'));
    router.back();
    return;
  } finally {
    closeLoader();
  }
};

const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    const available = await productsStore.fetchAvailable(product.product_id);
    closeLoader();
    if (!available || available.length === 0) {
      Notifications.error.micro($gettext('Продукт отсутствует на складе'));
      return true;
    }
    const hasInRequired = required.value.filter(r => r.product_id === product.product_id);
    let shelf_id: string = '';
    let count: number = 0;
    if (hasInRequired.length) {
      //  продукт добавлен, нужно попросить отсканировать полку
      const shelf = await scanShelf.asyncShow();
      if (!shelf) return true;
      shelf_id = shelf.shelf_id;
      const hasInRequiredOnShelf = hasInRequired.find(r => r.shelf_id === shelf_id);
      if (hasInRequiredOnShelf) {
        count = hasInRequiredOnShelf.count;
      }
    }
    await productDetails.asyncShow({
      product_id: product.product_id,
      shelf_id,
      count,
    });
    return true;
  } catch (error) {
    closeLoader();
    Notifications.error.micro($gettext('Отсканирован неверный баркод'));
    return true;
  }
});

const deleteActiveItem = (req: Required): void => {
  required.value = required.value.filter(i => {
    return i.product_id !== req.product_id || i.shelf_id !== req.shelf_id;
  });
};

const getErrorMessage = (message: string): string => {
  switch (message) {
    case 'Repair task from another store':
      return $gettext('Списание по заявке другого склада');
    case 'Repair task is not in progress':
      return $gettext('Списание по заявке еще не в работе');
    case 'Task in Lavkach is not of type REPAIR':
      return $gettext('Тип заявки - не списание');
    case 'Repair task not found':
      return $gettext('Заявка на списание не найдена');
    case 'No products':
      return $gettext('Нельзя создать списание без продуктов');
    default:
      return $gettext('Произошла ошибка при создании списания');
  }
};

const createWriteoff = async (): Promise<void> => {
  needBarcodeRequest.value = false;
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Завершить задание?'),
    text: $gettext('Все расходники должны быть  просканированы и корректно подсчитаны.'),
  });
  if (!confirmed) {
    needBarcodeRequest.value = true;
    return;
  }
  const { closeLoader } = showLoader();
  try {
    await api.order.assets_writeoff({
      external_id: uuidv1(),
      repair_task_external_id: props.taskId,
      repair_task_source: props.system,
      required: required.value,
    });
    closeLoader();
    Notifications.success.micro($gettext('Запрос на создание списания отправлен'));
    required.value = [];
    router.back();
  } catch (e: any) {
    closeLoader();
    if (e?.isAxiosError && e?.response) {
      const { data } = e.response;
      Notifications.modal({
        title: $gettext('Ошибка создания списания'),
        text: getErrorMessage(data.details?.message || data.message),
      });
    }
  }
};

const addProductToRequired = (req: Required): void => {
  const hasInRequired = required.value.findIndex(r => r.product_id === req.product_id && r.shelf_id === req.shelf_id);
  if (hasInRequired !== -1) {
    required.value[hasInRequired] = req;
  } else {
    required.value.push(req);
  }
};

const onCloseClick = async () => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Остановить списание по заявке?'),
    text: $gettext('Весь прогресс будет утерян.'),
  });
  if (confirmed) {
    router.back();
  }
};

checkTask();
</script>
