import { api } from '@/fsd/data/api/api.service';
import Product from '@/models/Product';
import ProductByBarcode from '@/models/ProductByBarcode';
import productQueue from '@/services/queue/product-queue';
import { ProductsResponse } from '@/services/response';
import { createCacheValidator } from '@/store/utils/common';
import { logger } from '@/temp/plugins/logs';
import { AvailableProduct } from '@/types/product';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

const cv = createCacheValidator();

interface Availables {
  has_stocks: boolean;
  found: AvailableProduct[];
}
export const useProducts = defineStore('products', {
  state() {
    return {
      products: new Map<Product['product_id'], Product>(),
      availableProducts: new Map<Product['product_id'], Availables>(),
    };
  },
  getters: {
    productById: state => (id?: string) => {
      if (!id) return undefined;
      useProducts().checkNeedReload(id);
      return state.products.get(id);
    },
    productsByBarcode:
      state =>
      (barcode: string): Product[] => {
        const products: Product[] = [];
        for (const [id, item] of state.products) {
          if (item.barcode.includes(barcode)) {
            useProducts().checkNeedReload(id);
            products.push(item);
          }
        }
        return products;
      },
    productByBarcode: state => barcode => {
      for (const [id, p] of state.products) {
        if (p.barcode.includes(barcode)) {
          useProducts().checkNeedReload(id);
          return p;
        }
      }
    },
    availableByProductId: state => (product_id?: Product['product_id']) => {
      if (!product_id) return undefined;
      return state.availableProducts.get(product_id)?.found;
    },
    hasStocks: state => (product_id: Product['product_id']) => {
      return state.availableProducts.get(product_id)?.has_stocks;
    },
  },
  actions: {
    set(products: Product[]) {
      products.forEach(product => {
        this.products.set(product.product_id, product);
        cv.set(product.product_id);
      });
    },
    clear() {
      this.products.clear();
      this.availableProducts.clear();
      cv.clear();
    },
    async fetchAvailable(product_id: string): Promise<AvailableProduct[] | undefined> {
      if (!product_id) return;
      try {
        const { data } = await api.stock.available({ product_id });
        this.availableProducts.set(product_id, { found: data.found, has_stocks: data.has_stocks });
        return data.found;
      } catch (e) {
        logger.error(e, { method: 'available', type: 'api', source: 'products.ts' });
      }
    },
    async getProductsByIds(ids: string[]) {
      ids = ids.filter(id => !!id);
      ids = [...new Set(ids)];
      if (ids.length === 0) return;
      const findProducts: Product[] = [];
      const productsToFetchIds: string[] = [];
      ids.forEach(id => {
        const product = this.productById(id);
        if (product) findProducts.push(product);
        else productsToFetchIds.push(id);
      });
      if (productsToFetchIds.length > 0) {
        try {
          const products = (await productQueue.loadMany(productsToFetchIds)).filter(Product.isProduct);
          if (products) {
            findProducts.push(...products);
          }
        } catch (e) {
          logger.error(e, { method: 'addToQueue', type: 'api', source: 'products.ts' });
        }
      }
      return findProducts;
    },
    async getProductById(id: string): Promise<Product | undefined> {
      const product = this.productById(id);
      if (product) return product;
      try {
        return await productQueue.load(id);
      } catch (e) {
        logger.error(e, { method: 'addToQueue', type: 'api', source: 'products.ts' });
        return undefined;
      }
    },
    async getProductByBarcode(barcode: string): Promise<Product> {
      let product: Product | undefined = this.productByBarcode(barcode);
      if (!product) {
        const { data } = await api.barcode({ barcode });
        const found = data.found[0];
        if (!found || !ProductByBarcode.isProductByBarcode(found)) throw new Error('wrong barcode');
        if (!product) {
          try {
            await productQueue.load(found.product_id);
          } catch (e) {
            logger.error(e, { method: 'addToQueue', type: 'api', source: 'products.ts' });
          }
        }
        product = this.productById(found.product_id);
      }
      if (!product) {
        throw Error('Продукт не был загружен');
      }
      return product;
    },
    async loadProducts({
      ids,
      config,
    }: {
      ids: string[];
      config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<ProductsResponse>> {
      const response = await api.products({ ids }, config);
      this.set(response.data.products);
      return response;
    },
    checkNeedReload(id: string) {
      if (cv.check(id) === false) {
        productQueue.load(id);
      }
    },
    checkValidCache(id: string) {
      return cv.check(id);
    },
  },
});
