<template>
  <ClientOrderSuggestCardContainer :data-test="`product card ${index}`">
    <div
      class="rounded-3xl h-full w-full flex flex-col"
      :class="{
        'border-2 border-red-normal': suggest?.type === 'box2shelf' && suggest?.status === 'request',
      }"
    >
      <!--Шапка-->
      <div class="flex pt-5">
        <div class="flex flex-col flex-1 items-center">
          <Body3 class="mb-2" color="text-secondary">
            {{ $gettext('Полка') }}
          </Body3>
          <Title4 color="text-primary" class="font-medium text-center" data-test="product shelf title">
            {{ shelf }}
          </Title4>
        </div>
        <OrderProductCardCount :index="index" :collected="collected.count" :suggest="suggest" :problem="problem" />
      </div>
      <!--Картинка-->
      <div class="relative flex flex-[2_1_0] m-8 justify-center h-48">
        <div class="w-full h-full">
          <ImageWrapper :img-src="suggest.imageSrc" :grayscale="isBox2Shelf">
            <div class="h-12 top-1/2 -translate-y-2/4 z-10 w-full absolute">
              <Badge v-if="isCompleted" class="-rotate-12" color="lime-toxic" data-test="order suggest-completed badge">
                {{ !order.isCanceled ? $gettext('Собран') : $gettext('Отсканировано') }}
              </Badge>
              <Badge
                v-if="order.isOrderPaused"
                class="-rotate-12"
                color="red-normal"
                data-test="order product-card pause-badge"
              >
                {{ $gettext('Обрабатывается оператором') }}
              </Badge>
              <Badge
                v-if="isProblem && !order.isOrderPaused"
                class="-rotate-12"
                color="red-normal"
                data-test="order product-card pause-badge"
              >
                {{ $gettext('Ждите обработки оператором') }}
              </Badge>
              <OrderProductCardBadge v-if="isBox2Shelf && suggest?.status === 'request'">
                <template #text>
                  {{
                    suggest.shelf.type === 'trash'
                      ? $gettext('Положите товар на полку Списание')
                      : $gettext('Верните этот товар обратно')
                  }}
                </template>
              </OrderProductCardBadge>
              <OrderProductCardBadge v-if="suggest.isBlocked" data-test="product block badge">
                <template #text>
                  {{ $gettext('Продукт ещё не готов') }}
                </template>
              </OrderProductCardBadge>
              <OrderProductCardBadge v-if="suggest.isCanceled" data-test="product canceled badge">
                <template #text>
                  {{ $gettext('Продукт отменён') }}
                </template>
              </OrderProductCardBadge>
            </div>
          </ImageWrapper>
        </div>
      </div>
      <!--Тэги-->
      <div class="flex justify-center">
        <PackageClassBadge v-if="item?.packageClass" :package_class="item.packageClass" class="mb-2" />
        <UltimaBadge v-if="item?.isUltimaParcel" class="mb-2" />
        <FragileBadge v-if="product?.fragile" class="mb-2" />
        <SpecialPackageBadge v-if="product?.special_package" class="mb-2" />
        <WeightBadge v-if="product?.isTrueWeight" :plu_code="product.plu_code" class="mb-2" />
      </div>
      <!--Название-->
      <div class="flex flex-auto justify-center items-center py-4 px-5">
        <Body2 color="text-primary" class="font-medium text-center" data-test="product title">
          {{ title }}
        </Body2>
      </div>
      <TrueMarkFlag v-if="isTrueMark" class="m-4" />
    </div>

    <template #footer>
      <LayoutFooter v-if="needCollectButton && !expSusanin">
        <UiButton
          :is-disabled="isDisabled"
          background-color="secondary"
          data-test="collect low_weight"
          @click="collect"
        >
          <template #icon>
            <Check />
            {{ $gettext('Собрано') }}
          </template>
        </UiButton>
      </LayoutFooter>
    </template>
  </ClientOrderSuggestCardContainer>
</template>

<script lang="ts">
import ClientOrderSuggestCardContainer from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardContainer.vue';
import OrderProductCardBadge from '@/fsd/widgets/order/RequiredCard/order-product-card-badge.vue';
import OrderProductCardCount from '@/fsd/widgets/order/RequiredCard/order-product-card-count.vue';
import TrueMarkFlag from '@/fsd/widgets/order/RequiredCard/true-mark-flag.vue';
import { checkBarcodeBySuggest } from '@/fsd/widgets/order/RequiredCard/useCollectOrder';
import {
  finishCheckTrueMark,
  finishRequestBarcode,
  sendRequestBarcodeTime,
  startAssembling,
  startRequestBarcode,
} from '@/fsd/widgets/order/rum/useMeasureAssemblingPosition';
import requestBarcode from '@/mixins/requestBarcode';
import Item from '@/models/Item';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { Problem } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { modalNotifyId } from '@/temp/constants/common';
import Check from '@/temp/icons/check.vue';
import { TrueMark } from '@/temp/services/true-mark';
import Badge from '@/temp/ui/badge/badge.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import Body3 from '@/ui/common/typo/body-3.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import FragileBadge from '@/ui/order/badges/fragile-badge.vue';
import PackageClassBadge from '@/ui/order/badges/package-class-badge.vue';
import SpecialPackageBadge from '@/ui/order/badges/special-package-badge.vue';
import UltimaBadge from '@/ui/order/badges/ultima-badge.vue';
import WeightBadge from '@/ui/order/badges/weight-badge.vue';
import { needLessWeightModal } from '@/utils/modals';
import { notify } from '@kyvg/vue3-notification';
import { PropType, defineComponent, watch } from 'vue';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
  collected: {
    count: number;
    weight: number;
    barcodes: string[];
  };
  true_mark?: string;
}

export default defineComponent({
  name: 'ClientOrderSuggestCardClassic',
  components: {
    ClientOrderSuggestCardContainer,
    OrderProductCardBadge,
    OrderProductCardCount,
    UltimaBadge,
    SpecialPackageBadge,
    WeightBadge,
    Body3,
    Body2,
    Title4,
    FragileBadge,
    PackageClassBadge,
    Badge,
    TrueMarkFlag,
    ImageWrapper,
    LayoutFooter,
    UiButton,
    Check,
  },
  mixins: [requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    problem: {
      type: Object as PropType<Problem>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: () => {
        return {} as Suggest;
      },
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['finish', 'unblock'],
  setup(props) {
    const { showLoader } = useLoader();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const shelvesStore = useShelves();
    const ordersStore = useOrders();
    const userStore = useUser();

    watch(
      () => props.isVisible,
      val => {
        if (val) startAssembling();
      },
      { immediate: true },
    );

    return { showLoader, itemsStore, productsStore, shelvesStore, userStore, ordersStore };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: false,
      collected: {
        count: 0,
        weight: 0,
        barcodes: [],
      },
      true_mark: undefined,
    };
  },
  computed: {
    order(): OrderOrder {
      return this.ordersStore.orderById(this.order_id) as OrderOrder;
    },
    expSusanin(): boolean {
      return this.userStore.experimentByName('exp_susanin');
    },
    title(): string {
      if (!this.suggest) {
        return this.product?.title || '';
      }
      if (this.suggest.vars?.mode === 'item') {
        return this.item?.title || '';
      } else {
        return this.product?.title || '';
      }
    },
    shelf(): string {
      if (!this.suggest.shelf_id) {
        return '—';
      }
      const shelf = this.shelvesStore.shelfById(this.suggest.shelf_id);
      if (shelf) {
        return shelf.title;
      }
      return '—';
    },
    isCompleted(): boolean {
      return !this.isProblem && (this.suggest.status === 'done' || this.suggest.count === this.collected.count);
    },
    isBox2Shelf(): boolean {
      return this.suggest.type === 'box2shelf';
    },
    product(): Product | undefined {
      return this.productsStore.productById(this.suggest.product_id);
    },
    item(): Item | undefined {
      return this.itemsStore.itemById(this.suggest.product_id);
    },
    isProblem(): boolean {
      return !!this.problem;
    },
    isTrueMark(): boolean {
      return this.suggest?.conditions?.need_true_mark;
    },
    isDisabled(): boolean {
      return !this.collected.count;
    },
    needCollectButton(): boolean | undefined {
      return (
        this.suggest.product &&
        this.suggest.product.isTrueWeight &&
        this.suggest.status !== 'done' &&
        this.suggest.type === 'shelf2box'
      );
    },
  },
  watch: {
    isVisible: {
      handler(visible) {
        if (this.suggest?.status !== 'request' || !visible || this.isProblem) {
          this.uiStateNeedBarcodeRequest = false;
        } else {
          this.uiStateNeedBarcodeRequest = true;
        }
        /* логика для будущей фичи: возможность "дособрать" весовой товар !*
        if (this.suggest?.status !== 'request' && this.product?.type_accounting === 'true_weight' && visible) {
          this.uiStateNeedBarcodeRequest = true;
        }*/
      },
      immediate: true,
    },
    // сделал разблокировку скана таким образом,
    // тк это показалось самым безопасным костылём
    'suggest.status'(newValue: SuggestStatusEnum, oldValue: SuggestStatusEnum) {
      if (newValue === 'request' && oldValue === 'blocked' && this.isVisible) {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
  },
  mounted() {
    if (!this.title && this.suggest) {
      this.suggest.loadProduct();
    }
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      if (this.isProblem) return true;
      if (this.order.isOrderPaused) {
        await this.$notification.modal({
          title: this.$gettext('Обрабатывается оператором'),
          text: this.$gettext('Данный заказ обрабатывается оператором, пожалуйста подождите'),
        });
        return true;
      }
      if (!this.uiStateNeedBarcodeRequest) return false;
      notify.close(modalNotifyId);
      // обработка сканов посылки!
      if (this.suggest.vars?.mode === 'item') {
        if (this.item!.barcode.includes(barcode)) {
          this.updateCollected(barcode);
          //  Проверить собранное и добавить или закрыть саджест и отменить запрос баркода
        } else {
          this.$notification.error.micro(this.$gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
        }
        return true;
      }

      startRequestBarcode(this.suggest?.suggest_id);
      const valid = await checkBarcodeBySuggest(barcode, this.suggest);
      finishRequestBarcode(this.suggest?.suggest_id);
      if (!valid) {
        return true;
      }
      return await this.handleProduct(barcode);
    },
    async handleProduct(barcode: string): Promise<boolean> {
      // проверяем марку, если это чз
      if (this.isTrueMark) {
        if (this.suggest?.type === 'shelf2box') {
          const { closeLoader } = this.showLoader();
          try {
            const response = await TrueMark.check({ true_mark: barcode, order_id: this.order_id });
            finishCheckTrueMark(response?.duration || 0);
            this.true_mark = barcode;
          } catch {
            return true;
          } finally {
            closeLoader();
          }
        } else {
          this.true_mark = barcode;
        }
      }
      // Настоящий весовой продукт
      if (this.product?.isTrueWeight) {
        const weight = Product.weightFromBarcode(barcode);
        if (!weight) {
          this.$notification.error.micro(this.$gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
          return true;
        }
        if (!this.suggest.max_count) {
          if (weight !== this.suggest.count) {
            this.$notification.error.micro(this.$gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
            return true;
          }
          this.collected.barcodes.push(barcode);
          this.collected.count = weight;
          this.finishSuggest();
          return false;
        }
        if (this.collected.count + weight > this.suggest.max_count) {
          const delta = this.collected.count + weight - this.suggest.max_count!;
          needLessWeightModal(delta.toString());
          return true;
        }
        this.collected.count += weight;
        this.collected.barcodes.push(barcode);
        if (this.collected.count < this.suggest.min_count!) {
          return true;
        }
        this.finishSuggest();
        return false;
      }
      // весовой продукт
      if (this.product?.type_accounting === 'weight') {
        const weight = Product.weightFromBarcode(barcode);
        return this.updateCollected(barcode, weight);
      }
      // обычный продукт
      return this.updateCollected(barcode);
    },
    updateCollected(barcode: string, weight: number = 0): boolean {
      this.collected.count += 1;
      this.collected.barcodes.push(barcode);
      if (weight) this.collected.weight = this.collected.weight + weight;
      if (this.product?.isTrueWeight) {
        this.finishSuggest();
        return false;
      }
      if (this.suggest.count === this.collected.count) {
        this.finishSuggest();
        return false;
      }
      return true;
    },
    clearCollectedAndRequestBarcodeOnProblem(): void {
      this.collected.count = 0;
      this.collected.weight = 0;
      this.collected.barcodes = [];
      this.uiStateNeedBarcodeRequest = true;
    },

    finishSuggest() {
      sendRequestBarcodeTime(this.suggest?.suggest_id);
      this.$emit('finish', {
        suggest: this.suggest,
        weight: this.collected.weight,
        true_mark: this.true_mark,
        barcodes: this.collected.barcodes,
        count: this.collected.count,
      });
    },
    async collect(): Promise<void> {
      const confirm = await this.$notification.confirmCenter({
        title: this.$gettext('Весь товар собран?'),
        text: this.$gettext('Убедитесь, что этого товара больше нет.'),
        ok: this.$gettext('Да, весь'),
        decline: this.$gettext('Отмена'),
      });
      if (!confirm) return;
      this.finishSuggest();
    },
  },
});
</script>
