import { getBox2ShelfSuggests, getSuggestsByStatusAndType } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { $gettext } from '@/temp/plugins/gettext';

export default class ShipmentOrder extends BaseOrder {
  get requiredActionsCount() {
    if (this.target === 'canceled') {
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.request, SuggestTypeEnum.box2shelf).length;
    }
    return super.requiredActionsCount;
  }

  get allActionsCount() {
    if (this.target === 'canceled') {
      return getBox2ShelfSuggests(this.suggests).length;
    }
    return super.allActionsCount;
  }

  get isMarketShipment() {
    return Boolean(this.vars.market_courier);
  }

  get title() {
    if (this.isMarketShipment) return $gettext('Возврат Маркета');
    return this.contractor;
  }
}
