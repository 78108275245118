<template>
  <div>
    <div class="settings-row" @click="settingContainer.show">
      <Typography>
        {{ $gettext('Тема') }}
      </Typography>
      <div class="flex items-center">
        <Typography color="secondary-text" margin="0 10px 0 0">
          {{ currentTheme }}
        </Typography>
        <div>
          <IconChevron color="text-primary" />
        </div>
      </div>
    </div>
    <SettingContainer v-if="settingContainer.visible.value" :caption="$gettext('Тема')" @close="settingContainer.hide">
      <CustomSelect :options="selectOptions" :value="theme" @change="onChange" />
    </SettingContainer>
  </div>
</template>

<script lang="ts">
import { useComponent } from '@/hooks/useComponent';
import { useUser } from '@/store/modules/user';
import { themes } from '@/temp/constants/translations';
import IconChevron from '@/temp/icons/icon-chevron.vue';
import CustomSelect, { SelectOption } from '@/ui/common/fields/select.vue';
import Typography from '@/ui/common/typography.vue';
import SettingContainer from '@/ui/home/settings/options/setting-container.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IconChevron,
    Typography,
    SettingContainer,
    CustomSelect,
  },
  setup() {
    const userStore = useUser();
    const settingContainer = useComponent();

    return { userStore, settingContainer };
  },
  computed: {
    selectOptions(): SelectOption[] {
      return Object.keys(themes).reduce((acc, value) => {
        acc.push({
          name: themes[value],
          value: value,
        });
        return acc;
      }, [] as SelectOption[]);
    },
    currentTheme(): string {
      return themes[this.userStore.theme];
    },
    theme(): string {
      return this.userStore.theme;
    },
  },
  methods: {
    async onChange(value): Promise<void> {
      this.userStore.setTheme(value);
      this.$notification.success.micro(this.$gettext('Тема сохранена'));
    },
  },
});
</script>

<style lang="scss" scoped>
.settings-row {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
}
</style>
