import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { AxiosError } from 'axios';

export default (e: AxiosError) => {
  const { response } = e as AxiosError;

  let reason = '';
  switch (true) {
    case !response:
      reason = $gettext('Что-то с сетью, попробуйте ещё раз');
      break;
    case response!.status === 423:
      reason = $gettext('Документ недоступен, попробуйте ещё раз');
      break;
    default:
      reason = `code: ${response!.status} \nmessage: ${response!.data?.message || ''}`;
      break;
  }
  return Notifications.confirmCenter({
    title: $gettext('Не удалось завершить задание'),
    text: reason,
    ok: $gettext('Повторить'),
    decline: $gettext('Отмена'),
    timeout: 5,
    dataTest: 'error-retry-confirm',
  });
};
