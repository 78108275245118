<template>
  <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="12" rx="6" fill="white" />
    <path
      d="M5.10333 7.74384L8.88875 3.95841C9.11496 3.7322 9.48171 3.7322 9.70792 3.95841C9.93412 4.18461 9.93412 4.55137 9.70792 4.77757L5.10333 9.38216L2.65958 6.93842C2.43338 6.71221 2.43338 6.34546 2.65958 6.11925C2.88579 5.89305 3.25254 5.89305 3.47874 6.11925L5.10333 7.74384Z"
      fill="#5AC31A"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCompleted',
};
</script>

<style scoped></style>
