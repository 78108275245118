import { UserLanguageEnum } from '@/models/User';

export const languages = {
  [UserLanguageEnum.ru_RU]: 'Русский',
  [UserLanguageEnum.en_EN]: 'English',
  [UserLanguageEnum.he_HE]: 'שפה עברית',
  [UserLanguageEnum.fr_FR]: 'Français',
  [UserLanguageEnum.ar_SA]: 'العربية',
  [UserLanguageEnum.uz_UZ]: 'Оʻzbek tili',
  [UserLanguageEnum.tr_TR]: 'Türkçe',
  [UserLanguageEnum.az_AZ]: 'Azərbaycan dili',
  [UserLanguageEnum.es_419]: 'Español',
  [UserLanguageEnum.kk_KZ]: 'Қазақ тілі',
};
