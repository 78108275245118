<template>
  <Blur @click="$emit('scanned')">
    <div
      data-test="overlay scan"
      class="shadow-2xl min-h-[283px] mt-5 p-4 flex bg-main rounded-t-lg box-border flex-col pointer-events-auto"
    >
      <div class="pb-4 flex items-center border-b-[0.5px] border-day-line">
        <Body1 class="font-medium">{{ text || $gettext('Сканируй полку на которой лежит товар') }}</Body1>
      </div>

      <div class="pt-4 text-center mx-auto">
        <img src="../overlay-scan/img/barcode.svg" alt="" />
      </div>
    </div>
  </Blur>
</template>

<script setup lang="ts">
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Shelf from '@/models/Shelf';
import { useShelves } from '@/store/modules/shelves';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Blur from '@/temp/ui/blur/blur.vue';
import Body1 from '@/ui/common/typo/body-1.vue';
import { defineEmits, defineProps } from 'vue';

interface ScanShelfProps {
  text?: string;
}

defineProps<ScanShelfProps>();

const emits = defineEmits<{ (e: 'scanned', shelf?: Shelf): void }>();

useRequestBarcode(async barcode => {
  try {
    const shelf = await useShelves().getShelfByBarcode(barcode);
    emits('scanned', shelf);
    return false;
  } catch (error) {
    console.error(error);
    Notifications.error.micro($gettext('Не найден штрихкод'));
    return true;
  }
});
</script>
