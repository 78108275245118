<template>
  <Overlay>
    <Layout class="pointer-events-auto">
      <template #header>
        <Bar
          :text="$gettext('Заказ %{number}', { number: order.attr.doc_number })"
          :caption="$gettext('Собранные товары')"
          :icons="{ left: 'back', right: 'menu' }"
          @close-click="$emit('close')"
        />
      </template>
      <ProductCard
        v-for="suggest in suggests"
        :key="suggest.suggest_id"
        class="product"
        :order-id="order.order_id"
        :product-id="suggest.product_id"
      >
        <template #info>
          <Row
            data-test="suggest card count row"
            :label="$gettext('Количество')"
            :value="$gettext('%{count} шт.', { count: String(suggest.count) })"
          />
        </template>
      </ProductCard>
      <template #footer>
        <LayoutFooter>
          <UiButton data-test="order close-basket-btn" background-color="secondary" @click="$emit('close')">
            {{ $gettext('Назад') }}
          </UiButton>
        </LayoutFooter>
      </template>
    </Layout>
  </Overlay>
</template>

<script lang="ts">
import Suggest from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import ProductCard from '@/ui/common/product-card/product-card.vue';
import Row from '@/ui/common/suggest-card/row.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Overlay,
    LayoutFooter,
    UiButton,
    Layout,
    Bar,
    ProductCard,
    Row,
  },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    order() {
      const order_id = this.suggests[0].order_id;
      return useOrders().orderById(order_id);
    },
  },
});
</script>
