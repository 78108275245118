<template>
  <Layout>
    <div class="flex flex-col items-justify">
      <div class="m-2">
        <img class="w-full" src="@/fsd/widgets/order/finish-order/rover-card/img/close.png" />
      </div>
      <title4 class="font-bold m-4">
        {{ $gettext('Заберите заказ и закройте крышку') }}
      </title4>
      <div class="rover-container my-2 mx-4 py-3 px-4 rounded-2xl bg-blue-light flex-1 flex flex-row justify-between">
        <IconRoverMini class="w-12" />
        <div class="flex flex-col mr-12 justify-center">
          <body2 class="text-center font-medium">
            {{ roverName }}
          </body2>
          <caption1 class="text-center font-medium">
            {{ $gettext('Найдите его на улице') }}
          </caption1>
        </div>
      </div>
      <body2 class="m-4">
        {{ $gettext('Чтобы закрыть крышку нужно нажать на кнопку close, или закрыть крышку вручную.') }}
      </body2>
    </div>
    <template #footer>
      <LayoutFooter>
        <UiButton data-test="refund-rover complete btn" @click="closeHatch">
          <span class="font-medium">{{ $gettext('Забрал заказ') }}</span>
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts">
import IconRoverMini from '@/fsd/shared/icons/rover/icon-rover-mini.vue';
import BaseOrder from '@/models/orders/BaseOrder';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'RoverHatch',
  components: {
    IconRoverMini,
    LayoutFooter,
    Layout,
    UiButton,
  },
  props: {
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
  },
  emits: ['closeHatch'],
  computed: {
    roverName(): string {
      return this.$gettext('Ровер %{name}', { name: this.order?.courier?.name || '' });
    },
  },
  methods: {
    closeHatch(): void {
      this.$emit('closeHatch');
    },
  },
});
</script>
