import Product from '@/models/Product';

/**
 * Получить количество полных квантов.
 * @constructor
 * @param {Product} product - Необходимый продукт.
 * @param {number} count - Текущее количество.
 */
export const getClosedPackage = (product: Product, count: number | undefined): number => {
  if (!count) return 0;
  return ~~(count / product.quants);
};
