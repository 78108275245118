import { BadgeOnImgConfig } from '@/fsd/shared/ui/badgeOnImg';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';

export const getImgBadge = (suggest: Suggest): BadgeOnImgConfig[] => {
  const result: BadgeOnImgConfig[] = [];
  if (suggest.status === SuggestStatusEnum.done) {
    if (suggest.isFull || suggest.isOver) {
      result.push({ color: 'lime-toxic', text: $gettext('Собран').toUpperCase() });
    } else if (suggest.isPartial) {
      result.push({ color: 'orange-normal', text: $gettext('Мало').toUpperCase() });
    } else {
      result.push({ color: 'day-error', text: $gettext('Нет').toUpperCase() });
    }
  }
  return result;
};
