import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { needLessWeightModal } from '@/utils/modals';

export enum ReturnWeightEnum {
  OVER = 'over',
  MORE = 'more',
  FULL = 'full',
  ERROR = 'error',
}

export const checkWeight = async (suggest: Suggest, barcodes: string[]): Promise<ReturnWeightEnum> => {
  if (!suggest.product?.isTrueWeight) return ReturnWeightEnum.ERROR;

  const weight = Product.weightFromBarcode(barcodes);

  if (!weight) {
    Notifications.error.micro(
      $gettext('Отсканирован неверный штрихкод %{barcode}', { barcode: barcodes[barcodes.length - 1] }),
    );
    return ReturnWeightEnum.ERROR;
  }

  if (suggest.max_count && weight > suggest.max_count) {
    const delta = weight - suggest.max_count;
    await needLessWeightModal(delta.toString());
    return ReturnWeightEnum.OVER;
  }
  if (suggest.min_count && weight < suggest.min_count) {
    return ReturnWeightEnum.MORE;
  }
  return ReturnWeightEnum.FULL;
};
