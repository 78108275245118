<template>
  <Caption2
    :color="color"
    class="font-medium uppercase px-1.5 py-1 rounded-lg"
    :class="`${backgroundColor}/${bgOpacity}`"
  >
    <slot />
  </Caption2>
</template>

<script>
import Caption2 from '@/ui/common/typo/caption-2.vue';

export default {
  name: 'Tag',
  components: {
    Caption2,
  },
  props: {
    bgColor: {
      type: String,
      default: 'white',
    },
    color: {
      type: String,
      default: 'black',
    },
    bgOpacity: {
      type: String,
      default: '100',
    },
  },
  computed: {
    backgroundColor() {
      return 'bg-' + this.bgColor;
    },
  },
};
</script>
