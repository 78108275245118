<template>
  <PageLayout :order_id="order_id">
    <OrderRetailFinish v-if="finish.visible.value" :order="order" />
    <OrderRetailChangeProduct
      v-else-if="change_product.visible.value && change_product.props.value"
      :suggest_id="change_product.props.value"
      :order="order"
      :collected="collected[change_product.props.value]?.length || 0"
      @close="change_product.hide"
      @remove-suggest="removeSuggest"
      @open-suggest-menu="openSuggestMenu"
      @suggest-done="finishSuggest"
      @change-done="s => change_product.executeAndHide(finishSuggest, s, getBarcodes(s))"
    />
    <OrderRetailSuggestDetails
      v-else-if="details.visible.value && details.props.value"
      :suggest_id="details.props.value"
      :order="order"
      :collected="collected[details.props.value] || []"
      @close="details.hide"
      @remove-suggest="removeSuggest"
      @open-suggest-menu="openSuggestMenu"
      @remove-products="removeProducts"
      @suggest-done="confirmPartSuggest"
      @change-products="change_product.asyncShow"
      @update-collected="updateCollected"
    />
    <OrderRetailMoreProduct
      v-else-if="more_product.visible.value && more_product.props.value"
      :data="more_product.props.value"
      :order="order"
      @close="more_product.hide"
      @suggest-done="finishSuggest"
    />
    <OrderRetailConfirmList
      v-else-if="confirm_list.visible.value"
      :order="order"
      @open-details="details.asyncShow"
      @finish-suggest="confirmPartSuggest"
      @remove-suggest="removeSuggest"
      @open-suggest-menu="openSuggestMenu"
      @open-more-product="more_product.asyncShow"
      @change-product="change_product.asyncShow"
      @to-finish="finish.show"
    />
    <OrderRetailResetSuggests
      v-else-if="reset_suggests.visible.value"
      :order="order"
      :suggest_id="reset_suggests.props.value"
      @back="reset_suggests.hide"
      @to-finish="finish.show"
    />
    <OrderRetailClientDetails
      v-else-if="client_details.visible.value"
      :order="order"
      @back="client_details.hide"
      @to-package="toPackageFromClient"
      @to-confirm="confirm_list.show"
    />
    <OrderRetailSuggestsList
      v-else
      :collected="collected"
      :order="order"
      @open-details="details.asyncShow"
      @finish-suggest="confirmPartSuggest"
      @remove-suggest="removeSuggest"
      @open-suggest-menu="openSuggestMenu"
      @update-collected="updateCollected"
      @change-product="change_product.asyncShow"
      @to-confirm="client_details.show"
      @to-finish="finish.show"
    />
  </PageLayout>
</template>

<script setup lang="ts">
import { useConfirmAssembledProducts } from '@/fsd/data/order/useConfirmAssembledProducts';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { prepareBarcodes, useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import {
  MoreProductSuggestProps,
  OrderRetailRemoveProducts,
  OrderRetailSuggestModal,
  ReturnWeightEnum,
  checkWeight,
} from '@/fsd/features/suggest';
import {
  OrderRetailChangeProduct,
  OrderRetailClientDetails,
  OrderRetailConfirmList,
  OrderRetailFinish,
  OrderRetailMoreProduct,
  OrderRetailResetSuggests,
  OrderRetailSuggestDetails,
  OrderRetailSuggestsList,
} from '@/fsd/widgets/order-retail';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import Product from '@/models/Product';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useOrders } from '@/store/modules/orders';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import { createPayloadForSuggestError } from '@/utils/createPayloadForSuggestError';
import { AxiosError } from 'axios';
import { computed, defineProps, markRaw, onMounted, ref, watch } from 'vue';

interface OrderRetailProps {
  order_id: string;
}

const details = useComponent<Suggest['suggest_id']>();
const client_details = useComponent();
const reset_suggests = useComponent<Suggest['suggest_id'] | void>();
const more_product = useComponent<MoreProductSuggestProps>();
const change_product = useComponent<Suggest['suggest_id']>();
const confirm_list = useComponent();
const finish = useComponent();
const ordersStore = useOrders();
const { confirmAssembledProducts } = useConfirmAssembledProducts();

const collected = ref<Record<string, string[]>>({});

const props = defineProps<OrderRetailProps>();

useHandleOrderStatus(props.order_id);

const order = computed<OrderOrderRetail>(() => ordersStore.orderById(props.order_id) as OrderOrderRetail);

const packedSuggests = computed<Suggest[]>(() => order.value?.packedSuggests || []);

const isOrderCanPayment = computed<boolean>(
  () => !!order.value?.vars?.fulfilled_conditions?.confirm_assembled_products,
);

watch(
  isOrderCanPayment,
  val => {
    if (val) {
      finish.show();
    }
  },
  { immediate: true },
);

watch(
  packedSuggests,
  suggests => {
    suggests.forEach(suggest => {
      if (suggest.isFull && suggest.fullChildrenSuggest.length && reset_suggests.props.value !== suggest.suggest_id) {
        reset_suggests.asyncShow(suggest.suggest_id);
      }
    });
  },
  { immediate: true },
);

onMounted(() => {
  const suggests = order.value.nonClosedChildSuggests;
  if (suggests.length && suggests[0].parentSuggest) {
    change_product.asyncShow(suggests[0].parentSuggest.suggest_id);
  }
});

const getBarcodes = (s: Suggest): string[] => {
  return collected.value[s.suggest_id] && collected.value[s.suggest_id].length
    ? collected.value[s.suggest_id]
    : s.barcodes;
};

const clearCollected = (suggest_id: Suggest['suggest_id']) => {
  collected.value[suggest_id] = [];
};

const finishSuggest = async (suggest: Suggest, bcodes?: string[]): Promise<any> => {
  const barcodes = bcodes || collected.value[suggest.suggest_id] || [];
  try {
    if (suggest.product?.isTrueWeight) {
      const count = Product.weightFromBarcode(barcodes);
      await useShelf2Box(props.order_id, {
        suggest_id: suggest.suggest_id,
        barcodes: prepareBarcodes(barcodes),
        count,
      });
    } else {
      await useShelf2Box(props.order_id, {
        suggest_id: suggest.suggest_id,
        barcodes: prepareBarcodes(barcodes),
        count: barcodes.length,
      });
    }

    if (!bcodes) clearCollected(suggest.suggest_id);
  } catch (error: any) {
    logger.error(error, { method: 'finishSuggest', type: 'order_retail' });
    if (!error.isAxiosError) {
      return;
    }
    const { response } = error as AxiosError;
    const majorErrorConfig = createPayloadForSuggestError({
      response,
      onClose: () => {
        collected.value[suggest.suggest_id] = [];
      },
      onRepeat: () => {
        finishSuggest(suggest);
      },
      operation: $gettext('Взять с полки'),
      // @ts-expect-error pinia
      products: [{ label: suggest.product.title, count: suggest.count!.toString() }],
    });
    Notifications.error.major.suggest(majorErrorConfig);
  }
};

const updateBarcodesInCollected = (suggest: Suggest, barcodes: string[]) => {
  collected.value[suggest.suggest_id]
    ? collected.value[suggest.suggest_id].push(...barcodes)
    : (collected.value[suggest.suggest_id] = barcodes);
};

const updateCollected = async (suggest: Suggest, barcodes: string[]) => {
  if (suggest.status === SuggestStatusEnum.done) {
    await finishSuggest(suggest, [...suggest.barcodes, ...barcodes]);
    return;
  }
  if (suggest.product?.isTrueWeight) {
    const allWeightBarcodes = collected.value[suggest.suggest_id]
      ? [...collected.value[suggest.suggest_id], ...barcodes]
      : barcodes;
    const typeWeight = await checkWeight(suggest, allWeightBarcodes);
    if (typeWeight === ReturnWeightEnum.MORE) {
      updateBarcodesInCollected(suggest, barcodes);
    }
    if (typeWeight === ReturnWeightEnum.FULL) {
      updateBarcodesInCollected(suggest, barcodes);
      await finishSuggest(suggest);
    }
    return;
  }
  updateBarcodesInCollected(suggest, barcodes);
  if (suggest.count && collected.value[suggest.suggest_id].length >= suggest.count) {
    await finishSuggest(suggest);
  }
};

const removeSuggest = async (suggest: Suggest): Promise<any> => {
  const confirm = await Notifications.confirmCenter({
    title: $gettext('Удалить товар из заказа?'),
    text: suggest.isChildSuggest
      ? $gettext('Вы уверены, что хотите удалить товар на замену?')
      : $gettext('Вы уверены, что не можете собрать его в заказ?'),
    decline: $gettext('Отмена'),
    ok: $gettext('Удалить'),
  });

  if (confirm) {
    clearCollected(suggest.suggest_id);
    await finishSuggest(suggest);
  }
};

const removeProducts = async (suggest: Suggest) => {
  const maxNum: number = collected.value[suggest.suggest_id]?.length || suggest.result_count || 0;
  const removeData: any = await Notifications.customComponentModal({
    component: markRaw(OrderRetailRemoveProducts),
    suggest: suggest,
    maxNum,
  });

  if (removeData && removeData.removeNum && removeData.barcode) {
    const { removeNum, barcode } = removeData;
    collected.value[suggest.suggest_id] = new Array(maxNum - removeNum).fill(barcode);
    if (suggest.status === SuggestStatusEnum.done) await finishSuggest(suggest);
  }
};

const confirmPartSuggest = async (suggest: Suggest): Promise<void> => {
  const confirm = await Notifications.confirmCenter({
    title: $gettext('Весь товар собран?'),
    text: order.value.isDeleteItemsMethod
      ? $gettext('Убедитесь, что этого товара больше нет.')
      : $gettext('Убедитесь, что этого товара больше нет, а также нет подходящих для него замен.'),
    decline: $gettext('Отмена'),
    ok: $gettext('Собран'),
  });

  if (confirm) {
    await finishSuggest(suggest);
  }
};

const openSuggestMenu = async (suggest: Suggest) => {
  const needDo = await Notifications.customComponentModal({
    component: markRaw(OrderRetailSuggestModal),
    suggest: suggest,
    collected: collected.value[suggest.suggest_id]?.length || suggest.result_count,
  });

  if (needDo === 'removeSuggest') await removeSuggest(suggest);
  if (needDo === 'removeProducts') await removeProducts(suggest);
  if (needDo === 'cancelScans') clearCollected(suggest.suggest_id);
};

const toPackageFromClient = async () => {
  if (order.value.needResetSuggests.length) {
    await reset_suggests.asyncShow();
  } else if (order.value.forFinishPacking.length) {
    await confirmAssembledProducts(order.value);
  } else {
    finish.show();
  }
};
</script>
