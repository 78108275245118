<template>
  <UiButton
    class="flex-0 mr-2"
    data-test="order basket-btn"
    background-color="secondary"
    is-icon
    @click="productList.show()"
  >
    <Basket />
  </UiButton>
  <Teleport to="#root">
    <template v-if="productList.visible.value">
      <ProductListExpStenGustafThulin v-if="exp_sten_gustaf_thulin" :suggests="suggests" @close="productList.hide()" />
      <ProductList v-else :suggests="suggests" @close="productList.hide()" />
    </template>
  </Teleport>
</template>

<script lang="ts">
import Basket from '@/fsd/shared/icons/basket/basket.vue';
import ProductListExpStenGustafThulin from '@/fsd/widgets/product-list/product-list-exp-sten-gustaf-thulin/ProductListExpStenGustafThulin.vue';
import ProductList from '@/fsd/widgets/product-list/product-list.vue';
import { useComponent } from '@/hooks/useComponent';
import Suggest from '@/models/Suggest';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { ProductListExpStenGustafThulin, ProductList, Basket, UiButton },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
  },
  emits: ['close'],

  setup() {
    const productList = useComponent<void>();
    const exp_sten_gustaf_thulin = useUser().experimentByName(experiments.exp_sten_gustaf_thulin);
    return { productList, exp_sten_gustaf_thulin };
  },
});
</script>
